import { ActionType, createAction } from 'typesafe-actions'

// TODO: разобраться с any
export const loadTasks = createAction('@tasks/LOAD_TASKS')<any>()
export const onLoadTasksSuccess = createAction('@tasks/ON_LOAD_TASKS_SUCCESS')<any>()
export const onLoadTasksError = createAction('@tasks/ON_LOAD_TASKS_ERROR')<any>()
export const setTasksQuery = createAction('@tasks/SET_TASKS_QUERY')<any>()
export const loadTask = createAction('@tasks/LOAD_TASKS_TASK')<any>()
export const onLoadTaskSuccess = createAction('@tasks/ON_LOAD_TASKS_TASK_SUCCESS')<any>()
export const onLoadTaskError = createAction('@tasks/ON_LOAD_TASKS_TASK_ERROR')<any>()

export type TasksActions =
	| ActionType<typeof loadTasks>
	| ActionType<typeof onLoadTasksSuccess>
	| ActionType<typeof onLoadTasksError>
	| ActionType<typeof loadTask>
	| ActionType<typeof onLoadTaskSuccess>
	| ActionType<typeof onLoadTaskError>
	| ActionType<typeof setTasksQuery>
