import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	changeContract,
	changeContractError,
	changeContractSuccess,
	createContract,
	createContractError,
	createContractSuccess,
	createPaymentArea,
	createPaymentAreaError,
	createPaymentAreaSuccess,
	deleteContract,
	deleteContractError,
	deleteContractSuccess,
	deletePaymentArea,
	deletePaymentAreaError,
	deletePaymentAreaSuccess,
	loadContract,
	loadContractError,
	loadContractSuccess,
} from '../actions/contract'
import { showAlert } from '../actions/alert'
import { updateCreateProcessesId } from '../actions/processes'
import { history } from '../reducers'
import { AxiosError } from 'axios'

const loadContractEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadContract)),
		switchMap(({ payload }: ActionType<typeof loadContract>) => {
			return from(deps.contractDataProvider.loadContracts(payload)).pipe(
				switchMap((contracts) => {
					return of(loadContractSuccess(contracts))
				}),
				catchError((err) => {
					return of(loadContractError(err))
				}),
			)
		}),
	)
}

const createContractEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createContract)),
		switchMap(({ payload }: ActionType<typeof createContract>) => {
			return from(deps.contractDataProvider.createContract(payload)).pipe(
				switchMap((response) => {
					const { id } = payload
					const stateNote = response.state_notes.filter((note) => note.content_type === 'data/contract-report')
					if (stateNote.length) {
						const reportNote = stateNote[0]
						window.open(reportNote.content.pdf_url, '_blank')
						window.open(reportNote.content.docx_url, '_blank')
					}

					history.push('/')

					return of(
						createContractSuccess(response),
						loadContract(payload),
						updateCreateProcessesId(store$.value.processes.createProcessesId.filter((el) => el !== id)),
					)
				}),
				catchError((err) => {
					const { id } = payload
					return of(
						createContractError(err),
						showAlert({ title: 'Ошибка!', text: JSON.stringify(err?.response?.data), type: 'error' }),
						updateCreateProcessesId(store$.value.processes.createProcessesId.filter((el) => el !== id)),
					)
				}),
			)
		}),
	)
}

const changeContractEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(changeContract)),
		switchMap(({ payload }: ActionType<typeof changeContract>) => {
			return from(deps.contractDataProvider.changeContract(payload)).pipe(
				switchMap((contracts) => {
					return of(
						changeContractSuccess(contracts),
						loadContract({ student: payload.student, parent: payload.counterparty }),
					)
				}),
				catchError((err) => {
					return of(changeContractError(err))
				}),
			)
		}),
	)
}

const deleteContractEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(deleteContract)),
		switchMap(({ payload }: ActionType<typeof deleteContract>) => {
			return from(deps.contractDataProvider.deleteContract(payload)).pipe(
				switchMap(() => {
					return of(
						deleteContractSuccess(payload),
						showAlert({ title: 'Успех!', text: 'Контракт успешно удален!', type: 'success' }),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						deleteContractError(err),
						showAlert({
							title: 'Ошибка!',
							text: 'Контракт не был удален! ' + err.response?.data?.message,
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const createPaymentAreaEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createPaymentArea)),
		switchMap(({ payload }: ActionType<typeof createPaymentArea>) => {
			return from(deps.contractDataProvider.createPaymentAreas(payload)).pipe(
				switchMap((contracts) => {
					return of(
						createPaymentAreaSuccess(contracts),
						loadContract({ student: payload.student_id, parent: payload.parent }),
						showAlert({ title: 'Успех!', text: 'Платежный участок успешно создан!', type: 'success' }),
					)
				}),
				catchError((err) => {
					return of(createPaymentAreaError(err))
				}),
			)
		}),
	)
}

const deletePaymentAreaEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(deletePaymentArea)),
		switchMap(({ payload }: ActionType<typeof deletePaymentArea>) => {
			return from(deps.contractDataProvider.deletePaymentArea(payload)).pipe(
				switchMap((contracts) => {
					return of(
						deletePaymentAreaSuccess(contracts),
						loadContract({ student: payload.student, parent: payload.parent }),
						showAlert({ title: 'Успех!', text: 'Платежный участок успешно удалён!', type: 'success' }),
					)
				}),
				catchError((err) => {
					return of(deletePaymentAreaError(err))
				}),
			)
		}),
	)
}

export const contractEpics = combineEpics(
	loadContractEpic,
	changeContractEpic,
	createPaymentAreaEpic,
	deletePaymentAreaEpic,
	createContractEpic,
	deleteContractEpic,
)
