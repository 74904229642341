import { FuncEpic } from '../../common/types'
import { combineEpics, ofType } from 'redux-observable'
import { ActionType, getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	createStudentOnLmsError,
	createStudentOnLmsStart,
	createStudentOnLmsSuccess,
	fetchExportCardsCsvError,
	fetchExportCardsCsvStart,
	fetchExportCardsCsvSuccess,
	loadStudentsCardsError,
	loadStudentsCardsStart,
	loadStudentsCardsSuccess,
	loadStudentsError,
	loadStudentsStart,
	loadStudentsSuccess,
	loadStudentProfileStart,
	updateStudentError,
	updateStudentStart,
	updateStudentSuccess,
	loadStudentProfileSuccess,
	loadStudentProfileError,
	blockStudentAuthStart,
	blockStudentAuthSuccess,
	blockStudentAuthError,
	blockStudentOlStart,
	blockStudentOlSuccess,
	blockStudentOlError,
} from '../actions/students'
import { showAlert } from '../actions/alert'

const loadStudentEpic: FuncEpic = (action$, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentProfileStart)),
		switchMap(({ payload }: ActionType<typeof loadStudentProfileStart>) => {
			return from(deps.studentsDataProvider.loadStudentProfile(payload.externalId)).pipe(
				switchMap((response) => {
					return of(loadStudentProfileSuccess(response))
				}),
				catchError((err) => {
					return of(loadStudentProfileError(err))
				}),
			)
		}),
	)
}

const blockStudentAuthEpic: FuncEpic = (action$, store$, deps) => {
	return action$.pipe(
		ofType(getType(blockStudentAuthStart)),
		switchMap(({ payload }: ActionType<typeof blockStudentAuthStart>) => {
			return from(deps.studentsDataProvider.blockStudentAuth(payload.externalId, payload.isBlocked)).pipe(
				switchMap((response) => {
					return of(
						blockStudentAuthSuccess(response),
						loadStudentProfileStart({ externalId: payload.externalId }),
						showAlert({
							title: 'Успех!',
							text: `Ученик успешно ${payload.isBlocked ? 'заблокирован' : 'разблокирован'}!`,
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(
						blockStudentAuthError(err),
						showAlert({
							title: 'Ошибка!',
							text: `Ученик не был ${payload.isBlocked ? 'заблокирован' : 'разблокирован'}`,
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const blockStudentOlEpic: FuncEpic = (action$, store$, deps) => {
	return action$.pipe(
		ofType(getType(blockStudentOlStart)),
		switchMap(({ payload }: ActionType<typeof blockStudentOlStart>) => {
			return from(deps.studentsDataProvider.blockStudentOl(payload.externalId, payload.isOlBlocked, payload.id)).pipe(
				switchMap((response) => {
					return of(
						blockStudentOlSuccess(response),
						loadStudentProfileStart({ externalId: payload.externalId }),
						showAlert({
							title: 'Успех!',
							text: `Ученик успешно ${payload.isOlBlocked ? 'заблокирован' : 'разблокирован'}!`,
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(
						blockStudentOlError(err),
						showAlert({
							title: 'Ошибка!',
							text: `Ученик не был ${payload.isOlBlocked ? 'заблокирован' : 'разблокирован'}`,
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const updateStudentEpic: FuncEpic = (action$, store$, deps) => {
	return action$.pipe(
		ofType(getType(updateStudentStart)),
		switchMap(({ payload }: ActionType<typeof updateStudentStart>) => {
			return from(deps.studentsDataProvider.updateStudent(payload)).pipe(
				switchMap((response) => {
					return of(
						updateStudentSuccess(response),
						loadStudentProfileStart({ externalId: response.user.externalId }),
						showAlert({ title: 'Успех!', text: 'Ученик успешно обновлен!', type: 'success' }),
					)
				}),
				catchError((err) => {
					return of(
						updateStudentError(err),
						showAlert({
							title: 'Ошибка!',
							text: 'Студент не обновлен',
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const loadStudentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentsStart)),
		switchMap(({ payload }: ActionType<typeof loadStudentsStart>) => {
			return from(deps.studentsDataProvider.loadStudents(payload)).pipe(
				switchMap((students) => {
					return of(loadStudentsSuccess(students))
				}),
				catchError((err) => {
					return of(loadStudentsError(err))
				}),
			)
		}),
	)
}

const loadStudentsCardsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentsCardsStart)),
		switchMap(({ payload }: ActionType<typeof loadStudentsCardsStart>) => {
			return from(deps.studentsDataProvider.loadStudentsCards(payload)).pipe(
				switchMap((students) => {
					return of(loadStudentsCardsSuccess(students))
				}),
				catchError((err) => {
					return of(loadStudentsCardsError(err))
				}),
			)
		}),
	)
}

const fetchExportCardsCsvEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(fetchExportCardsCsvStart)),
		switchMap(({ payload }: ActionType<typeof fetchExportCardsCsvStart>) => {
			return from(deps.studentsDataProvider.fetchExportCardsCsv(payload)).pipe(
				switchMap((response) => {
					const blob = new Blob(['\uFEFF' + response], { type: 'text/csv; charset=utf-8' })
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'students.csv'
					link.click()
					return of(fetchExportCardsCsvSuccess(response))
				}),
				catchError((err) => {
					return of(fetchExportCardsCsvError(err))
				}),
			)
		}),
	)
}

const createStudentOnLmsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createStudentOnLmsStart)),
		switchMap(({ payload }: ActionType<typeof createStudentOnLmsStart>) => {
			return from(deps.studentsDataProvider.createStudentOnLms(payload)).pipe(
				switchMap((res) => {
					return of(
						createStudentOnLmsSuccess(res),
						showAlert({ title: 'Успех!', text: 'Ученик успешно создан на lms!', type: 'success' }),
					)
				}),
				catchError((err) => {
					return of(
						createStudentOnLmsError(err),
						showAlert({
							title: 'Ошибка!',
							text:
                                err?.response?.data?.message === 'Validation error'
                                	? Object.entries(err?.response?.data?.extra?.fields?.extra?.fields)
                                		.reduce((prev, [_, value]) => prev.concat(value), [])
                                		.join(' ')
                                	: 'Ученик не был создан.',
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

export const studentsEpics = combineEpics(
	loadStudentEpic,
	updateStudentEpic,
	loadStudentsEpic,
	loadStudentsCardsEpic,
	createStudentOnLmsEpic,
	fetchExportCardsCsvEpic,
	blockStudentAuthEpic,
	blockStudentOlEpic,
)
