import React, { useCallback } from 'react'
import { Button, Col, Popover, Row } from 'react-bootstrap'

interface CutUser {
    last_name: string;
    first_name: string;
    patronymic: string;
}

interface CreateContractWithInProgressProps {
    id: number;
    student_id: number;
    parent_id: number;
    education_cost: number;
    parent: CutUser;
    student: CutUser;
}

export const CreateContractWithInProgress: React.FC<CreateContractWithInProgressProps> = ({
	id,
	student_id,
	parent_id,
	education_cost,
	parent,
	student,
}) => {
	const getButtonHref = useCallback(
		(action: string) => `/process/${action}/?process=${action}&id=${id}&student_id=${student_id}&parent_id=${parent_id}&education_cost=${education_cost}&parent_name=${parent.last_name} ${parent.first_name} ${parent.patronymic}&student_name=${student.last_name} ${student.first_name} ${student.patronymic}`,
		[education_cost, id, parent.first_name, parent.last_name, parent.patronymic, parent_id, student.first_name, student.last_name, student.patronymic, student_id],
	)

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Имеется договор в процессе заполнения</Popover.Title>
			</Row>
			<Row className="mb-3">
				<Col md={12}>
					<Button
						variant="primary"
						href={getButtonHref('change_contract')}
					>
                        Продолжить заполнение
					</Button>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col md={12}>
					<Button variant="primary" href={getButtonHref('create_contract')}>
                        Создать договор
					</Button>
				</Col>
			</Row>
		</Col>
	)
}
