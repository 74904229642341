import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import { processesSearchChange } from '../../../store/actions/search'
import { ProcessesSearchType } from '../../../store/types/search'
import { Col, Popover, Row } from 'react-bootstrap'
import { setCurrentPage } from '../../../store/actions/processes'

export const SchoolFilter: React.FC = () => {
	const dispatch = useDispatch()
	const constants = useSelector((state: State) => state.constants.constants)
	const { schoolSearch } = useSelector((state: State) => state.search)

	const schoolSearchChange = useCallback(
		(value) => {
			dispatch(setCurrentPage(1))
			dispatch(
				processesSearchChange({
					value: value,
					type: ProcessesSearchType.SCHOOL,
				}),
			)
		},
		[dispatch],
	)

	const handleFilterItemClick = useCallback(
		(school) => {
			schoolSearchChange(
				schoolSearch.find((storeSchool) => storeSchool === school)
					? schoolSearch.filter((storeSchool) => storeSchool !== school)
					: [...schoolSearch, school],
			)
		},
		[schoolSearch, schoolSearchChange],
	)

	return (
		<Col className="p-3 school-filter__wrapper" md={12} xl={12}>
			<Row className="mb-3 justify-content-between school-filter__header">
				<Popover.Title as="h5">Выберите школу</Popover.Title>

				<div onClick={schoolSearchChange.bind(null, [])} className={'searchItem-clear'}>
                    &times;
				</div>
			</Row>

			<Row style={{ maxHeight: 200, overflowY: 'scroll' }}>
				<Col md={12}>
					{Object.keys(constants.SCHOOLS).map((id) => {
						const isChecked = schoolSearch.includes(Number(id))

						return (
							<div style={{ paddingTop: 5, paddingBottom: 5 }} className="d-flex" key={`school-${id}`}>
								<div style={{ marginRight: 10 }}>
									<input
										id={id}
										type="checkbox"
										name="school"
										onChange={() => handleFilterItemClick(Number(id))}
										checked={isChecked}
									/>
								</div>
								<label htmlFor={id}>{constants.SCHOOLS[id]}</label>
							</div>
						)
					})}
				</Col>
			</Row>
		</Col>
	)
}
