import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	cancelActionByType,
	createGroupAction,
	loadActionsList,
	loadActionsTypeList,
	loadActionsTypeNestedTable,
	loadGroupActions,
	onCancelActionByTypeError,
	onCancelActionByTypeSuccess,
	onCreateGroupActionError,
	onCreateGroupActionSuccess,
	onLoadActionsListError,
	onLoadActionsListSuccess,
	onLoadActionsTypeListError,
	onLoadActionsTypeListSuccess,
	onLoadActionsTypeNestedTableError,
	onLoadActionsTypeNestedTableSuccess,
	onLoadGroupActionsError,
	onLoadGroupActionsSuccess,
	setGroupActionsQuery,
} from '@store/actions/groupActions'
import { GroupActionsState } from '@store/types/groupActions'
import { persistReducer } from 'redux-persist'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const groupActionsInitialState: GroupActionsState = {
	history: {
		list: [],
		fetchStatus: FetchStatus.INITIAL,
		pagination: null,
		errors: [],
	},
	planned: {
		list: [],
		fetchStatus: FetchStatus.INITIAL,
		pagination: null,
		errors: [],
	},
	active: {
		list: [],
		fetchStatus: FetchStatus.INITIAL,
		pagination: null,
		errors: [],
	},
	create: {
		errors: [],
		pagination: null,
		query: {},
		list: [],
		fetchStatus: FetchStatus.INITIAL,
		createModal: {
			list: [],
			fetchStatus: FetchStatus.INITIAL,
			createFetchStatus: FetchStatus.INITIAL,
		},
	},
}

export const groupActionsReducer = (state = groupActionsInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadGroupActions): {
			return {
				...state,
				create: {
					...state.create,
					fetchStatus: FetchStatus.FETCHING,
				},
			}
		}
		case getType(onLoadGroupActionsSuccess): {
			return {
				...state,
				create: {
					...state.create,
					list: action.payload.results,
					pagination: action.payload.pagination,
					fetchStatus: FetchStatus.FETCHED,
				},
			}
		}
		case getType(onLoadGroupActionsError): {
			return {
				...state,
				create: {
					...state.create,
					fetchStatus: FetchStatus.ERROR,
				},
			}
		}
		case getType(loadActionsList): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						fetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onLoadActionsListSuccess): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						list: action.payload,
						fetchStatus: FetchStatus.FETCHED,
					},
				},
			}
		}
		case getType(onLoadActionsListError): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						fetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(createGroupAction): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						createFetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onCreateGroupActionSuccess): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						createFetchStatus: FetchStatus.FETCHED,
					},
				},
			}
		}
		case getType(onCreateGroupActionError): {
			return {
				...state,
				create: {
					...state.create,
					createModal: {
						...state.create.createModal,
						createFetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(setGroupActionsQuery): {
			return {
				...state,
				create: {
					...state.create,
					query: action.payload,
				},
			}
		}

		case getType(loadActionsTypeList): {
			const type = action.payload.status
			const isMore = action.payload.isMore
			return {
				...state,
				[type]: {
					...state?.[type],
					fetchStatus: isMore ? FetchStatus.FETCHING_MORE : FetchStatus.FETCHING,
				},
			}
		}
		case getType(onLoadActionsTypeListSuccess): {
			const type = action.payload.payload.status
			const isMore = action.payload.payload.isMore
			const response = action.payload.response
			return {
				...state,
				[type]: {
					...state?.[type],
					list: isMore ? [...state[type].list, ...response.results] : response.results,
					pagination: response.pagination,
					fetchStatus: FetchStatus.FETCHED,
				},
			}
		}
		case getType(onLoadActionsTypeListError): {
			const type = action.payload.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					fetchStatus: FetchStatus.FETCHED,
				},
			}
		}

		case getType(loadActionsTypeNestedTable): {
			const id = action.payload.id
			const type = action.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type]?.tables,
						[id]: {
							...state?.[type]?.tables?.[id],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadActionsTypeNestedTableSuccess): {
			const id = action.payload.payload.id
			const type = action.payload.payload.status
			const response = action.payload.response
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type].tables,
						[id]: {
							...state?.[type].tables[id],
							list: response.results,
							pagination: response.pagination,
							fetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}
		case getType(onLoadActionsTypeNestedTableError): {
			const id = action.payload.payload.id
			const type = action.payload.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type].tables,
						[id]: {
							...state?.[type].tables[id],
							fetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}
		case getType(cancelActionByType): {
			const id = action.payload.groupAction
			const type = action.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type]?.tables,
						[id]: {
							...state?.[type]?.tables?.[id],
							cancelFetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onCancelActionByTypeSuccess): {
			const id = action.payload.payload.groupAction
			const type = action.payload.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type].tables,
						[id]: {
							...state?.[type].tables[id],
							cancelFetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}
		case getType(onCancelActionByTypeError): {
			const id = action.payload.payload.groupAction
			const type = action.payload.payload.status
			return {
				...state,
				[type]: {
					...state?.[type],
					tables: {
						...state?.[type].tables,
						[id]: {
							...state?.[type].tables[id],
							cancelFetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedGroupActionsReducer: any = persistReducer(
	{
		key: 'AdminPanel::groupActionsReducer',
		storage: indexedDBStore,
		whitelist: ['create'],
		version: 1,
	},
	groupActionsReducer,
)
