import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap,} from 'rxjs/operators'
import {ActionType, getType} from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	loadChat,
	loadChatError,
	loadChatSuccess,
	loadUnreadMessages,
	loadUnreadMessagesError,
	loadUnreadMessagesSuccess,
	sendChatMessageError,
	sendChatMessageStart,
	sendChatMessageSuccess
} from '../actions/chat'

const loadChatEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadChat)),
		switchMap(({ payload: { student_id } } : ActionType<typeof loadChat>) => {
			return from(deps.chatDataProvider.loadChat(student_id)).pipe(
				switchMap(courses => {
					return of(loadChatSuccess(courses), loadUnreadMessages())
				}),
				catchError(err => {
					return of(loadChatError(err))
				})
			)
		})
	)
}

const loadUnreadMessagesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadUnreadMessages)),
		switchMap(({} : ActionType<typeof loadUnreadMessages>) => {
			return from(deps.chatDataProvider.loadUnreadMessages()).pipe(
				switchMap(courses => {
					return of(loadUnreadMessagesSuccess(courses))
				}),
				catchError(err => {
					return of(loadUnreadMessagesError(err))
				})
			)
		})
	)
}


const sendMessageEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(sendChatMessageStart)),
		switchMap(({ payload } : ActionType<typeof sendChatMessageStart>) => {
			return from(deps.chatDataProvider.sendMessage(payload)).pipe(
				switchMap(courses => {
					return of(sendChatMessageSuccess(courses), loadChat({ student_id: payload.student }))
				}),
				catchError(err => {
					return of(sendChatMessageError(err))
				})
			)
		})
	)
}

export const chatEpics = combineEpics(loadChatEpic, sendMessageEpic, loadUnreadMessagesEpic)
