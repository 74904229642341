import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	loadTasks,
	onLoadTasksError,
	onLoadTasksSuccess,
	loadTask,
	onLoadTaskError,
	onLoadTaskSuccess,
	setTasksQuery,
} from '@store/actions/tasks'
import { TasksState } from '@store/types/tasks'
import { persistReducer } from 'redux-persist'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const tasksInitialState: TasksState = {
	fetchStatus: FetchStatus.INITIAL,
	list: [],
	errors: [],
	query: {},
	tasksData: {},
}

export const tasksReducer = (state = tasksInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadTasks): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadTasksSuccess): {
			return {
				...state,
				list: action.payload.list.results,
				pagination: action.payload.list.pagination,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadTasksError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(loadTask): {
			return {
				...state,
				tasksData: {
					...state.tasksData,
					[action.payload]: {
						...state.tasksData[action.payload],
						fetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onLoadTaskSuccess): {
			return {
				...state,
				tasksData: {
					...state.tasksData,
					[action.payload.id]: {
						...state.tasksData[action.payload.id],
						data: action.payload.data,
						fetchStatus: FetchStatus.FETCHED,
					},
				},
			}
		}
		case getType(onLoadTaskError): {
			return {
				...state,
				tasksData: {
					...state.tasksData,
					[action.payload.id]: {
						...state.tasksData[action.payload.id],
						fetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(setTasksQuery): {
			return {
				...state,
				query: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedTasksReducer: any = persistReducer(
	{
		key: 'AdminPanel::tasks',
		storage: indexedDBStore,
		whitelist: ['query'],
		version: 1,
	},
	tasksReducer,
)