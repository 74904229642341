import React, { FC, useEffect } from 'react'
import { MainTable } from '@components/processes-monitoring'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { loadParentCardTabProcesses } from '@store/actions/parentCard'
import { FetchStatus } from '@/common/types'
import { ILoadTabProcessParams, TUserCardPopupTabProcesses } from './UserCardPopupTabProcesses.types'
import { loadStudentCardTabProcesses } from '@store/actions/studentCard'

/**
 * @description - Карточка родителя - таб "Процессы"
 * @param props
 * @constructor
 */
const UserCardPopupTabProcesses: FC<TUserCardPopupTabProcesses> = (props) => {
	const { id, userType } = props

	const dispatch = useDispatch()

	const processes = useSelector((state: State) => state?.[`${userType}CardNew`]?.cards?.[id]?.processes)
	const processesData = processes?.data
	const isLoading = processes?.fetchStatus === FetchStatus.FETCHING

	useEffect(() => {
		const normalizedParams: ILoadTabProcessParams = {
			currentPage: 1,
			limit: 999,
		}
		if (userType === 'parent') {
			normalizedParams.filters = [{ field: 'user__id', option: 'is', value: id }]
			dispatch(loadParentCardTabProcesses(normalizedParams))
		}
		if (userType === 'student') {
			normalizedParams.filters = [{ field: 'student__id', option: 'is', value: id }]
			dispatch(loadStudentCardTabProcesses(normalizedParams))
		}
	}, [dispatch, id, userType])

	return <MainTable isLoading={isLoading} list={processesData?.results} isRenderParent={false} pagination={null} />
}

export default UserCardPopupTabProcesses
