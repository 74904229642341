import React, { useEffect } from 'react'
import { AuthFooter, AuthHeader, AuthLoginCard, AuthorizationLayout } from '@frontend-modules/elements'
import { useHistory } from 'react-router-dom'
import { AuthBanner } from '@components/auth'
import LogoWithTextSvg from '@assets/brand/logo-text.svg'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '@store/actions/login'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { parseQueryString } from '@/helpers/parseQueryString'
import { setAccessToken, setRefreshToken } from '@/common/token'

/**
 * @description - страница входа в ЕРП
 * @constructor
 */
const Login = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const fetchStatus = useSelector((state: State) => state.login.fetchStatus)

	const isLoading = fetchStatus === FetchStatus.FETCHING

	const onLogin = (params) => {
		const { username, password } = params
		dispatch(login({ password, username }))
	}

	useEffect(() => {
		const { token } = parseQueryString(history.location.search)
		if (token) {
			setAccessToken(token)
			setRefreshToken(token)
			history.push('/')
		}
	}, [history])

	return (
		<AuthorizationLayout banner={<AuthBanner />}>
			<AuthHeader logoWithText={<img src={LogoWithTextSvg} alt={'logo'} />} />
			<AuthLoginCard router={history} isLoading={isLoading} authUserAction={onLogin} />
			<AuthFooter router={history} />
		</AuthorizationLayout>
	)
}

export default Login
