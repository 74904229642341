import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '../store'
import { SpecialistState } from '../types/specialist'
import { loadSpecialistError, loadSpecialistStart, loadSpecialistSuccess } from '../actions/specialist'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	specialist: null,
}

export const reducer = (state: SpecialistState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadSpecialistStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadSpecialistSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				specialist: action.payload,
			}
		}

		case getType(loadSpecialistError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const specialist: any = persistReducer(
	{
		key: 'AdminPanel::specialist',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
