import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'
import {MessageState} from '../types/message'
import {sendMessage, sendMessageError, sendMessageSuccess} from '../actions/message'

const initialState = {
	fetchStatus: FetchStatus.FETCHED,
	error: null,
}

export const message = (state: MessageState = initialState, action: Action) => {

	switch (action.type) {
	case getType(sendMessage): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(sendMessageSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
		}
	}

	case getType(sendMessageError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
