import { BaseRestDataProvider } from './BaseRestDataProvider'
import { AxiosResponse } from 'axios'
import { ssoPath } from '@/api/path/ssoPath'
import { ssoAxios } from './axios-init'

export class AuthDataProvider extends BaseRestDataProvider {
	login = (username: string, password: string) => {
		return ssoAxios
			.post(`${this.host}/${ssoPath.token}`, {
				username,
				password,
			})
			.then(
				(
					res: AxiosResponse<{
						access: string
						refresh: string
						links: Array<{ key: string; url: string; title: string }>
					}>,
				) => {
					return res?.data
				},
			)
	}
}
