import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadConstants } from '../../../store/actions/constants'
import { loadContract } from '../../../store/actions/contract'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'

export const SkipGenerateContract: React.FC<any> = ({ id, parent_id, student_id }) => {
	const dispatch = useDispatch()
	const { fetchStatus, contracts } = useSelector((store: State) => store.contract)
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((store: State) => store.constants)
	const [selectedContract, setSelectedContract] = useState<any>(null)
	const [signChoise, setSignChoise] = useState(null)

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	const signChoiseChangeCallback = useCallback(
		(value) => {
			setSignChoise(value)
		},
		[signChoise],
	)

	useEffect(() => {
		if (constantsFetchStatus !== FetchStatus.FETCHED) {
			dispatch(loadConstants())
		}
	}, [])

	useEffect(() => {
		dispatch(
			loadContract({
				student: student_id,
				parent: parent_id,
				process_id: id,
			}),
		)
	}, [id, parent_id, student_id])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				process: 'skip_generate_contract',
				id: id,
				parameters: {
					contract: selectedContract?.value,
					signed_with: signChoise?.value,
				},
			}),
		)
	}, [dispatch, id, selectedContract?.value, signChoise?.value])

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Пропустить шаг генерации</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						{fetchStatus === FetchStatus.FETCHED ? (
							<div className="d-flex mb-3">
								<Form.Group>
									<Form.Label>Выберите договор</Form.Label>
									<Select
										placeholder="Выберите договор"
										options={contracts
											.filter((contract) => !contract?.signed_with)
											.map((contract) => ({
												label: contract.number,
												value: contract.id,
											}))}
										value={selectedContract}
										onChange={selectedContractChangeCallback}
									/>
								</Form.Group>
							</div>
						) : (
							<div className="mb-3">
								<Skeleton height={50} />
							</div>
						)}

						{selectedContract ? (
							constants.CONTRACT_SIGN_CHOICES ? (
								<div className="d-flex mb-3">
									<Form.Group>
										<Form.Label>Выберите вариант подписания договора</Form.Label>
										<Select
											placeholder="Выберите вариант"
											options={Object.keys(constants.CONTRACT_SIGN_CHOICES).map((key) => {
												const label = constants.CONTRACT_SIGN_CHOICES[key]

												return {
													label: label,
													value: key,
												}
											})}
											value={signChoise}
											onChange={signChoiseChangeCallback}
										/>
									</Form.Group>
								</div>
							) : (
								<div className="mb-3">
									<Skeleton height={50} />
								</div>
							)
						) : null}
						<Button
							onClick={submitButtonPressHandler}
							disabled={!selectedContract && !signChoise}
							variant="primary"
						>
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
