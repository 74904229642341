import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import { loadDecree, onLoadDecreeSuccess, onLoadDecreeError, setDecreeQuery } from '@store/actions/decree'
import { DecreeState } from '@store/types/decree'
import { persistReducer } from 'redux-persist'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const decreeInitialState: DecreeState = {
	fetchStatus: FetchStatus.INITIAL,
	list: [],
	errors: [],
	query: {},
}

export const decreeReducer = (state = decreeInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadDecree): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadDecreeSuccess): {
			return {
				...state,
				list: action.payload.results,
				pagination: action.payload.pagination,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadDecreeError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(setDecreeQuery): {
			return {
				...state,
				query: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedDecreeReducer: any = persistReducer(
	{
		key: 'AdminPanel::decree',
		storage: indexedDBStore,
		whitelist: ['query'],
		version: 1,
	},
	decreeReducer,
)
