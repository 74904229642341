import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { loadCourses } from '../../../store/actions/courses'
import { TrialDaysState } from '../../../store/types/trialDays'
import { CoursesState } from '../../../store/types/courses'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'

interface IRecordTrialProps {
	trialDays: TrialDaysState
	courses: CoursesState
	id: number
	loadCourses: (payload: any) => {}
	distributeOnCourse: (payload: any) => {}
	student_id: number
}

export const Component: React.FC<IRecordTrialProps> = ({
	trialDays,
	courses,
	loadCourses,
	distributeOnCourse,
	id,
	student_id,
}) => {
	const [selectedCourse, setSelectedCourse] = useState<null | string>(null)
	const [errors, setErrors] = useState<Array<string>>([])

	useEffect(() => {
		loadCourses(student_id)
	}, [student_id])

	const selectedCourseChangeCallback = useCallback(
		(event: any) => {
			setSelectedCourse(event.target.value)
		},
		[selectedCourse],
	)

	const submitButtonPressHandler = () => {
		const newErrState = []

		if (!selectedCourse) {
			newErrState.push('selectedCourse')
		}

		if (newErrState.length === 0) {
			distributeOnCourse({
				process: 'distribute_on_course_final',
				id: id,
				parameters: {
					course: selectedCourse,
				},
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Распределить ребенка в класс</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '100%' }} className="mr-3" controlId="formBasicEmail">
								<Form.Label>Выберите паралель</Form.Label>
								{courses.fetchStatus === FetchStatus.FETCHING ? (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								) : (
									<Form.Control
										isInvalid={errors.includes('selectedCourse')}
										value={selectedCourse == null ? undefined : selectedCourse}
										onChange={selectedCourseChangeCallback}
										as="select"
										placeholder="Выберите паралель"
									>
										{courses.courses.map((el: any, index: number) => (
											<option value={el.id} key={index}>
												{el.name}
											</option>
										))}
									</Form.Control>
								)}
							</Form.Group>
						</div>
						<div className="d-flex"></div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedCourse} variant="primary">
							Распределить
						</Button>
					</Form>
				</Col>
				{/* <Col md={5}>
                    {
                        trialDays.fetchStatus === FetchStatus.FETCHED &&
                        <>
                        <h5>Предыдущие записи на пробные дни</h5>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <td>
                                        ФИО
                                    </td>
                                    <td>
                                        Дата начала пробных дней
                                    </td>
                                    <td>
                                        Курс
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    trialDays.trialDays.map((el: any, index: number) => (<TrialDaysItem key={el.id} {...el} />))
                                }
                            </tbody>
                        </Table>
                        </>
                    }
                    {
                        trialDays.fetchStatus === FetchStatus.ERROR && <h5>Не удалось загрузить предыдущие записи на пробные дни</h5>
                    }

                    {
                        trialDays.fetchStatus === FetchStatus.FETCHING && <Skeleton width={400} height={150} />
                    }
                    
                </Col> */}
			</Row>
		</Col>
	)
}

export const DistributeOnCourseFinal = connect(
	({ courses, trialDays }: State, ownProps: any) => ({ courses, trialDays, ...ownProps }),
	(dispatch) => {
		return {
			distributeOnCourse: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadCourses: (payload: any) => {
				return dispatch(loadCourses(payload))
			},
		}
	},
)(Component)
