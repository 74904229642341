import React, { FC, useEffect, useState } from 'react'
import { UserCardPopupTabCommunicationsTable } from '@components/user'
import { AppTab, AppText } from '@frontend-modules/ui-kit'
import './UserCardPopupTabCommunications.scss'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { communicationTabList } from './UserCardPopupTabCommunications.config'
import { IUserCardPopupTabCommunicationsProps } from './UserCardPopupTabCommunications.types'

/**
 * @description - карточка пользователя - таб "коммуникации"
 * @param props
 * @constructor
 */
const UserCardPopupTabCommunications: FC<IUserCardPopupTabCommunicationsProps> = (props) => {
	const { id, userType, onLoadTab } = props

	const communication = useSelector((state: State) => state?.[`${userType}CardNew`].cards?.[id]?.communication)
	const communicationData = communication?.data?.generator

	const isLoading = communication?.fetchStatus === FetchStatus.FETCHING

	const [selectedTab, setSelectedTab] = useState(communicationTabList[0])

	useEffect(() => {
		const params = {
			[`${userType}Id`]: id,
			tabName: 'communication',
		}
		onLoadTab(params)
	}, [userType, id, onLoadTab])

	return (
		<div className={'user-card-popup-tab-communications'}>
			<AppText
				className={'user-card-popup-tab-communications-title'}
				textStyle={'DesktopH3'}
				text={'Коммуникации с пользователем'}
			/>
			<AppTab
				className={'user-card-popup-tab-communications-tabs'}
				isFullWidth={false}
				items={communicationTabList}
				defaultValue={communicationTabList[0]}
				onChange={setSelectedTab}
			/>
			<div className={'user-card-popup-tab-communications-content'}>
				{selectedTab.key === 'email' && (
					<UserCardPopupTabCommunicationsTable
						isLoading={isLoading}
						data={communicationData?.letters || []}
						contentType={'email'}
					/>
				)}
				{selectedTab.key === 'sms' && (
					<UserCardPopupTabCommunicationsTable
						isLoading={isLoading}
						data={communicationData?.sms || []}
						contentType={'sms'}
					/>
				)}
			</div>
		</div>
	)
}

export default UserCardPopupTabCommunications
