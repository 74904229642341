import React, { FC, useMemo } from 'react'
import { AppButton, AppText, AppTable, AppFontIcon, AppHardModalButton } from '@frontend-modules/ui-kit'
import { AppLink } from '@ui'
import { IMainTableProps } from './MainTable.types'
import './MainTable.scss'
import { formatDate } from '@frontend-modules/frontend-utils'
import { AccessHelper } from '@frontend-modules/access-controller'

import { getTableIcon } from '@components/tasks/MainTable/MainTable.utils'
import { TaskPopup } from '@components/tasks'
import { StatusEnum } from '@components/tasks/MainTable/MainTable.config'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
/**
 * @description - таблица процессов
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const {
		isLoading,
		list,
		paginationLimit,
		pagination,
		onRedirectToTask,
		onRefreshList,
		onPaginationChange,
		sortingConfig,
	} = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}
	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	const columns = useMemo(() => {
		return AccessHelper.filterList([
			{
				title: '№',
				dataIndex: 'number',
				key: 'number',
				accessKey: 'tasks.tasks.columns.number',
				width: '100px',
				render: (_, record, index) => {
					const config = getTableIcon(record?.level)
					return (
						<div className={'number-cell'}>
							{paginationLimit * pagination.currentPage - paginationLimit + index + 1}{' '}
							<AppFontIcon icon={config.icon} color={config.color} size={24} />
						</div>
					)
				},
			},
			{
				title: 'Статус',
				dataIndex: 'status',
				key: 'status',
				width: '300px',
				render: (_, record) => {
					return StatusEnum[record?.status]
				},
			},
			{
				title: 'Исполнитель',
				dataIndex: 'assignet',
				key: 'assignet',
				accessKey: 'tasks.tasks.columns.assignet',
				width: '300px',
				render: (_, record) => {
					return record?.assignet?.map((item, index) => (
						<AppText
							key={item.id}
							text={`${index + 1}. ${item?.last_name} ${item?.first_name} ${item?.patronymic} <br />`}
						/>
					))
				},
			},
			{
				title: 'Связанные обьекты',
				accessKey: 'tasks.tasks.columns.relatedObjects',
				dataIndex: 'relatedObjects',
				key: 'relatedObjects',
				width: '400px',
				render: (_, record) => {
					return record?.parent ? (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.parent?.last_name} ${record?.parent?.first_name} ${record?.parent?.patronymic}`}
							query={{
								type: HARD_MODAL_ENUMS.USER_CARD,
								id: record?.parent?.id,
								userType: 'parent',
							}}
						/>
					) : (
						'-'
					)
				},
			},
			{
				title: 'Задача',
				dataIndex: 'title',
				accessKey: 'tasks.tasks.columns.title',
				key: 'title',
				width: '300px',
				render: (_, record) => {
					const title = record?.proccess?.id ? record?.proccess?.title : record.title
					const description = record?.proccess?.id ? record?.title : record?.description
					return `${title} - \n${description}`
				},
			},
			{
				title: 'Время постановки',
				dataIndex: 'start',
				width: '160px',
				accessKey: 'tasks.tasks.columns.start',
				key: 'start',
				render: (_, record) => {
					return `${
						record.datetime_created ? formatDate(record.datetime_created, 'DD.MM.YYYY HH:mm:ss') : '-'
					}`
				},
			},
			{
				title: 'Дедлайн',
				key: 'deadline',
				accessKey: 'tasks.tasks.columns.deadline',
				dataIndex: 'deadline',
				width: '140px',
				render: (_, record) => {
					return `${
						record.datetime_deadline ? formatDate(record.datetime_deadline, 'DD.MM.YYYY HH:mm:ss') : '-'
					}`
				},
			},
			{
				title: '',
				dataIndex: 'actions',
				key: 'actions',
				width: '150px',
				accessKey: 'tasks.tasks.columns.actions',
				render: (_, record) => {
					const render =
						record.action === 'redirect' ? (
							<AppButton
								accessKey={'tasks.tasks.actions.completeTask'}
								type={'medium-primary'}
								onClick={() => {
									onRedirectToTask?.(record)
								}}
								label={'Выполнить'}
							/>
						) : null
					return <div className={'buttons-container'}>{render}</div>
				},
			},
		])
	}, [paginationLimit, pagination.currentPage, onRefreshList, onRedirectToTask])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isCanDeleteRow={false}
			isNeedNumbering={false}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={{
				total: pagination?.count,
				pageSize: paginationLimit,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				onChange: onPaginationChangeLocal,
				locale: { items_per_page: 'записей ' },
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			rowKey={(record) => `${record.id}-${record.datetime_created}`}
			className={'tasks-main-table'}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
		/>
	)
}

export default MainTable
