import React, { FC, useMemo } from 'react'
import { AppButton, AppFontIcon, AppPopoverConfirm, AppTable } from '@frontend-modules/ui-kit'
import { IExpandedTableProps } from './ExpdandedTable.types'
import { formatDate } from '@frontend-modules/frontend-utils'
import { AccessHelper } from '@frontend-modules/access-controller'
import { trimSeparator } from '@frontend-modules/frontend-utils'

/**
 * @description - таблица с данными по платежам конкретного студента,
 * которая открывается внутри главной таблицы
 * @param {IExpandedTableProps} props
 * @constructor
 */
const ExpandedTable: FC<IExpandedTableProps> = (props) => {
	const { list, isLoading, skeletonCounter, onPayedPaymentClick } = props

	const columns = useMemo(() => {
		const onPayedClick = (data) => {
			onPayedPaymentClick?.(data)
		}

		return AccessHelper.filterList([
			{
				title: 'Начало',
				dataIndex: 'start',
				key: 'start',
				width: '90px',
				accessKey: 'debts.payments_areas_table.columns.start',
				render: (_, record) => {
					return `${record.start ? formatDate(record.start) : '-'}`
				},
			},
			{
				title: 'Конец',
				dataIndex: 'end',
				key: 'end',
				width: '90px',
				accessKey: 'debts.payments_areas_table.columns.end',
				render: (_, record) => {
					return `${record.end ? formatDate(record.end) : '-'}`
				},
			},
			{
				title: 'Дата оплаты',
				dataIndex: 'date',
				key: 'date',
				width: '100px',
				accessKey: 'debts.payments_areas_table.columns.date',
				render: (_, record) => {
					return `${record.date ? formatDate(record.date) : '-'}`
				},
			},
			{
				title: 'Описание',
				dataIndex: 'description',
				key: 'description',
				accessKey: 'debts.payments_areas_table.columns.description',
				render: (_, record) => {
					return record?.description || '-'
				},
			},
			{
				title: 'Уже оплачено',
				dataIndex: 'already_paid',
				key: 'already_paid',
				width: '100px',
				accessKey: 'debts.payments_areas_table.columns.alreadyPayed',
				render: (_, record) => {
					return trimSeparator(record.already_paid)
				},
			},
			{
				title: 'Скидка',
				dataIndex: 'discount',
				key: 'discount',
				accessKey: 'debts.payments_areas_table.columns.sale',
				width: '100px',
				render: (_, record) => {
					return `${record.discount}%`
				},
			},
			{
				title: 'Сумма',
				dataIndex: 'sum',
				key: 'sum',
				width: '100px',
				accessKey: 'debts.payments_areas_table.columns.sum',
				render: (_, record) => {
					return trimSeparator(record.sum)
				},
			},
			{
				title: 'Оповещение',
				dataIndex: 'payment_notification',
				key: 'payment_notification',
				width: '150px',
				accessKey: 'debts.payments_areas_table.columns.notified',
				render: (_, record) => {
					return (
						record?.payment_notification?.map((item, index) => {
							return `${item.value}${record?.payment_notification.length - 1 === index ? '' : ',\n'}`
						}) || '-'
					)
				},
			},
			{
				title: '',
				dataIndex: 'actions_expanded',
				key: 'actions_expanded',
				accessKey: 'debts.payments_areas_table.actions.columns',
				render: (_, record) => {
					return (
						<AppPopoverConfirm
							placement={'rightTop'}
							cancelText={'Отмена'}
							icon={false}
							okText={'Зафиксировать'}
							okButtonProps={{
								icon: <AppFontIcon icon={'check-s'} />,
							}}
							onConfirm={() => {
								onPayedClick(record)
							}}
							key={record.id}
							title={'Зафиксировать платеж?'}
						>
							<AppButton
								label={'Оплачено'}
								type={'medium-primary'}
								accessKey={'debts.payments_areas_table.actions.payed'}
							/>
						</AppPopoverConfirm>
					)
				},
			},
		])
	}, [onPayedPaymentClick])

	// div не убирать, иначе поедут стили вложенной таблицы!
	return (
		<div>
			<AppTable
				isLoading={isLoading}
				skeletonRowCount={skeletonCounter > 5 ? 5 : skeletonCounter ?? 2}
				columns={columns}
				isNeedNumbering={false}
				isCanDeleteRow={false}
				pagination={
					list?.length > 5
						? {
								total: list?.length,
								pageSize: 5,
								defaultCurrent: 1,
								showSizeChanger: false,
						  }
						: false
				}
				dataSource={list}
				rowKey={(record) => record.id}
			/>
		</div>
	)
}

export default ExpandedTable
