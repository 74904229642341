import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadParentCardTabContracts } from '@store/actions/parentCard'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import './UserCardPopupTabContracts.scss'
import { OfferContractList } from '@frontend-modules/elements'
import { AppButton, AppText } from '@frontend-modules/ui-kit'
import { isEmptyList, isListExist } from '@frontend-modules/frontend-utils'
import { TUserCardPopupTabContracts } from './UserCardPopupTabContracts.types'
import { Empty } from 'antd'
import { loadStudentCardTabContracts } from '@store/actions/studentCard'

/**
 * @description - Карточка пользователя - таб "Договоры"
 * @param props
 * @constructor
 */
const UserCardPopupTabContracts: FC<TUserCardPopupTabContracts> = (props) => {
	const { id, userType } = props

	const contractsData = useSelector((state: State) => state?.[`${userType}CardNew`].cards?.[id]?.contracts)

	const activeContracts = contractsData?.data?.activeContracts
	const archivedContracts = contractsData?.data?.archivedContracts
	const isLoading = contractsData?.fetchStatus === FetchStatus.FETCHING

	const [isShowArchivedContracts, setShowArchivedContracts] = useState(false)

	const onShowArchivedContractsClick = () => {
		setShowArchivedContracts(!isShowArchivedContracts)
	}

	const isShowArchiveButton = isListExist(archivedContracts)
	const isNoContracts = isEmptyList(activeContracts) && isEmptyList(archivedContracts) && !isLoading

	const dispatch = useDispatch()

	useEffect(() => {
		if (userType === 'parent') {
			dispatch(loadParentCardTabContracts({ parent: id }))
		} else {
			dispatch(loadStudentCardTabContracts({ student: id }))
		}
	}, [dispatch, id, userType])
	return (
		<div className={'user-card-popup-tab-contracts'}>
			{isNoContracts ? (
				<div className={'user-card-popup-tab-contracts-empty'}>
					<Empty description={'Договоры не найдены'} />
				</div>
			) : (
				<>
					<OfferContractList isNeedStudentName contractList={activeContracts || []} isLoading={isLoading} />
					{isShowArchiveButton && (
						<div className={'user-card-popup-tab-contracts-button'}>
							<AppButton
								label={
									isShowArchivedContracts ? 'Скрыть архивные договоры' : 'Показать архивные договоры'
								}
								type={'danger-outline'}
								isDisabled={isLoading}
								isLoading={isLoading}
								onClick={onShowArchivedContractsClick}
								isFullWidth={false}
							/>
						</div>
					)}
					{isShowArchivedContracts ? (
						<OfferContractList
							isNeedStudentName
							contractList={archivedContracts || []}
							isLoading={isLoading}
						/>
					) : null}
				</>
			)}
		</div>
	)
}

export default UserCardPopupTabContracts
