import { removeAccessToken, removeRefreshToken } from '@/common/token'
import { history } from '../store/reducers'
import { deleteAllDatabases, LastUrlService } from '@utils'
import { ssoPath } from '@/api/path/ssoPath'
import { ssoAxios } from '@/api/axios-init'

export const on403Logout = () => {
	console.log('403 logout')
}

export const on401Logout = (config?: { isSaveLocation?: boolean }) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.ChatWidget?.clearAllWidgetData()
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.ChatWidget?.clearNotificationToken()
	ssoLogout()
	removeAccessToken()
	removeRefreshToken()
	if (config?.isSaveLocation) {
		LastUrlService.save(window.location.href)
	}
	if (window.location.pathname !== '/login') {
		history.push('/login')
	}
}

export const onLogout = async () => {
	ssoLogout()
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.ChatWidget?.clearAllWidgetData()
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.ChatWidget?.clearNotificationToken()
	deleteAllDatabases()
	LastUrlService?.remove()
	localStorage.clear()
	if (window.location.pathname !== '/login') {
		history.push('/login')
	}
}

export const ssoLogout = async (refresh?: string) => {
	removeAccessToken()
	removeRefreshToken()
	if (refresh) {
		await ssoAxios.post(ssoPath.logout, {
			refresh,
		})
	} else {
		await ssoAxios.post(ssoPath.logout)
	}
}
