import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { grades } from '../../../common/constants'

export interface ISelectParallelProps {
    process: string;
    id: number;
}

export const SelectParallel: React.FC<ISelectParallelProps> = ({ process, id }) => {
	const [selectedParallel, setSelectedParallel] = useState<number>()
	const dispatch = useDispatch()

	const changeSelectedParallel = useCallback((value) => {
		setSelectedParallel(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					parallel: selectedParallel,
				},
			}),
		)
	}, [dispatch, id, process, selectedParallel])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Выбор параллели</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
								<Form.Label>Выберите параллель</Form.Label>
								<Form.Control
									value={selectedParallel}
									onChange={(event) => changeSelectedParallel(event.target.value)}
									as="select"
									placeholder="Выберите параллель"
								>
									<option value={''} defaultChecked>
                                        Не выбрано
									</option>
									{grades.map((grade) => (
										<option value={grade} key={grade}>
											{grade}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						</div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedParallel} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
