import React, { useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getAccessToken } from '../../../common/token'
import DocumentUpload from '../../../Components/Documents/DocumentUpload'
import { createProcessStart } from '../../../store/actions/processes'
import { host } from '../../../store/store'
import { oldAxios } from '@/api/axios-init'

export const SendStatementToParent: React.FC<any> = ({ id, parent_id }) => {
	const [file, setFile] = useState(null)
	const dispatch = useDispatch()

	const onUploadDocument = async (files) => {
		const document = files[0]
		const file = new FormData()
		file.append('file', document, document.name)
		file.append('user', parent_id)
		const bill = await oldAxios
			.post(`${host}/api/files/`, file, {
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
				},
			})
			.then((res) => res.data)

		setFile(bill)
	}

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				id,
				process: 'send_statement_to_parent',
				parameters: {
					statement_file: file.id,
				},
			}),
		)
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Отправить выписку родителю</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Ссылка</Form.Label>
							{/* <Form.Control value={link} onChange={linkChangeCallback} type="file" placeholder="Введите ссылку" /> */}
							{file ? (
								<div style={{ marginBottom: 20 }}>
									<a target="_blank" href={file?.file} rel="noreferrer">
										Файл
									</a>
								</div>
							) : (
								<DocumentUpload title={'Загрузить'} uploadDocument={onUploadDocument} />
							)}
						</Form.Group>
						<Button onClick={submitButtonPressHandler} disabled={!file} variant="primary">
							Отправить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
