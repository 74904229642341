import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadContracts, loadContractsError, loadContractsSuccess } from '../actions/contracts'

const loadContractsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadContracts)),
		switchMap(({ payload }: ActionType<typeof loadContracts>) => {
			return from(deps.contractDataProvider.loadContractsPage(payload)).pipe(
				switchMap((response) => {
					return of(loadContractsSuccess(response))
				}),
				catchError((err) => {
					return of(loadContractsError(err))
				}),
			)
		}),
	)
}

export const contractsEpics = combineEpics(
	loadContractsEpic,
)
