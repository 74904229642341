import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import { SearchItem } from '../../Screens/Home/SidebarContent/SearchItem'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import {host, State} from '../../store/store'
import { FetchStatus } from '../../common/types'
import { loadConstants } from '../../store/actions/constants'
import { ProcessesSearchType } from '../../store/types/search'
import { setCurrentPage } from '../../store/actions/processes'
import { processesSearchChange } from '../../store/actions/search'
import { Search } from '../SearchItem/SearchItem'
import { ActionsFilter } from './ActionsFilter/ActionsFilter'
import { StaffUsersFilter } from './StaffUsersFilter'
import { SchoolFilter } from './SchoolFilter'
import { TariffFilter } from './TariffFilter'
import { EducationFormatFilter } from './EducationFormatFilter'
import './ProcessesFilters.scss'
import { ProcessTypesFilter } from './ProcessTypesFilter'
import Select from 'react-select'
import { MonthsEnum } from '../../common/constants'
import { useLocation } from 'react-router-dom'
import { loadStudentsStart } from '../../store/actions/students'
import {StudentsDataProvider} from '../../api/StudentsDataProvider'

const currentYear = new Date().getFullYear()

export const ProcessesFilters: React.FC = () => {
	const {
		parentSearch,
		idSearch,
		actionsSearch,
		schoolSearch,
		tariffSearch,
		formatSearch,
		staffUsersSearch,
		createdMonthSearch,
		processTypesSearch,
		gradeSearch,
	} = useSelector((state: State) => state.search)
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((state: State) => state.constants)
	const { fetchStatus: studentsFetchStatus, students } = useSelector((state: State) => state.students)
	const dispatch = useDispatch()
	const location = useLocation()

	const [altCreatedMonthFilter, setAltCreatedMonthFilter] = useState({ month: null, year: currentYear })

	const isFirefox = navigator.userAgent.search(/Firefox/) > 0

	useEffect(() => {
		dispatch(
			loadStudentsStart({
				paramStr: '?all=yes',
			}),
		)
	}, [dispatch])

	const [studentsOptions, setStudentsOptions] = useState<{ label: string; value: number }[]>([])

	const loadStudentsOptions = async (provider: StudentsDataProvider, searchValue: string) => {
		const students = await provider.searchStudentsCards({ student: searchValue })

		return setStudentsOptions(students.map((student) => ({
			label: `${student.last_name} ${student.first_name} ${student.patronymic}`,
			value: student.id
		})))
	}

	let timer: ReturnType<typeof setTimeout>
	const onSearchSelect = (newValue: string) => {
		if (newValue.length < 3) return
		clearTimeout(timer)

		const studentsDataProvider = new StudentsDataProvider(host)
		if (studentsOptions.length === 0) {
			loadStudentsOptions(studentsDataProvider, newValue)
			return
		}

		timer = setTimeout(async () => {
			loadStudentsOptions(studentsDataProvider, newValue)
			return
		}, 1500)
	}

	const searchChangeCallback = useCallback(
		(value: any, type: ProcessesSearchType) => {
			dispatch(setCurrentPage(1))
			dispatch(
				processesSearchChange({
					value: value,
					type: type,
				}),
			)
		},
		[dispatch],
	)

	useEffect(() => {
		if (isFirefox && altCreatedMonthFilter.month && altCreatedMonthFilter.year)
			searchChangeCallback(
				`${altCreatedMonthFilter.month}.${altCreatedMonthFilter.year}`,
				ProcessesSearchType.CREATED_MONTH,
			)
	}, [altCreatedMonthFilter])

	useEffect(() => {
		if (constantsFetchStatus === FetchStatus.FETCHED) dispatch(loadConstants())
		const params = new URLSearchParams(location.search)
		Object.entries(ProcessesSearchType).forEach(([_, value]) => {
			const paramValue = params.get(value)
			if (paramValue) {
				if (
					[
						ProcessesSearchType.ACTIONS,
						ProcessesSearchType.SCHOOL,
						ProcessesSearchType.TARIFF,
						ProcessesSearchType.EDUCATION_FORMAT,
						ProcessesSearchType.STAFF_USERS,
						ProcessesSearchType.PROCESS_TYPES,
					].includes(value)
				) {
					if ([ProcessesSearchType.STAFF_USERS, ProcessesSearchType.SCHOOL].includes(value))
						searchChangeCallback(
							paramValue.split(',').map((item) => Number(item)),
							value,
						)
					else searchChangeCallback(paramValue.split(','), value)
				} else searchChangeCallback(paramValue, value)
			}
		})
	}, [])

	const clearSearch = useCallback(
		(type: ProcessesSearchType) => {
			if (
				type === ProcessesSearchType.EDUCATION_FORMAT ||
                type === ProcessesSearchType.ACTIONS ||
                type === ProcessesSearchType.TARIFF ||
                type === ProcessesSearchType.SCHOOL ||
                type === ProcessesSearchType.PROCESS_TYPES
			)
				searchChangeCallback([], type)
			else searchChangeCallback('', type)
		},
		[searchChangeCallback],
	)

	const staffUsersSearchChange = useCallback(
		(value) => {
			dispatch(setCurrentPage(1))
			dispatch(
				processesSearchChange({
					value: value,
					type: ProcessesSearchType.STAFF_USERS,
				}),
			)
		},
		[dispatch],
	)

	const handleChangeAltMonth = useCallback((value, type: 'month' | 'year') => {
		setAltCreatedMonthFilter((prevState) => ({ ...prevState, [type]: value }))
	}, [])

	const filters = useMemo(() => {
		return [
			{
				title: 'Родитель',
				clearable: !!parentSearch,
				type: ProcessesSearchType.PARENT,
				children: (
					<Search
						search={parentSearch}
						searchChangeCallback={(e) => searchChangeCallback(e.target.value, ProcessesSearchType.PARENT)}
					/>
				),
			},
			{
				title: 'Ученик',
				clearable: !!idSearch,
				type: ProcessesSearchType.ID,
				children: (
					<div>
						<Select
							placeholder="Выберите ученика"
							options={studentsOptions}
							value={studentsOptions?.find((option) => option.value === +idSearch) || null}
							isLoading={studentsFetchStatus !== FetchStatus.FETCHED}
							onChange={(suggest) => searchChangeCallback(suggest.value, ProcessesSearchType.ID)}
							onInputChange={onSearchSelect}
						/>
					</div>
				),
			},
			{
				title: 'Возможные действия',
				clearable: !!actionsSearch.length,
				type: ProcessesSearchType.ACTIONS,
				children: <ActionsFilter />,
			},
			{
				title: 'Пользователи в процессе',
				clearable: !!staffUsersSearch.length,
				type: ProcessesSearchType.STAFF_USERS,
				children: (
					<StaffUsersFilter
						staffUsersSearch={staffUsersSearch}
						searchChangeCallback={staffUsersSearchChange}
					/>
				),
			},
			{
				title: 'Школа',
				clearable: !!schoolSearch.length,
				type: ProcessesSearchType.SCHOOL,
				children: <SchoolFilter />,
			},
			{
				title: 'Тариф',
				clearable: !!tariffSearch.length,
				type: ProcessesSearchType.TARIFF,
				children: <TariffFilter />,
			},
			{
				title: 'Формат обучения',
				clearable: !!formatSearch.length,
				type: ProcessesSearchType.EDUCATION_FORMAT,
				children: <EducationFormatFilter />,
			},
			{
				title: 'Дата начала',
				clearable: !!createdMonthSearch,
				type: ProcessesSearchType.CREATED_MONTH,
				children: isFirefox ? (
					<div className="alternative-month-input">
						<div>
							<label>Месяц</label>
							<Select
								placeholder="Месяц"
								options={Object.keys(MonthsEnum)
									.filter((v) => isNaN(Number(v)))
									.map((month) => ({
										label: month,
										value:
                                            MonthsEnum[month] >= 10
                                            	? MonthsEnum[month].toString()
                                            	: `0${MonthsEnum[month]}`,
									}))}
								value={
									altCreatedMonthFilter.month
										? {
											label: MonthsEnum[Number(altCreatedMonthFilter.month)],
											value: altCreatedMonthFilter.month,
										}
										: null
								}
								onChange={(item) => handleChangeAltMonth(item.value, 'month')}
							/>
						</div>
						<div>
							<label>Год</label>
							<Select
								placeholder="Год"
								options={Array.from({ length: 30 }, (v, k) => ({
									value: Number(currentYear) - 5 + k,
									label: Number(currentYear) - 5 + k,
								}))}
								value={{
									label: Number(altCreatedMonthFilter.year),
									value: Number(altCreatedMonthFilter.year),
								}}
								onChange={(item) => handleChangeAltMonth(item.value, 'year')}
							/>
						</div>
					</div>
				) : (
					<Form.Control
						placeholder="Введите дату"
						value={createdMonthSearch ? createdMonthSearch.split('.').reverse().join('-') : ''}
						type="month"
						onChange={(e) =>
							searchChangeCallback(
								String(e.target.value).split('-').reverse().join('.'),
								ProcessesSearchType.CREATED_MONTH,
							)
						}
					/>
				),
			},
			{
				title: 'Типы процессов',
				clearable: !!processTypesSearch?.length,
				type: ProcessesSearchType.PROCESS_TYPES,
				children: (
					<ProcessTypesFilter
						processTypesSearch={processTypesSearch}
						searchChangeCallback={(value) => searchChangeCallback(value, ProcessesSearchType.PROCESS_TYPES)}
					/>
				),
			},
			{
				title: 'Класс',
				clearable: !!gradeSearch,
				type: ProcessesSearchType.GRADE,
				children: (
					<Form.Control
						placeholder="Введите класс"
						value={gradeSearch}
						onChange={(e) => searchChangeCallback(e.target.value, ProcessesSearchType.GRADE)}
					/>
				),
			},
		]
	}, [
		parentSearch,
		studentsOptions,
		studentsFetchStatus,
		actionsSearch.length,
		staffUsersSearch,
		staffUsersSearchChange,
		schoolSearch.length,
		tariffSearch.length,
		formatSearch.length,
		createdMonthSearch,
		isFirefox,
		altCreatedMonthFilter.month,
		altCreatedMonthFilter.year,
		processTypesSearch,
		gradeSearch,
		searchChangeCallback,
		idSearch,
		handleChangeAltMonth,
	])

	return (
		<div className="processes-filters__container">
			<h5 className="sidebar-content__header">Фильтры:</h5>
			<Accordion className="processes-filters__accordion">
				{filters.map((filter, index) => (
					<SearchItem
						key={index.toString()}
						title={filter.title}
						eventKey={index.toString()}
						clearable={filter.clearable}
						clearSearch={clearSearch.bind(null, filter.type)}
					>
						{constants.ACTIONS && constants.EDUCATIONAL_FORMS && constants.PROCESSES_BY_NAME ? (
							filter.children
						) : (
							<Skeleton width={144} height={84} />
						)}
					</SearchItem>
				))}
			</Accordion>
		</div>
	)
}
