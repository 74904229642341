import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AppCard, AppText } from '@frontend-modules/ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import './Tasks.scss'
import { useQueryFiltersParams, parseQueryFiltersParamsToObject, trimSeparator } from '@frontend-modules/frontend-utils'
import { SERVICE_NAME, PAGINATION_LIMIT } from './Tasks.config'
import { MainTable } from '@components/tasks'
import { loadTasks, setTasksQuery } from '@store/actions/tasks'
import { useHistory } from 'react-router-dom'
import { FiltersPanel } from '@frontend-modules/filters-generator'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'

/**
 * @description - Страница "Процессы"
 * @constructor
 */
export const Tasks: FC = () => {
	const { fetchStatus, list, pagination, query } = useSelector((state: State) => state.tasks)

	const { entities, catalog, fetchStatus: metaFetchStatus } = useSelector((state: State) => state.meta)

	const [initQuery, changeQueryParams] = useQueryFiltersParams()

	const [selectedFilters, setSelectedFilters] = useState(initQuery || [])

	const dispatch = useDispatch()
	const history = useHistory()

	const loadTasksData = useCallback(
		(params) => {
			const normalizedParams = {
				filters: params?.filters || [],
				pagination: {
					currentPage: params?.pagination?.currentPage || 1,
					limit: params?.pagination?.limit || PAGINATION_LIMIT,
				},
				sorting: params.sorting || [],
			}
			dispatch(setTasksQuery(normalizedParams))
			dispatch(loadTasks(normalizedParams))
			setSelectedFilters(normalizedParams)
			changeQueryParams(normalizedParams)
		},
		[changeQueryParams, dispatch],
	)

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: pagination?.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadTasksData(params)
		}

		const onSortingClear = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: selectedFilters.pagination?.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadTasksData(params)
		}

		return {
			availableList: entities?.tasks?.sorting,
			selectedList: selectedFilters.sorting,
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
	}, [entities?.tasks?.sorting, loadTasksData, pagination?.limit, selectedFilters])

	const redirectToTask = (task) => {
		history.push(`tasks/${task.id}/`)
	}

	/**
	 * @description реагируем на добавление фильтра
	 */
	const onFilterAdd = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: pagination?.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadTasksData(params)
	}

	/**
	 * @description реагируем на удаление фильтра
	 */
	const onFilterRemove = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: selectedFilters.pagination?.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadTasksData(params)
	}
	/**
	 * @description реагируем на полную очистку фильтров
	 */
	const onFiltersClear = () => {
		const params = {
			filters: [],
			sorting: selectedFilters.sorting,
			pagination: {
				currentPage: 1,
				limit: selectedFilters?.pagination?.limit,
			},
		}
		loadTasksData(params)
	}

	const onPaginationChange = ({ page, limit }) => {
		const newPagination = {
			currentPage: page,
			limit,
		}
		loadTasksData({
			filters: selectedFilters?.filters,
			sorting: selectedFilters?.sorting,
			pagination: newPagination,
		})
	}

	useEffect(() => {
		const tasksParams = {
			filters: selectedFilters.filters,
			pagination: selectedFilters.pagination,
			sorting: selectedFilters.sorting,
		}
		const currentQuery = parseQueryFiltersParamsToObject(location.search)
		// нужно для загрузки query параметров из стор перзистного
		if (
			query &&
			(!currentQuery?.filters || currentQuery?.filters?.length <= 0) &&
			(!currentQuery?.sorting || currentQuery?.sorting?.length <= 0)
		) {
			loadTasksData(query)
		} else {
			loadTasksData(tasksParams)
		}
		// ничего не добавлять в deps кроме dispatch!
	}, [dispatch])

	return (
		<AppCard
			className={'tasks-page'}
			title={
				<>
					<AppText textStyle={'Title'} style={{ margin: '0' }} text={'Задачи (НОВЫЕ)'} />
					<AppText
						className={'counter'}
						textStyle={'DesktopH4'}
						text={`Всего записей: <b>${trimSeparator(pagination?.count) || 0}</b>`}
					/>
				</>
			}
		>
			<div className={'filters-container'}>
				<FiltersPanel
					isFetching={metaFetchStatus === FetchStatus.FETCHING}
					selectsApiConfig={filtersApiConfig}
					availableFilters={entities?.tasks?.filters}
					selectedFiltersList={selectedFilters.filters}
					onFilterAdd={onFilterAdd}
					onFilterRemove={onFilterRemove}
					onFiltersClear={onFiltersClear}
					serviceName={SERVICE_NAME}
				/>
			</div>
			<MainTable
				isLoading={fetchStatus === FetchStatus.FETCHING}
				list={list}
				onRedirectToTask={redirectToTask}
				pagination={{ count: pagination?.count, currentPage: selectedFilters?.pagination?.currentPage }}
				paginationLimit={selectedFilters?.pagination?.limit || PAGINATION_LIMIT}
				onPaginationChange={onPaginationChange}
				onRefreshList={() => {
					onPaginationChange({ page: pagination.currentPage, limit: pagination?.limit })
				}}
				sortingConfig={sortingConfig}
			/>
		</AppCard>
	)
}

export default Tasks
