import { ActionType, createAction } from 'typesafe-actions'

export const loadAdditionalCourses = createAction('@additionalCourses/LOAD_ADDITIONAL_COURSES')<any>()
export const onLoadAdditionalCoursesSuccess = createAction(
	'@additionalCourses/ON_LOAD_ADDITIONAL_COURSES_SUCCESS',
)<any>()
export const onLoadAdditionalCoursesError = createAction('@additionalCourses/ON_LOAD_ADDITIONAL_COURSES_ERROR')<any>()
export const setAdditionalCoursesQuery = createAction('@additionalCourses/SET_ADDITIONAL_COURSES_QUERY')<any>()

export type AdditionalCoursesActions =
	| ActionType<typeof loadAdditionalCourses>
	| ActionType<typeof onLoadAdditionalCoursesSuccess>
	| ActionType<typeof onLoadAdditionalCoursesError>
	| ActionType<typeof setAdditionalCoursesQuery>
