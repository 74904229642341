import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { getEducationCostNum } from '../../Screens/Debts/utils'
import { loadProcessesStart } from '../actions/processes'
import {
	loadStaffUsers,
	loadStaffUsersError,
	loadStaffUsersSuccess,
	setStudentsStaffUsers,
	setStudentsStaffUsersError,
	setStudentsStaffUsersSuccess,
	updateUsersToProcess,
	updateUsersToProcessError,
	updateUsersToProcessSuccess,
} from '../actions/staffUsers'
import { loadStudentsCardsStart } from '../actions/students'

const loadStaffUsersEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStaffUsers)),
		switchMap(({ payload }: ActionType<typeof loadStaffUsers>) => {
			return from(deps.usersDataProvider.loadStaffUsers(payload.roles)).pipe(
				switchMap((users) => {
					return of(loadStaffUsersSuccess(users))
				}),
				catchError((err) => {
					return of(loadStaffUsersError(err))
				}),
			)
		}),
	)
}

const updateStaffUsersEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(updateUsersToProcess)),
		switchMap(({ payload }: ActionType<typeof updateUsersToProcess>) => {
			return from(deps.usersDataProvider.updateStaffUsersInProcess(payload.users, payload.processId)).pipe(
				switchMap((result) => {
					const {
						studentSearch,
						idSearch,
						parentSearch,
						actionsSearch,
						schoolSearch,
						formatSearch,
						tariffSearch,
						staffUsersSearch,
						createdMonthSearch,
						processTypesSearch,
						gradeSearch,
					} = store$.value.search
					const { constants } = store$.value.constants
					return of(
						updateUsersToProcessSuccess(result),
						loadProcessesStart({
							page: 1,
							searchStudent: studentSearch,
							idSearch,
							searchParent: parentSearch,
							searchActions: actionsSearch,
							searchTariff: constants.EDUCATION_COST
								? tariffSearch.map((tariff) =>
									getEducationCostNum(constants.constants.EDUCATION_COST, tariff),
								)
								: [],
							searchSchool: schoolSearch,
							searchForm: formatSearch,
							searchStaffUsers: staffUsersSearch,
							searchCreatedMonth: createdMonthSearch,
							searchProcessTypes: processTypesSearch,
							searchGrade: gradeSearch,
						}),
					)
				}),
				catchError((err) => {
					return of(updateUsersToProcessError(err))
				}),
			)
		}),
	)
}

const setStudentsStaffUsersEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(setStudentsStaffUsers)),
		switchMap(({ payload }: ActionType<typeof setStudentsStaffUsers>) => {
			return from(deps.usersDataProvider.setStudentsStaffUsers(payload.users, payload.students)).pipe(
				switchMap((result) => {
					const {
						studentSearch,
						idSearch,
						parentSearch,
						actionsSearch,
						schoolSearch,
						formatSearch,
						tariffSearch,
						staffUsersSearch,
						createdMonthSearch,
						processTypesSearch,
						gradeSearch,
					} = store$.value.search
					const { studentsCardsSearch, studentsCards } = store$.value.students
					const { constants } = store$.value.constants
					return of(
						setStudentsStaffUsersSuccess(result),
						loadProcessesStart({
							page: 1,
							searchStudent: studentSearch,
							idSearch,
							searchParent: parentSearch,
							searchActions: actionsSearch,
							searchTariff: constants.EDUCATION_COST
								? tariffSearch.map((tariff) =>
									getEducationCostNum(constants.constants.EDUCATION_COST, tariff),
								)
								: [],
							searchSchool: schoolSearch,
							searchForm: formatSearch,
							searchStaffUsers: staffUsersSearch,
							searchCreatedMonth: createdMonthSearch,
							searchProcessTypes: processTypesSearch,
							searchGrade: gradeSearch,
						}),
						loadStudentsCardsStart({
							page: studentsCards?.currentPage,
							filter: {
								student: studentsCardsSearch?.fullName,
								school: studentsCardsSearch?.schoolId?.join(),
								education_cost: studentsCardsSearch?.educationCost?.join(),
								process_type: studentsCardsSearch?.processTypes?.join(),
								staff_users: studentsCardsSearch?.staffUsers?.join(),
								document_type: studentsCardsSearch?.documentTypes?.join(),
								contract_date_end: studentsCardsSearch?.contractDateEnd,
								grade: studentsCardsSearch?.grade?.join(),
								contract_status: studentsCardsSearch?.contractStatus?.join(),
								educational_plan:
                                    studentsCardsSearch?.educationalPlan !== 'none'
                                    	? studentsCardsSearch?.educationalPlan
                                    	: undefined,
								student_group:
                                    studentsCardsSearch?.studentGroup !== 'none'
                                    	? studentsCardsSearch?.studentGroup
                                    	: undefined,
								education_contract:
                                    studentsCardsSearch?.educationContract !== 'true' ? undefined : 'false',
								loaded_documents:
                                    studentsCardsSearch?.uploadDocuments !== 'none'
                                    	? studentsCardsSearch?.uploadDocuments
                                    	: undefined,
							},
						}),
					)
				}),
				catchError((err) => {
					return of(setStudentsStaffUsersError(err))
				}),
			)
		}),
	)
}

export const staffUsersEpics = combineEpics(loadStaffUsersEpic, updateStaffUsersEpic, setStudentsStaffUsersEpic)
