import Cookies from 'universal-cookie'
import { AxiosResponse } from 'axios'
import { ssoPath } from '@/api/path/ssoPath'
import { ssoAxios } from '@/api/axios-init'
export const isLocalHost = window.location.href.includes('localhost')

export const cookiesDomain = isLocalHost
	? undefined
	: process.env.REACT_APP_SSO_HOST.includes('tech')
	? 'og1.tech'
	: 'og1.ru'

export const getAccessToken = (): string => new Cookies().get('access_token') || ''
export const removeAccessToken = (): void => {
	new Cookies().remove('access_token')
}

export const getRefreshToken = (): string => (new Cookies().get('refresh_token') as string) || ''
export const removeRefreshToken = (): void => {
	new Cookies().remove('refresh_token')
}

export const setAccessToken = (token: string) => {
	const tokenData = getDecodedTokenData(token)
	const expires = tokenData?.exp ? new Date(+tokenData?.exp * 1000) : new Date()

	new Cookies().set('access_token', token, {
		secure: true,
		expires: expires,
		domain: cookiesDomain,
		sameSite: 'none',
		path: '/',
	})
}
export const setRefreshToken = (token: string) => {
	const tokenData = getDecodedTokenData(token)
	const expires = tokenData?.exp ? new Date(+tokenData?.exp * 1000) : new Date()

	new Cookies().set('refresh_token', token, {
		secure: true,
		expires: expires,
		domain: cookiesDomain,
		sameSite: 'none',
		path: '/',
	})
}

export const getDecodedTokenData = (token: string): Record<string, string | number> => {
	const tokenData = token.split('.')[1]
	return JSON.parse(window.atob(tokenData)) as Record<string, string | number>
}

export interface IRefreshToken {
	access?: string
	refresh?: string
}

export const updateRefreshToken = (): Promise<AxiosResponse<IRefreshToken>> => {
	const refresh = getRefreshToken()
	if (refresh) {
		return ssoAxios.post(ssoPath.refresh, { refresh })
	}
	return ssoAxios.post(ssoPath.refresh)
}
