import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { TrialDaysState } from '../../../store/types/trialDays'

interface IRecordTrialProps {
	trialDays: TrialDaysState
	id: number
	student_id: number
	recordTrial: (payload: any) => {}
}

export const Component: React.FC<IRecordTrialProps> = ({ recordTrial, id, student_id }) => {
	const [selectedCourse, setSelectedCourse] = useState<null | string>(null)
	const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))
	const [errors, setErrors] = useState<Array<string>>([])

	const startDateChangeCallback = useCallback(
		(e: React.ChangeEvent<any>) => {
			setErrors([])
			setStartDate(moment(e.target.value).toDate())
		},
		[startDate],
	)

	const selectedCourseChangeCallback = useCallback(
		(event: any) => {
			setErrors([])
			setSelectedCourse(event.target.value)
		},
		[selectedCourse],
	)

	const submitButtonPressHandler = () => {
		const newErrState = []

		if (!selectedCourse) {
			newErrState.push('selectedCourse')
		}

		if (newErrState.length === 0) {
			recordTrial({
				process: 'record_trial',
				id: id,
				parameters: {
					date: moment(startDate).format('YYYY-MM-DD'),
					course: selectedCourse,
				},
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Записать ребенка на пробные дни</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '40%' }} className="mr-3" controlId="formBasicEmail">
								<Form.Label style={{ height: 50 }}>Выберите паралель</Form.Label>
								<Form.Control
									isInvalid={errors.includes('selectedCourse')}
									value={selectedCourse == null ? undefined : selectedCourse}
									onChange={selectedCourseChangeCallback}
									as="select"
									placeholder="Выберите паралель"
								>
									<option>Не выбрано</option>
									{[5, 6, 7, 8, 9, 10, 11].map((el: any, index: number) => (
										<option value={el} key={index}>
											{el}
										</option>
									))}
								</Form.Control>
							</Form.Group>

							<Form.Group style={{ width: '50%' }} className="mr-3" controlId="formBasicEmail">
								<Form.Label style={{ height: 50 }}>Выберите дату начала пробных дней</Form.Label>
								<Form.Control
									value={moment(startDate).format('YYYY-MM-DD')}
									onChange={startDateChangeCallback}
									type="date"
									placeholder="Выберите дату начала пробных дней"
								/>
							</Form.Group>
						</div>
						<div className="d-flex"></div>
						<Button onClick={submitButtonPressHandler} disabled={!!errors.length} variant="primary">
							Записать на пробные дни
						</Button>
					</Form>
				</Col>
				{/* <Col md={5}>
                    {
                        trialDays.fetchStatus === FetchStatus.FETCHED &&
                        <>
                        <h5>Предыдущие записи на пробные дни</h5>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <td>
                                        ФИО
                                    </td>
                                    <td>
                                        Дата начала пробных дней
                                    </td>
                                    <td>
                                        Курс
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    trialDays.trialDays.map((el: any, index: number) => (<TrialDaysItem key={el.id} {...el} />))
                                }
                            </tbody>
                        </Table>
                        </>
                    }
                    {
                        trialDays.fetchStatus === FetchStatus.ERROR && <h5>Не удалось загрузить предыдущие записи на пробные дни</h5>
                    }

                    {
                        trialDays.fetchStatus === FetchStatus.FETCHING && <Skeleton width={400} height={150} />
                    }
                    
                </Col> */}
			</Row>
		</Col>
	)
}

export const RecordTrial = connect(
	({ courses, trialDays }: State, ownProps: any) => ({ trialDays, ...ownProps }),
	(dispatch) => {
		return {
			recordTrial: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
		}
	},
)(Component)

{
	/* <Form.Control isInvalid={errors.includes("selectedCourse")} value={selectedCourse == null ? undefined : selectedCourse} onChange={selectedCourseChangeCallback} as="select" placeholder="Выберите паралель" >
    {
        courses.fetchStatus === FetchStatus.FETCHED && courses.courses.map((el: any, index: number) => (<option value={el.name} key={index}>{el.name}</option>))
    }
</Form.Control> */
}

export default RecordTrial
