import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import './assets/scss/style.scss'
import './index.css'
import { App } from './Screens/App/App'
import 'moment/locale/ru'
import moment from 'moment'
import { initSentry } from '@utils/sentry'

moment.locale('ru')

const isProduction = process.env.REACT_APP_ENV === 'production'
if (isProduction) {
	initSentry()
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
