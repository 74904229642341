import { Action } from '../store'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { StudentsState } from '../types/students'
import {
	blockStudentAuthError,
	blockStudentAuthStart,
	blockStudentAuthSuccess,
	blockStudentOlError,
	blockStudentOlStart,
	blockStudentOlSuccess,
	createStudentOnLmsError,
	createStudentOnLmsStart,
	createStudentOnLmsSuccess,
	loadStudentProfileError,
	loadStudentProfileStart,
	loadStudentProfileSuccess,
	loadStudentsCardsError,
	loadStudentsCardsStart,
	loadStudentsCardsSuccess,
	loadStudentsError,
	loadStudentsStart,
	loadStudentsSuccess,
	setStudentsCardsCurrentPage,
	studentsCardsSearchChange,
	updateStudentError,
	updateStudentStart,
	updateStudentSuccess,
} from '../actions/students'
import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState: StudentsState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	students: null,
	studentsCards: {
		currentPage: 1,
		totalCount: 1,
		results: null,
	},
	studentsCardsFetchStatus: FetchStatus.INITIAL,
	studentsCardsError: null,
	studentsCardsSearch: {
		educationCost: null,
		schoolId: null,
		fullName: null,
		processTypes: null,
		staffUsers: null,
		documentTypes: null,
		contractDateEnd: null,
		grade: null,
		contractStatus: null,
		educationalPlan: null,
		studentGroup: null,
		educationContract: null,
		uploadDocuments: null,
	},
	createStudentOnLmsStatus: FetchStatus.INITIAL,
	createStudentOnLmsError: null,
	updateStudentStatus: FetchStatus.INITIAL,
	updateStudentError: null,
	studentProfiles: [],
	loadStudentProfileStatus: FetchStatus.INITIAL,
	loadStudentProfileError: null,
	blockStudentAuth: {
		blockStudentAuthStatus: FetchStatus.INITIAL,
		blockStudentAuthError: null,
	},
	blockStudentOl: {
		blockStudentOlStatus: FetchStatus.INITIAL,
		blockStudentOlError: null,
	},
}

export const reducer = (state: StudentsState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadStudentProfileStart): {
		return {
			...state,
			loadStudentProfileStatus: FetchStatus.FETCHING,
		}
	}
	case getType(loadStudentProfileSuccess): {
		return {
			...state,
			studentProfiles: action.payload,
			loadStudentProfileStatus: FetchStatus.FETCHED,
			loadStudentProfileError: null,
		}
	}
	case getType(loadStudentProfileError): {
		return {
			...state,
			loadStudentProfileStatus: FetchStatus.ERROR,
			loadStudentProfileError: action.payload,
		}
	}

	case getType(blockStudentAuthStart): {
		return {
			...state,
			blockStudentAuth: { ...state.blockStudentAuth, blockStudentAuthStatus: FetchStatus.FETCHING },
		}
	}
	case getType(blockStudentAuthSuccess): {
		return {
			...state,
			blockStudentAuth: {
				...state.blockStudentAuth,
				blockStudentAuthStatus: FetchStatus.FETCHED,
				blockStudentAuthError: null,
			},
		}
	}
	case getType(blockStudentAuthError): {
		return {
			...state,
			blockStudentAuth: {
				...state.blockStudentAuth,
				blockStudentAuthStatus: FetchStatus.ERROR,
				blockStudentAuthError: action.payload,
			},
		}
	}

	case getType(blockStudentOlStart): {
		return {
			...state,
			blockStudentOl: { ...state.blockStudentOl, blockStudentOlStatus: FetchStatus.FETCHING },
		}
	}

	case getType(blockStudentOlSuccess): {
		return {
			...state,
			blockStudentOl: {
				...state.blockStudentOl,
				blockStudentOlStatus: FetchStatus.FETCHED,
				blockStudentOlError: null,
			},
		}
	}
	case getType(blockStudentOlError): {
		return {
			...state,
			blockStudentOl: {
				...state.blockStudentOl,
				blockStudentOlStatus: FetchStatus.ERROR,
				blockStudentOlError: action.payload,
			},
		}
	}

	case getType(updateStudentStart): {
		return {
			...state,
			updateStudentStatus: FetchStatus.FETCHING,
		}
	}
	case getType(updateStudentSuccess): {
		return {
			...state,
			updateStudentStatus: FetchStatus.FETCHED,
			updateStudentError: null,
		}
	}
	case getType(updateStudentError): {
		return {
			...state,
			updateStudentStatus: FetchStatus.ERROR,
			updateStudentError: action.payload,
		}
	}

	case getType(loadStudentsStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadStudentsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			students: action.payload,
		}
	}

	case getType(loadStudentsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(loadStudentsCardsStart): {
		return {
			...state,
			studentsCardsFetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadStudentsCardsSuccess): {
		return {
			...state,
			studentsCardsFetchStatus: FetchStatus.FETCHED,
			studentsCards: {
				...state.studentsCards,
				results: action.payload?.results,
				totalCount: action.payload?.count,
			},
		}
	}

	case getType(loadStudentsCardsError): {
		return {
			...state,
			studentsCardsFetchStatus: FetchStatus.ERROR,
			studentsCardsError: action.payload,
		}
	}

	case getType(studentsCardsSearchChange): {
		return {
			...state,
			studentsCardsSearch: {
				...state.studentsCardsSearch,
				[action.payload.type]: action.payload.value,
			},
		}
	}

	case getType(setStudentsCardsCurrentPage): {
		return {
			...state,
			studentsCards: {
				...state.studentsCards,
				currentPage: action.payload,
			},
		}
	}

	case getType(createStudentOnLmsStart): {
		return {
			...state,
			createStudentOnLmsStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createStudentOnLmsSuccess): {
		return {
			...state,
			createStudentOnLmsStatus: FetchStatus.FETCHED,
			createStudentOnLmsError: null,
		}
	}

	case getType(createStudentOnLmsError): {
		return {
			...state,
			createStudentOnLmsStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const students: any = persistReducer(
	{
		key: 'AdminPane::students',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
