/**
 * @description -  принимает два элемента: элемент, до которого вы хотите прокрутить,
 * и контейнер, в котором вы хотите выполнить прокрутку. Функция выполняет цикл,
 * добавляя смещение от верхнего края текущего элемента до контейнера,
 * и перемещается по цепочке родительских элементов до тех пор, пока
 * не достигнет контейнера или не дойдет до корневого элемента документа.
 * @param element
 * @param container
 */
const getOffsetTopToContainer = (element, container) => {
	let offset = 0

	while (element && element !== container) {
		offset += element.offsetTop
		element = element.offsetParent
	}

	return offset
}

export { getOffsetTopToContainer }
