import { ActionType, createAction } from 'typesafe-actions'

export const collapseMenu = createAction('@nav-bar/COLLAPES_MENU')()
export const collapseToggle = createAction('@nav-bar/COLLAPES_TOGGLE')<any>()
export const fullScreen = createAction('@nav-bar/FULL_SCREEN')()
export const fullScreenExit = createAction('@nav-bar/FULL_SCREEN_EXIT')()
export const changeLayout = createAction('@nav-bar/CHANGE_LAYOUT')<any>()
export const navContentLeave = createAction('@nav-bar/CONTENT_LEAVE')<any>()
export const navCollapseLeave = createAction('@nav-bar/COLLAPSE_LEAVE')<any>()


export type NavBarActions = ActionType<typeof collapseMenu> | ActionType<typeof collapseToggle> | ActionType<typeof fullScreen> | ActionType<typeof fullScreenExit> | ActionType<typeof changeLayout> | ActionType<typeof navCollapseLeave> | ActionType<typeof navContentLeave>;