import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadContract } from '../../../store/actions/contract'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'

export const GenerateAdditionalAgreement: React.FC<any> = ({ id, parent_id, student_id }) => {
	const dispatch = useDispatch()
	const { fetchStatus, contracts } = useSelector((store: State) => store.contract)
	const [selectedContract, setSelectedContract] = useState<any>(null)

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	useEffect(() => {
		dispatch(
			loadContract({
				student: student_id,
				parent: parent_id,
				process_id: id,
			}),
		)
	}, [id, parent_id, student_id])

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				process: 'generate_additional_agreement',
				id: id,
				parameters: {
					contract_id: selectedContract?.value,
				},
			}),
		)
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Сгенерировать дополнительное соглашение</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						{fetchStatus === FetchStatus.FETCHED ? (
							<div className="d-flex mb-3">
								<Form.Group>
									<Form.Label>Выберите договор</Form.Label>
									<Select
										placeholder="Выберите договор"
										options={contracts
											.filter((contract) => contract?.status === 'active')
											.map((contract) => ({
												label: contract.number,
												value: contract.id,
											}))}
										value={selectedContract}
										onChange={selectedContractChangeCallback}
									/>
								</Form.Group>
							</div>
						) : (
							<div className="mb-3">
								<Skeleton height={50} />
							</div>
						)}
						<Button onClick={submitButtonPressHandler} disabled={!selectedContract} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
