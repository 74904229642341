import {getType} from 'typesafe-actions'
import {FetchStatus} from '../../common/types'
import {Action} from '../store'

import {addBuisnessProcessError, addBuisnessProcessStart, addBuisnessProcessSuccess} from '../actions/buisness'
import {BuisnessState} from '../types/buisness'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
}

export const buisness = (state: BuisnessState = initialState, action: Action) => {

	switch (action.type) {
	case getType(addBuisnessProcessStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(addBuisnessProcessSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
		}
	}

	case getType(addBuisnessProcessError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
