import React, { useEffect, useMemo, useState } from 'react'
import { AppCheckboxGroup, AppDatepicker, AppTable } from '@frontend-modules/ui-kit'
import './UserCardPopupHistoryTable.scss'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import { getTypeLabel } from './UserCardPopupHistoryTable.utils'
import { getCurrentDate, getCurrentDateMinusSomeDays } from '@utils'
import { PAGINATION_LIMIT } from './UserCardPopupHistoryTable.config'
import { formatDate } from '@frontend-modules/frontend-utils'
/**
 * @description - таблица История раздела "лицевой счет
 * @param props
 * @constructor
 */
const UserCardPopupHistoryTable = (props) => {
	const { tableData, isLoading, onLoad, id } = props
	const { body: initialData, results: list, pagination } = tableData

	const [startDate, setStartDate] = useState(initialData?.dateStart || getCurrentDateMinusSomeDays(30))
	const [endDate, setEndDate] = useState(initialData?.dateEnd || getCurrentDate())

	const checkboxList = useMemo(
		() => [
			{ label: getTypeLabel['payments'], value: 'payments' },
			{ label: getTypeLabel['realizations'], value: 'realizations' },
		],
		[],
	)
	const [checkboxes, setCheckboxes] = useState<Array<CheckboxOptionType>>(
		checkboxList.filter((check) =>
			typeof initialData?.[check.value] === 'boolean' ? initialData?.[check.value] : true,
		),
	)

	const columns = useMemo(() => {
		return [
			{
				title: 'Дата',
				dataIndex: 'date',
				key: 'date',
				render: (_, record) => {
					return `${formatDate(record?.date) || '-'}`
				},
			},
			{
				title: 'Сумма',
				dataIndex: 'sum',
				key: 'sum',
				className: 'sum-td',
				render: (_, record) => {
					return `${trimSeparator(record?.amount) || '-'}`
				},
			},
			{
				title: 'Тип',
				dataIndex: 'type',
				key: 'type',
				render: (_, record) => {
					return `${getTypeLabel[record?.type] || '-'}`
				},
			},
			{
				title: 'Назначение',
				dataIndex: 'purpose',
				key: 'purpose',
				render: (_, record) => {
					return `${record?.purpose || '-'}`
				},
			},
		]
	}, [])

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination?.currentPage) {
			const params = {
				limit: pagination?.limit || PAGINATION_LIMIT,
				currentPage: page,
			}
			loadTable?.(params)
		}
	}

	const loadTable = (pagination) => {
		const types = checkboxes.map((elem) => elem.value)

		const params = {
			types,
			parentId: id,
			dateStart: startDate,
			dateEnd: endDate,
			...pagination,
		}
		onLoad?.(params)
	}

	useEffect(() => {
		loadTable({
			limit: PAGINATION_LIMIT,
			currentPage: 1,
		})
	}, [startDate, endDate, checkboxes, id])

	return (
		<div className={'user-card-popup-history-table'}>
			<div className={'user-card-popup-history-table-filters'}>
				<div className={'user-card-popup-history-table-filters-dates'}>
					<AppDatepicker defaultValue={startDate} label={'Дата начала'} required onChange={setStartDate} />
					<AppDatepicker defaultValue={endDate} label={'Дата конца'} required onChange={setEndDate} />
				</div>
				<div className={'user-card-popup-history-table-filters-checkboxes'}>
					<AppCheckboxGroup
						defaultValue={checkboxes}
						onChange={(list) => setCheckboxes(list)}
						label={''}
						items={checkboxList}
					/>
				</div>
			</div>
			<AppTable
				isLoading={isLoading}
				columns={columns}
				isCanDeleteRow={false}
				isNeedNumbering={true}
				dataSource={list}
				locale={{ emptyText: 'Ничего не найдено' }}
				pagination={{
					total: pagination?.count,
					pageSize: pagination?.limit,
					defaultCurrent: 1,
					current: pagination?.currentPage,
					locale: { items_per_page: 'записей ' },
					onChange: onPaginationChangeLocal,
					showSizeChanger: false,
				}}
				rowKey={(record, index) => `${index}`}
				sortingConfig={{}}
				titleSortingType={'customSorting'}
			/>
		</div>
	)
}

export default UserCardPopupHistoryTable
