import { ActionType, createAction } from 'typesafe-actions'
import {ISchoolsPayload} from '../types/school'


export const loadSchool = createAction('@school/LOAD/START')<any>()
export const loadSchoolSuccess = createAction('@school/LOAD/SUCCESS')<any>()
export const loadSchoolError = createAction('@school/LOAD/ERROR')<any>()

export const loadSchoolsStart = createAction('@schools/LOAD/START')<ISchoolsPayload>()
export const loadSchoolsSuccess = createAction('@schools/LOAD/SUCCESS')<any>()
export const loadSchoolsError = createAction('@schools/LOAD/ERROR')<any>()

export type SchoolActions =
    ActionType<typeof loadSchool>
    | ActionType<typeof loadSchoolSuccess>
    | ActionType<typeof loadSchoolError>
    | ActionType<typeof loadSchoolsStart>
    | ActionType<typeof loadSchoolsSuccess>
    | ActionType<typeof loadSchoolsError>;
