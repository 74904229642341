export const DEMO = {
	BLANK_LINK: '#!',
}

export const URL_PREFIX = '/'

export const isStateInModal: any = {
	record_trial: true,
	distribute_on_course_final: true,
	distribute_to_school: true,
	transfer_to_do_specialist: true,
	request_contract_changes: true,
	confirm_address: true,
	send_courier: true,
	missing_documents: true,
	send_copy_to_parent: true,
	confirm_documents_received: true,
	request_missing_documents: false,
	change_trial: true,
	change_school: true,
	change_school_from_other_process: true,
	change_specialist: true,
	send_internal_courier: true,
	set_school_action: true,
	set_specialist: true,
	set_missing_documents: true,
	set_kontur_url: true,
	change_kontur_url: true,
	upload_courier_bill: true,
	change_education_cost: true,
	set_education_cost: true,
	pencil_set_data: true,
	pencil_set_tracking_link: true,
	pencil_change_tracking_link: true,
	add_existing_contract: true,
	head_teacher_reject: true,
	book_grade: true,
	book_foreign_language: true,
	booking_to_trial_day: true,
	send_statement_to_parent: true,
	sign_agreement_EDC: true,
	manual_confirm_courier_payment: true,
	manual_confirm_contract_sign: true,
	generate_additional_agreement: true,
	documents_sent: true,
	terminate_in_one_click: true,
	skip_generate_contract: true,
	generate_order: true,
	generate_order_existing_contract: true,
	generate_expulsion_order: true,
	upload_certificate: true,
	change_certificate: true,
	select_education_cost_action: true,
	select_school_action: true,
	put_in_closet: false,
	refuse_of_trial_days: true,
	confirm_trial_day_completion: true,
	select_tariff: true,
	select_parallel: true,
	book_on_education_plan: true,
	notify_old_school: true,
}

export const documentStates: any = {
	awaiting_verification: 'Ожидает проверки',
	aproved: 'Проверен',
}

export const uploadDocumentStates: any = { parent: 'Родитель', manager: 'Менеджер', gimnasium: 'Гимназия' }

export const hiddenActions: any = {
	generate_agreement: true,
	generate_report: true,
}

export const grades = [5, 6, 7, 8, 9, 10, 11]

export const FOREIGN_LANGUAGES_SUBJECT_IDS = [19, 20]

export const emailStatuses: any = {
	delivered: 'Доставлено',
	clicked: 'Переход по ссылке',
	opened: 'Открыто',
	permanent_fail: 'Невозможно доставить',
	complained: 'Жалоба',
	temporary_fail: 'Временная неудача',
	failed: 'Неудача',
	unsubscribed: 'Отписка',
}

export enum MonthsEnum {
    Январь = 1,
    Февраль,
    Март,
    Апрель,
    Май,
    Июнь,
    Июль,
    Август,
    Сентябрь,
    Октябрь,
    Ноябрь,
    Декабрь,
}
