export const parseTimeSpent = (ms) => {
	const days = Math.floor(ms / (24 * 60 * 60 * 1000))
	const daysms = ms % (24 * 60 * 60 * 1000)
	const hours = Math.floor(daysms / (60 * 60 * 1000))
	const hoursms = ms % (60 * 60 * 1000)
	const minutes = Math.floor(hoursms / (60 * 1000))
	if (days <= 0 && hours <= 0) {
		return `${minutes} мин`
	}
	if (days <= 0 && hours > 0) {
		if (minutes > 0) {
			return `${hours} ч ${minutes} мин`
		}
		return `${hours} ч`
	}
	return `${days} д ${hours > 0 ? `${hours} ч` : ''}`
}
