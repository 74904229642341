import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap, } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {loadSpecialistError, loadSpecialistStart, loadSpecialistSuccess} from '../actions/specialist'

const loadSpecialistEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadSpecialistStart)),
		switchMap(({  }: ActionType<typeof loadSpecialistStart>) => {
			return from(deps.processesDataProvider.loadSpecialist()).pipe(
				switchMap(specialist => {
					return of(loadSpecialistSuccess(specialist))
				}),
				catchError(err => {
					return of(loadSpecialistError(err))
				})
			)
		})
	)
}

export const specialistEpics = combineEpics(loadSpecialistEpic)
