import {useHistory, useLocation} from 'react-router-dom'
import {parseQueryParamsToObject} from '@utils'

const useQueryPopupsParams = () => {
	const location = useLocation()
	const history = useHistory()
	const MAX_COUNT_MODALS = 4

	/**
	 * @description - Обновляет список модальных окон в URL. Автоматически устанавливает ранжирование (layer)
	 * @param updatedModalList - Новый список модальных окон.
	 */
	const updateModalListInUrl = (updatedModalList: object[]) => {
		for (let i = 0; i < updatedModalList.length; i++) {
			updatedModalList[i]['layer'] = updatedModalList.length - i
		}
		const url = new URL(window.location.href)
		url.searchParams.set('modals', JSON.stringify(updatedModalList))
		history.replace({ pathname: location.pathname, search: url.search })
	}


	/**
	 * @description - устанавливаем в URL параметр modals
	 */
	const openPopup = (modal: object) => {
		const locationSearch = location.search
		const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals')
		let newModalList: object[] = [...parsedQueries.modals, modal]
		const formattedModalList: Array<object> = [...newModalList]
		for (let i = 0; i < formattedModalList.length - MAX_COUNT_MODALS; i++) {
			formattedModalList[i]['isHide'] = true
		}
		newModalList = [...(formattedModalList)]
		updateModalListInUrl(newModalList)
	}

	/**
	 * @description - закрываем последнюю открытую модалку, очищая ее из URL
	 */
	const closeLastPopup = () => {
		const locationSearch = location.search
		const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals')
		const modalList = parsedQueries.modals
		let newModalList: Array<object> = modalList.filter((modal, index) => index !== modalList.length - 1)
		const formattedModalList: Array<object> = [...newModalList]
		if (formattedModalList.length > 0) {
			let indexToOpen = formattedModalList.findLastIndex((modal) => modal['isHide'])
			if (indexToOpen === -1) indexToOpen = formattedModalList.length - 1
			formattedModalList[indexToOpen]['isHide'] = false
		}
		newModalList = [...formattedModalList]
		updateModalListInUrl(newModalList)
	}

	/**
	 * @description - закрываем последнюю открытую модалку, очищая ее из URL
	 */
	const replaceLastPopup = (modal: object) => {
		const locationSearch = location.search
		const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals')
		const modalList = parsedQueries.modals
		const newModalList = modalList.filter((modal, index) => index !== modalList.length - 1)
		const formattedModalList = [...newModalList, modal]
		updateModalListInUrl(formattedModalList)
	}

	return {
		openPopup,
		closeLastPopup,
		replaceLastPopup,
	}
}

export default useQueryPopupsParams
