import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../store/store'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'

interface ProcessTypeFilterProps {
    processTypesSearch: string[];
    searchChangeCallback: (value: string[]) => void;
}

export const ProcessTypesFilter: React.FC<ProcessTypeFilterProps> = ({ processTypesSearch, searchChangeCallback }) => {
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((store: State) => store.constants)

	return (
		<Select
			placeholder="Выберите тип процесса"
			isLoading={constantsFetchStatus === FetchStatus.FETCHING || !constants}
			value={processTypesSearch.map((processType) => ({
				label: constants?.PROCESSES_BY_NAME[processType],
				value: processType,
			}))}
			isMulti
			onChange={(value) => searchChangeCallback(value.map((item) => item.value))}
			options={Object.entries(constants?.PROCESSES_BY_NAME).map(([key, value]) => {
				return {
					label: value,
					value: key,
				}
			})}
		/>
	)
}
