import { BaseRestDataProvider } from './BaseRestDataProvider'
import { DeletePaymentStartPayload, IPaymentSitesPayload } from '../store/types/payment'
import { getFilterParams } from '../utils/filters'
import { oldAxios } from './axios-init'

export class PaymentDataProvider extends BaseRestDataProvider {
	loadPaymentSites = (payload: IPaymentSitesPayload) => {
		let searchParam = `${payload.paramStr ? payload.paramStr : ''}`
		searchParam += getFilterParams(payload.filter)
		return oldAxios.get(`${this.host}/payment/schedules/${searchParam}`).then((res) => res.data)
	}

	loadVerPayments = (payload: any) => {
		return this.loadPaymentBills(`?verification=V&page=${payload.vPage}&${payload.vQuery}`)
	}

	loadUnverPayments = (payload: any) => {
		return this.loadPaymentBills(`?verification=U&page=${payload.uPage}&${payload.uQuery}`)
	}

	loadPaymentBills = (query: string) => {
		return oldAxios.get(`${this.host}/payment/payment_bills/${query}`).then((res) => res.data)
	}

	loadConstants = () => {
		return oldAxios.get(`${this.host}/api/constants/`).then((res) => res.data)
	}

	identificate = (payload: any) => {
		return oldAxios
			.patch(`${this.host}/payment/payment_bills/${payload.payment_id}`, {
				payment_schedules: payload.payment_schedules,
			})
			.then((res) => res.data)
	}

	delete = ({ paymentId }: DeletePaymentStartPayload) => {
		return oldAxios.delete(`${this.host}/payment/payment_bills/${paymentId}`).then((res) => res.data)
	}

	unfrozePayment = (payload: any) => {
		return oldAxios.patch(`${this.host}/payment/schedules/${payload.id}/`, payload.data).then((res) => res.data)
	}

	unfrozeAllPayment = (payload) => {
		return oldAxios.post(`${this.host}/payment/schedules/unfreeze_all/`, payload).then((res) => res.data)
	}

	loadFrozenPayment = ({ page }: any) => {
		return oldAxios
			.get(`${this.host}/payment/contracts/?payment_schedules__frozen=True&page=${page}`)
			.then((res) => res.data)
	}

	loadCurrentFrozenPayment = (contractId: any) => {
		return oldAxios
			.get(`${this.host}/payment/schedules/?contract=${contractId}&frozen=True`)
			.then((res) => res.data.results)
	}

	loadUnfrozenPayment = (search: any) => {
		const query = `?${Object.keys(search).map((key) => `${key}=${search[key]}&`)}`.replace(/,/g, '')
		return oldAxios.get(`${this.host}/payment/schedules/${query}`).then((res) => res.data)
	}

	fetchPaymentCount = (start: string, end: string) => {
		return oldAxios
			.get(`${this.host}/payment/statistics/payment_bills?date_after=${start}&date_before=${end}`)
			.then((res) => res.data)
	}
}
