import { ActionType, createAction } from 'typesafe-actions'
import {IAddDocumentTypePayload, IDocumentType, IRemoveDocumentTypePayload} from '../types/documentTypes'
import { IStudent } from '../types/students'

export const loadDocumentTypesStart = createAction('@documentTypes/LOAD/START')<any>()
export const loadDocumentTypesSuccess = createAction('@documentTypes/LOAD/SUCCESS')<IDocumentType>()
export const loadDocumentTypesError = createAction('@documentTypes/LOAD/ERROR')<any>()

export const loadParentStudentDocumentTypes = createAction('@documentTypes/LOAD_STUDENT_PARENT/START')<any>()
export const loadParentStudentDocumentTypesSuccess = createAction('@documentTypes/LOAD_STUDENT_PARENT/SUCCESS')<any>()
export const loadParentStudentDocumentTypesError = createAction('@documentTypes/LOAD_STUDENT_PARENT/ERROR')<any>()

export const addDocumentTypeStart = createAction('@documentTypes/ADD/START')<IAddDocumentTypePayload>()
export const addDocumentTypeSuccess = createAction('@documentTypes/ADD/SUCCESS')<IStudent>()
export const addDocumentTypeError = createAction('@documentTypes/ADD/ERROR')<any>()

export const removeDocumentTypeStart = createAction('@documentTypes/REMOVE/START')<IRemoveDocumentTypePayload>()

export const createDocumentTypeStart = createAction('@documentTypes/CREATE/START')<IDocumentType>()
export const createDocumentTypeSuccess = createAction('@documentTypes/CREATE/SUCCESS')<IDocumentType>()
export const createDocumentTypeError = createAction('@documentTypes/CREATE/ERROR')<any>()

export type DocumentTypesActions =
    | ActionType<typeof loadDocumentTypesStart>
    | ActionType<typeof loadDocumentTypesSuccess>
    | ActionType<typeof loadDocumentTypesError>
    | ActionType<typeof addDocumentTypeStart>
    | ActionType<typeof addDocumentTypeSuccess>
    | ActionType<typeof addDocumentTypeError>
    | ActionType<typeof createDocumentTypeStart>
    | ActionType<typeof createDocumentTypeSuccess>
    | ActionType<typeof createDocumentTypeError>
    | ActionType<typeof loadParentStudentDocumentTypes>
    | ActionType<typeof loadParentStudentDocumentTypesSuccess>
    | ActionType<typeof loadParentStudentDocumentTypesError>
    | ActionType<typeof removeDocumentTypeStart>
