import { trimSeparator } from '@frontend-modules/frontend-utils'

export const PAGINATION_LIMIT = 20
export const UsersBonusesConfig = {
	cancel: {
		main: 'rgb(234, 89, 89)',
		back: 'rgb(249, 204, 204)',
	},
	outcome: {
		main: '#252232',
		back: '#EDEEF2',
		prefix: '-',
	},
	active: {
		main: '#2F929A',
		back: '#D1E6E8',
	},
	awaits: {
		main: '#E5A139',
		back: '#F9E9CC',
	},
}

export const getUsersBonusesStatusText = (type) => {
	switch (type) {
		case 'active':
			return 'Поступления'
		case 'outcome':
			return 'Списания'
		case 'awaits':
			return 'Ожидают поступления'
		case 'cancel':
			return 'Отменены'
		default:
			return ''
	}
}

export const getUsersBonusesText = (sum, type): string => {
	return `${UsersBonusesConfig[type].prefix || ''}${trimSeparator(sum)}`
}

export const userBonusesCheckboxList = [
	{ label: getUsersBonusesStatusText('active'), value: 'active' },
	{ label: getUsersBonusesStatusText('outcome'), value: 'outcome' },
	{ label: getUsersBonusesStatusText('awaits'), value: 'awaits' },
	{ label: getUsersBonusesStatusText('cancel'), value: 'cancel' },
]
