import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap,} from 'rxjs/operators'
import {ActionType, getType} from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	loadSchool,
	loadSchoolError,
	loadSchoolsError,
	loadSchoolsStart,
	loadSchoolsSuccess,
	loadSchoolSuccess
} from '../actions/school'

const loadSchoolEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadSchool)),
		switchMap(({ payload }) => {
			return from(deps.processesDataProvider.loadSchool(payload)).pipe(
				switchMap(schools => {
					return of(loadSchoolSuccess(schools))
				}),
				catchError(err => {
					return of(loadSchoolError(err))
				})
			)
		})
	)
}

const loadSchoolsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadSchoolsStart)),
		switchMap(({ payload }: ActionType<typeof loadSchoolsStart>) => {
			return from(deps.processesDataProvider.loadSchools(payload)).pipe(
				switchMap(schools => {
					return of(loadSchoolsSuccess(schools))
				}),
				catchError(err => {
					return of(loadSchoolsError(err))
				})
			)
		})
	)
}

export const schoolEpics = combineEpics(loadSchoolEpic, loadSchoolsEpic)
