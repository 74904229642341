import { ActionType, createAction } from 'typesafe-actions'

// TODO: разобраться с any
export const loadGroupActions = createAction('@group-actions/LOAD_GROUP_ACTIONS')<any>()
export const onLoadGroupActionsSuccess = createAction('@group-actions/ON_LOAD_GROUP_ACTIONS_SUCCESS')<any>()
export const onLoadGroupActionsError = createAction('@group-actions/ON_LOAD_GROUP_ACTIONS_ERROR')<any>()
export const loadActionsList = createAction('@group-actions/LOAD_ACTIONS_LIST')<any>()
export const onLoadActionsListSuccess = createAction('@group-actions/ON_LOAD_ACTIONS_LIST_SUCCESS')<any>()
export const onLoadActionsListError = createAction('@group-actions/ON_LOAD_ACTIONS_LIST_ERROR')<any>()
export const createGroupAction = createAction('@group-actions/CREATE_ACTION')<any>()
export const onCreateGroupActionSuccess = createAction('@group-actions/ON_CREATE_ACTION_SUCCESS')<any>()
export const onCreateGroupActionError = createAction('@group-actions/ON_CREATE_ACTION_ERROR')<any>()
export const setGroupActionsQuery = createAction('@group-actions/SET_GROUP_ACTIONS_QUERY')<any>()
export const loadActionsTypeList = createAction('@group-actions/LOAD_ACTIONS_TYPE_LIST')<any>()
export const onLoadActionsTypeListSuccess = createAction('@group-actions/ON_LOAD_ACTIONS__TYPE_LIST_SUCCESS')<any>()
export const onLoadActionsTypeListError = createAction('@group-actions/ON_LOAD_ACTIONS__TYPE_LIST_ERROR')<any>()
export const loadActionsTypeNestedTable = createAction('@group-actions/LOAD_ACTIONS_NESTED_TABLE')<any>()
export const onLoadActionsTypeNestedTableSuccess = createAction(
	'@group-actions/ON_LOAD_ACTIONS_NESTED_TABLE_SUCCESS',
)<any>()
export const onLoadActionsTypeNestedTableError = createAction(
	'@group-actions/ON_LOAD_ACTIONS_NESTED_TABLE_ERROR',
)<any>()
export const cancelActionByType = createAction('@group-actions/CANCEL_ACTION_BY_TYPE')<any>()
export const onCancelActionByTypeSuccess = createAction('@group-actions/ON_CANCEL_ACTION_BY_TYPE_SUCCESS')<any>()
export const onCancelActionByTypeError = createAction('@group-actions/ON_CANCEL_ACTION_BY_TYPE_ERROR')<any>()
export const editActionByType = createAction('@group-actions/EDIT_ACTION_BY_TYPE')<any>()
export const onEditActionByTypeSuccess = createAction('@group-actions/ON_EDIT_ACTION_BY_TYPE_SUCCESS')<any>()
export const onEditActionByTypeError = createAction('@group-actions/ON_EDIT_ACTION_BY_TYPE_ERROR')<any>()

export type GroupActionsActions =
	| ActionType<typeof loadGroupActions>
	| ActionType<typeof onLoadGroupActionsSuccess>
	| ActionType<typeof onLoadGroupActionsError>
	| ActionType<typeof loadActionsList>
	| ActionType<typeof onLoadActionsListSuccess>
	| ActionType<typeof onLoadActionsListError>
	| ActionType<typeof createGroupAction>
	| ActionType<typeof onCreateGroupActionSuccess>
	| ActionType<typeof onCreateGroupActionError>
	| ActionType<typeof setGroupActionsQuery>
	| ActionType<typeof loadActionsTypeList>
	| ActionType<typeof onLoadActionsTypeListSuccess>
	| ActionType<typeof onLoadActionsTypeListError>
	| ActionType<typeof loadActionsTypeNestedTable>
	| ActionType<typeof onLoadActionsTypeNestedTableSuccess>
	| ActionType<typeof onLoadActionsTypeNestedTableError>
	| ActionType<typeof cancelActionByType>
	| ActionType<typeof onCancelActionByTypeSuccess>
	| ActionType<typeof onCancelActionByTypeError>
	| ActionType<typeof editActionByType>
	| ActionType<typeof onEditActionByTypeSuccess>
	| ActionType<typeof onEditActionByTypeError>
