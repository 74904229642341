import React, { useEffect, useState } from 'react'
import { Sidebar } from '@components/layouts'
import { Layout as AntdLayout } from 'antd'
import { useHistory } from 'react-router-dom'
import './Layots.scss'
import { SidebarService } from '@utils'
import { onLogout } from '@/common/logout'
/**
 * @description - компонент обертка для внутренних страниц ERP
 * @param props
 * @constructor
 */
const Layout: React.FC = (props) => {
	const { children } = props

	const [isCollapsedMenu, setCollapsedMenu] = useState(SidebarService.getCollapsed())
	const history = useHistory()

	useEffect(() => {
		const documentBody: any = document.getElementById('project-body')
		documentBody?.classList?.toggle('is-hiding-support-bubble')
		return () => {
			documentBody?.classList?.toggle('is-hiding-support-bubble')
		}
	}, [])

	const onMenuItemClick = ({ key }) => {
		history.push(key)
	}

	const onCollapseMenu = (newState) => {
		SidebarService.setCollapsed(newState)
		setCollapsedMenu(newState)
	}

	return (
		<AntdLayout hasSider>
			<Sidebar
				isCollapsed={isCollapsedMenu}
				setCollapsedMenu={onCollapseMenu}
				onMenuItemClick={onMenuItemClick}
				onLogout={onLogout}
			/>
			<AntdLayout id={'contentContainer'} className="site-layout">
				<div className="pcoded-main-container">
					<div className="pcoded-wrapper">
						<div className="pcoded-content">
							<div className="pcoded-inner-content">
								<div className="main-body">
									<div className="page-wrapper">{children}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AntdLayout>
		</AntdLayout>
	)
}
export default Layout
