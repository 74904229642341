import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { loadStudentCard } from '../../../store/actions/studentCard'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'

export interface INotifyOldSchoolProps {
    process: string;
    id: number;
    student_id: number;
}

export const NotifyOldSchool: React.FC<INotifyOldSchoolProps> = ({ process, id, student_id }) => {
	const [email, setEmail] = useState('')
	const dispatch = useDispatch()
	const { card, fetchStatus: cardFetchStatus } = useSelector((store: State) => store.studentCard)

	useEffect(() => {
		dispatch(loadStudentCard(student_id))
	}, [dispatch, student_id])

	const emailChangeCallback = useCallback((value) => {
		setEmail(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					email: email,
				},
			}),
		)
	}, [dispatch, email, id, process])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Уведомить старую школу</Popover.Title>
			</Row>
			{cardFetchStatus === FetchStatus.FETCHED && !!card?.school_came_from && <p>{card?.school_came_from}</p>}

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex mb-3">
							<Form.Control
								value={email}
								onChange={(e) => emailChangeCallback(e.target.value)}
								type="text"
								placeholder="Введите email"
							/>
						</div>
						<Button disabled={!email} onClick={submitButtonPressHandler} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
