import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	loadStudentCardTab,
	onLoadStudentCardTabSuccess,
	onLoadStudentCardTabError,
	loadStudentCardTabContracts,
	onLoadStudentCardTabContractsSuccess,
	onLoadStudentCardTabContractsError,
	loadStudentCardTabProcesses,
	loadStudentCardTabNotes,
	onLoadStudentCardTabProcessesError,
	onAddStudentNoteError,
	onLoadStudentCardTabProcessesSuccess,
	onAddStudentNoteSuccess,
	onLoadStudentCardTabNotesError,
	onLoadStudentCardTabNotesSuccess,
	addStudentNote,
} from '@store/actions/studentCard'

const loadStudentCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentCardTab)),
		switchMap(({ payload }) => {
			return from(deps.studentService.loadStudentCard(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadStudentCardTabSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadStudentCardTabError({ payload, error }))
				}),
			)
		}),
	)
}

const loadStudentCardTabContractsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentCardTabContracts)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardContracts(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadStudentCardTabContractsSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadStudentCardTabContractsError({ payload, error }))
				}),
			)
		}),
	)
}

const loadStudentCardTabProcessesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentCardTabProcesses)),
		switchMap(({ payload }) => {
			return from(deps.tasksService.loadProcessesMonitoring(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadStudentCardTabProcessesSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadStudentCardTabProcessesError({ payload, error }))
				}),
			)
		}),
	)
}

const loadStudentCardTabNotesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentCardTabNotes)),
		switchMap(({ payload }) => {
			return from(deps.studentService.loadStudentCardNotes(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadStudentCardTabNotesSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadStudentCardTabNotesError({ payload, error }))
				}),
			)
		}),
	)
}

const addStudentNoteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addStudentNote)),
		switchMap(({ payload }) => {
			return from(deps.studentService.loadStudentCardNotes(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onAddStudentNoteSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onAddStudentNoteError({ payload, error }))
				}),
			)
		}),
	)
}

export const studentCardEpics = combineEpics(
	loadStudentCardEpic,
	loadStudentCardTabContractsEpic,
	addStudentNoteEpic,
	loadStudentCardTabProcessesEpic,
	loadStudentCardTabNotesEpic,
)
