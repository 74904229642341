import { $BaseHttpClient, $HttpClient } from '@utils'
import { IMetaCatalogRequestResponse } from '@store/types/meta'
import { AxiosRequestConfig } from 'axios'

export class MetaServices extends $BaseHttpClient {
	/**
	 *s
	 * @description загружаем список мета-данных проекта
	 */
	loadMeta = () => $HttpClient.get('meta/')

	/**
	 *
	 * @description загружаем необходимые каталоги
	 */
	loadMetaCatalog = async (config: AxiosRequestConfig): Promise<IMetaCatalogRequestResponse> => {
		const res: unknown = await $HttpClient.request(config)
		return Promise.resolve(res as IMetaCatalogRequestResponse)
	}
}
