import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadProblemStudents, loadProblemStudentsError, loadProblemStudentsSuccess } from '../actions/problemStudents'

const loadProblemStudentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadProblemStudents)),
		switchMap(({}: ActionType<typeof loadProblemStudents>) => {
			return from(deps.usersDataProvider.loadProblemStudents()).pipe(
				switchMap((problemUsers) => {
					return of(loadProblemStudentsSuccess(problemUsers))
				}),
				catchError((err) => {
					return of(loadProblemStudentsError(err))
				}),
			)
		}),
	)
}

export const problemStudentsEpics = combineEpics(loadProblemStudentsEpic)
