import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '../store'
import { CardState } from '../types/card'
import { updateCard, updateCardError, updateCardSuccess } from '../actions/card'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState = {
	fetchStatus: FetchStatus.FETCHED,
	error: null,
	status: 'CREATE'
}

export const reducer = (state: CardState = initialState, action: Action) => {

	switch (action.type) {
	case getType(updateCard): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}
        
	case getType(updateCardSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			courses: action.payload
		}
	}

	case getType(updateCardError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}

export const card: any = persistReducer({
	key: 'AdminPanel::card',
	storage: indexedDBOldStore,
	blacklist: [],
	version: 1
}, reducer)