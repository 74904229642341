import React, { FC, useCallback, useEffect } from 'react'
import { FormGenerator } from '@frontend-modules/form-generator'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'
import { AppSkeleton } from '@frontend-modules/ui-kit'
import { FetchStatus } from '@/common/types'
import { IUserCardPopupTabMainProps } from './UserCardPopupTabMain.types'
import { showSuccessToast } from '@utils'

/**
 * @description - раздел "Основное" карточки пользователя
 * @param props
 * @constructor
 */
const UserCardPopupTabMain: FC<IUserCardPopupTabMainProps> = (props) => {
	const { id, userType, onLoadTab } = props

	const personalData = useSelector((state: State) => state?.[`${userType}CardNew`].cards?.[id]?.personalData)

	const isLoading = personalData?.fetchStatus === FetchStatus.FETCHING

	const loadTab = useCallback(() => {
		const params = {
			[`${userType}Id`]: id,
			tabName: 'personalData',
		}
		onLoadTab(params)
	}, [userType, id, onLoadTab])

	useEffect(() => {
		loadTab()
	}, [loadTab, id, onLoadTab])

	return (
		<div>
			{isLoading ? (
				<AppSkeleton active />
			) : (
				<FormGenerator
					isSendOnlyChangedFields
					metaData={personalData?.data?.generator}
					onControlClickSuccess={() => {
						loadTab()
						showSuccessToast({ message: 'Карточка успешно обновлена' })
					}}
				/>
			)}
		</div>
	)
}

export default UserCardPopupTabMain
