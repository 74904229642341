import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	changePayInfo,
	loadMoreRegistries,
	loadPayInfo,
	loadPays,
	onChangePayInfoError,
	onChangePayInfoSuccess,
	onLoadMoreRegistriesError,
	onLoadMoreRegistriesSuccess,
	onLoadPayInfoError,
	onLoadPayInfoSuccess,
	onLoadPaysError,
	onLoadPaysSuccess,
} from '@store/actions/paymentsPays'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadPaymentsPaysEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadPays)),
		switchMap(({ payload }) => {
			const { fetchingTypes, ...restPayload } = payload
			const normalizedPayload = {
				...restPayload,
				filters: normalizeFilterPayload(restPayload.filters),
			}
			return from(deps.paymentsService.loadPaysList(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadPaysSuccess({ ...response, fetchingTypes }))
				}),
				catchError((err) => {
					return of(onLoadPaysError({ ...err, fetchingTypes }))
				}),
			)
		}),
	)
}

const loadMorePaymentsRegistriesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMoreRegistries)),
		switchMap(({ payload }) => {
			const { fetchingTypes, ...restPayload } = payload
			const normalizedPayload = {
				...restPayload,
				filters: normalizeFilterPayload(restPayload.filters),
			}
			return from(deps.paymentsService.loadPaysList(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadMoreRegistriesSuccess({ ...response, fetchingTypes }))
				}),
				catchError((err) => {
					return of(onLoadMoreRegistriesError({ ...err, fetchingTypes }))
				}),
			)
		}),
	)
}

const loadPaymentPayInfoEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadPayInfo)),
		switchMap(({ payload }) => {
			return from(deps.paymentsService.crudPay(payload)).pipe(
				switchMap((response) => {
					return of(onLoadPayInfoSuccess({ id: payload.id, response }))
				}),
				catchError((err) => {
					return of(onLoadPayInfoError({ id: payload.id, err }))
				}),
			)
		}),
	)
}

const changePaymentPayInfoEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(changePayInfo)),
		switchMap(({ payload }) => {
			return from(deps.paymentsService.crudPay(payload)).pipe(
				switchMap((response) => {
					if (response?.response?.status === 400) {
						return of(onChangePayInfoError({ id: payload.id, response }))
					} else {
						return of(onChangePayInfoSuccess({ id: payload.id, response }))
					}
				}),
				catchError((err) => {
					return of(onChangePayInfoError({ id: payload.id, err }))
				}),
			)
		}),
	)
}

export const paymentsPaysEpics = combineEpics(
	loadPaymentsPaysEpic,
	loadPaymentPayInfoEpic,
	changePaymentPayInfoEpic,
	loadMorePaymentsRegistriesEpic,
)
