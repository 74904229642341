import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap, mergeMap, map } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	onLoadTasksSuccess,
	loadTasks,
	onLoadTasksError,
	loadTask,
	onLoadTaskSuccess,
	onLoadTaskError,
} from '@store/actions/tasks'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadTasksEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadTasks)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
				sorting: payload?.sorting,
			}
			return from(deps.tasksService.loadTasks(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadTasksSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadTasksError(err))
				}),
			)
		}),
	)
}

const loadTaskEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadTask)),
		mergeMap(({ payload }) => {
			return from(deps.tasksService.loadTaskData(payload)).pipe(
				map((response) => {
					if (response?.isAxiosError) {
						return onLoadTaskError({ id: payload, data: response })
					}
					return onLoadTaskSuccess({ id: payload, data: response })
				}),
				catchError((err) => {
					return of(onLoadTaskError(err))
				}),
			)
		}),
	)
}

export const tasksEpics = combineEpics(loadTasksEpic, loadTaskEpic)
