import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const GenerateOrder: React.FC<any> = ({ id, action_name }) => {
	const [number, setNumber] = useState('')
	const [school, setSchool] = useState('')
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

	const dispatch = useDispatch()

	const handleChangeSchool = useCallback((e) => {
		setSchool(e.target.value)
	}, [])

	const numberChangeCallback = useCallback((e: any) => {
		setNumber(e.target.value)
	}, [])

	const dateChangeCallback = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				process: action_name,
				parameters: {
					number,
					[action_name === 'generate_order_existing_contract' ? 'order_date' : 'date']:
                        moment(date).format('YYYY-MM-DD'),
					parameters: {
						school_came_from: school,
					},
				},
				id,
			}),
		)
	}, [action_name, date, dispatch, id, number, school])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Сгенерировать приказ</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<div>
						<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
							<Form.Label>Номер</Form.Label>
							<Form.Control
								value={number}
								onChange={numberChangeCallback}
								placeholder="Номер"
								type="text"
							/>
						</Form.Group>
					</div>
					<div>
						<Form.Group>
							<Form.Label>Введите школу, из которой прибыл ученик</Form.Label>
							<Form.Control
								name="school_came_from"
								onChange={handleChangeSchool}
								value={school}
								type="text"
								placeholder="Введите школу"
							/>
						</Form.Group>
					</div>
					<div>
						<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
							<Form.Label>Дата</Form.Label>
							<Form.Control value={date} onChange={dateChangeCallback} placeholder="Дата" type="date" />
						</Form.Group>
					</div>
					<Button onClick={submitButtonPressHandler} variant="primary">
                        Сгенерировать
					</Button>
				</Col>
			</Row>
		</Col>
	)
}
