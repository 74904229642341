import React, { useEffect, useState } from 'react'
import { AppButton } from '@frontend-modules/ui-kit'
import { FormGeneratorModal } from '@frontend-modules/form-generator'
import './AvailableActionsPopup.scss'

/**
 * @description - компонент доступных действий
 * @param props
 * @constructor
 */
const AvailableActionsPopup = (props) => {
	const { actions, isButtonDisabled, onFinalStep } = props
	const [isPopupShow, setPopupShow] = useState(false)

	const [localMetaData, setLocalMetaData] = useState(null)

	useEffect(() => {
		setLocalMetaData(actions)
	}, [actions, isPopupShow])

	/**
	 * @description при нажатии на кнопки выбора в модалке, смотрим есть ли в текущем шаге данные для следующего шага,
	 *              если есть, то делаем следующий шаг текущим и так пока шаги не закончатся,
	 *              после чего отправляем все данные пришедшие из модалки
	 * @param data
	 */
	const onControlClickSuccess = (data) => {
		const nextPopupData = localMetaData?.availableActions?.[data?.chooseAction?.value]
		if (nextPopupData) {
			setLocalMetaData(nextPopupData)
		} else {
			setPopupShow(false)
			onFinalStep?.(data)
		}
	}

	return (
		<>
			{isPopupShow && (
				<FormGeneratorModal
					actionType={'returnData'}
					metaData={localMetaData}
					isShow={isPopupShow}
					setShow={setPopupShow}
					onControlClickSuccess={onControlClickSuccess}
					rootClassName={'available-actions-popup'}
				/>
			)}
			<AppButton
				isDisabled={isButtonDisabled || !actions?.widgetList?.length}
				label={'Выбрать действие'}
				onClick={() => {
					setPopupShow(true)
				}}
			/>
		</>
	)
}

export default AvailableActionsPopup
