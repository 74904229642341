import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { updateCard, updateCardError, updateCardSuccess } from '../actions/card'
import {
	addParentCard,
	addParentCardError,
	addParentCardSuccess,
	loadParentCard,
	loadParentCardError,
	loadParentCardSuccess,
	loadParentLetterStatuses,
	loadParentLetterStatusesError,
	loadParentLetterStatusesSuccess,
	loadParentSmsStatuses,
	loadParentSmsStatusesError,
	loadParentSmsStatusesSuccess,
} from '../actions/parentCard'
import { loadProcessesStart } from '../actions/processes'
import {
	loadStudentCard,
	loadStudentCardError,
	loadStudentCardSuccess,
	generateEnrollmentCertificate,
	onGenerateEnrollmentCertificateError,
	StudentCardActions,
	onGenerateEnrollmentCertificateSuccess,
} from '../actions/studentCard'
import { showAlert } from '../actions/alert'
import { getEducationCostNum } from '../../Screens/Debts/utils'
import { showErrorToast } from '@utils'

const updateCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(updateCard)),
		switchMap(({ payload: { user_role, id, data } }: ActionType<typeof updateCard>) => {
			return from(deps.processesDataProvider.updateCard(user_role, id, data)).pipe(
				switchMap((response) => {
					const {
						studentSearch,
						parentSearch,
						actionsSearch,
						schoolSearch,
						formatSearch,
						tariffSearch,
						staffUsersSearch,
						createdMonthSearch,
						processTypesSearch,
						gradeSearch,
						idSearch,
					} = store$.value.search
					const { constants } = store$.value.constants

					return of(
						updateCardSuccess(response),
						loadProcessesStart({
							page: store$.value.processes.currentPage,
							searchStudent: studentSearch,
							idSearch,
							searchParent: parentSearch,
							searchActions: actionsSearch,
							searchTariff: constants.EDUCATION_COST
								? tariffSearch.map((tariff) =>
										getEducationCostNum(constants.constants.EDUCATION_COST, tariff),
								  )
								: [],
							searchSchool: schoolSearch,
							searchForm: formatSearch,
							searchStaffUsers: staffUsersSearch,
							searchCreatedMonth: createdMonthSearch,
							searchProcessTypes: processTypesSearch,
							searchGrade: gradeSearch,
						}),
						showAlert({
							title: 'Успешно сохранено!',
							type: 'success',
						}),
						// {
						//     type: '@@router/LOCATION_CHANGE',
						//     payload: {
						//         location: {
						//             pathname: '/',
						//             search: '',
						//             hash: '',
						//         },
						//         action: 'PUSH',
						//         isFirstRendering: false,
						//     },
						// },
					)
				}),
				catchError((err) => {
					return of(
						updateCardError(err),
						showAlert({
							title: 'Ошибка!',
							text: 'Данные не были сохранены',
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const loadParentCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCard)),
		switchMap(({ payload }: ActionType<typeof loadParentCard>) => {
			return from(deps.processesDataProvider.loadParentCard(payload)).pipe(
				switchMap((response) => {
					return of(loadParentCardSuccess(response))
				}),
				catchError((err) => {
					return of(loadParentCardError(err))
				}),
			)
		}),
	)
}

const loadParentSmsStatusesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentSmsStatuses)),
		switchMap(({ payload }: ActionType<typeof loadParentSmsStatuses>) => {
			return from(deps.processesDataProvider.loadParentSmsStatuses(payload)).pipe(
				switchMap((response) => {
					return of(loadParentSmsStatusesSuccess(response))
				}),
				catchError((err) => {
					return of(loadParentSmsStatusesError(err))
				}),
			)
		}),
	)
}

const loadParentLetterStatusesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentLetterStatuses)),
		switchMap(({ payload }: ActionType<typeof loadParentSmsStatuses>) => {
			return from(deps.processesDataProvider.loadParentLettersStatuses(payload)).pipe(
				switchMap((response) => {
					return of(loadParentLetterStatusesSuccess(response))
				}),
				catchError((err) => {
					return of(loadParentLetterStatusesError(err))
				}),
			)
		}),
	)
}

const loadStudentCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadStudentCard)),
		switchMap(({ payload }: ActionType<typeof loadStudentCard>) => {
			return from(deps.processesDataProvider.loadStudentCard(payload)).pipe(
				switchMap((response) => {
					return of(loadStudentCardSuccess(response))
				}),
				catchError((err) => {
					return of(loadStudentCardError(err))
				}),
			)
		}),
	)
}

const generateEnrollmentCertificateEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(generateEnrollmentCertificate)),
		switchMap(({ payload }: ActionType<typeof generateEnrollmentCertificate>) => {
			return from(deps.processesDataProvider.generateEnrollmentCertificate(payload)).pipe(
				switchMap((response) => {
					window.open(response.file)
					return of(onGenerateEnrollmentCertificateSuccess(response))
				}),
				catchError((err) => {
					showErrorToast({
						message:
							err?.response?.data?.extra?.fields?.non_field_errors || 'Справку сформировать не удалось',
					})
					return of(onGenerateEnrollmentCertificateError(err))
				}),
			)
		}),
	)
}

const addParentCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addParentCard)),
		switchMap(({ payload }: ActionType<typeof addParentCard>) => {
			return from(deps.processesDataProvider.addParentCard(payload)).pipe(
				switchMap((response) => {
					return of(addParentCardSuccess(response), {
						type: '@@router/LOCATION_CHANGE',
						payload: {
							location: {
								pathname: '/',
								search: '',
								hash: '',
							},
							action: 'PUSH',
							isFirstRendering: false,
						},
					})
				}),
				catchError((err) => {
					return of(addParentCardError(err))
				}),
			)
		}),
	)
}

export const cardEpics = combineEpics(
	updateCardEpic,
	loadParentCardEpic,
	loadStudentCardEpic,
	generateEnrollmentCertificateEpic,
	addParentCardEpic,
	loadParentSmsStatusesEpic,
	loadParentLetterStatusesEpic,
)
