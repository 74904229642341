import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import {ActionType, getType, PayloadAction} from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { showAlert } from '../actions/alert'
import {
	deletePaymentError,
	deletePaymentStart,
	deletePaymentSuccess,
	identificateError,
	identificateStart,
	identificateSuccess,
	loadPaymentSitesError,
	loadPaymentSitesStart,
	loadPaymentSitesSuccess,
	loadUnverPaymentsError,
	loadUnverPaymentsStart,
	loadUnverPaymentsSuccess,
	loadVerPaymentsError,
	loadVerPaymentsStart,
	loadVerPaymentsSuccess,
} from '../actions/payment'
import {DeletePaymentStartPayload, IdentificateStartPayload} from '../types/payment'

const loadPaymentSitesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadPaymentSitesStart)),
		switchMap(({ payload }) => {
			return from(deps.paymentDataProvider.loadPaymentSites(payload)).pipe(
				switchMap((paymentSites) => {
					return of(loadPaymentSitesSuccess(paymentSites))
				}),
				catchError((err) => {
					return of(loadPaymentSitesError(err))
				}),
			)
		}),
	)
}

const loadVerPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadVerPaymentsStart)),
		switchMap(({ payload }) => {
			return from(deps.paymentDataProvider.loadVerPayments(payload)).pipe(
				switchMap((res) => {
					return of(loadVerPaymentsSuccess(res))
				}),
				catchError((err) => {
					return of(loadVerPaymentsError(err))
				}),
			)
		}),
	)
}

const loadUnverPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadUnverPaymentsStart)),
		switchMap(({ payload }) => {
			return from(deps.paymentDataProvider.loadUnverPayments(payload)).pipe(
				switchMap((res) => {
					return of(loadUnverPaymentsSuccess(res))
				}),
				catchError((err) => {
					return of(loadUnverPaymentsError(err))
				}),
			)
		}),
	)
}

const deletePaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(deletePaymentStart)),
		switchMap(({ payload }: { payload: DeletePaymentStartPayload }) => {
			return from(deps.paymentDataProvider.delete(payload)).pipe(
				switchMap(() => {
					return of(
						deletePaymentSuccess(payload.paymentId),
						loadUnverPaymentsStart({ uPage: 1, uQuery: payload.queryString }),
						showAlert({
							title: 'Успех!',
							text: 'Платеж успешно удален!',
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(deletePaymentError(err))
				}),
			)
		}),
	)
}

const identificatePaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(identificateStart)),
		switchMap(({ payload }: { payload: IdentificateStartPayload }) => {
			return from(deps.paymentDataProvider.identificate(payload)).pipe(
				switchMap((notes) => {
					return of(
						identificateSuccess(payload.payment_id),
						loadUnverPaymentsStart({ uPage: 1, uQuery: payload.queryString }),
						loadVerPaymentsStart({ vPage: 1, vQuery: 'type=qr' }),
						showAlert({
							title: 'Успех!',
							text: 'Платеж успешно идентифицирован!',
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(identificateError(err))
				}),
			)
		}),
	)
}

export const paymentEpics = combineEpics(
	loadVerPaymentEpic,
	loadUnverPaymentEpic,
	identificatePaymentEpic,
	loadPaymentSitesEpic,
	deletePaymentEpic,
)
