import { ActionType, createAction } from 'typesafe-actions'

export const loadStudentCardTab = createAction('@student/LOAD_STUDENT_CARD_TAB')<any>()
export const onLoadStudentCardTabSuccess = createAction('@student/ON_LOAD_STUDENT_CARD_TAB_SUCCESS')<any>()
export const onLoadStudentCardTabError = createAction('@student/ON_LOAD_STUDENT_CARD_TAB_ERROR')<any>()

export const loadStudentCardTabContracts = createAction('@student/LOAD_STUDENT_CARD_TAB_CONTRACTS_HISTORY')<any>()
export const onLoadStudentCardTabContractsSuccess = createAction(
	'@student/ON_LOAD_STUDENT_CARD_TAB_CONTRACTS_SUCCESS',
)<any>()
export const onLoadStudentCardTabContractsError = createAction(
	'@student/ON_LOAD_STUDENT_CARD_TAB_CONTRACTS_ERROR',
)<any>()

export const loadStudentCardTabProcesses = createAction('@student/LOAD_STUDENT_CARD_TAB_PROCESSES')<any>()
export const onLoadStudentCardTabProcessesSuccess = createAction(
	'@student/ON_LOAD_STUDENT_CARD_TAB_PROCESSES_SUCCESS',
)<any>()
export const onLoadStudentCardTabProcessesError = createAction(
	'@student/ON_LOAD_STUDENT_CARD_TAB_PROCESSES_ERROR',
)<any>()

export const loadStudentCardTabNotes = createAction('@student/LOAD_STUDENT_CARD_TAB_NOTES')<any>()
export const onLoadStudentCardTabNotesSuccess = createAction('@student/ON_LOAD_STUDENT_CARD_TAB_NOTES_SUCCESS')<any>()
export const onLoadStudentCardTabNotesError = createAction('@student/ON_LOAD_STUDENT_CARD_TAB_NOTES_ERROR')<any>()
export const addStudentNote = createAction('@student/ADD_STUDENT_NOTE')<any>()
export const onAddStudentNoteSuccess = createAction('@student/ON_ADD_STUDENT_NOTE_SUCCESS')<any>()
export const onAddStudentNoteError = createAction('@student/ON_ADD_STUDENT_NOTE_ERROR')<any>()

export type StudentCardActions =
	| ActionType<typeof loadStudentCardTab>
	| ActionType<typeof onLoadStudentCardTabSuccess>
	| ActionType<typeof onLoadStudentCardTabError>
	| ActionType<typeof loadStudentCardTabContracts>
	| ActionType<typeof onLoadStudentCardTabContractsSuccess>
	| ActionType<typeof onLoadStudentCardTabContractsError>
	| ActionType<typeof loadStudentCardTabProcesses>
	| ActionType<typeof onLoadStudentCardTabProcessesSuccess>
	| ActionType<typeof onLoadStudentCardTabProcessesError>
	| ActionType<typeof loadStudentCardTabNotes>
	| ActionType<typeof onLoadStudentCardTabNotesSuccess>
	| ActionType<typeof onLoadStudentCardTabNotesError>
	| ActionType<typeof addStudentNote>
	| ActionType<typeof onAddStudentNoteSuccess>
	| ActionType<typeof onAddStudentNoteError>
