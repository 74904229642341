import { persistReducer } from 'redux-persist'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '../store'
import { ProfileState } from '../types/profile'
import { loadProfile, loadProfileError, loadProfileSuccess, resetProfileState } from '../actions/profile'
import { indexedDBOldStore } from '@/store/STORAGE_INDEXDB'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	profile: null,
	roles: null,
}

export const reducer = (state: ProfileState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadProfile): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(resetProfileState): {
			return {
				...initialState,
			}
		}

		case getType(loadProfileSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				profile: action.payload.profile,
				roles: action.payload.roles,
			}
		}

		case getType(loadProfileError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const profile: any = persistReducer(
	{
		key: 'AdminPane::profile',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
