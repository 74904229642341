import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import { ParentsState } from '../types/parents'
import {
	loadMoreParents,
	loadParents,
	onLoadMoreParentsError,
	onLoadMoreParentsSuccess,
	onLoadParentsError,
	onLoadParentsSuccess,
} from '@store/actions/parents'

const initialState = {
	fetchStatus: FetchStatus.INITIAL,
	errors: null,
	data: null,
}

export const parentsReducer = (state: ParentsState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadParents): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(onLoadParentsSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				data: action.payload,
			}
		}

		case getType(onLoadParentsError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				errors: action.payload,
			}
		}
		case getType(loadMoreParents): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING_MORE,
			}
		}

		case getType(onLoadMoreParentsSuccess): {
			const list = action.payload.results
			const pagination = action.payload.pagination

			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				data: {
					results: [...state.data.results, ...list],
					pagination: pagination,
				},
			}
		}

		case getType(onLoadMoreParentsError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				errors: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
