import React, { FC, useCallback, useEffect } from 'react'
import { ILoadAgreementsParams, TUserCardPopupTabAgreements } from './UserCardPopupTabAgreements.types'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { AppSkeleton } from '@frontend-modules/ui-kit'
import { FormGenerator } from '@frontend-modules/form-generator'
import './UserCardPopupTabAgreements.scss'
import { Empty } from 'antd'

/**
 * @description - таб со списком соглашений пользователя
 * генерируется на бекенде
 * @param props
 * @constructor
 */
const UserCardPopupTabAgreements: FC<TUserCardPopupTabAgreements> = (props) => {
	const { id, userType, onLoadTab } = props

	const agreementsData = useSelector((state: State) => state?.[`${userType}CardNew`].cards?.[id]?.agreements)
	const isAgreementsExist = !!agreementsData?.data?.generator?.widgetList.length

	const isLoading = agreementsData?.fetchStatus === FetchStatus.FETCHING

	const loadTab = useCallback(() => {
		const params: ILoadAgreementsParams = {
			tabName: 'agreements',
		}
		if (userType === 'parent') {
			params.parentId = id
		}
		if (userType === 'student') {
			params.studentId = id
		}
		onLoadTab(params)
	}, [userType, id, onLoadTab])

	useEffect(() => {
		loadTab()
	}, [loadTab, id, onLoadTab])

	return (
		<div className={'user-card-popup-tab-agreements'}>
			{isLoading && (
				<div className={'user-card-popup-tab-agreements-loader'}>
					<AppSkeleton.Button active />
					<AppSkeleton.Button active />
				</div>
			)}
			{!isLoading && !isAgreementsExist && (
				<div className={'user-card-popup-tab-agreements-empty'}>
					<Empty description={'Документы не найдены'} />
				</div>
			)}
			{!isLoading && isAgreementsExist && <FormGenerator metaData={agreementsData?.data?.generator} />}
		</div>
	)
}

export default UserCardPopupTabAgreements
