import moment from 'moment/moment'

const getCurrentDate = (): string => {
	return moment().format('YYYY-MM-DD')
}

const getCurrentDateMinusSomeDays = (daysToMinus: number): string => {
	return moment().subtract(daysToMinus, 'days').format('YYYY-MM-DD')
}

const getCurrentDatePlusOneYear = (): string => {
	return moment().add(1, 'year').format('YYYY-MM-DD')
}

export { getCurrentDate, getCurrentDateMinusSomeDays, getCurrentDatePlusOneYear }
