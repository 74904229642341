import { ActionType, createAction } from 'typesafe-actions'

export const updateForm = createAction('@form/UPDATE')<any>()
export const clearForm = createAction('@form/CLEAR')<any>()

export const loadForm = createAction('@form/LOAD/START')<{
    student_id: any,
    parent_id: any,
}>()
export const loadFormSuccess = createAction('@form/LOAD/SUCCESS')<any>()
export const loadFormError = createAction('@form/LOAD/ERROR')<any>()


export type FormActions = ActionType<typeof updateForm> | ActionType<typeof clearForm> | ActionType<typeof loadForm> | ActionType<typeof loadFormSuccess> | ActionType<typeof loadFormError>;
