import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap,} from 'rxjs/operators'
import { getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {addBuisnessProcessError, addBuisnessProcessStart, addBuisnessProcessSuccess} from '../actions/buisness'
import {showAlert} from '../actions/alert'
import {  } from '../../common/constants'

const addBuisnessProcessEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addBuisnessProcessStart)),
		switchMap(({ payload }) => {
			return from(deps.processesDataProvider.addBuisnessProcess(payload)).pipe(
				switchMap(res => {
					return of(addBuisnessProcessSuccess(res), {
						type: '@@router/LOCATION_CHANGE',
						payload: {
							location: {
								pathname: '/',
								search: '',
								hash: '',
							},
							action: 'PUSH',
							isFirstRendering: false
						}
					}, showAlert({
						type: 'success',
						title: 'Процесс успешно создан!',
						text: ''
					}))
				}),
				catchError(err => {
					return of(addBuisnessProcessError(err), showAlert({
						title: 'Ошибка',
						text: JSON.stringify(err?.response?.data),
						type: 'error'
					}))
				})
			)
		})
	)
}

export const buisnessEpics = combineEpics(addBuisnessProcessEpic)
