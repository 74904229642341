import { $BaseHttpClient, $HttpClient } from '@utils'

export class ParentServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список родителей
	 */
	loadParentList = (params) => {
		return $HttpClient.get('users/', params)
	}
	/**
	 *
	 * @description загружаем таб карточки родителя
	 */
	loadParentCard = (params) => {
		return $HttpClient.post('parent-card/', params)
	}

	/**
	 *
	 * @description загружаем информацию о личном счете родителя
	 */
	loadParentCardPersonalAccountBalance = (params) => {
		return $HttpClient.post('parent-card/personal-account/today/', params)
	}

	/**
	 *
	 * @description загружаем список платежей родителя
	 */
	loadParentCardPersonalAccountHistory = (params) => {
		return $HttpClient.post('api/v1/personal-account-history/', params)
	}

	/**
	 *
	 * @description загружаем договоров родителя
	 */
	loadParentCardContracts = (params) => {
		return $HttpClient.post('contract-tree/', params)
	}

	/**
	 *
	 * @description загружаем заметки по родителю
	 */
	loadParentCardNotes = (params) => {
		return $HttpClient.post('notes/parent/', params)
	}

	sendMessage = (params) => {
		return $HttpClient.post('api/send/message/', params)
	}

	/**
	 *
	 * @description загружаем бонусы родителя
	 */
	loadParentCardBonuses = (params) => {
		return $HttpClient.post('parent-card/bonuses/', params)
	}
}
