import React from 'react'

import moment from 'moment'

export const Messages = (props: any) => {
	let image = <></>
	if (props.type) {
		image = (
			<div className="media-left photo-table">
				{/*<img className="media-object img-radius img-radius m-t-5" src={props.photo} alt={props.name} />*/}
				{
					props.name
				}
			</div>
		)
	}

	let msgClass = ['media-body']
	if(props.type) {
		msgClass = [...msgClass, 'chat-menu-content']
	} else {
		msgClass = [...msgClass, 'chat-menu-reply']
	}

	return (
		<div className={`media chat-messages ${props.type ? 'left' : 'right'}`}>
			{image}
			<div className={msgClass.join(' ')}>
				<div className="">
					<p style={{ wordBreak: 'break-word' }} className="chat-cont">{props.message.text}</p>
				</div>
				<p className="chat-time">{moment(props.message.pub_date).format('DD.MM.YYYY HH:mm')}</p>
			</div>
		</div>
	)
}
