import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'
import { NoteState } from '../types/note'
import { loadNote, loadNoteError, loadNoteSuccess } from '../actions/note'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	notes: [],
}

export const note = (state: NoteState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadNote): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadNoteSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			notes: action.payload,
		}
	}

	case getType(loadNoteError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
