import { FuncEpic } from '../../common/types'
import { combineEpics, ofType } from 'redux-observable'
import { ActionType, getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	addCuratorsError,
	addCuratorsStart,
	addCuratorsSuccess,
	addStudentsError,
	addStudentsStart,
	addStudentsSuccess,
	createClassError,
	createClassStart,
	createClassSuccess,
	deleteClassError,
	deleteClassStart,
	deleteClassSuccess,
	editClassError,
	editClassStart,
	editClassSuccess,
	expelStudentError,
	expelStudentStart,
	expelStudentSuccess,
	loadClassesError,
	loadClassesStart,
	loadClassesSuccess,
	loadCuratorsError,
	loadCuratorsStart,
	loadCuratorsSuccess,
	loadParallelsError,
	loadParallelsStart,
	loadParallelsSuccess,
	loadTutorsError,
	loadTutorsStart,
	loadTutorsSuccess,
	removeCuratorsError,
	removeCuratorsStart,
	removeCuratorsSuccess,
} from '../actions/classes'
import { AxiosError } from 'axios'
import { showAlert } from '../actions/alert'

const expelStudentEpic: FuncEpic = (action$, _, deps) => {
	return action$.pipe(
		ofType(getType(expelStudentStart)),
		switchMap(({ payload }: ActionType<typeof expelStudentStart>) => {
			return from(deps.classesDataProvider.expelStudent(payload.studentGroupId, payload.studentId)).pipe(
				switchMap((response) => {
					return of(
						expelStudentSuccess(response),
						loadClassesStart({}),
						showAlert({
							type: 'success',
							title: 'Успех!',
							text: 'Студент отчислен из класса.',
						}),
					)
				}),
				catchError((err) => {
					return of(
						expelStudentError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Студент не отчислен.',
						}),
					)
				}),
			)
		}),
	)
}

const loadClassesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadClassesStart)),
		switchMap(({ payload }: ActionType<typeof loadClassesStart>) => {
			return from(deps.classesDataProvider.loadClasses(payload)).pipe(
				switchMap((response: any) => {
					return of(loadClassesSuccess(response))
				}),
				catchError((err: AxiosError) => {
					return of(loadClassesError(err))
				}),
			)
		}),
	)
}

const loadCuratorsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadCuratorsStart)),
		switchMap(() => {
			return from(deps.classesDataProvider.loadCurators()).pipe(
				switchMap((response: any) => {
					return of(loadCuratorsSuccess(response))
				}),
				catchError((err: AxiosError) => {
					return of(loadCuratorsError(err))
				}),
			)
		}),
	)
}

const loadTutorEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadTutorsStart)),
		switchMap(() => {
			return from(deps.classesDataProvider.loadTutors()).pipe(
				switchMap((response: any) => {
					return of(loadTutorsSuccess(response))
				}),
				catchError((err: AxiosError) => {
					return of(loadTutorsError(err))
				}),
			)
		}),
	)
}

const loadParallelsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParallelsStart)),
		switchMap(() => {
			return from(deps.classesDataProvider.loadParallels()).pipe(
				switchMap((response: any) => {
					return of(loadParallelsSuccess(response))
				}),
				catchError((err: AxiosError) => {
					return of(loadParallelsError(err))
				}),
			)
		}),
	)
}

const addCuratorsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addCuratorsStart)),
		switchMap(({ payload }: ActionType<typeof addCuratorsStart>) => {
			return from(deps.classesDataProvider.addCurators(payload)).pipe(
				switchMap((response: any) => {
					return of(
						addCuratorsSuccess(response),
						showAlert({
							type: 'success',
							title: 'Кураторы успешно добавлены!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						addCuratorsError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Кураторы не были добавлены.',
						}),
					)
				}),
			)
		}),
	)
}

const removeCuratorsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(removeCuratorsStart)),
		switchMap(({ payload }: ActionType<typeof removeCuratorsStart>) => {
			return from(deps.classesDataProvider.removeCurators(payload)).pipe(
				switchMap((response: any) => {
					return of(
						removeCuratorsSuccess(response),
						showAlert({
							type: 'success',
							title: 'Кураторы успешно удалены!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						removeCuratorsError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Кураторы не были удалены.',
						}),
					)
				}),
			)
		}),
	)
}

const addStudentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addStudentsStart)),
		switchMap(({ payload }: ActionType<typeof addStudentsStart>) => {
			return from(deps.classesDataProvider.addStudents(payload)).pipe(
				switchMap((response: any) => {
					return of(
						addStudentsSuccess(response),
						loadClassesStart({}),
						showAlert({
							type: 'success',
							title: 'Студенты успешно добавлены!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						addStudentsError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Студенты не были добавлены.',
						}),
					)
				}),
			)
		}),
	)
}

const createClassEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createClassStart)),
		switchMap(({ payload }: ActionType<typeof createClassStart>) => {
			return from(deps.classesDataProvider.createClass(payload)).pipe(
				switchMap((response: any) => {
					return of(
						createClassSuccess(response),
						showAlert({
							type: 'success',
							title: 'Новый класс успешно добавлен!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						createClassError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: err?.response?.data?.extra?.fields?.[0] || 'Класс не был добавлен.',
						}),
					)
				}),
			)
		}),
	)
}

const editClassEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(editClassStart)),
		switchMap(({ payload }: ActionType<typeof editClassStart>) => {
			return from(deps.classesDataProvider.editClass(payload)).pipe(
				switchMap((response: any) => {
					return of(
						editClassSuccess(response),
						showAlert({
							type: 'success',
							title: 'Класс успешно изменен!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						editClassError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Класс не был изменен. Поля не могут быть пустыми.',
						}),
					)
				}),
			)
		}),
	)
}

const deleteClassEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(deleteClassStart)),
		switchMap(({ payload }: ActionType<typeof deleteClassStart>) => {
			return from(deps.classesDataProvider.deleteClass(payload)).pipe(
				switchMap(() => {
					return of(
						deleteClassSuccess(payload),
						showAlert({
							type: 'success',
							title: 'Класс успешно удален!',
							text: '',
						}),
					)
				}),
				catchError((err: AxiosError) => {
					return of(
						deleteClassError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Класс не был удален.',
						}),
					)
				}),
			)
		}),
	)
}

export const classesEpics = combineEpics(
	expelStudentEpic,
	loadClassesEpic,
	createClassEpic,
	editClassEpic,
	deleteClassEpic,
	loadCuratorsEpic,
	loadTutorEpic,
	addCuratorsEpic,
	removeCuratorsEpic,
	addStudentsEpic,
	loadParallelsEpic,
)
