import { BaseRestDataProvider } from './BaseRestDataProvider'
import { oldAxios } from './axios-init'

export class MessageDataProvider extends BaseRestDataProvider {
	sendMessage = (payload: any) => {
		return oldAxios
			.post(`${this.host}/api/send/message/`, payload, {
				withCredentials: true,
				xsrfCookieName: 'csrftoken',
				xsrfHeaderName: 'X-CSRFToken',
			})
			.then((res) => res.data)
	}
}
