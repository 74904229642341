import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	createContract,
	onCreateContractError,
	onCreateContractSuccess,
	createTeacherProfile,
	onCreateTeacherProfileSuccess,
	onCreateTeacherProfileError,
	loadParentCardTab,
	onLoadParentCardTabSuccess,
	onLoadParentCardTabError,
	loadParentCardTabProcesses,
	onLoadParentCardTabProcessesSuccess,
	onLoadParentCardTabProcessesError,
	sendMessage,
	onSendMessageSuccess,
	onSendMessageError,
	loadParentCardTabPersonalAccountBalance,
	onLoadParentCardTabPersonalAccountBalanceSuccess,
	onLoadParentCardTabPersonalAccountBalanceError,
	loadParentCardTabPersonalAccountHistory,
	onLoadParentCardTabPersonalAccountHistorySuccess,
	onLoadParentCardTabPersonalAccountHistoryError,
	loadParentCardTabContracts,
	onLoadParentCardTabContractsSuccess,
	onLoadParentCardTabContractsError,
	loadParentCardTabNotes,
	onLoadParentCardTabNotesSuccess,
	onLoadParentCardTabNotesError,
	addParentNote,
	onAddParentNoteSuccess,
	onAddParentNoteError,
	loadParentCardTabBonuses,
	onLoadParentCardTabBonusesSuccess,
	onLoadParentCardTabBonusesError,
} from '@store/actions/parentCard'
import { showSuccessToast } from '@utils'

const loadParentCardEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTab)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCard(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabError({ payload, error }))
				}),
			)
		}),
	)
}

const loadParentCardTabProcessesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabProcesses)),
		switchMap(({ payload }) => {
			return from(deps.tasksService.loadProcessesMonitoring(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabProcessesSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabProcessesError({ payload, error }))
				}),
			)
		}),
	)
}

const loadParentCardTabPersonalAccountBalanceEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabPersonalAccountBalance)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardPersonalAccountBalance(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabPersonalAccountBalanceSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabPersonalAccountBalanceError({ payload, error }))
				}),
			)
		}),
	)
}

const loadParentCardTabPersonalAccountHistoryEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabPersonalAccountHistory)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardPersonalAccountHistory(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabPersonalAccountHistorySuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabPersonalAccountHistoryError({ payload, error }))
				}),
			)
		}),
	)
}

const loadParentCardTabContractsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabContracts)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardContracts(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabContractsSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabContractsError({ payload, error }))
				}),
			)
		}),
	)
}

const loadParentCardTabNotesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabNotes)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardNotes(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabNotesSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabNotesError({ payload, error }))
				}),
			)
		}),
	)
}

const addParentNoteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addParentNote)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardNotes(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onAddParentNoteSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onAddParentNoteError({ payload, error }))
				}),
			)
		}),
	)
}

const createContractEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createContract)),
		switchMap(({ payload }) => {
			return from(deps.tasksService.createTwoSidesContract(payload)).pipe(
				switchMap((response) => {
					if (response) {
						showSuccessToast({
							message: 'Заключение договора началось',
						})
						return of(onCreateContractSuccess(response))
					}
				}),
				catchError((err) => {
					return of(onCreateContractError(err))
				}),
			)
		}),
	)
}

const createTeacherProfileEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createTeacherProfile)),
		switchMap(({ payload }) => {
			return from(deps.tasksService.createTeacherProfile(payload)).pipe(
				switchMap((response) => {
					if (response) {
						showSuccessToast({
							message: 'Создание ученического профиля началось',
						})
						return of(onCreateTeacherProfileSuccess(response))
					}
				}),
				catchError((err) => {
					return of(onCreateTeacherProfileError(err))
				}),
			)
		}),
	)
}
const sendMessageEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(sendMessage)),
		switchMap(({ payload }) => {
			return from(deps.parentService.sendMessage(payload)).pipe(
				switchMap((response) => {
					showSuccessToast({
						message: 'Сообщение успешно отправлено',
					})
					return of(onSendMessageSuccess(response))
				}),
				catchError((err) => {
					return of(onSendMessageError(err))
				}),
			)
		}),
	)
}

const loadParentCardTabBonusesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentCardTabBonuses)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentCardBonuses(payload)).pipe(
				switchMap((response) => {
					if (response) {
						return of(onLoadParentCardTabBonusesSuccess({ payload, response }))
					}
				}),
				catchError((error) => {
					return of(onLoadParentCardTabBonusesError({ payload, error }))
				}),
			)
		}),
	)
}

export const parentCardEpics = combineEpics(
	createContractEpic,
	createTeacherProfileEpic,
	loadParentCardEpic,
	loadParentCardTabProcessesEpic,
	loadParentCardTabPersonalAccountBalanceEpic,
	loadParentCardTabPersonalAccountHistoryEpic,
	loadParentCardTabBonusesEpic,
	loadParentCardTabNotesEpic,
	loadParentCardTabContractsEpic,
	addParentNoteEpic,
	sendMessageEpic,
)
