import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { showAlert } from '../../../store/actions/alert'
import { loadClassesStart, loadParallelsStart } from '../../../store/actions/classes'
import { loadStudentCard } from '../../../store/actions/studentCard'

interface BookGradeProps {
    id: number;
    student_id: number;
}

export const BookGrade: React.FC<BookGradeProps> = ({ id, student_id }) => {
	const { fetchStatus, classes, parallelsFetchStatus, parallels } = useSelector((state: State) => state.classes)
	const studentCard = useSelector((state: State) => state.studentCard)
	const dispatch = useDispatch()
	const [selectedClass, setSelectedClass] = useState(null)

	useEffect(() => {
		dispatch(loadClassesStart({}))
		dispatch(loadStudentCard(student_id))
		dispatch(loadParallelsStart({}))
	}, [dispatch])

	const selectedClassChangeCallback = useCallback((obj: any) => {
		setSelectedClass(obj.value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			showAlert({
				title: 'Данный процесс может долго грузиться',
				text: 'Немного подождите',
				type: 'success',
			}),
		)
		dispatch(
			createProcessStart({
				process: 'book_grade',
				id,
				parameters: {
					student_group_id: selectedClass,
				},
			}),
		)
	}, [dispatch, id, selectedClass])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Записать в учебный класс</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '100%' }}>
								<Form.Label>Класс</Form.Label>
								<Select
									placeholder="Выберите класс"
									onChange={selectedClassChangeCallback}
									isLoading={
										fetchStatus !== FetchStatus.FETCHED ||
                                        studentCard.fetchStatus !== FetchStatus.FETCHED ||
                                        parallelsFetchStatus !== FetchStatus.FETCHED
									}
									options={
										classes
											?.filter(
												(group) =>
													group?.parallel ===
                                                    parallels.find(
                                                    	(parallel) =>
                                                    		parallel.titleShort === studentCard?.card?.grade.toString(),
                                                    )?.id,
											)
											?.map((el) => {
												return {
													label: `${el.title} (${el?.students?.length} уч.)`,
													value: el.id,
												}
											}) || []
									}
								/>
							</Form.Group>
						</div>

						<Button onClick={submitButtonPressHandler} disabled={!selectedClass} variant="primary">
                            Записать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
