import { ActionType, createAction } from 'typesafe-actions'
import {
	IStudentsCardsPayload,
	IStudentsCardsSearchChangePayload,
	IStudentsPayload,
	IUpdateStudentPayload,
} from '../types/students'

export const loadStudentProfileStart = createAction('@students/LOAD_STUDENT_PROFILE/START')<{ externalId: string }>()
export const loadStudentProfileSuccess = createAction('@students/LOAD_STUDENT_PROFILE/SUCCESS')<any>()
export const loadStudentProfileError = createAction('@students/LOAD_STUDENT_PROFILE/ERROR')<any>()

export const blockStudentAuthStart = createAction('@students/BLOCK_STUDENT_AUTH/START')<{
    externalId: string;
    isBlocked: boolean;
}>()
export const blockStudentAuthSuccess = createAction('@students/BLOCK_STUDENT_AUTH/SUCCESS')<any>()
export const blockStudentAuthError = createAction('@students/BLOCK_STUDENT_AUTH/ERROR')<any>()

export const blockStudentOlStart = createAction('@students/BLOCK_STUDENT_OL/START')<{
    externalId: string;
    isOlBlocked: boolean;
    id: string,
}>()
export const blockStudentOlSuccess = createAction('@students/BLOCK_STUDENT_OL/SUCCESS')<any>()
export const blockStudentOlError = createAction('@students/BLOCK_STUDENT_OL/ERROR')<any>()

export const updateStudentStart = createAction('@students/UPDATE_STUDENT/START')<IUpdateStudentPayload>()
export const updateStudentSuccess = createAction('@students/UPDATE_STUDENT/SUCCESS')<unknown>()
export const updateStudentError = createAction('@students/UPDATE_STUDENT/ERROR')<unknown>()

export const loadStudentsStart = createAction('@students/LOAD/START')<IStudentsPayload>()
export const loadStudentsSuccess = createAction('@students/LOAD/SUCCESS')<any>()
export const loadStudentsError = createAction('@students/LOAD/ERROR')<any>()

export const loadStudentsCardsStart = createAction('@studentsCards/LOAD/START')<IStudentsCardsPayload>()
export const loadStudentsCardsSuccess = createAction('@studentsCards/LOAD/SUCCESS')<any>()
export const loadStudentsCardsError = createAction('@studentsCards/LOAD/ERROR')<any>()
export const studentsCardsSearchChange =
    createAction('@studentsCards/SEARCH/CHANGE')<IStudentsCardsSearchChangePayload>()

export const setStudentsCardsCurrentPage = createAction('@studentsCards/PAGE')<any>()

export const createStudentOnLmsStart = createAction('@students/CREATE_ON_LMS/START')<number>()
export const createStudentOnLmsSuccess = createAction('@students/CREATE_ON_LMS/SUCCESS')<any>()
export const createStudentOnLmsError = createAction('@students/CREATE_ON_LMS/ERROR')<any>()

export const fetchExportCardsCsvStart = createAction('@studentsCards/EXPORT_CSV/START')<
    Pick<IStudentsCardsPayload, 'filter'>
>()
export const fetchExportCardsCsvSuccess = createAction('@studentsCards/EXPORT_CSV/SUCCESS')<any>()
export const fetchExportCardsCsvError = createAction('@studentsCards/EXPORT_CSV/ERROR')<any>()

export type StudentsActions =
    | ActionType<typeof loadStudentProfileStart>
    | ActionType<typeof loadStudentProfileSuccess>
    | ActionType<typeof loadStudentProfileError>
    | ActionType<typeof updateStudentStart>
    | ActionType<typeof updateStudentSuccess>
    | ActionType<typeof updateStudentError>
    | ActionType<typeof loadStudentsStart>
    | ActionType<typeof loadStudentsSuccess>
    | ActionType<typeof loadStudentsError>
    | ActionType<typeof loadStudentsCardsStart>
    | ActionType<typeof loadStudentsCardsSuccess>
    | ActionType<typeof loadStudentsCardsError>
    | ActionType<typeof setStudentsCardsCurrentPage>
    | ActionType<typeof studentsCardsSearchChange>
    | ActionType<typeof createStudentOnLmsStart>
    | ActionType<typeof createStudentOnLmsSuccess>
    | ActionType<typeof createStudentOnLmsError>
    | ActionType<typeof fetchExportCardsCsvStart>
    | ActionType<typeof fetchExportCardsCsvSuccess>
    | ActionType<typeof fetchExportCardsCsvError>
    | ActionType<typeof blockStudentAuthStart>
    | ActionType<typeof blockStudentAuthSuccess>
    | ActionType<typeof blockStudentAuthError>
    | ActionType<typeof blockStudentOlStart>
    | ActionType<typeof blockStudentOlSuccess>
    | ActionType<typeof blockStudentOlError>;
