import { ActionType, createAction } from 'typesafe-actions'

export const loadAssignments = createAction('@paymentsAssignments/LOAD_ASSIGNMENTS')<any>()
export const onLoadAssignmentsSuccess = createAction('@paymentsAssignments/ON_LOAD_ASSIGNMENTS_SUCCESS')<any>()
export const onLoadAssignmentsError = createAction('@paymentsAssignments/ON_LOAD_ASSIGNMENTS_ERROR')<any>()
export const loadMorePOFiles = createAction('@paymentsAssignments/LOAD_MORE_FILES')<any>()
export const onLoadMorePOFilesSuccess = createAction('@paymentsAssignments/ON_LOAD_MORE_FILES_SUCCESS')<any>()
export const onLoadMorePOFilesError = createAction('@paymentsAssignments/ON_LOAD_MORE_FILES_ERROR')<any>()
export const setAssignmentsQuery = createAction('@paymentsAssignments/SET_ASSIGNMENTS_QUERY')<any>()
export const setAssignmentsStartDate = createAction('@paymentsAssignments/SET_ASSIGNMENTS_START_DATE')<any>()
export const setAssignmentsEndDate = createAction('@paymentsAssignments/SET_ASSIGNMENTS_END_DATE')<any>()

export const loadAssignmentInfo = createAction('@paymentsAssignments/LOAD_ASSIGNMENTS_INFO')<any>()
export const onLoadAssignmentInfoSuccess = createAction('@paymentsAssignments/ON_LOAD_ASSIGNMENTS_INFO_SUCCESS')<any>()
export const onLoadAssignmentInfoError = createAction('@paymentsAssignments/ON_LOAD_ASSIGNMENTS_INFO_ERROR')<any>()

export const changeAssignmentInfo = createAction('@paymentsAssignments/CHANGE_ASSIGNMENTS_INFO')<any>()
export const onChangeAssignmentInfoSuccess = createAction(
	'@paymentsAssignments/ON_CHANGE_PAY_ASSIGNMENTS_SUCCESS',
)<any>()
export const onChangeAssignmentInfoError = createAction(
	'@paymentsAssignments/ON_CHANGE_ASSIGNMENTS_ASSIGNMENTS_ERROR',
)<any>()

export type PaymentsAssignmentsActions =
	| ActionType<typeof loadAssignments>
	| ActionType<typeof onLoadAssignmentsSuccess>
	| ActionType<typeof onLoadAssignmentsError>
	| ActionType<typeof loadMorePOFiles>
	| ActionType<typeof onLoadMorePOFilesSuccess>
	| ActionType<typeof onLoadMorePOFilesError>
	| ActionType<typeof loadAssignmentInfo>
	| ActionType<typeof onLoadAssignmentInfoSuccess>
	| ActionType<typeof onLoadAssignmentInfoError>
	| ActionType<typeof changeAssignmentInfo>
	| ActionType<typeof onChangeAssignmentInfoSuccess>
	| ActionType<typeof onChangeAssignmentInfoError>
	| ActionType<typeof setAssignmentsQuery>
	| ActionType<typeof setAssignmentsStartDate>
	| ActionType<typeof setAssignmentsEndDate>
