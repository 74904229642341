import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadProblemStudents, loadProblemStudentsError, loadProblemStudentsSuccess } from '../actions/problemStudents'
import { Action } from '../store'
import { ProblemStudentsState } from '../types/problemStudents'

const initialState: ProblemStudentsState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	data: {
		no_contract: [],
		not_in_erp: [],
	},
}

export const problemStudents = (state: ProblemStudentsState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadProblemStudents): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadProblemStudentsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
		}
	}
	case getType(loadProblemStudentsError): {
		return {
			...state,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
