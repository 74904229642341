import React from 'react'
import './TableContentCell.scss'
const TableContentCell = (props) => {
	const { left, center, right } = props
	return (
		<div className={'table-content-cell'}>
			<div className={'left-column'}>
				<div className={'left'}>{left}</div>
				<div className={'center'}>{center}</div>
			</div>
			{right && (
				<div className={'right-column'}>
					<div className={'right'}>{right}</div>
				</div>
			)}
		</div>
	)
}

export default TableContentCell
