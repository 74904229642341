import React, { useMemo } from 'react'
import { FiltersPanel } from '@frontend-modules/filters-generator'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'

const NestedFiltersPanel = (props) => {
	const { type, filters, onFilterAdd, onFilterRemove, onFiltersClear } = props

	const { entities, catalog } = useSelector((state: State) => state.meta)

	const dispatch = useDispatch()

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	return (
		<div className={'group-action-collapse-table__content_filters'}>
			<FiltersPanel
				title={''}
				selectsApiConfig={filtersApiConfig}
				availableFilters={entities?.groupActions?.filters}
				selectedFiltersList={filters}
				onFilterAdd={onFilterAdd}
				onFilterRemove={onFilterRemove}
				onFiltersClear={onFiltersClear}
				serviceName={''}
				isCanEdit={type === 'planned'}
			/>
		</div>
	)
}

export default NestedFiltersPanel
