import { BaseRestDataProvider } from './BaseRestDataProvider'
import { ILoadUserVisitsPayload, LoadManagerInfoPayload } from '../store/types/userLogging'
import { getFilterParams } from '../utils/filters'
import { oldAxios } from './axios-init'

export class UserLoggingDataProvider extends BaseRestDataProvider {
	loadUserVisits = (payload: ILoadUserVisitsPayload) => {
		const searchParams = {
			user: payload.user ? `&user=${payload.user}` : '',
			requestMethod: payload.requestMethod.length ? `&request_method=${payload.requestMethod.join(',')}` : '',
			responseStatus: payload?.responseStatus.length
				? `&response_status=${payload?.responseStatus.join(',')}`
				: '',
			createdAt: payload.createdAt
				? `&created_at_after=${payload.createdAt.split('/')[0]}&created_at_before=${
						payload.createdAt.split('/')[1]
				  }`
				: '',
			pageSize: payload.pageSize ? `&page_size=${payload.pageSize}` : '',
		}
		return oldAxios
			.get(
				`${this.host}/api/user_logging/statistics/?page=${payload.page}${Object.values(searchParams).join('')}`,
				{
					withCredentials: true,
				},
			)
			.then((res) => res.data)
	}

	loadManagerInfo = (payload: LoadManagerInfoPayload) => {
		const createdAt = payload.filter?.created_at
		delete payload.filter?.created_at
		let searchParam = getFilterParams(payload.filter)
		if (createdAt) {
			searchParam += `&created_at_after=${createdAt.split('/')[0]}&created_at_before=${createdAt.split('/')[1]}`
		}

		return oldAxios
			.get(`${this.host}/api/user_logging/manager-info/?page=${payload.page}${searchParam}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}
}
