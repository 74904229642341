import { RouterAction, RouterState } from 'connected-react-router'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineEpics, createEpicMiddleware, EpicMiddleware } from 'redux-observable'
import { persistStore } from 'redux-persist'
import { AuthDataProvider } from '@/api/AuthDataProvider'
import { DocumentsDataProvider } from '@/api/DocumentsDataProvider'
import { ProcessesDataProvider } from '@/api/ProcessesDataProvider'
import { AlertActions } from './actions/alert'
import { CardActions } from './actions/card'
import { CoursesActions } from './actions/courses'
import { DocumentActions } from './actions/document'
import { FormActions } from './actions/form'
import { NavBarActions } from './actions/navBar'
import { ParentCardActions } from './actions/parentCard'
import { ProcessesActions } from './actions/processes'
import { StudentCardActions } from './actions/studentCard'
import { TrialDaysActions } from './actions/trialDays'
import { cardEpics } from './epics/card'
import { coursesEpics } from './epics/courses'
import { documentEpics } from './epics/document'
import { processesEpics } from './epics/processes'
import { trialDaysEpics } from './epics/trialDays'
import { reducers } from './reducers'
import { AlertState } from './types/alert'
import { CardState, UserCardState } from './types/card'
import {
	AvailableCoursesState,
	CoursesState,
	ForeignLanguagesCoursesState,
	AdditionalCoursesState,
} from './types/courses'
import { DocumentState, GeneratedDocumentsState } from './types/document'
import { FormState } from './types/form'
import { NavBarState } from './types/navBar'
import { ProcessesState } from './types/processes'
import { TrialDaysState } from './types/trialDays'
import { FormDataProvider } from '../api/FormDataProvider'
import { formEpics } from './epics/form'
import { ProfileActions } from './actions/profile'
import { ProfileState } from './types/profile'
import { profileEpics } from './epics/profile'
import { SchoolActions } from './actions/school'
import { SchoolState } from './types/school'
import { schoolEpics } from './epics/school'
import { SpecialistActions } from './actions/specialist'
import { SpecialistState } from './types/specialist'
import { specialistEpics } from './epics/specialist'
import { ContractActions } from './actions/contract'
import { ContractState } from './types/contract'
import { ContractDataProvider } from '../api/ContractDataProvider'
import { contractEpics } from './epics/contract'
import { MessageActions } from './actions/message'
import { MessageState } from './types/message'
import { messageEpics } from './epics/message'
import { MessageDataProvider } from '../api/MessageDataProvider'
import { ChatActions } from './actions/chat'
import { ChatState } from './types/chat'
import { ChatDataProvider } from '../api/ChatDataProvider'
import { chatEpics } from './epics/chat'
import { BuisnessActions } from './actions/buisness'
import { BuisnessState } from './types/buisness'
import { buisnessEpics } from './epics/buisness'
import { NoteActions } from './actions/note'
import { NoteState } from './types/note'
import { noteEpics } from './epics/note'
import { PaymentActions } from './actions/payment'
import { PaymentSitesState, PaymentState } from './types/payment'
import { paymentEpics } from './epics/payment'
import { PaymentDataProvider } from '../api/PaymentDataProvider'
import { authMiddleware } from './middleware/auth'
import { errorMiddleware } from './middleware/error'
import { ConstantsActions } from './actions/constants'
import { ConstantsState } from './types/constants'
import { constantsEpics } from './epics/constants'
import { DebtsSearchState, SearchState, UserVisitsSearchState } from './types/search'
import { SearchActions } from './actions/search'
import { ClassesActions } from './actions/classes'
import { ClassesDataProvider } from '../api/ClassesDataProvider'
import { classesEpics } from './epics/classes'
import { ClassesState, DeleteClassState } from './types/classes'
import { StudentsActions } from './actions/students'
import { StudentsDataProvider } from '../api/StudentsDataProvider'
import { studentsEpics } from './epics/students'
import { StudentsState } from './types/students'
import { UsersDataProvider } from '../api/UsersDataProvider'
import { UsersActions } from './actions/users'
import { usersEpics } from './epics/users'
import { UsersListState } from './types/users'
import { DocumentTypesActions } from './actions/documentTypes'
import { DocumentTypesDataProvider } from '../api/DocumentTypesDataProvider'
import { documentTypesEpics } from './epics/documentTypes'
import { CreateDocTypeState, DocumentTypesState } from './types/documentTypes'
import { CoursesDataProvider } from '../api/CoursesDataProvider'
import { FrozenPaymentActions } from './actions/frozenPayment'
import { FrozenPaymentState } from './types/frozenPayment'
import { frozenPaymentEpics } from './epics/frozenPayment'
import { GlobalClassesState } from './types/globalClasses'
import { GlobalClassesActions } from './actions/globalClasses'
import { globalClassesEpics } from './epics/globalClasses'
import { ContractsActions } from './actions/contracts'
import { ContractsState } from './types/contracts'
import { contractsEpics } from './epics/contracts'
import { StaffUsersActions } from './actions/staffUsers'
import { StaffUsersState } from './types/staffUsers'
import { staffUsersEpics } from './epics/staffUsers'
import { UnfrozenPaymentActions } from './actions/unfrozenPayment'
import { UnfrozenPaymentState } from './types/unfrozenPayment'
import { unfrozenPaymentEpics } from './epics/unfrozenPayment'
import { UserLoggingActions } from './actions/userLogging'
import { UserLoggingDataProvider } from '../api/UserLoggingDataProvider'
import { userLoggingEpics } from './epics/userLogging'
import { ManagerInfoState, UserVisitsState } from './types/userLogging'
import { AnceteActions } from './actions/ancete'
import { AnceteState } from './types/ancete'
import { AnceteDataProvider } from '../api/AnceteDataProvider'
import { anceteEpics } from './epics/ancete'
import { ProblemStudentsActions } from './actions/problemStudents'
import { ProblemStudentsState } from './types/problemStudents'
import { problemStudentsEpics } from './epics/problemStudents'
import { AnalyticsState } from './types/analytics'
import { AnalyticsActions } from './actions/analytics'
import { analyticsEpics } from './epics/analytics'
import { Sentry } from '@utils/sentry'
// new store imports
import { DebtsActions } from '@store/actions/debts'
import { DebtsState } from '@store/types/debts'
import { debtsEpics } from '@store/epics/debts'
import { MetaActions } from '@store/actions/meta'
import { MetaState } from '@store/types/meta'
import { metaEpics } from '@store/epics/meta'
import { TasksActions } from '@store/actions/tasks'
import { TasksState } from '@store/types/tasks'
import { tasksEpics } from '@store/epics/tasks'
import { ProcessesMonitoringActions } from '@store/actions/processesMonitoring'
import { ProcessessMonitoringState } from '@store/types/processessMonitoring'
import { processesMonitoringEpics } from '@store/epics/processesMonitoring'
import { AdditionalCoursesActions } from '@store/actions/additionalCourses'
import { AdditionalCoursesState as AdditionalCoursesStateNew } from '@store/types/additionalCourses'
import { additionalCoursesEpics } from '@store/epics/additionalCourses'

import {
	DebtsServices,
	MetaServices,
	TasksServices,
	ParentServices,
	PaymentsService,
	StudentServices,
	CoursesService,
	GroupActionsServices,
	DecreeServices,
} from '@services'
import { ParentCardState } from '@store/types/parentCard'
import { parentCardEpics } from '@store/epics/parentCard'
import { ParentCardActions as ParentCardNewActions } from '@store/actions/parentCard'
import { ParentsActions } from '@store/actions/parents'
import { parentsEpics } from '@store/epics/parents'
import { ParentsState } from '@store/types/parents'

import { LoginActions } from '@store/actions/login'
import { loginEpics } from '@store/epics/login'
import { LoginState } from '@store/types/login'
import { PaymentsPaysState } from '@store/types/paymentsPays'
import { paymentsPaysEpics } from '@store/epics/paymentsPays'
import { PaymentsPaysActions } from '@store/actions/paymentsPays'
import { PaymentsAssignmentsState } from '@store/types/paymentsAssignments'
import { PaymentsAssignmentsActions } from '@store/actions/paymentsAssignments'
import { paymentsAssignmentsEpics } from '@store/epics/paymentsAssignments'

import { StudentCardState } from '@store/types/studentCard'
import { studentCardEpics } from '@store/epics/studentCard'
import { StudentCardActions as StudentCardActionsNew } from '@store/actions/studentCard'
import { GroupActionsActions } from '@store/actions/groupActions'
import { GroupActionsState } from '@store/types/groupActions'
import { groupActionsEpics } from '@store/epics/groupActions'
import { DecreeActions } from '@store/actions/decree'
import { DecreeState } from '@store/types/decree'
import { decreeEpics } from '@store/epics/decree'
// export const host = 'http://localhost';
export const host = process.env.REACT_APP_HOST
export const ssoHost = process.env.REACT_APP_SSO_HOST

export type Action =
	| NavBarActions
	| RouterAction
	| LoginActions
	| ProcessesActions
	| CoursesActions
	| TrialDaysActions
	| FormActions
	| CardActions
	| AlertActions
	| DocumentActions
	| StudentCardActions
	| ParentCardActions
	| ProfileActions
	| SchoolActions
	| SpecialistActions
	| ContractActions
	| MessageActions
	| ChatActions
	| BuisnessActions
	| NoteActions
	| PaymentActions
	| ConstantsActions
	| SearchActions
	| ClassesActions
	| StudentsActions
	| UsersActions
	| DocumentTypesActions
	| FrozenPaymentActions
	| GlobalClassesActions
	| ContractsActions
	| StaffUsersActions
	| UnfrozenPaymentActions
	| UserLoggingActions
	| ProblemStudentsActions
	| AnceteActions
	| AnalyticsActions
	| DebtsActions
	| MetaActions
	| TasksActions
	| ProcessesMonitoringActions
	| ParentsActions
	| PaymentsPaysActions
	| PaymentsAssignmentsActions
	| ParentCardNewActions
	| StudentCardActionsNew
	| AdditionalCoursesActions
	| GroupActionsActions
	| DecreeActions

export interface State {
	navBar: NavBarState
	router: RouterState
	login: LoginState
	processes: ProcessesState
	courses: CoursesState
	trialDays: TrialDaysState
	form: FormState
	additionalCourses: AdditionalCoursesState
	card: CardState
	alert: AlertState
	document: DocumentState
	generatedDocuments: GeneratedDocumentsState
	studentCard: UserCardState
	parentCard: UserCardState
	profile: ProfileState
	school: SchoolState
	specialist: SpecialistState
	contract: ContractState
	message: MessageState
	chat: ChatState
	buisness: BuisnessState
	note: NoteState
	payment: PaymentState
	constants: ConstantsState
	search: SearchState
	classes: ClassesState
	deleteClass: DeleteClassState
	students: StudentsState
	paymentSites: PaymentSitesState
	users: UsersListState
	debtsSearch: DebtsSearchState
	documentTypes: DocumentTypesState
	createDocType: CreateDocTypeState
	availableCourses: AvailableCoursesState
	frozenPayment: FrozenPaymentState
	globalClasses: GlobalClassesState
	foreignLanguagesCourses: ForeignLanguagesCoursesState
	contracts: ContractsState
	staffUsers: StaffUsersState
	unfrozenPayment: UnfrozenPaymentState
	userVisits: UserVisitsState
	userVisitsSearch: UserVisitsSearchState
	managerInfo: ManagerInfoState
	ancete: AnceteState
	problemStudents: ProblemStudentsState
	analytics: AnalyticsState
	debts: DebtsState
	meta: MetaState
	tasks: TasksState
	processesMonitoring: ProcessessMonitoringState
	parents: ParentsState
	paymentsPays: PaymentsPaysState
	paymentsAssignments: PaymentsAssignmentsState
	parentCardNew: ParentCardState
	studentCardNew: StudentCardState
	additionalCoursesNew: AdditionalCoursesStateNew
	groupActions: GroupActionsState
	decree: DecreeState
}

export interface EpicDeps {
	authDataProvider: AuthDataProvider
	processesDataProvider: ProcessesDataProvider
	documentsDataProvider: DocumentsDataProvider
	formDataProvider: FormDataProvider
	contractDataProvider: ContractDataProvider
	messageDataProvider: MessageDataProvider
	chatDataProvider: ChatDataProvider
	paymentDataProvider: PaymentDataProvider
	classesDataProvider: ClassesDataProvider
	studentsDataProvider: StudentsDataProvider
	usersDataProvider: UsersDataProvider
	documentTypesDataProvider: DocumentTypesDataProvider
	coursesDataProvider: CoursesDataProvider
	userLoggingDataProvider: UserLoggingDataProvider
	anceteDataProvider: AnceteDataProvider
	debtsService: DebtsServices
	metaService: MetaServices
	tasksService: TasksServices
	parentService: ParentServices
	studentService: StudentServices
	paymentsService: PaymentsService
	coursesService: CoursesService
	groupActionsService: GroupActionsServices
	decreeService: DecreeServices
}

const createMiddleware = (epicMiddleware: EpicMiddleware<Action, Action, State, EpicDeps>) =>
	applyMiddleware(epicMiddleware, authMiddleware, errorMiddleware)

const composeEnhancers = composeWithDevTools({ serialize: true })

const epicMiddleware = createEpicMiddleware<Action, Action, State, EpicDeps>({
	dependencies: {
		authDataProvider: new AuthDataProvider(ssoHost),
		processesDataProvider: new ProcessesDataProvider(host),
		documentsDataProvider: new DocumentsDataProvider(host),
		formDataProvider: new FormDataProvider(host),
		contractDataProvider: new ContractDataProvider(host),
		messageDataProvider: new MessageDataProvider(host),
		chatDataProvider: new ChatDataProvider(host),
		paymentDataProvider: new PaymentDataProvider(host),
		classesDataProvider: new ClassesDataProvider(host),
		studentsDataProvider: new StudentsDataProvider(host),
		usersDataProvider: new UsersDataProvider(host),
		documentTypesDataProvider: new DocumentTypesDataProvider(host),
		coursesDataProvider: new CoursesDataProvider(host),
		userLoggingDataProvider: new UserLoggingDataProvider(host),
		anceteDataProvider: new AnceteDataProvider(host),
		debtsService: new DebtsServices(),
		metaService: new MetaServices(),
		tasksService: new TasksServices(),
		parentService: new ParentServices(),
		paymentsService: new PaymentsService(),
		studentService: new StudentServices(),
		coursesService: new CoursesService(),
		groupActionsService: new GroupActionsServices(),
		decreeService: new DecreeServices(),
	},
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const store: any = createStore<State, Action, {}, {}>(
	reducers,
	composeEnhancers(createMiddleware(epicMiddleware), sentryReduxEnhancer),
)

export const persistor = persistStore(store)

epicMiddleware.run(
	combineEpics(
		loginEpics,
		classesEpics,
		usersEpics,
		documentTypesEpics,
		studentsEpics,
		processesEpics,
		coursesEpics,
		trialDaysEpics,
		cardEpics,
		documentEpics,
		formEpics,
		profileEpics,
		schoolEpics,
		specialistEpics,
		contractEpics,
		messageEpics,
		chatEpics,
		buisnessEpics,
		noteEpics,
		paymentEpics,
		constantsEpics,
		frozenPaymentEpics,
		globalClassesEpics,
		contractsEpics,
		staffUsersEpics,
		unfrozenPaymentEpics,
		userLoggingEpics,
		anceteEpics,
		problemStudentsEpics,
		analyticsEpics,
		debtsEpics,
		metaEpics,
		tasksEpics,
		processesMonitoringEpics,
		additionalCoursesEpics,
		parentCardEpics,
		studentCardEpics,
		parentsEpics,
		paymentsPaysEpics,
		paymentsAssignmentsEpics,
		groupActionsEpics,
		decreeEpics,
	),
)
