import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'
import { ContractState } from '../types/contract'
import {
	createPaymentArea,
	createPaymentAreaError,
	createPaymentAreaSuccess,
	loadContract,
	loadContractError,
	loadContractSuccess,
	changeContract,
	createContract,
	createContractSuccess,
	createContractError,
} from '../actions/contract'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	contracts: null,
	paymentAreaStatus: FetchStatus.FETCHED,
	createContractStatus: FetchStatus.FETCHED,
}

export const contract = (state: ContractState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadContract): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadContractSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			contracts: action.payload,
		}
	}

	case getType(loadContractError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(createContract): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
			createContractStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createContractSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			createContractStatus: FetchStatus.FETCHED,
		}
	}

	case getType(createContractError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
			createContractStatus: FetchStatus.ERROR,
		}
	}

	case getType(changeContract): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createPaymentArea): {
		return {
			...state,
			paymentAreaStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createPaymentAreaError): {
		return {
			...state,
			paymentAreaStatus: FetchStatus.FETCHED,
		}
	}

	case getType(createPaymentAreaSuccess): {
		return {
			...state,
			paymentAreaStatus: FetchStatus.FETCHED,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
