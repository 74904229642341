import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import {
	addParentCard,
	addParentCardError,
	addParentCardSuccess,
	loadParentCard,
	loadParentCardError,
	loadParentCardSuccess,
	loadParentSmsStatuses,
	loadParentSmsStatusesSuccess,
	loadParentSmsStatusesError,
	loadParentLetterStatusesError,
	loadParentLetterStatusesSuccess,
	loadParentLetterStatuses,
} from '../actions/parentCard'
import { Action } from '../store'
import { UserCardState } from '../types/card'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	card: null,
	smsStatuses: {
		fetchStatus: FetchStatus.INITIAL,
		error: null,
		data: null,
	},
	letterStatuses: {
		fetchStatus: FetchStatus.INITIAL,
		error: null,
		data: null,
	},
	addParentStatus: FetchStatus.FETCHED,
}

export const parentCard = (state: UserCardState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadParentCard): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadParentCardSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				card: action.payload,
			}
		}

		case getType(loadParentCardError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		case getType(loadParentSmsStatuses): {
			return {
				...state,
				smsStatuses: {
					...state.smsStatuses,
					fetchStatus: FetchStatus.FETCHING,
				},
			}
		}

		case getType(loadParentSmsStatusesSuccess): {
			return {
				...state,
				smsStatuses: {
					...state.smsStatuses,
					fetchStatus: FetchStatus.FETCHED,
					data: action.payload,
				},
			}
		}

		case getType(loadParentSmsStatusesError): {
			return {
				...state,
				smsStatuses: {
					...state.smsStatuses,
					fetchStatus: FetchStatus.ERROR,
					error: action.payload,
				},
			}
		}
		case getType(loadParentLetterStatuses): {
			return {
				...state,
				letterStatuses: {
					...state.letterStatuses,
					fetchStatus: FetchStatus.FETCHING,
				},
			}
		}

		case getType(loadParentLetterStatusesSuccess): {
			return {
				...state,
				letterStatuses: {
					...state.letterStatuses,
					fetchStatus: FetchStatus.FETCHED,
					data: action.payload,
				},
			}
		}

		case getType(loadParentLetterStatusesError): {
			return {
				...state,
				letterStatuses: {
					...state.letterStatuses,
					fetchStatus: FetchStatus.ERROR,
					error: action.payload,
				},
			}
		}

		case getType(addParentCard): {
			return {
				...state,
				addParentStatus: FetchStatus.FETCHING,
			}
		}

		case getType(addParentCardSuccess): {
			return {
				...state,
				addParentStatus: FetchStatus.FETCHED,
			}
		}

		case getType(addParentCardError): {
			return {
				...state,
				addParentStatus: FetchStatus.FETCHED,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
