import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import {
	fetchActiveParents,
	fetchActiveParentsError,
	fetchActiveParentsSuccess,
	fetchActiveParentsWithContracts,
	fetchActiveParentsWithContractsError,
	fetchActiveParentsWithContractsSuccess,
	fetchPaymentCount,
	fetchPaymentCountError,
	fetchPaymentCountSuccess,
} from '../actions/analytics'
import { Action } from '../store'
import { AnalyticsState } from '../types/analytics'

const initialState: AnalyticsState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	paymentCount: null,
	activeParents: null,
	activeParentsWithContract: null,
	activeParentsFetchStatus: FetchStatus.FETCHING,
	paymentCountFetchStatus: FetchStatus.FETCHING,
}

export const analytics = (state: AnalyticsState = initialState, action: Action) => {
	switch (action.type) {
	case getType(fetchActiveParents): {
		return {
			...state,
			activeParentsFetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(fetchActiveParentsSuccess): {
		return {
			...state,
			activeParentsFetchStatus: FetchStatus.FETCHED,
			activeParents: action.payload.active_users,
			activeParentsWithContract: action.payload.active_users_with_contracts,
		}
	}

	case getType(fetchActiveParentsError): {
		return {
			...state,
			activeParentsFetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(fetchActiveParentsWithContracts): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(fetchActiveParentsWithContractsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			activeParentsWithContract: action.payload.active_users_with_contracts,
		}
	}

	case getType(fetchActiveParentsWithContractsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(fetchPaymentCount): {
		return {
			...state,
			paymentCountFetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(fetchPaymentCountSuccess): {
		return {
			...state,
			paymentCountFetchStatus: FetchStatus.FETCHED,
			paymentCount: action.payload,
		}
	}

	case getType(fetchPaymentCountError): {
		return {
			...state,
			paymentCountFetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
