import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import { ParentCardState } from '@store/types/parentCard'
import { getType } from 'typesafe-actions'
import {
	createContract,
	onCreateContractSuccess,
	onCreateContractError,
	onCreateTeacherProfileError,
	onCreateTeacherProfileSuccess,
	createTeacherProfile,
	loadParentCardTab,
	onLoadParentCardTabSuccess,
	onLoadParentCardTabError,
	loadParentCardTabProcesses,
	onLoadParentCardTabProcessesSuccess,
	onLoadParentCardTabProcessesError,
	sendMessage,
	onSendMessageSuccess,
	onSendMessageError,
	loadParentCardTabPersonalAccountBalance,
	onLoadParentCardTabPersonalAccountBalanceSuccess,
	onLoadParentCardTabPersonalAccountBalanceError,
	loadParentCardTabPersonalAccountHistory,
	onLoadParentCardTabPersonalAccountHistorySuccess,
	onLoadParentCardTabPersonalAccountHistoryError,
	loadParentCardTabContracts,
	onLoadParentCardTabContractsSuccess,
	onLoadParentCardTabContractsError,
	loadParentCardTabNotes,
	onLoadParentCardTabNotesSuccess,
	onLoadParentCardTabNotesError,
	addParentNote,
	onAddParentNoteSuccess,
	onAddParentNoteError,
	loadParentCardTabBonuses,
	onLoadParentCardTabBonusesSuccess,
	onLoadParentCardTabBonusesError,
} from '@store/actions/parentCard'
import { normalizeOfferContractsList } from '@frontend-modules/elements'

const parentCardInitialState: ParentCardState = {
	fetchStatus: FetchStatus.INITIAL,
	errors: [],
	createFetchStatus: FetchStatus.INITIAL,
	createTeacherProfileFetchStatus: FetchStatus.INITIAL,
	sendMessageFetchStatus: FetchStatus.INITIAL,
}

export const parentCardReducer = (state = parentCardInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadParentCardTab): {
			return {
				...state,
				cards: {
					...state.cards,
					[action.payload.parentId]: {
						...state.cards?.[action.payload.parentId],
						[action.payload.tabName]: {
							...state.cards?.[action.payload.parentId]?.[action.payload.tabName],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabSuccess): {
			const payload = action.payload.payload
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[payload.parentId]: {
						...state.cards[payload.parentId],
						[payload.tabName]: {
							...state.cards[payload.parentId][payload.tabName],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabError): {
			const payload = action.payload.payload
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[payload.parentId]: {
						...state.cards[payload.parentId],
						[payload.tabName]: {
							...state.cards[payload.parentId][payload.tabName],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadParentCardTabProcesses): {
			const parentId = action.payload.filters[0].value
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['processes']: {
							...state.cards?.[parentId]?.['processes'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabProcessesSuccess): {
			const parentId = action.payload.payload.filters[0].value
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['processes']: {
							...state.cards[parentId]?.['processes'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabProcessesError): {
			const parentId = action.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['processes']: {
							...state.cards[parentId]?.['processes'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadParentCardTabContracts): {
			const parentId = action.payload.parent
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['contracts']: {
							...state.cards?.[parentId]?.['contracts'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabContractsSuccess): {
			const parentId = action.payload.payload.parent
			const response = normalizeOfferContractsList(action.payload.response || [])
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['contracts']: {
							...state.cards[parentId]?.['contracts'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabContractsError): {
			const parentId = action.payload.payload.parent
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['contracts']: {
							...state.cards[parentId]?.['contracts'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadParentCardTabPersonalAccountBalance): {
			const parentId = action.payload.parentId
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							balance: {
								...state.cards?.[parentId]?.['personalAccount']?.balance,
								fetchStatus: FetchStatus.FETCHING,
							},
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabPersonalAccountBalanceSuccess): {
			const parentId = action.payload.payload.parentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							balance: {
								...state.cards?.[parentId]?.['personalAccount']?.balance,
								fetchStatus: FetchStatus.FETCHED,
								data: response,
							},
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabPersonalAccountBalanceError): {
			const parentId = action.payload.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							balance: {
								...state.cards?.[parentId]?.['personalAccount']?.balance,
								fetchStatus: FetchStatus.ERROR,
								errors: error,
							},
						},
					},
				},
			}
		}
		case getType(loadParentCardTabPersonalAccountHistory): {
			const parentId = action.payload.parentId
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							history: {
								...state.cards?.[parentId]?.['personalAccount']?.history,
								fetchStatus: FetchStatus.FETCHING,
							},
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabPersonalAccountHistorySuccess): {
			const parentId = action.payload.payload.parentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							history: {
								...state.cards?.[parentId]?.['personalAccount']?.history,
								fetchStatus: FetchStatus.FETCHED,
								data: response,
							},
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabPersonalAccountHistoryError): {
			const parentId = action.payload.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['personalAccount']: {
							...state.cards?.[parentId]?.['personalAccount'],
							history: {
								...state.cards?.[parentId]?.['personalAccount']?.history,
								fetchStatus: FetchStatus.ERROR,
								errors: error,
							},
						},
					},
				},
			}
		}

		case getType(loadParentCardTabNotes): {
			const parentId = action.payload.parentId
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['notes']: {
							...state.cards?.[parentId]?.['notes'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabNotesSuccess): {
			const parentId = action.payload.payload.parentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['notes']: {
							...state.cards[parentId]?.['notes'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabNotesError): {
			const parentId = action.payload.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['notes']: {
							...state.cards[parentId]?.['notes'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(addParentNote): {
			const parentId = action.payload.parentId
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['notes']: {
							...state.cards?.[parentId]?.['notes'],
							addFetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onAddParentNoteSuccess): {
			const parentId = action.payload.payload.parentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['notes']: {
							...state.cards[parentId]?.['notes'],
							addFetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onAddParentNoteError): {
			const parentId = action.payload.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['notes']: {
							...state.cards[parentId]?.['notes'],
							addFetchStatus: FetchStatus.ERROR,
							addErrors: error,
						},
					},
				},
			}
		}
		case getType(sendMessage): {
			return {
				...state,
				sendMessageFetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onSendMessageSuccess): {
			return {
				...state,
				sendMessageFetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onSendMessageError): {
			return {
				...state,
				sendMessageFetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(createContract): {
			return {
				...state,
				createFetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onCreateContractSuccess): {
			return {
				...state,
				createFetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onCreateContractError): {
			return {
				...state,
				createFetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(createTeacherProfile): {
			return {
				...state,
				createTeacherProfileFetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onCreateTeacherProfileSuccess): {
			return {
				...state,
				createTeacherProfileFetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onCreateTeacherProfileError): {
			return {
				...state,
				createTeacherProfileFetchStatus: FetchStatus.ERROR,
			}
		}

		case getType(loadParentCardTabBonuses): {
			const parentId = action.payload.parentId
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards?.[parentId],
						['bonuses']: {
							...state.cards?.[parentId]?.['bonuses'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabBonusesSuccess): {
			const parentId = action.payload.payload.parentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['bonuses']: {
							...state.cards[parentId]?.['bonuses'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadParentCardTabBonusesError): {
			const parentId = action.payload.parentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[parentId]: {
						...state.cards[parentId],
						['bonuses']: {
							...state.cards[parentId]?.['bonuses'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
