import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { getAccessToken } from '../../../common/token'
import { FetchStatus } from '../../../common/types'
import DocumentUpload from '../../../Components/Documents/DocumentUpload'
import { loadContract } from '../../../store/actions/contract'
import { createProcessStart } from '../../../store/actions/processes'
import { host, State } from '../../../store/store'
import { oldAxios } from '@/api/axios-init'

export const Component: React.FC<any> = ({ billCourier, id, student_id, parent_id }) => {
	const dispatch = useDispatch()

	const [courierPrice, setCourierPrice] = useState('')
	const [file, setFile] = useState(null)
	const [selectedContract, setSelectedContract] = useState<any>(null)
	const { fetchStatus, contracts } = useSelector((store: State) => store.contract)
	const [sendAll, setSendAll] = useState(false)

	const sendAllChangeCallback = useCallback(() => {
		setSendAll(!sendAll)
	}, [sendAll])

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	useEffect(() => {
		dispatch(
			loadContract({
				student: student_id,
				parent: parent_id,
				process_id: id,
			}),
		)
	}, [id, parent_id, student_id])

	const submitButtonPressHandler = () => {
		billCourier({
			process: 'upload_courier_bill',
			id: id,
			parameters: {
				courier_price: courierPrice,
				courier_bill_file: file.id,
				contract_id: !sendAll ? [selectedContract.value] : contracts.map((contract) => contract.id),
			},
		})
	}

	const uploadCourierPrice = async (documents: any) => {
		const document = documents[0]
		const file = new FormData()
		file.append('file', document, document.name)
		file.append('user', String(parent_id))

		const bill = await oldAxios
			.post(`${host}/api/files/`, file, {
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
				},
			})
			.then((res) => res.data)

		setFile(bill)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Загрузить счет на оплату курьера</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					{fetchStatus === FetchStatus.FETCHED ? (
						<Form>
							<div className="d-flex mb-3">
								<Form.Control
									value={courierPrice}
									onChange={(e) => setCourierPrice(e.target.value)}
									type="text"
									placeholder="Введите цену"
								/>
							</div>
							<div className="mb-3">
								<div style={{ marginBottom: 5 }}>Счет за доставку договора</div>
								{file ? (
									<div style={{ marginBottom: 20 }}>
										<a target="_blank" href={file?.file} rel="noreferrer">
											Счет за доставку договора
										</a>
									</div>
								) : (
									<DocumentUpload
										uploadDocument={uploadCourierPrice}
										title="Счет за доставку договора"
									/>
								)}
							</div>
							<div onClick={sendAllChangeCallback} className="mb-3">
								<Form.Group style={{ display: 'flex' }}>
									<Form.Label style={{ marginRight: 20 }}>Единая отправка</Form.Label>
									<Form.Check checked={sendAll} />
								</Form.Group>
							</div>
							{!sendAll ? (
								<div className="d-flex mb-3">
									<Form.Group>
										<Form.Label>Выберите договор</Form.Label>
										<Select
											placeholder="Выберите договор"
											options={contracts.map((contract) => ({
												label: contract.number,
												value: contract.id,
											}))}
											value={selectedContract}
											onChange={selectedContractChangeCallback}
										/>
									</Form.Group>
								</div>
							) : null}
							<Button onClick={submitButtonPressHandler} variant="primary">
								Подтвердить
							</Button>
						</Form>
					) : (
						<div className="mb-3">
							<Skeleton height={50} />
						</div>
					)}
				</Col>
			</Row>
		</Col>
	)
}

export const UploadCourierBill = connect(
	({ processes }: State, ownProps: any) => ({ ...ownProps }),
	(dispatch) => {
		return {
			billCourier: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
		}
	},
)(Component)
