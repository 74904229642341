import { FetchStatus } from '@/common/types'
import { Action } from '../store'
import { getType } from 'typesafe-actions'
import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { CreateDocTypeState, DocumentTypesState } from '../types/documentTypes'
import {
	createDocumentTypeError,
	createDocumentTypeStart,
	createDocumentTypeSuccess,
	loadDocumentTypesError,
	loadDocumentTypesStart,
	loadDocumentTypesSuccess,
	loadParentStudentDocumentTypes,
	loadParentStudentDocumentTypesError,
	loadParentStudentDocumentTypesSuccess,
} from '../actions/documentTypes'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState: DocumentTypesState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	documentTypes: null,
	parentStudentDocTypes: null,
	parentStudentDocTypesFetchStatus: FetchStatus.INITIAL,
}

const createDocTypeInitialState: CreateDocTypeState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	documentType: null,
}

export const reducer = (state: DocumentTypesState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadDocumentTypesStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadDocumentTypesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			documentTypes: action.payload,
		}
	}

	case getType(loadDocumentTypesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(loadParentStudentDocumentTypes): {
		return {
			...state,
			parentStudentDocTypesFetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadParentStudentDocumentTypesSuccess): {
		return {
			...state,
			parentStudentDocTypesFetchStatus: FetchStatus.FETCHED,
			parentStudentDocTypes: action.payload,
		}
	}

	case getType(loadParentStudentDocumentTypesError): {
		return {
			...state,
			parentStudentDocTypesFetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const createDocType = (state: CreateDocTypeState = createDocTypeInitialState, action: Action) => {
	switch (action.type) {
	case getType(createDocumentTypeStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createDocumentTypeSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			documentType: action.payload,
		}
	}

	case getType(createDocumentTypeError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const documentTypes: any = persistReducer(
	{
		key: 'AdminPane::documentTypes',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
