import { ActionType, createAction } from 'typesafe-actions'
import { GeneratedDocsSearchChangePayload, LoadGeneratedDocumentsPayload } from '../types/document'
import { AxiosError } from 'axios'

export const uploadDocument = createAction('@document/UPLOAD/START')<any>()
export const uploadDocumentSuccess = createAction('@document/UPLOAD/SUCCESS')<any>()
export const uploadDocumentError = createAction('@document/UPLOAD/ERROR')<any>()

export const loadDocumentList = createAction('@document/LOAD/START')<any, any>()
export const loadDocumentListSuccess = createAction('@document/LOAD/SUCCESS')<any>()
export const loadDocumentListError = createAction('@document/LOAD/ERROR')<any>()

export const approveDocumentStart = createAction('@document/APPROVE/START')<any>()
export const approveDocumentSuccess = createAction('@document/APPROVE/SUCCESS')<any>()
export const approveDocumentError = createAction('@document/APPROVE/ERROR')<any>()

export const loadGeneratedDocumentsStart = createAction(
	'@document/LOAD_GENERATED/START',
)<LoadGeneratedDocumentsPayload>()
export const loadGeneratedDocumentsSuccess = createAction('@document/LOAD_GENERATED/SUCCESS')<any>()
export const loadGeneratedDocumentsError = createAction('@document/LOAD_GENERATED/ERROR')<any>()

export const setGeneratedDocumentsCurrentPage = createAction('@document/GENERATED_DOCUMENTS_PAGE/SET')<number>()

export const generatedDocsSearchChange = createAction('@document/SEARCH/CHANGE')<GeneratedDocsSearchChangePayload>()

export const deleteDocument = createAction('document/delete/start')<number>()
export const deleteDocumentSuccess = createAction('document/delete/success')<number>()
export const deleteDocumentError = createAction('document/delete/error')<AxiosError>()

export type DocumentActions =
    | ActionType<typeof uploadDocument>
    | ActionType<typeof uploadDocumentSuccess>
    | ActionType<typeof uploadDocumentError>
    | ActionType<typeof loadDocumentList>
    | ActionType<typeof loadDocumentListSuccess>
    | ActionType<typeof loadDocumentListError>
    | ActionType<typeof approveDocumentStart>
    | ActionType<typeof approveDocumentSuccess>
    | ActionType<typeof approveDocumentError>
    | ActionType<typeof loadGeneratedDocumentsStart>
    | ActionType<typeof loadGeneratedDocumentsSuccess>
    | ActionType<typeof loadGeneratedDocumentsError>
    | ActionType<typeof setGeneratedDocumentsCurrentPage>
    | ActionType<typeof generatedDocsSearchChange>
    | ActionType<typeof deleteDocument>
    | ActionType<typeof deleteDocumentSuccess>
    | ActionType<typeof deleteDocumentError>;
