import { BaseRestDataProvider } from './BaseRestDataProvider'
import { AxiosError } from 'axios'
import {
	ISearchStudentsCardPayload,
	ISearchStudentsCardResponse,
	IStudentsCardsPayload,
	IStudentsPayload,
	IUpdateStudentPayload,
} from '../store/types/students'
import { getFilterParams } from '../utils/filters'
import { oldAxios } from './axios-init'

export class StudentsDataProvider extends BaseRestDataProvider {
	updateStudent = ({ studentId, ...payload }: IUpdateStudentPayload) => {
		return oldAxios
			.patch<{ user: { externalId: string } }>(
				`${this.host}/api/lms/proxy/?path=student-profile/external/update/${studentId}/`,
				payload,
			)
			.then((res) => res?.data)
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadStudentProfile = (externalId: string) => {
		return oldAxios
			.get(`${this.host}/api/lms/proxy/?path=student-profile/external/?external_id=${externalId}`)
			.then((res) => res.data)
			.catch((error: AxiosError) => {
				throw error
			})
	}

	blockStudentAuth = (externalId: string, isBlocked: boolean) => {
		return oldAxios
			.patch(`${this.host}/api/lms/proxy/?path=user/external/block-auth/`, { externalId, isBlocked })
			.then((res) => res.data)
			.catch((error: AxiosError) => {
				throw error
			})
	}

	/**
	 * @description         - метод блокировки/разблокировки ребенка
	 * @param externalId    - lms id
	 * @param isOlBlocked   - статус блокировки
	 * @param id            - student_id
	 */
	blockStudentOl = (externalId: string, isOlBlocked: boolean, id: string) => {
		return oldAxios
			.post(`${this.host}/api/processes/student/card/${id}/block_financial/`, {
				block_financial: isOlBlocked,
				id,
			})
			.then((res) => res.data)
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadStudents = (payload: IStudentsPayload) => {
		let searchParam = `${payload.paramStr ? payload.paramStr : ''}`
		searchParam += getFilterParams(payload.filter)
		return oldAxios
			.get(`${this.host}/api/students/${searchParam}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadStudentsCards = (payload: IStudentsCardsPayload) => {
		const searchParam = getFilterParams(payload.filter)
		return oldAxios
			.get(`${this.host}/api/processes/students_cards/?page=${payload.page}${searchParam}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	searchStudentsCards = async (payload: ISearchStudentsCardPayload) => {
		const response = await oldAxios.get<ISearchStudentsCardResponse>(
			`${this.host}/api/processes/students_cards/?student=${payload.student}`,
			{
				withCredentials: true,
			},
		)

		return response.data.results
	}

	fetchExportCardsCsv = (payload: Pick<IStudentsCardsPayload, 'filter'>) => {
		const searchParam = getFilterParams(payload.filter)
		return oldAxios
			.get(`${this.host}/api/processes/students_card_export_csv/?${searchParam.slice(1)}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	createStudentOnLms = (payload: number) => {
		return oldAxios
			.post(`${this.host}/api/processes/create_student_on_lms/${payload}/`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}
}
