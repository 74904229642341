import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUserCardPopupTabBonuses } from './UserCardPopupTabBonuses.types'
import { AppCheckboxGroup, AppDatepicker, AppInfo, AppTable, AppTag, AppText } from '@frontend-modules/ui-kit'
import './UserCardPopupTabBonuses.scss'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { loadParentCardTabBonuses } from '@store/actions/parentCard'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { formatDate, trimSeparator } from '@frontend-modules/frontend-utils'
import { getCurrentDateMinusSomeDays, getCurrentDatePlusOneYear } from '@utils'
import {
	PAGINATION_LIMIT,
	userBonusesCheckboxList,
	UsersBonusesConfig,
	getUsersBonusesText,
} from './UserCardPopupTabBonuses.config'

/**
 * @description - вкладка с бонусами родителя
 * @param props
 * @constructor
 */
const UserCardPopupTabBonuses: FC<IUserCardPopupTabBonuses> = (props) => {
	const { userType, id } = props
	const dispatch = useDispatch()

	const bonuses = useSelector((state: State) => state?.[`${userType}CardNew`]?.cards?.[id]?.bonuses)
	const bonusesData = bonuses?.data
	const pagination = bonusesData?.pagination
	const isLoading = bonuses?.fetchStatus === FetchStatus.FETCHING

	const [startDate, setStartDate] = useState(() => getCurrentDateMinusSomeDays(30))
	const [endDate, setEndDate] = useState(() => getCurrentDatePlusOneYear())
	const [selectedCheckboxes, setSelectedCheckboxes] = useState<Array<CheckboxOptionType>>(userBonusesCheckboxList)
	const [checkboxes, setCheckboxes] = useState<Array<CheckboxOptionType>>(userBonusesCheckboxList)

	/**
	 * @description - если в списке остался всего 1 чекбокс, то надо
	 * его заблокировать
	 * @param list
	 */
	const onCheckboxChange = (list) => {
		setSelectedCheckboxes(list)
		if (list.length === 1) {
			const lastItem = list[0]
			const newCheckboxList = checkboxes.map((item) => {
				if (item.value === lastItem.value) {
					return {
						...item,
						disabled: true,
					}
				}
				return item
			})
			setCheckboxes(newCheckboxList)
		} else {
			setCheckboxes(userBonusesCheckboxList)
		}
	}

	const columns = useMemo(() => {
		return [
			{
				title: 'Дата',
				dataIndex: 'date',
				key: 'date',
				width: '100px',
				render: (_, record) => {
					return formatDate(record.date)
				},
			},
			{
				title: 'Бонусы',
				dataIndex: 'amount',
				key: 'amount',
				width: '150px',
				render: (_, record) => {
					return (
						<AppTag color={UsersBonusesConfig[record.type]}>
							<AppText
								text={getUsersBonusesText(record.amount, record.type)}
								textStyle={'DesktopFontBody'}
								color={UsersBonusesConfig[record.type].main}
							/>
						</AppTag>
					)
				},
			},
			{
				title: 'Описание',
				dataIndex: 'description',
				key: 'description',
			},
		]
	}, [])

	const onLoad = (params) => {
		dispatch(loadParentCardTabBonuses(params))
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination?.currentPage) {
			const params = {
				limit: pagination?.limit || PAGINATION_LIMIT,
				currentPage: page,
			}
			loadTable?.(params)
		}
	}

	const loadTable = (pagination) => {
		const types = selectedCheckboxes.map((elem) => elem.value)
		const params = {
			types,
			parentId: id,
			dateStart: startDate,
			dateEnd: endDate,
			...pagination,
		}
		onLoad?.(params)
	}

	useEffect(() => {
		loadTable({
			limit: PAGINATION_LIMIT,
			currentPage: 1,
		})
	}, [startDate, endDate, selectedCheckboxes, id])

	return (
		<div className={'user-card-popup-tab-bonuses'}>
			<AppInfo value={`Баланс бонусов: <b>${trimSeparator(bonusesData?.balance)}</b>`} />
			<AppText
				className={'user-card-popup-tab-bonuses__title'}
				textStyle={'DesktopH3'}
				text={'История движения бонусов'}
			/>
			<div className={'user-card-popup-tab-bonuses__filters'}>
				<div className="user-card-popup-tab-bonuses__filters-selects">
					<AppDatepicker
						allowClear={false}
						label={'Начало периода'}
						defaultValue={startDate}
						onChange={setStartDate}
					/>
					<AppDatepicker
						allowClear={false}
						label={'Конец периода'}
						defaultValue={endDate}
						onChange={setEndDate}
					/>
				</div>
				<div className="user-card-popup-tab-bonuses__filters-checkboxes">
					<AppCheckboxGroup
						defaultValue={selectedCheckboxes}
						onChange={onCheckboxChange}
						label={''}
						items={checkboxes}
					/>
				</div>
			</div>
			<div className={'user-card-popup-tab-bonuses__table'}>
				<AppTable
					isLoading={isLoading}
					columns={columns}
					isCanDeleteRow={false}
					isNeedNumbering={false}
					dataSource={bonusesData?.results || []}
					locale={{ emptyText: 'Ничего не найдено' }}
					pagination={{
						total: pagination?.count,
						pageSize: pagination?.limit,
						defaultCurrent: 1,
						current: pagination?.currentPage,
						locale: { items_per_page: 'записей ' },
						onChange: onPaginationChangeLocal,
						showSizeChanger: false,
					}}
					rowKey={(record) => `${record.id}`}
					sortingConfig={{}}
					titleSortingType={'customSorting'}
				/>
			</div>
		</div>
	)
}

export default UserCardPopupTabBonuses
