import React, { FC, useCallback } from 'react'
import {
	UserCardPopupHeader,
	UserCardPopupTabMain,
	UserCardPopupTabProcesses,
	UserCardPopupTabPersonalAccount,
	UserCardPopupTabContracts,
	UserCardPopupTabCommunications,
	UserCardPopupTabNotes,
	UserCardPopupTabAgreements,
	UserCardPopupTabBonuses,
} from '@components/user'
import './UserCardPopup.scss'
import { AppTab } from '@frontend-modules/ui-kit'
import { useDispatch } from 'react-redux'
import { loadParentCardTab } from '@store/actions/parentCard'
import { AppHardModal } from '@frontend-modules/ui-kit'
import { IUserCardPopupProps } from './UserCardPopup.types'
import { AccessHelper } from '@frontend-modules/access-controller'
import { loadStudentCardTab } from '@store/actions/studentCard'

/**
 * @description - карточка пользователя "родитель/ученик"
 * @param props
 * @constructor
 */
const UserCardPopup: FC<IUserCardPopupProps> = (props) => {
	const { urlProps, ...rest } = props
	const { id, userType } = urlProps
	const dispatch = useDispatch()

	/**
	 * @description - запрашиваем инфу по "табам"
	 * через апи parent-card с параметром разных табов
	 */
	const loadTabInfo = useCallback(
		(params) => {
			if (userType === 'parent') {
				dispatch(loadParentCardTab(params))
			}
			if (userType === 'student') {
				dispatch(loadStudentCardTab(params))
			}
		},
		[dispatch, userType],
	)

	const tabList =
		userType === 'parent'
			? AccessHelper.filterList([
					{
						label: 'Основное',
						key: 'personalData',
						accessKey: 'parentCard.tabs.mainInfo',
						children: <UserCardPopupTabMain id={id} userType={userType} onLoadTab={loadTabInfo} />,
					},
					{
						label: 'Процессы',
						key: 'processes',
						accessKey: 'parentCard.tabs.processes',
						children: <UserCardPopupTabProcesses id={id} userType={userType} />,
					},
					{
						label: 'Договоры',
						key: 'contracts',
						accessKey: 'parentCard.tabs.contracts',
						children: <UserCardPopupTabContracts id={id} userType={userType} />,
					},
					{
						label: 'Документы',
						key: 'agreements',
						accessKey: 'parentCard.tabs.agreements',
						children: <UserCardPopupTabAgreements id={id} userType={userType} onLoadTab={loadTabInfo} />,
					},
					{
						label: 'Коммуникации',
						key: 'communication',
						accessKey: 'parentCard.tabs.communications',
						children: (
							<UserCardPopupTabCommunications id={id} userType={userType} onLoadTab={loadTabInfo} />
						),
					},
					{
						label: 'Лицевой счет',
						key: 'personalAccount',
						accessKey: 'parentCard.tabs.personalAccount',
						children: <UserCardPopupTabPersonalAccount userType={userType} id={id} />,
					},
					{
						label: 'Бонусы',
						key: 'bonuses',
						accessKey: 'parentCard.tabs.bonuses',
						children: <UserCardPopupTabBonuses userType={userType} id={id} />,
					},
					{
						label: 'Заметки',
						key: 'notes',
						accessKey: 'parentCard.tabs.notes',
						children: <UserCardPopupTabNotes userType={userType} id={id} />,
					},
			  ])
			: [
					{
						label: 'Основное',
						key: 'personalData',
						accessKey: 'studentCard.tabs.mainInfo',
						children: <UserCardPopupTabMain id={id} userType={userType} onLoadTab={loadTabInfo} />,
					},
					{
						label: 'Процессы',
						key: 'processes',
						accessKey: 'studentCard.tabs.processes',
						children: <UserCardPopupTabProcesses id={id} userType={userType} />,
					},
					{
						label: 'Договоры',
						key: 'contracts',
						accessKey: 'studentCard.tabs.contracts',
						children: <UserCardPopupTabContracts id={id} userType={userType} />,
					},
					{
						label: 'Документы',
						key: 'agreements',
						accessKey: 'studentCard.tabs.agreements',
						children: <UserCardPopupTabAgreements id={id} userType={userType} onLoadTab={loadTabInfo} />,
					},
					{
						label: 'Коммуникации',
						key: 'communication',
						accessKey: 'studentCard.tabs.communications',
						children: (
							<UserCardPopupTabCommunications id={id} userType={userType} onLoadTab={loadTabInfo} />
						),
					},
					{
						label: 'Заметки',
						key: 'notes',
						accessKey: 'studentCard.tabs.notes',
						children: <UserCardPopupTabNotes userType={userType} id={id} />,
					},
			  ]

	return (
		<AppHardModal
			{...rest}
			size={'large'}
			title={<UserCardPopupHeader id={id} userType={userType} onLoadTab={loadTabInfo} />}
		>
			<div className={'user-card-popup'}>
				<AppTab
					className={'user-card-popup-tabs'}
					defaultValue={tabList[0]}
					isWhiteBackground
					isFullWidth
					items={tabList}
				/>
			</div>
		</AppHardModal>
	)
}

export default UserCardPopup
