import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form as BForm, Popover, Row} from 'react-bootstrap'
import {connect} from 'react-redux'
import {createProcessStart} from '../../../store/actions/processes'
import {State} from '../../../store/store'

import {Form, Field} from 'react-final-form'
import {loadParentCard} from '../../../store/actions/parentCard'
import {UserCardState} from '../../../store/types/card'
import {FetchStatus} from '../../../common/types'
import Skeleton from 'react-loading-skeleton'


interface INeedToChangeProps extends UserCardState {
    createProcess: (payload: any) => void;
    loadParent: (parent_id: any) => void;
    student_id: string;
    id: string;
    parent_id: string;
}

export const Component: React.FC<INeedToChangeProps> = ({
	createProcess,
	children,
	student_id,
	id,
	parent_id,
	error,
	fetchStatus,
	card,
	loadParent
}) => {

	useEffect(() => {
		loadParent(parent_id)
	}, [parent_id])

	const onSubmit = async (values: any) => {
		createProcess({
			process: 'confirm_address',
			id: id,
			parameters: {
				address: values.address,
				note: values.wishes
			}
		})
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Подтвердить адрес</Popover.Title>
			</Row>

			<Row>
				{
					fetchStatus === FetchStatus.FETCHED ?
						<Col>
							<Form initialValues={{address: card.delivery_address}} onSubmit={onSubmit}>
								{
									({submitting, handleSubmit}) => (
										<form onSubmit={handleSubmit}>
											<div>
												<Field name={'address'}>
													{
														({input, name, onChange}) => (
															<BForm.Group style={{width: '100%'}}>
																<BForm.Label>Адрес</BForm.Label>
																<BForm.Control
																	type="text"
																	as="textarea"
																	value={input.value}
																	onChange={input.onChange}
																/>
															</BForm.Group>
														)
													}
												</Field>

												<Field name={'wishes'}>
													{
														({input, name, onChange}) => (
															<BForm.Group style={{width: '100%'}}>
																<BForm.Label>Пожелания</BForm.Label>
																<BForm.Control
																	type="text"
																	value={input.value}
																	onChange={input.onChange}
																/>
															</BForm.Group>
														)
													}
												</Field>
											</div>
											<Button disabled={submitting} type="submit" variant="primary">
                                                Подтвердить
											</Button>
										</form>
									)
								}
							</Form>
						</Col>
						:
						<Col>
							<Skeleton height={50} width={200}/>
						</Col>
				}
			</Row>
		</Col>
	)
}


export const GetAddress = connect(
	({parentCard}: State, ownProps: any) => ({...parentCard}),
	(dispatch) => {
		return {
			createProcess: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadParent: (parent_id: any) => {
				return dispatch(loadParentCard(parent_id))
			}
		}
	}
)(Component)
