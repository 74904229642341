import { ActionType, createAction } from 'typesafe-actions'

export const loadMeta = createAction('@debts/LOAD_META')()
export const onLoadMetaSuccess = createAction('@debts/ON_LOAD_META_SUCCESS')<any>()
export const onLoadMetaError = createAction('@debts/ON_LOAD_META_ERROR')<any>()
export const loadMetaCatalog = createAction('@debts/LOAD_META_CATALOG')<any>()
export const onLoadMetaCatalogSuccess = createAction('@debts/ON_LOAD_META_CATALOG_SUCCESS')<any>()
export const onLoadMetaCatalogError = createAction('@debts/ON_LOAD_META_CATALOG_ERROR')<any>()
export const loadMoreMetaCatalog = createAction('@debts/LOAD_MORE_META_CATALOG')<any>()
export const onLoadMoreMetaCatalogSuccess = createAction('@debts/ON_LOAD_MORE_META_CATALOG_SUCCESS')<any>()
export const onLoadMoreMetaCatalogError = createAction('@debts/ON_LOAD_MORE_META_CATALOG_ERROR')<any>()
export type MetaActions =
    | ActionType<typeof loadMeta>
    | ActionType<typeof loadMeta>
    | ActionType<typeof onLoadMetaSuccess>
    | ActionType<typeof onLoadMetaError>
    | ActionType<typeof loadMetaCatalog>
    | ActionType<typeof loadMoreMetaCatalog>
    | ActionType<typeof onLoadMetaCatalogSuccess>
    | ActionType<typeof onLoadMetaCatalogError>
    | ActionType<typeof onLoadMoreMetaCatalogSuccess>
    | ActionType<typeof onLoadMoreMetaCatalogError>;
