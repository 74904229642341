import React, { useEffect, useState } from 'react'
import { AppCard, AppText } from '@frontend-modules/ui-kit'

const PageLoader = () => {
	const [isShowHint, setShowHind] = useState(false)

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowHind(true)
		}, 500)

		return () => {
			clearTimeout(timeout)
		}
	}, [])

	return isShowHint ? (
		<AppCard>
			<AppText text={'Страница загружается...'} />
		</AppCard>
	) : null
}

export default PageLoader
