import React, { useEffect, useState } from 'react'
import '@frontend-modules/error-handler/assets/styles/error-handler-styles.scss'

import { AccessHelper } from '@frontend-modules/access-controller'
import { loadConstants } from '@/store/actions/constants'
import { loadMeta } from '@store/actions/meta'
import { loadProfile } from '@/store/actions/profile'
import 'react-quill/dist/quill.snow.css'

import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'

import Routes from '@/src-new/Routes'
import HttpInterceptor from '@components/http-interceptor'
import { parseQueryString } from '@/helpers/parseQueryString'
import { setAccessToken, setRefreshToken } from '@/common/token'

export const App: React.FC = () => {
	const dispatch = useDispatch()
	const { entities } = useSelector((state: State) => state.meta)
	const [entitiesState, setEntities] = useState(entities)

	useEffect(() => {
		dispatch(loadProfile({}))
		dispatch(loadConstants())
		dispatch(loadMeta())
	}, [dispatch])

	useEffect(() => {
		const { access, refresh } = parseQueryString(window.location.search)
		if (access) {
			setAccessToken(access)
			setRefreshToken(refresh || access)
			window.open('/', '_self')
		}
	}, [])

	/**
	 * @description - установка данных для access permission проверок
	 */
	useEffect(() => {
		AccessHelper.setData(entities)
		setEntities(entities)
	}, [entities])

	return (
		<>
			<HttpInterceptor />
			<Routes />
		</>
	)
}
