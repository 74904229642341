import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadProfile, loadProfileError, loadProfileSuccess } from '../actions/profile'
import { setChatWootUser } from '@/utils/chatwoot'

const loadProfileEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadProfile)),
		switchMap(({ payload }: ActionType<typeof loadProfile>) => {
			return from(deps.usersDataProvider.loadProfile()).pipe(
				switchMap(({ user: profile, roles }) => {
					setChatWootUser({ ...profile, roles })

					return of(loadProfileSuccess({ profile, roles }))
				}),
				catchError((err) => {
					return of(loadProfileError(err))
				}),
			)
		}),
	)
}

export const profileEpics = combineEpics(loadProfileEpic)
