/**
 *
 * @param inputString
 * @param startChars
 * @param endChars
 */
export const truncateString = (inputString, startChars, endChars) => {
	if (typeof inputString !== 'string' || typeof startChars !== 'number' || typeof endChars !== 'number') {
		throw new Error('Invalid input. Please provide a string and two numbers.')
	}

	if (startChars + endChars >= inputString.length) {
		return inputString // Возвращаем исходную строку, если сумма символов начала и конца превышает или равна длине строки.
	}

	return `${inputString.substring(0, startChars)}...${inputString.substring(inputString.length - endChars)}`
}
