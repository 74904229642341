import { ActionType, createAction } from 'typesafe-actions'
import {
	DebtsFiltersPayload,
	IDebtsSearchPayload,
	IProcessesSearchPayload,
	IUserVisitsSearchPayload,
} from '../types/search'

export const parentSearchChange = createAction('@search/PARENT/CHANGE')<any>()
export const studentSearchChange = createAction('@search/STUDENT/CHANGE')<any>()
export const actionSearchChange = createAction('@search/ACTION/CHANGE')<any>()

export const processesSearchChange = createAction('@processesSearch/SEARCH/CHANGE')<IProcessesSearchPayload>()

export const debtsSearchChange = createAction('@debtsSearch/SEARCH/CHANGE')<IDebtsSearchPayload>()
export const debtsFiltersChange = createAction('@debtsSearch/FILTERS/CHANGE')<DebtsFiltersPayload>()

export const userVisitsSearchChange = createAction('@userVisits/SEARCH/CHANGE')<IUserVisitsSearchPayload>()

export type SearchActions =
    | ActionType<typeof parentSearchChange>
    | ActionType<typeof studentSearchChange>
    | ActionType<typeof actionSearchChange>
    | ActionType<typeof processesSearchChange>
    | ActionType<typeof debtsSearchChange>
    | ActionType<typeof debtsFiltersChange>
    | ActionType<typeof userVisitsSearchChange>;
