export const getInitialDate = (value) => {
	if (value) {
		const startDate = new Date(value.split('/')[0])
		const endDate = new Date(value.split('/')[1])
		return {
			startDate: startDate,

			endDate: endDate,
		}
	}
	return null
}
