import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import {
	generateEnrollmentCertificate,
	loadStudentCard,
	loadStudentCardError,
	loadStudentCardSuccess,
	onGenerateEnrollmentCertificateError,
	onGenerateEnrollmentCertificateSuccess,
} from '../actions/studentCard'
import { Action } from '../store'
import { UserCardState } from '../types/card'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	card: null,
	generateEnrollmentCertificateFetchStatus: FetchStatus.INITIAL,
}

export const studentCard = (state: UserCardState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadStudentCard): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadStudentCardSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				card: action.payload,
			}
		}

		case getType(loadStudentCardError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		case getType(generateEnrollmentCertificate): {
			return {
				...state,
				generateEnrollmentCertificateFetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(onGenerateEnrollmentCertificateSuccess): {
			return {
				...state,
				generateEnrollmentCertificateFetchStatus: FetchStatus.FETCHED,
			}
		}

		case getType(onGenerateEnrollmentCertificateError): {
			return {
				...state,
				generateEnrollmentCertificateFetchStatus: FetchStatus.ERROR,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
