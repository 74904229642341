import React, { useEffect, useState, useCallback } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadForeignLanguagesCourses } from '../../../store/actions/courses'

export const BookForeignLanguage: React.FC<{ student_id: number, id: number }> = ({ student_id, id }) => {
	const { fetchStatus, courses, error } = useSelector((state: State) => state.foreignLanguagesCourses)
	const dispatch = useDispatch()
	const [selectedCourse, setSelectedCourse] = useState(null)

	useEffect(() => {
		dispatch(loadForeignLanguagesCourses({})) 
	}, [])

	const selectedCourseChangeCallback = useCallback((obj: any) => {
		setSelectedCourse(obj.value)
	}, [selectedCourse])

	const submitButtonPressHandler = () => {
		dispatch(createProcessStart({
			process: 'book_foreign_language',
			id,
			parameters: {
				student_id,
				limit_course_id: selectedCourse
			}
		}))
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Записать в группу иностранного языка</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '100%' }}>
								<Form.Label>Класс</Form.Label>
								<Select
									placeholder="Выберите класс"
									onChange={selectedCourseChangeCallback}
									isLoading={fetchStatus === FetchStatus.FETCHING}
									options={courses?.map(
										(el) => {
											return ({
												label: `${el.fullname} ${el.count}/${el.value}`,
												value: el.id.toString(),
											})
										}
									) || []}
								/>
							</Form.Group>
						</div>

						<Button onClick={submitButtonPressHandler} disabled={!selectedCourse} variant="primary">
                            Передать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
