import React, { useCallback, useEffect, useState } from 'react'
import { AppButton, AppPopover, AppSelect, AppSpecialButton, AppText, AppTextareaInput } from '@frontend-modules/ui-kit'
import './UserCardPopupButtons.scss'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessage } from '@store/actions/parentCard'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { useHistory } from 'react-router-dom'
import { usePrevState } from '@/hooks'

const UserCardPopupButtons = (props) => {
	const { id, phoneList, userType, onMessagesLoad } = props

	const dispatch = useDispatch()
	const history = useHistory()
	const sendMessageFetchStatus = useSelector((state: State) => state.parentCardNew.sendMessageFetchStatus)
	const prevSendMessageFetchStatusRef = usePrevState(sendMessageFetchStatus)

	const [phoneLocalList, setPhoneLocalList] = useState([])
	const [selectedPhone, setSelectedPhone] = useState<{ value: string; label: string }>(null)
	const [message, setMessage] = useState('')
	const [isMessagePopupShow, setMessagePopupShow] = useState(false)

	const onOldCardClick = () => {
		history.push(`/card/${userType}/?${userType}_id=${id}`)
	}

	useEffect(() => {
		setPhoneLocalList(
			phoneList?.map((item) => {
				const newItem = {
					label: item.number,
					value: item.number,
				}
				if (item.default) {
					setSelectedPhone(newItem)
				}
				return newItem
			}),
		)
	}, [phoneList])

	const isPhoneEmpty = !selectedPhone

	useEffect(() => {
		// очищаем и закрываем окно успешной после отправки SMS
		if (
			prevSendMessageFetchStatusRef === FetchStatus.FETCHING &&
			sendMessageFetchStatus === FetchStatus.FETCHED &&
			!!message.length
		) {
			setMessage('')
			setMessagePopupShow?.(false)
			onMessagesLoad?.()
		}
	}, [message, onMessagesLoad, prevSendMessageFetchStatusRef, sendMessageFetchStatus])

	const smsContent = useCallback(() => {
		const onSendMessage = () => {
			dispatch(
				sendMessage({
					to: selectedPhone.value,
					text: message,
					parent: id,
				}),
			)
		}

		return (
			<div style={{ display: 'grid', gridRowGap: '10px' }}>
				<AppSelect
					label={'Номер телефона'}
					defaultValue={selectedPhone}
					items={phoneLocalList}
					onChange={setSelectedPhone}
				/>
				<AppTextareaInput
					maxLength={70}
					defaultValue={message}
					onChange={setMessage}
					label={`Сообщение ${message.length}/70`}
				/>
				<AppButton
					type={'primary'}
					isDisabled={isPhoneEmpty || !message}
					label={'Отправить'}
					isLoading={sendMessageFetchStatus === FetchStatus.FETCHING}
					onClick={onSendMessage}
				/>
			</div>
		)
	}, [id, dispatch, isPhoneEmpty, message, phoneLocalList, selectedPhone, sendMessageFetchStatus])

	const callContent = useCallback(() => {
		const onCallClick = () => {
			const link = document.createElement('a')
			link.setAttribute('href', `tel:${selectedPhone.value}`)
			link.click()
		}

		return (
			<div style={{ display: 'grid', gridRowGap: '10px' }}>
				<AppSelect
					label={'Номер телефона'}
					defaultValue={selectedPhone}
					items={phoneLocalList}
					onChange={setSelectedPhone}
				/>
				<AppButton type={'primary'} isDisabled={isPhoneEmpty} label={'Позвонить'} onClick={onCallClick} />
			</div>
		)
	}, [isPhoneEmpty, phoneLocalList, selectedPhone])

	return (
		<div className={`user-card-popup-buttons ${userType}`}>
			<AppButton
				accessKey={`${userType}Card.buttons.oldCard`}
				type={'medium-background'}
				label={'Старая карточка'}
				onClick={onOldCardClick}
			/>
			{userType === 'parent' && (
				<>
					<AppPopover
						title={<AppText text={'Отправить смс'} />}
						trigger={'click'}
						open={isMessagePopupShow}
						onOpenChange={setMessagePopupShow}
						content={smsContent}
					>
						<AppSpecialButton icon={'mail-l'} />
					</AppPopover>

					<AppPopover title={<AppText text={'Позвонить'} />} trigger={'click'} content={callContent}>
						<AppSpecialButton icon={'phone-l'} />
					</AppPopover>
				</>
			)}
			{/* coming soon */}
			{/*<AppSpecialButton icon={'rocket-chat-l'} />*/}
		</div>
	)
}

export default UserCardPopupButtons
