import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { login as loginAction, onLoginError, onLoginSuccess } from '../actions/login'
import { LoginState } from '../types/login'
import { Action } from '@/store/store'

const initialState = {
	fetchStatus: FetchStatus.FETCHED,
	error: null,
}

export const loginReducer = (state: LoginState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loginAction): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(onLoginSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
			}
		}

		case getType(onLoginError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
