import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadCurrentFrozenPayment, loadCurrentFrozenPaymentError, loadCurrentFrozenPaymentSuccess, loadFrozenPayment, loadFrozenPaymentError, loadFrozenPaymentSuccess, setFrozenPaymentCurrentPage } from '../actions/frozenPayment'
import { Action } from '../store'
import { FrozenPaymentState } from '../types/frozenPayment'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	payments: {},
	count: 0,
	currentPage: 1,
	length: 0,
	currentContractFetchStatus: FetchStatus.FETCHING,
	currentContractPayments: [],
}

const formatFrozenPayments = (contracts) => {
	const result = {}

	for (const contract of contracts) {
		if (result[contract.id]) {
			result[contract.id].push(contract)
		} else {
			result[contract.id] = [contract]
		}
	}

	return result
}

export const frozenPayment = (state: FrozenPaymentState = initialState, action: Action) => {

	switch (action.type) {

	case getType(loadFrozenPayment): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING
		}
	}

	case getType(loadFrozenPaymentSuccess): {
		return { 
			...state,
			fetchStatus: FetchStatus.FETCHED,
			payments: formatFrozenPayments(action.payload.results),
			count: action.payload.count,
			length: action.payload.results.length
		}
	}
        
	case getType(loadFrozenPaymentError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	case getType(setFrozenPaymentCurrentPage): {
		return {
			...state,
			currentPage: action.payload
		}
	}

	case getType(loadCurrentFrozenPayment): {
		return {
			...state,
			currentContractFetchStatus: FetchStatus.FETCHING
		}
	}


	case getType(loadCurrentFrozenPaymentSuccess): {
		return {
			...state,
			currentContractFetchStatus: FetchStatus.FETCHED,
			currentContractPayments: action.payload
		}
	}

        
	case getType(loadCurrentFrozenPaymentError): {
		return {
			...state,
			currentContractFetchStatus: FetchStatus.ERROR
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
