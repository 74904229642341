export interface SearchState {
    studentSearch: string;
    idSearch: string;
    parentSearch: string;
    actionsSearch: any;
    schoolSearch: number[];
    tariffSearch: string[];
    formatSearch: string[];
    statesSearch: string[];
    staffUsersSearch: number[];
    createdMonthSearch: string;
    processTypesSearch: string[];
    gradeSearch: string;
}

export interface IPaymentSitesSearch {
    start?: string;
    date_before?: string;
    page: number;
    page_size?: number;
    school?: number;
    user?: number;
    student?: number;
    grade?: number;
    education_cost?: number;
    in_process?: string;
    student_group?: string;
}

export interface DebtsSearchState {
    parent: string;
    student: string;
    school: string;
    class: string;
    tariff: string;
    paymentSite: string;
    filters?: {
        activeContract?: 'false' | 'true' | 'none';
        classEnrollment?: 'false' | 'true' | 'none';
        parent: string;
        student: string;
    };
}

export enum ProcessesSearchType {
    STUDENT = 'studentSearch',
    PARENT = 'parentSearch',
    SCHOOL = 'schoolSearch',
    ID = 'idSearch',
    EDUCATION_FORMAT = 'formatSearch',
    STATES = 'statesSearch',
    TARIFF = 'tariffSearch',
    ACTIONS = 'actionsSearch',
    STAFF_USERS = 'staffUsersSearch',
    CREATED_MONTH = 'createdMonthSearch',
    PROCESS_TYPES = 'processTypesSearch',
    GRADE = 'gradeSearch',
}

export interface IProcessesSearchPayload {
    value: string | string[] | number[];
    type: ProcessesSearchType;
}

export enum DebtsSearchType {
    STUDENT = 'student',
    PARENT = 'parent',
    SCHOOL = 'school',
    CLASS = 'class',
    TARIFF = 'tariff',
    PAYMENT_SITE = 'paymentSite',
}

export enum DebtsFiltersType {
    ACTIVE_CONTRACT = 'activeContract',
    CLASS_ENROLLMENT = 'classEnrollment',
    STUDENT = 'student',
    PARENT = 'parent',
}

export interface IDebtsSearchPayload {
    value: string;
    type: DebtsSearchType;
}

export interface DebtsFiltersPayload {
    value: string;
    type: DebtsFiltersType;
}

export interface UserVisitsSearchState {
    user: {
        label: string;
        value: number;
    } | null;
    requestMethod: string[];
    responseStatus: string[];
    createdAt: string;
    pageSize: string;
}

export enum UserVisitsSearchType {
    USER = 'user',
    REQUEST_METHOD = 'requestMethod',
    RESPONSE_STATUS = 'responseStatus',
    CREATED_AT = 'createdAt',
}

export interface IUserVisitsSearchPayload {
    value: string;
    type: UserVisitsSearchType;
}
