import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getCurrentDate, getCurrentDateMinusSomeDays, getOffsetTopToContainer } from '@utils'
import {
	formatDate,
	parseQueryFiltersParamsToObject,
	trimSeparator,
	useQueryFiltersParams,
} from '@frontend-modules/frontend-utils'
import { AppButton, AppDatepicker, AppFontIcon, AppPopover, AppTable, AppText } from '@frontend-modules/ui-kit'
import { DocumentList, PageTemplate, PayModal, StatisticCard } from '@components/payments'
import { FiltersPanel } from '@frontend-modules/filters-generator'
import { useDispatch, useSelector } from 'react-redux'
import {
	loadMoreRegistries,
	loadPays,
	setPaysQuery,
	setPaysStartDate,
	setPaysEndDate,
} from '@store/actions/paymentsPays'
import { State } from '@/store/store'
import { REGISTRIES_PAGINATION_LIMIT, SERVICE_NAME, TABLE_PAGINATION_LIMIT } from './Pays.config'
import { FetchStatus } from '@/common/types'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'
import { AccessHelper } from '@frontend-modules/access-controller'

const Pays = () => {
	const {
		errors,
		summary,
		table,
		registries,
		query,
		tableFetchStatus,
		registriesFetchStatus,
		startDate: storeStartDate,
		endDate: storeEndDate,
	} = useSelector((state: State) => state.paymentsPays)
	const [startDate, setStartDate] = useState(storeStartDate || getCurrentDateMinusSomeDays(7))
	const [endDate, setEndDate] = useState(storeEndDate || getCurrentDate())
	const [selectedPay, setSelectedPay] = useState(null)
	const [initQuery, changeQueryParams] = useQueryFiltersParams()

	const [selectedFilters, setSelectedFilters] = useState(initQuery || [])
	const [tablePagination, setTablePagination] = useState({
		tableCurrentPage: 1,
		tableLimit: TABLE_PAGINATION_LIMIT,
	})
	const [registriesPagination, setRegistriesPagination] = useState({
		registryCurrentPage: 1,
		registryLimit: REGISTRIES_PAGINATION_LIMIT,
	})

	const dispatch = useDispatch()
	const { entities, catalog, fetchStatus: metaFetchStatus } = useSelector((state: State) => state.meta)

	const columns = useMemo(() => {
		return AccessHelper.filterList([
			{
				title: 'Дата',
				dataIndex: 'date',
				key: 'date',
				accessKey: 'payments.table.columns.paymentDate',
				render: (_, record) => {
					return (
						<>
							{!!record.errors.find(
								(err) => err === 'no_personal_account' || err === 'no_payment_order',
							) && <AppFontIcon icon={'exclamation-in-circle-l'} color={'#C13C37'} />}
							{formatDate(record?.paymentDate) || '-'}
						</>
					)
				},
			},
			{
				title: 'Сумма',
				dataIndex: 'amount',
				key: 'amount',
				width: '100px',
				accessKey: 'payments.table.columns.amount',
				render: (_, record) => {
					return `${trimSeparator(record?.amount) || '-'}`
				},
			},
			{
				title: 'Получатель',
				dataIndex: 'recipient',
				key: 'recipient',
				accessKey: 'payments.table.columns.recipient',
				render: (_, record) => {
					return `${record?.recipient || '-'}`
				},
			},
			{
				title: 'Плательщик',
				dataIndex: 'payer',
				key: 'payer',
				accessKey: 'payments.table.columns.payer',
				render: (_, record) => {
					return `${record?.payer || '-'}`
				},
			},
			{
				title: 'Реквизиты',
				dataIndex: 'requisites',
				key: 'requisites',
				accessKey: 'payments.table.columns.requisites',
				render: (_, record) =>
					record.requisites.length ? (
						<AppPopover
							content={record?.requisites?.map((req) => (
								<>
									<AppText text={req.label} />
									<AppText text={req.value} textStyle={'DesktopH4'} />
								</>
							))}
						>
							Реквизиты: <AppFontIcon icon={'homework-m'} color={'rgb(22, 119, 255)'} />
						</AppPopover>
					) : (
						'-'
					),
			},
			{
				title: 'Номер ПП',
				dataIndex: 'paymentOrder',
				key: 'paymentOrder',
				accessKey: 'payments.table.columns.paymentOrder',
				render: (_, record) => {
					return `${record?.paymentOrder?.number || '-'}`
				},
			},
			{
				title: '',
				dataIndex: 'actions',
				key: 'actions',
				width: '150px',
				render: (_, record) => {
					return (
						<>
							<AppButton
								accessKey={'payments.table.actions.edit'}
								type={'medium-primary'}
								label={'Редактировать'}
								onClick={() => {
									setSelectedPay(record)
								}}
							/>
						</>
					)
				},
			},
		])
	}, [])

	const loadPaysData = useCallback(
		(params, isLoadingMoreRegistries = false) => {
			// это должно попасть в юрлу
			const normalizedQuery = {
				filters: params?.filters || [],
				sorting: params.sorting || [],
			}
			const tablePaginationLocal = {
				tableCurrentPage: params?.tableCurrentPage,
				tableLimit: params?.tableLimit,
			}
			const registryPaginationLocal = {
				registryCurrentPage: params?.registryCurrentPage,
				registryLimit: params?.registryLimit,
			}
			const normalizedParams = {
				...normalizedQuery,
				...tablePaginationLocal,
				...registryPaginationLocal,
				dateStart: params.dateStart,
				dateEnd: params.dateEnd,
				fetchingTypes: params?.fetchingTypes,
			}
			dispatch(setPaysQuery(normalizedQuery))
			setSelectedFilters(normalizedQuery)
			setTablePagination(tablePaginationLocal)
			setRegistriesPagination(registryPaginationLocal)
			changeQueryParams(normalizedQuery)
			if (isLoadingMoreRegistries) {
				dispatch(loadMoreRegistries(normalizedParams))
			} else {
				dispatch(loadPays(normalizedParams))
			}
		},
		[changeQueryParams, dispatch],
	)

	const loadData = (isInit?: boolean) => {
		const baseParams = {
			tableCurrentPage: isInit ? 1 : tablePagination.tableCurrentPage,
			tableLimit: tablePagination?.tableLimit,
			registryCurrentPage: isInit ? 1 : registriesPagination.registryCurrentPage,
			registryLimit: registriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['tableFetchStatus', 'registriesFetchStatus'],
		}
		const paysParams = {
			...baseParams,
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
		}
		const currentQuery = parseQueryFiltersParamsToObject(location.search)
		// нужно для загрузки query параметров из стор перзистного
		if (
			query &&
			(!currentQuery?.filters || currentQuery?.filters?.length <= 0) &&
			(!currentQuery?.sorting || currentQuery?.sorting?.length <= 0)
		) {
			loadPaysData({
				...query,
				...baseParams,
			})
		} else {
			loadPaysData(paysParams)
		}
	}

	useEffect(() => {
		loadData(true)
	}, [startDate, endDate, dispatch])

	const onPaginationChange = ({ page, limit }) => {
		const newPagination = {
			currentPage: page,
			limit,
		}
		loadPaysData({
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
			tableCurrentPage: newPagination?.currentPage,
			tableLimit: newPagination?.limit,
			registryCurrentPage: registriesPagination.registryCurrentPage,
			registryLimit: registriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['tableFetchStatus'],
		})
	}

	const onRegistriesUpdate = ({ page, limit, isLoadingMore }) => {
		const newRegistriesPagination = {
			registryCurrentPage: page,
			registryLimit: limit,
		}
		const params = {
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
			tableCurrentPage: tablePagination?.tableCurrentPage,
			tableLimit: tablePagination?.tableLimit,
			registryCurrentPage: newRegistriesPagination.registryCurrentPage,
			registryLimit: newRegistriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['registriesFetchStatus'],
		}
		loadPaysData(params, isLoadingMore)
	}

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
		scrollToTableStart()
	}
	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== table?.pagination.currentPage) {
			onPaginationChange?.({ page, limit: tablePagination?.tableLimit })
		}
		scrollToTableStart()
	}

	const scrollToTableStart = () => {
		const container = document.getElementById('contentContainer')
		const element = document.getElementById('paysTable')
		container.scrollTop = getOffsetTopToContainer(element, container) - 25
	}

	/**
	 * @description реагируем на добавление фильтра
	 */
	const onFilterAdd = (newFilters) => {
		const params = {
			filters: newFilters,
			tableCurrentPage: 1,
			tableLimit: tablePagination.tableLimit,
			sorting: selectedFilters.sorting,
			registryCurrentPage: registriesPagination.registryCurrentPage,
			registryLimit: registriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['tableFetchStatus'],
		}
		loadPaysData(params)
	}

	/**
	 * @description реагируем на удаление фильтра
	 */
	const onFilterRemove = (newFilters) => {
		const params = {
			filters: newFilters,
			tableCurrentPage: 1,
			tableLimit: tablePagination.tableLimit,
			sorting: selectedFilters.sorting,
			registryCurrentPage: registriesPagination.registryCurrentPage,
			registryLimit: registriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['tableFetchStatus'],
		}
		loadPaysData(params)
	}
	/**
	 * @description реагируем на полную очистку фильтров
	 */
	const onFiltersClear = () => {
		const params = {
			filters: [],
			sorting: selectedFilters.sorting,
			tableCurrentPage: 1,
			tableLimit: tablePagination.tableLimit,
			registryCurrentPage: registriesPagination.registryCurrentPage,
			registryLimit: registriesPagination.registryLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['tableFetchStatus'],
		}
		loadPaysData(params)
	}

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				tableCurrentPage: 1,
				tableLimit: tablePagination.tableLimit,
				registryCurrentPage: registriesPagination.registryCurrentPage,
				registryLimit: registriesPagination.registryLimit,
				dateEnd: endDate,
				dateStart: startDate,
				fetchingTypes: ['tableFetchStatus'],
			}
			loadPaysData(params)
		}

		const onSortingClear = (newSorting) => {
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				tableCurrentPage: 1,
				tableLimit: tablePagination.tableLimit,
				registryCurrentPage: registriesPagination.registryCurrentPage,
				registryLimit: registriesPagination.registryLimit,
				dateEnd: endDate,
				dateStart: startDate,
				fetchingTypes: ['tableFetchStatus'],
			}
			loadPaysData(params)
		}

		return {
			availableList: entities?.payments?.sorting,
			selectedList: selectedFilters.sorting,
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
	}, [
		entities?.payments?.sorting,
		selectedFilters,
		tablePagination?.tableLimit,
		registriesPagination,
		endDate,
		startDate,
		loadPaysData,
	])

	const onStartDateChange = (date) => {
		setStartDate(date)
		dispatch(setPaysStartDate(date))
	}

	const onEndDateChange = (date) => {
		setEndDate(date)
		dispatch(setPaysEndDate(date))
	}

	return (
		<>
			{!!selectedPay && (
				<PayModal
					id={selectedPay.id}
					isShow={!!selectedPay}
					onSetShow={(open, isWasChanges) => {
						if (!open) setSelectedPay(null)
						if (!open && isWasChanges) loadData()
					}}
				/>
			)}
			<PageTemplate
				type={'pays'}
				periodStart={
					<AppDatepicker
						allowClear={false}
						defaultValue={startDate}
						label={'Дата начала'}
						onChange={onStartDateChange}
					/>
				}
				periodEnd={
					<AppDatepicker
						allowClear={false}
						defaultValue={endDate}
						label={'Дата конца'}
						onChange={onEndDateChange}
					/>
				}
				filters={
					<FiltersPanel
						isFetching={metaFetchStatus === FetchStatus.FETCHING}
						selectsApiConfig={filtersApiConfig}
						availableFilters={entities?.payments?.filters || []}
						selectedFiltersList={selectedFilters.filters}
						onFilterAdd={onFilterAdd}
						onFilterRemove={onFilterRemove}
						onFiltersClear={onFiltersClear}
						serviceName={SERVICE_NAME}
					/>
				}
				infoStatistic={
					<StatisticCard
						type={'pays'}
						data={{
							total: summary?.payments?.total,
							incoming: summary?.payments?.incoming,
							incomingValue: summary?.payments?.incomingCoins,
							outgoing: summary?.payments?.outgoing,
							outgoingValue: summary?.payments?.outgoingCoins,
							red: summary?.paymentMismatch,
						}}
					/>
				}
				infoDocumentList={
					<DocumentList
						isLoading={registriesFetchStatus === FetchStatus.FETCHING}
						isLoadingMore={registriesFetchStatus === FetchStatus.FETCHING_MORE}
						type={'pays'}
						data={registries}
						onLoad={onRegistriesUpdate}
					/>
				}
				table={
					<AppTable
						id={'paysTable'}
						isLoading={tableFetchStatus === FetchStatus.FETCHING}
						columns={columns}
						isCanDeleteRow={false}
						isNeedNumbering={false}
						dataSource={table?.results || []}
						locale={{ emptyText: 'Ничего не найдено' }}
						rowKey={(record, index) => `${index}`}
						sortingConfig={sortingConfig}
						pagination={{
							pageSize: tablePagination?.tableLimit,
							defaultCurrent: 1,
							onChange: onPaginationChangeLocal,
							onShowSizeChange: onPaginationSizeChangeLocal,
							locale: { items_per_page: 'записей ' },
							total: table?.pagination?.count,
							current: tablePagination?.tableCurrentPage,
						}}
						titleSortingType={'customSorting'}
					/>
				}
			/>
		</>
	)
}

export default Pays
