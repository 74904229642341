import * as Sentry from '@sentry/react'

const initSentry = () => {
	Sentry.init({
		dsn:
			process.env.NODE_ENV === 'development'
				? 'https://5568c46ad2d74a4b92ecece39a50450c@sentry.og1.ru/10'
				: 'https://c2c7ad99c57640648f059e149a3e7cc0@sentry.og1.ru/9',
		tracesSampleRate: 1, //флаг, указывающий на то, что 100% ошибок будет отправлено в сентри
		replaysSessionSampleRate: 0.1, //записывать 10% сессий пользователей в видеоподобном формате (replay)
		replaysOnErrorSampleRate: 1.0, //записывать 100% скринкастов при возникновении ошибок (replay)
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		environment: process.env.REACT_APP_ENV, //среда разработки (названия сред тоже прописываются настройках sentry)
	})
}

export { Sentry, initSentry }
