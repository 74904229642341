import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'
import { ManagerInfoState, UserVisitsState } from '../types/userLogging'
import {
	loadManagerInfo,
	loadManagerInfoError,
	loadManagerInfoSuccess,
	loadUserVisitsError,
	loadUserVisitsStart,
	loadUserVisitsSuccess,
	managerInfoSearchChange,
	setManagerInfoCurrentPage,
	setUserVisitsCurrentPage,
} from '../actions/userLogging'

const initialState: UserVisitsState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	userVisits: null,
	currentPage: 1,
	totalCount: 0,
}

export const userVisits = (state: UserVisitsState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadUserVisitsStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadUserVisitsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			userVisits: action.payload.results,
			totalCount: action.payload.count,
		}
	}

	case getType(loadUserVisitsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(setUserVisitsCurrentPage): {
		return {
			...state,
			currentPage: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

const DEFAULT_PAGE_SIZE = 20

const managerInfoInitialState: ManagerInfoState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	data: null,
	currentPage: 1,
	totalCount: 0,
	search: {
		user: null,
		action: null,
		createdAt: null,
		pageSize: DEFAULT_PAGE_SIZE,
	},
}

export const managerInfo = (state: ManagerInfoState = managerInfoInitialState, action: Action) => {
	switch (action.type) {
	case getType(loadManagerInfo): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadManagerInfoSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload.results,
			totalCount: action.payload.count,
		}
	}

	case getType(loadManagerInfoError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(setManagerInfoCurrentPage): {
		return {
			...state,
			currentPage: action.payload,
		}
	}

	case getType(managerInfoSearchChange): {
		return {
			...state,
			search: {
				...state.search,
				[action.payload.type]: action.payload.value,
			},
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
