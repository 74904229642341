import React, { FC, PropsWithChildren } from 'react'
import './Task.scss'
import { ITaskPageProps } from './Task.types'
import { TaskForm } from '@components/tasks'

/**
 * @description - Страница выполнения "задачи"
 * @constructor
 */
export const Task: FC<PropsWithChildren<ITaskPageProps>> = (props) => {
	const { match } = props
	return <TaskForm id={match?.params?.id} />
}

export default Task
