import { ActionType, createAction } from 'typesafe-actions'

export const loadParentCard = createAction('@parentCard/LOAD/START')<any>()
export const loadParentCardSuccess = createAction('@parentCard/LOAD/SUCCESS')<any>()
export const loadParentCardError = createAction('@parentCard/LOAD/ERROR')<any>()

export const loadParentSmsStatuses = createAction('@parentCard/LOAD/SMS_STATUSES_START')<any>()
export const loadParentSmsStatusesSuccess = createAction('@parentCard/LOAD/SMS_STATUSES_SUCCESS')<any>()
export const loadParentSmsStatusesError = createAction('@parentCard/LOAD/SMS_STATUSES_ERROR')<any>()

export const loadParentLetterStatuses = createAction('@parentCard/LOAD/LETTER_STATUSES_START')<any>()
export const loadParentLetterStatusesSuccess = createAction('@parentCard/LOAD/LETTER_STATUSES_SUCCESS')<any>()
export const loadParentLetterStatusesError = createAction('@parentCard/LOAD/LETTER_STATUSES_ERROR')<any>()

export const addParentCard = createAction('@parentCard/ADD/START')<any>()
export const addParentCardSuccess = createAction('@parentCard/ADD/SUCCESS')<any>()
export const addParentCardError = createAction('@parentCard/ADD/ERROR')<any>()

export type ParentCardActions =
	| ActionType<typeof loadParentCard>
	| ActionType<typeof loadParentCardSuccess>
	| ActionType<typeof loadParentCardError>
	| ActionType<typeof loadParentSmsStatuses>
	| ActionType<typeof loadParentSmsStatusesSuccess>
	| ActionType<typeof loadParentSmsStatusesError>
	| ActionType<typeof loadParentLetterStatuses>
	| ActionType<typeof loadParentLetterStatusesSuccess>
	| ActionType<typeof loadParentLetterStatusesError>
	| ActionType<typeof addParentCard>
	| ActionType<typeof addParentCardSuccess>
	| ActionType<typeof addParentCardError>
