import {FetchStatus} from '../../common/types'
import {Action} from '../store'
import {getType} from 'typesafe-actions'
import {ClassesState, DeleteClassState} from '../types/classes'
import {
	addCuratorsError,
	addCuratorsStart,
	addCuratorsSuccess,
	addStudentsError,
	addStudentsStart,
	addStudentsSuccess,
	createClassError,
	createClassStart,
	createClassSuccess,
	deleteClassError,
	deleteClassStart,
	deleteClassSuccess,
	expelStudentError,
	expelStudentStart,
	expelStudentSuccess,
	loadClassesError,
	loadClassesStart,
	loadClassesSuccess,
	loadCuratorsError,
	loadCuratorsStart,
	loadCuratorsSuccess,
	loadParallelsError,
	loadParallelsStart,
	loadParallelsSuccess, loadTutorsError,
	loadTutorsSuccess,
	removeCuratorsError,
	removeCuratorsStart,
	removeCuratorsSuccess,
} from '../actions/classes'

const classesInitialState: ClassesState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	classes: null,
	curators: null,
	curatorsFetchStatus: FetchStatus.INITIAL,
	curatorsError: null,
	tutors: null,
	tutorsFetchStatus: FetchStatus.INITIAL,
	tutorsError: null,
	createClassFetchStatus: FetchStatus.INITIAL,
	createClassError: null,
	addCuratorsFetchStatus: FetchStatus.INITIAL,
	addCuratorsError: null,
	removeCuratorsFetchStatus: FetchStatus.INITIAL,
	removeCuratorsError: null,
	addStudentsFetchStatus: FetchStatus.INITIAL,
	addStudentsError: null,
	parallels: null,
	parallelsFetchStatus: FetchStatus.INITIAL,
	parallelsError: null,
	expelStudentFetchStatus: FetchStatus.INITIAL,
	expelStudentError: null,
}

export const classes = (state: ClassesState = classesInitialState, action: Action) => {
	switch (action.type) {
	case getType(expelStudentStart): {
		return {
			...state,
			expelStudentFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(expelStudentSuccess): {
		return {
			...state,
			expelStudentFetchStatus: FetchStatus.FETCHED,
			expelStudentError: null,
		}
	}
	case getType(expelStudentError): {
		return {
			...state,
			expelStudentFetchStatus: FetchStatus.ERROR,
			expelStudentError: action.payload,
		}
	}

	case getType(loadClassesStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(loadClassesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			classes: action.payload,
			error: null,
		}
	}
	case getType(loadClassesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(createClassStart): {
		return {
			...state,
			createClassFetchStatus: FetchStatus.FETCHING,
			createClassError: null,
		}
	}
	case getType(createClassSuccess): {
		return {
			...state,
			createClassFetchStatus: FetchStatus.FETCHED,
			classes: [...state.classes, action.payload],
			createClassError: null,
		}
	}
	case getType(createClassError): {
		return {
			...state,
			createClassFetchStatus: FetchStatus.ERROR,
			createClassError: action.payload,
		}
	}

	case getType(deleteClassSuccess): {
		return {
			...state,
			classes: state.classes.filter((el) => el.id === action.payload.id),
		}
	}

	case getType(loadCuratorsStart): {
		return {
			...state,
			curatorsFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(loadCuratorsSuccess): {
		return {
			...state,
			curatorsFetchStatus: FetchStatus.FETCHED,
			curators: action.payload,
			curatorsError: null,
		}
	}
	case getType(loadCuratorsError): {
		return {
			...state,
			curatorsFetchStatus: FetchStatus.ERROR,
			curatorsError: action.payload,
		}
	}
	case getType(loadTutorsSuccess): {
		return {
			...state,
			tutorsFetchStatus: FetchStatus.FETCHED,
			tutors: action.payload,
			tutorsError: null
		}
	}
	case getType(loadTutorsError): {
		return {
			...state,
			tutorsFetchStatus: FetchStatus.ERROR,
			tutorsError: action.payload
		}
	}
	case getType(loadParallelsStart): {
		return {
			...state,
			parallelsFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(loadParallelsSuccess): {
		return {
			...state,
			parallelsFetchStatus: FetchStatus.FETCHED,
			parallels: action.payload,
			parallelsError: null,
		}
	}
	case getType(loadParallelsError): {
		return {
			...state,
			parallelsFetchStatus: FetchStatus.ERROR,
			parallelsError: action.payload,
		}
	}

	case getType(addCuratorsStart): {
		return {
			...state,
			addCuratorsFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(addCuratorsSuccess): {
		return {
			...state,
			addCuratorsFetchStatus: FetchStatus.FETCHED,
			addCuratorsError: null,
			classes: state.classes.map((item) =>
				item.id === action.payload?.id ? { ...item, curators: action.payload?.curators } : item,
			),
		}
	}
	case getType(addCuratorsError): {
		return {
			...state,
			addCuratorsFetchStatus: FetchStatus.ERROR,
			addCuratorsError: action.payload,
		}
	}

	case getType(removeCuratorsStart): {
		return {
			...state,
			removeCuratorsFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(removeCuratorsSuccess): {
		return {
			...state,
			removeCuratorsFetchStatus: FetchStatus.FETCHED,
			removeCuratorsError: null,
			classes: state.classes.map((item) =>
				item.id === action.payload?.id ? { ...item, curators: action.payload?.curators } : item,
			),
		}
	}
	case getType(removeCuratorsError): {
		return {
			...state,
			removeCuratorsFetchStatus: FetchStatus.ERROR,
			removeCuratorsError: action.payload,
		}
	}

	case getType(addStudentsStart): {
		return {
			...state,
			addStudentsFetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(addStudentsSuccess): {
		return {
			...state,
			addStudentsFetchStatus: FetchStatus.FETCHED,
			addStudentsError: null,
			classes: state.classes.map((item) =>
				item.id === action.payload?.id ? { ...item, students: action.payload?.students } : item,
			),
		}
	}
	case getType(addStudentsError): {
		return {
			...state,
			addStudentsFetchStatus: FetchStatus.ERROR,
			addStudentsError: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

const deleteClassInitialState: DeleteClassState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	data: null,
}

export const deleteClass = (state: DeleteClassState = deleteClassInitialState, action: Action) => {
	switch (action.type) {
	case getType(deleteClassStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(deleteClassSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
			error: null,
		}
	}
	case getType(deleteClassError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			data: null,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
