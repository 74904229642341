import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	loadDebts,
	loadDebtsPayments,
	onLoadDebtsError,
	onLoadDebtsPaymentsError,
	onLoadDebtsPaymentsSuccess,
	onLoadDebtsSuccess,
	onSendDebtsNotifyError,
	onSendDebtsNotifySuccess,
	onSendDebtsStudentBlockError,
	onSendDebtsStudentBlockSuccess,
	onUpdateDebtsPaymentStatusError,
	onUpdateDebtsPaymentStatusSuccess,
	sendDebtsNotify,
	sendDebtsStudentBlock,
	setDebtsQuery,
	updateDebtsPaymentStatus,
} from '@store/actions/debts'
import { DebtsState } from '@store/types/debts'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'
import { persistReducer } from 'redux-persist'

const debtsInitialState: DebtsState = {
	fetchStatus: FetchStatus.INITIAL,
	list: [],
	errors: [],
	query: {},
	paymentUpdatingStatus: FetchStatus.INITIAL,
	notifySendingStatus: FetchStatus.INITIAL,
	blockSendingStatus: FetchStatus.INITIAL,
}

export const debtsReducer = (state = debtsInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadDebts): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadDebtsSuccess): {
			return {
				...state,
				list: action.payload.results,
				pagination: action.payload.pagination,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadDebtsError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(setDebtsQuery): {
			return {
				...state,
				query: action.payload,
			}
		}
		case getType(loadDebtsPayments): {
			return {
				...state,
				paymentsData: {
					...state.paymentsData,
					[action.payload]: {
						...state[action.payload],
						fetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onLoadDebtsPaymentsSuccess): {
			return {
				...state,
				paymentsData: {
					...state.paymentsData,
					[action.payload.id]: {
						...state[action.payload.id],
						list: action.payload.list,
						fetchStatus: FetchStatus.FETCHED,
					},
				},
			}
		}
		case getType(onLoadDebtsPaymentsError): {
			return {
				...state,
				paymentsData: {
					...state.paymentsData,
					[action.payload.id]: {
						...state[action.payload.id],
						fetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(updateDebtsPaymentStatus): {
			return {
				...state,
				paymentUpdatingStatus: FetchStatus.FETCHING,
				updatePaymentProcessData: {
					...state.updatePaymentProcessData,
					fetchStatus: FetchStatus.FETCHING,
				},
			}
		}
		case getType(onUpdateDebtsPaymentStatusSuccess): {
			const paymentID = action.payload.paymentID
			const studentID = action.payload.studentID
			const stateWithoutDeletedPayment =
				state.paymentsData?.[studentID]?.list?.filter((item) => item?.id !== paymentID) || []
			const isNewStateEmpty = !stateWithoutDeletedPayment?.length
			// если платежки закончились, убираем студента из списка совсем
			if (isNewStateEmpty) {
				const newStudentsList = state?.list?.filter((item) => item?.id !== studentID) || []
				return {
					...state,
					list: newStudentsList,
					paymentUpdatingStatus: FetchStatus.FETCHED,
					updatePaymentProcessData: {
						...state.updatePaymentProcessData,
						fetchStatus: FetchStatus.FETCHED,
					},
				}
			}
			// если платежки еще остались, просто убираем оплаченную
			// и уменьшаем количество платежных участков
			const studentList = state.list.map((student) => {
				if (student.id === studentID) {
					return {
						...student,
						payment_count: student.payment_count - 1,
					}
				}
				return student
			})
			return {
				...state,
				list: studentList,
				paymentsData: {
					...state.paymentsData,
					[studentID]: {
						...state.paymentsData[studentID],
						list: stateWithoutDeletedPayment,
					},
				},
				paymentUpdatingStatus: FetchStatus.FETCHED,
				updatePaymentProcessData: {
					...state.updatePaymentProcessData,
					fetchStatus: FetchStatus.FETCHED,
				},
			}
		}
		case getType(onUpdateDebtsPaymentStatusError): {
			return {
				...state,
				paymentUpdatingStatus: FetchStatus.ERROR,
				updatePaymentProcessData: {
					...state.updatePaymentProcessData,
					fetchStatus: FetchStatus.ERROR,
				},
			}
		}

		case getType(sendDebtsNotify): {
			return {
				...state,
				notifySendingStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onSendDebtsNotifySuccess): {
			return {
				...state,
				notifySendingStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onSendDebtsNotifyError): {
			return {
				...state,
				notifySendingStatus: FetchStatus.ERROR,
			}
		}
		case getType(sendDebtsStudentBlock): {
			return {
				...state,
				blockSendingStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onSendDebtsStudentBlockSuccess): {
			const studentList = state.list.map((student) => {
				if (student.id === action.payload.id) {
					return {
						...student,
						block_financial: !student.block_financial,
					}
				}
				return student
			})
			return {
				...state,
				blockSendingStatus: FetchStatus.FETCHED,
				list: studentList,
			}
		}
		case getType(onSendDebtsStudentBlockError): {
			return {
				...state,
				blockSendingStatus: FetchStatus.ERROR,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedDebtsReducer: any = persistReducer(
	{
		key: 'AdminPanel::debts',
		storage: indexedDBStore,
		whitelist: ['query'],
		version: 1,
	},
	debtsReducer,
)
