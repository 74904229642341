import { persistReducer } from 'redux-persist'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import {
	deleteDocument,
	deleteDocumentError,
	deleteDocumentSuccess,
	generatedDocsSearchChange,
	loadDocumentList,
	loadDocumentListError,
	loadDocumentListSuccess,
	loadGeneratedDocumentsError,
	loadGeneratedDocumentsStart,
	loadGeneratedDocumentsSuccess,
	setGeneratedDocumentsCurrentPage,
	uploadDocument,
	uploadDocumentError,
	uploadDocumentSuccess,
} from '../actions/document'
import { Action } from '../store'
import { DocumentState, GeneratedDocumentsState } from '../types/document'
// import localStorage from 'redux-persist/es/storage'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState: DocumentState = {
	fetchStatus: FetchStatus.FETCHED,
	error: null,
	status: 'CREATE',
	documents: [],
	deleteDocument: {
		fetchStatus: FetchStatus.INITIAL,
		error: null,
	},
}

export const reducer = (state: DocumentState = initialState, action: Action) => {
	switch (action.type) {
	case getType(uploadDocument): {
		return {
			...state,
			status: 'UPLOADING',
		}
	}

	case getType(uploadDocumentSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			status: 'CREATE',
		}
	}

	case getType(uploadDocumentError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			status: 'ERROR',
		}
	}

	case getType(loadDocumentList): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
			documents: [],
		}
	}

	case getType(loadDocumentListSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			documents: action.payload,
		}
	}

	case getType(loadDocumentListError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
		}
	}

	case getType(deleteDocument): {
		return {
			...state,
			deleteDocument: {
				...state.deleteDocument,
				fetchStatus: FetchStatus.FETCHING,
			},
		}
	}

	case getType(deleteDocumentSuccess): {
		return {
			...state,
			deleteDocument: {
				...state.deleteDocument,
				fetchStatus: FetchStatus.FETCHED,
				error: null,
			},
			documents: state.documents.map((storeDocument) => ({
				...storeDocument,
				files: storeDocument?.files?.filter((file) => file?.id !== action.payload),
			})),
		}
	}

	case getType(deleteDocumentError): {
		return {
			...state,
			deleteDocument: {
				...state.deleteDocument,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			},
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

const generatedDocumentsInitialState: GeneratedDocumentsState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	generatedDocuments: [],
	generatedDocsSearch: {
		student: null,
	},
	currentPage: 1,
	totalCount: 1,
}

export const generatedDocuments = (state: GeneratedDocumentsState = generatedDocumentsInitialState, action: Action) => {
	switch (action.type) {
	case getType(loadGeneratedDocumentsStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadGeneratedDocumentsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			error: null,
			generatedDocuments: action.payload?.results,
			totalCount: action.payload?.count,
		}
	}

	case getType(loadGeneratedDocumentsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(setGeneratedDocumentsCurrentPage): {
		return {
			...state,
			currentPage: action.payload,
		}
	}

	case getType(generatedDocsSearchChange): {
		return {
			...state,
			generatedDocsSearch: {
				...state.generatedDocsSearch,
				[action.payload.type]: action.payload.value,
			},
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const document: any = persistReducer(
	{
		key: 'AdminPanel::document',
		storage: indexedDBOldStore,
		blacklist: ['status', 'error'],
		version: 1,
	},
	reducer,
)
