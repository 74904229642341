import { ActionType, createAction } from 'typesafe-actions'

export const loadContract = createAction('@contract/LOAD/START')<any>()
export const loadContractSuccess = createAction('@contract/LOAD/SUCCESS')<any>()
export const loadContractError = createAction('@contract/LOAD/ERROR')<any>()

export const createContract = createAction('@contract/CREATE/START')<any>()
export const createContractSuccess = createAction('@contract/CREATE/SUCCESS')<any>()
export const createContractError = createAction('@contract/CREATE/ERROR')<any>()

export const changeContract = createAction('@contract/CHANGE/START')<any>()
export const changeContractSuccess = createAction('@contract/CHANGE/SUCCESS')<any>()
export const changeContractError = createAction('@contract/CHANGE/ERROR')<any>()

export const deleteContract = createAction('@contract/DELETE/START')<number>()
export const deleteContractSuccess = createAction('@contract/DELETE/SUCCESS')<any>()
export const deleteContractError = createAction('@contract/DELETE/ERROR')<any>()

export const createPaymentArea = createAction('@contract/AREA/CREATE/START')<any>()
export const createPaymentAreaSuccess = createAction('@contract/AREA/CREATE/SUCCESS')<any>()
export const createPaymentAreaError = createAction('@contract/AREA/CREATE/ERROR')<any>()

export const deletePaymentArea = createAction('@contract/AREA/DELETE/START')<any>()
export const deletePaymentAreaSuccess = createAction('@contract/AREA/DELETE/SUCCESS')<any>()
export const deletePaymentAreaError = createAction('@contract/AREA/DELETE/ERROR')<any>()

export type ContractActions =
    | ActionType<typeof loadContract>
    | ActionType<typeof loadContractSuccess>
    | ActionType<typeof loadContractError>
    | ActionType<typeof changeContract>
    | ActionType<typeof changeContractSuccess>
    | ActionType<typeof changeContractError>
    | ActionType<typeof createPaymentArea>
    | ActionType<typeof createPaymentAreaSuccess>
    | ActionType<typeof createPaymentAreaError>
    | ActionType<typeof deletePaymentArea>
    | ActionType<typeof deletePaymentAreaSuccess>
    | ActionType<typeof deletePaymentAreaError>
    | ActionType<typeof createContract>
    | ActionType<typeof createContractError>
    | ActionType<typeof createContractSuccess>
    | ActionType<typeof deleteContract>
    | ActionType<typeof deleteContractSuccess>
    | ActionType<typeof deleteContractError>;
