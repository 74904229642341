import React, { FC, useMemo } from 'react'
import { AppButton, AppTable } from '@frontend-modules/ui-kit'
import { IMainTableProps } from './MainTable.types'
import './MainTable.scss'
/**
 * @description - таблица приказов
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const { isLoading, list, onPaginationChange, paginationLimit, pagination, sortingConfig } = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	const columns = useMemo(() => {
		return [
			{
				title: 'Номер приказа',
				dataIndex: 'number',
				width: '50px',
				key: 'number',
			},
			{
				title: 'Дата приказа',
				dataIndex: 'dateCreated',
				width: '150px',
				key: 'dateCreated',
				render: (_, record) => {
					return `${record?.dateCreated || '-'}`
				},
			},
			{
				title: 'Тип приказа',
				dataIndex: 'orderType',
				key: 'orderType',
				render: (_, record) => {
					return <div className={'white-space-block'}>{record.orderType || '-'}</div>
				},
			},
			{
				title: 'Вид образования',
				dataIndex: 'educationType',
				key: 'educationType',
				render: (_, record) => {
					return <div className={'white-space-block'}>{record.educationType || '-'}</div>
				},
			},
			{
				title: 'Доп информация',
				dataIndex: 'additionalInfo',
				key: 'additionalInfo',
				render: (_, record) => {
					return (
						<div
							className={'white-space-block'}
							dangerouslySetInnerHTML={{ __html: `${record.additionalInfo || '-'}` }}
						/>
					)
				},
			},
			{
				title: '',
				dataIndex: 'actions',
				width: '50px',
				key: 'actions',
				render: (_, record) => {
					return (
						<AppButton
							style={{ maxWidth: 75 }}
							type={'medium-primary'}
							onClick={() => {
								window.open(record.file, '_blank')
							}}
							label={'Скачать'}
						/>
					)
				},
			},
		]
	}, [paginationLimit, pagination.currentPage])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isNeedNumbering={false}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={{
				total: pagination?.count,
				pageSize: paginationLimit,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				onChange: onPaginationChangeLocal,
				locale: { items_per_page: 'записей ' },
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			rowKey={(record) => record.id}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
			className={'decree-main-table'}
		/>
	)
}

export default MainTable
