import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'
import { ChatState } from '../types/chat'
import { closeChat, loadChat, loadChatError, loadChatSuccess, loadUnreadMessages, loadUnreadMessagesError, loadUnreadMessagesSuccess, openChat } from '../actions/chat'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	chat: [],
	error: null,
	student_id: null,
	unread: {}
}

const formatUnreadMessages = (messages: Array<any>) => {
	const result = {}

	for (const message of messages) {
		result[message.child] = true
	}

	return result
}

export const chat = (state: ChatState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadChat): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadChatSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			chat: action.payload,
		}
	}

	case getType(loadChatError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(openChat): {
		return {
			...state,
			student_id: action.payload,
		}
	}

	case getType(closeChat): {
		return {
			...state,
			student_id: null,
		}
	}

	case getType(loadUnreadMessages): {
		return {
			...state
		}
	}

	case getType(loadUnreadMessagesSuccess): {
		return {
			...state,
			unread: formatUnreadMessages(action.payload)
		}
	}

	case getType(loadUnreadMessagesError): {
		return {
			...state,
			error: action.payload
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
