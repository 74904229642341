import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import {
	AppButton,
	AppCard,
	AppHardModalButton,
	AppModal,
	AppSearchSelect,
	AppSpecialButton,
	AppTable,
	AppText,
} from '@frontend-modules/ui-kit'
import './AssignmentModal.scss'
import { CreateEditPayPopup } from '@components/payments'
import { formatDate, trimSeparator } from '@frontend-modules/frontend-utils'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { loadAssignmentInfo, changeAssignmentInfo } from '@store/actions/paymentsAssignments'
import { PaymentsService } from '@services'
import { usePrevState } from '@/hooks'
import { showSuccessToast } from '@utils'
import { IAssignmentModalProps } from './AssignmentModal.types'
const paymentsService = new PaymentsService()
/**
 * @description - модалка платежных поручений
 * @param props
 * @constructor
 */
const AssignmentModal: FC<IAssignmentModalProps> = (props) => {
	const { isShow, onSetShow, id } = props

	const assignmentInfo = useSelector((state: State) => state.paymentsAssignments?.assignmentsInfo) || {}
	const { fetchStatus, changeFetchStatus, data } = assignmentInfo[id] || {}
	const [isWasChanges, setWasChanges] = useState(false)

	const [pay, setPay] = useState(null)
	const [payList, setPayList] = useState(null)
	const [payFetchingStatus, setPayFetchingStatus] = useState(FetchStatus.INITIAL)

	const prevChangeFetchStatus = usePrevState(changeFetchStatus)
	const dispatch = useDispatch()

	useEffect(() => {
		if (prevChangeFetchStatus === FetchStatus.FETCHING && changeFetchStatus === FetchStatus.FETCHED) {
			dispatch(loadAssignmentInfo({ id: id }))
			showSuccessToast({ message: 'Данные успешно обновлены!' })
			setPay(null)
			setWasChanges(true)
		}
	}, [prevChangeFetchStatus, changeFetchStatus, dispatch, id])

	const isLoading = fetchStatus === FetchStatus.FETCHING || changeFetchStatus === FetchStatus.FETCHING

	useEffect(() => {
		dispatch(loadAssignmentInfo({ id: id }))
	}, [id, dispatch])

	const loadPaysList = useCallback(
		(params, isMore) => {
			setPayFetchingStatus(isMore ? FetchStatus.FETCHING_MORE : FetchStatus.FETCHING)
			paymentsService.findPays(params).then((res: any) => {
				setPayFetchingStatus(FetchStatus.FETCHED)
				if (isMore) {
					const newPagination = res.pagination
					const newList = [...(payList?.results || []), ...res.results]
					setPayList({ pagination: newPagination, results: newList })
				} else {
					setPayList(res)
				}
			})
		},
		[payList],
	)

	const loadMorePaysList = useCallback(
		(params) => {
			loadPaysList?.(params, true)
		},
		[loadPaysList],
	)

	const dispatchChangeAssignmentInfo = useCallback(
		(payload) => {
			dispatch(changeAssignmentInfo(payload))
		},
		[dispatch],
	)

	const onPayConnect = () => {
		dispatchChangeAssignmentInfo({ paymentAssignmentId: id, id: pay.id, update: { paymentOrder: id } })
	}
	const onPayRemove = (item) => {
		dispatchChangeAssignmentInfo({ paymentAssignmentId: id, id: item.id, update: { paymentOrder: null } })
	}

	const onPayCreate = (saveData) => {
		dispatchChangeAssignmentInfo({
			paymentAssignmentId: id,
			create: {
				...saveData,
				paymentOrder: id,
				paymentDate: formatDate(new Date(), 'YYYY-MM-DD'),
			},
		})
	}

	const onPayEdit = (item, saveData) => {
		dispatchChangeAssignmentInfo({
			paymentAssignmentId: id,
			id: item.id,
			update: {
				...saveData,
			},
		})
	}

	const columns = useMemo(() => {
		return [
			{
				title: '№',
				dataIndex: 'number',
				key: 'number',
			},
			{
				title: 'Дата платежа',
				dataIndex: 'paymentDate',
				key: 'paymentDate',
				render: (_, record) => {
					return `${formatDate(record?.paymentDate) || '-'}`
				},
			},
			{
				title: 'Сумма платежа',
				dataIndex: 'sum',
				key: 'sum',
				render: (_, record) => {
					return `${trimSeparator(record?.amount) || '-'}`
				},
			},
			{
				title: 'Родитель',
				dataIndex: 'parent',
				key: 'parent',
				render: (_, record) => {
					return (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.personalAccount?.user?.lastName} ${record?.personalAccount?.user?.firstName} ${record?.personalAccount?.user?.patronymic}`}
							query={{
								type: HARD_MODAL_ENUMS.USER_CARD,
								id: record?.personalAccount?.user?.id,
								userType: 'parent',
							}}
						/>
					)
				},
			},
			{
				title: 'Назначение',
				dataIndex: 'info',
				key: 'info',
				render: (_, record) => {
					return record.distributions.map((item, index) => (
						<div key={index}>
							{index + 1}. {item.title} <b>({trimSeparator(item.amount)})</b>
						</div>
					))
				},
			},
			{
				title: '',
				dataIndex: 'actions',
				key: 'actions',
				render: (_, record) => {
					return (
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: 8 }}>
							<CreateEditPayPopup
								type={'edit'}
								data={record}
								onSave={(saveData) => {
									onPayEdit(record, saveData)
								}}
								changeFetchStatus={changeFetchStatus}
							>
								<AppSpecialButton icon={'pencil-l'} />
							</CreateEditPayPopup>
							<AppSpecialButton
								icon={'delete-l'}
								color={'red'}
								type={'bordered'}
								onClick={() => onPayRemove(record)}
							/>
						</div>
					)
				},
			},
		]
	}, [])

	const onSetShowLocal = (state) => {
		onSetShow(state, isWasChanges)
	}

	return (
		<AppModal
			zIndex={9}
			title={`Платежное поручение №${data?.number || '-'} на сумму ${
				data?.amount !== undefined ? trimSeparator(data?.amount) : '-'
			} р`}
			width={1400}
			isShow={isShow}
			className={'assignment-modal'}
			setShow={onSetShowLocal}
		>
			<div className="assignment-modal-content">
				<AppCard>
					<AppText text={'Реквизиты'} />
					{!data?.requisites?.length && <AppText text={'Отсутствуют'} textStyle={'DesktopH4'} />}
					{!!data?.requisites?.length &&
						data?.requisites?.map((item, index) => (
							<Fragment key={index}>
								<AppText text={`${item.label}`} textStyle={'DesktopH4'} />
								<AppText text={`${item.value}`} textStyle={'DesktopFontBody'} />
								{index < data?.requisites.length - 1 && <hr />}
							</Fragment>
						))}
				</AppCard>
				<AppCard
					title={`Разнесено по платежам ${
						data?.amountPayments !== undefined ? trimSeparator(data?.amountPayments) : '-'
					}/${data?.amount !== undefined ? trimSeparator(data?.amount) : '-'}`}
					extra={
						<CreateEditPayPopup
							type={'create'}
							onSave={(saveData) => {
								onPayCreate(saveData)
							}}
							defaultPaySum={data?.amount - data?.amountPayments}
							changeFetchStatus={changeFetchStatus}
						>
							<AppButton type={'outline'} label={'Создать платеж'} />
						</CreateEditPayPopup>
					}
				>
					<div className={'assignment-modal-content_add-pay'}>
						<AppText
							text={'Связать с платежом'}
							className={'assignment-modal-content_add-pay__title'}
							textStyle={'DesktopH4'}
						/>
						<div className={'assignment-modal-content_add-pay__inputs'}>
							<AppSearchSelect
								label={'Найти платеж'}
								placeholder={'Введите номер платежа/ФИО плательщика/номер договора'}
								results={payList?.results}
								pagination={payList?.pagination}
								selectedValue={pay}
								onSetSelectedValue={setPay}
								labelFields={['title']}
								isFetching={payFetchingStatus === FetchStatus.FETCHING}
								onLoad={loadPaysList}
								onLoadMore={loadMorePaysList}
								isFetchingMore={payFetchingStatus === FetchStatus.FETCHING_MORE}
							/>
							<AppButton label={'Привязать'} disabled={!pay} onClick={onPayConnect} />
						</div>
					</div>
					<AppTable
						columns={columns}
						isCanDeleteRow={false}
						isNeedNumbering={false}
						dataSource={data?.payments || []}
						isLoading={isLoading}
						locale={{ emptyText: 'Ничего не найдено' }}
						rowKey={(record, index) => `${record.id}-${index}`}
						sortingConfig={{}}
						pagination={false}
						titleSortingType={'customSorting'}
					/>
				</AppCard>
			</div>
		</AppModal>
	)
}
export default AssignmentModal
