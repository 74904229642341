import { BaseRestDataProvider } from './BaseRestDataProvider'
import { IUsersListPayload } from '../store/types/users'
import { IParent } from '@store/types/parents'
import { oldAxios } from './axios-init'

export class UsersDataProvider extends BaseRestDataProvider {
	loadUsersList = (payload: IUsersListPayload) => {
		const search = payload.search ? `?search=${payload.search}` : ''
		return oldAxios
			.get(`${this.host}/api/users_list/${search}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadStaffUsers = (roles) => {
		return oldAxios
			.get(`${this.host}/api/users_list/?roles=${roles}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	updateStaffUsersInProcess = (users: Array<number>, processId: number) => {
		return oldAxios.patch(`${this.host}/api/processes/update/${processId}/`, {
			staff_users: users,
		})
	}

	setStudentsStaffUsers = (users: Array<number>, students: Array<number>) => {
		return oldAxios.post(`${this.host}/api/processes/students_responsible/`, {
			student_id_list: students,
			stuff_id_list: users,
		})
	}

	async getParentCard(id: number): Promise<IParent> {
		const response = await oldAxios.get<IParent>(`${this.host}/api/processes/parent/card/${id}/`)
		return response.data
	}

	loadProblemStudents = () => {
		return oldAxios.get(`${this.host}/payment/problem_students/`).then((res) => res.data)
	}

	loadActiveParents = (start: string, end: string) => {
		return oldAxios
			.get(`${this.host}/api/statistics/active_users?date_after=${start}&date_before=${end}`)
			.then((res) => res.data)
	}

	loadActiveParentsWithContracts = (start: string, end: string) => {
		return oldAxios
			.get(`${this.host}/api/statistics/active_users?date_after=${start}&date_before=${end}`)
			.then((res) => res.data)
	}

	loadProfile = async () => {
		const user = await oldAxios.get(`${this.host}/api/users/`).then((res) => res.data)

		const roles = await oldAxios
			.get(`${this.host}/api/current_user_roles/`)
			.then((res) => res.data.roles)
			.catch(() => [])

		return {
			user,
			roles,
		}
	}
}
