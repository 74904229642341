import React, { FC, useMemo, useState } from 'react'
import {
	AppButton,
	AppDatepicker,
	AppDropdownButton,
	AppFontIcon,
	AppPopoverConfirm,
	AppTable,
} from '@frontend-modules/ui-kit'
import { IMainTableProps } from './MainTable.types'
import { ExpandedTable } from '@components/debts'
import { FetchStatus } from '@/common/types'
import './MainTable.scss'
import { dropdownList } from './MainTable.mocks'
import { formatDate } from '@frontend-modules/frontend-utils'
import { AccessHelper } from '@frontend-modules/access-controller'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { AppHardModalButton } from '@frontend-modules/ui-kit'
/**
 * @description - таблица студентов по платежам
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const {
		isLoading,
		list,
		onPaginationChange,
		onItemExpand,
		paymentsData,
		tariffList,
		onNotifyClick,
		onBlockClick,
		onPayedPaymentClick,
		paginationLimit,
		pagination,
		sortingConfig,
	} = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	/**
	 * @description - "костыль", чтобы доставать значение
	 * из dropdown и передавать его в Popup
	 * @TODO: - придумать метод получше 🤷‍
	 */
	const [lastSelectedNotifyPopupMethod, setLastSelectedNotifyPopupMethod] = useState('')
	const [lastSelectedPopupBanDate, setLastSelectedPopupBanDate] = useState(null)

	const columns = useMemo(() => {
		const onDropdownSelectLocal = ({ key }, date = '') => {
			setLastSelectedNotifyPopupMethod(key)
			setLastSelectedPopupBanDate(date)
		}

		return AccessHelper.filterList([
			{
				title: 'Родитель',
				accessKey: 'debts.parents_table.columns.number',
				dataIndex: 'parent',
				width: '150px',
				key: 'parent',
				render: (_, record) => {
					return (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.parent?.last_name} ${record?.parent?.first_name} ${record?.parent?.patronymic}`}
							query={{
								type: HARD_MODAL_ENUMS.USER_CARD,
								id: record?.parent?.id,
								userType: 'parent',
							}}
						/>
					)
				},
			},
			{
				title: 'Ученик',
				dataIndex: 'student',
				accessKey: 'debts.parents_table.columns.student',
				key: 'student',
				width: '150px',
				render: (_, record) => {
					return (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.last_name} ${record?.first_name} ${record?.patronymic}`}
							query={{
								type: HARD_MODAL_ENUMS.USER_CARD,
								id: record?.id,
								userType: 'student',
							}}
						/>
					)
				},
			},
			{
				title: 'Школа',
				dataIndex: 'school',
				accessKey: 'debts.parents_table.columns.school',
				key: 'school',
				render: (_, record) => {
					return `${record?.school?.name || '-'}`
				},
			},
			{
				title: 'Класс',
				key: 'grade',
				accessKey: 'debts.parents_table.columns.parallel',
				dataIndex: 'grade',
				width: '100px',
			},
			{
				title: 'Тариф',
				key: 'education_cost',
				dataIndex: 'education_cost',
				accessKey: 'debts.parents_table.columns.tariff',
				render: (_, record) => {
					return tariffList?.[record?.education_cost] || '-'
				},
			},
			{
				title: 'Платёжные участки',
				dataIndex: 'paymentRegions',
				accessKey: 'debts.parents_table.columns.paymentAreas',
				key: 'paymentRegions',
				width: '150px',
				render: (_, record) => {
					return `Всего участков: ${record?.payment_count}`
				},
			},
			{
				title: 'Дата авт. блокировки',
				dataIndex: 'autoBlockDate',
				accessKey: 'debts.parents_table.columns.dateBlock',
				width: '150px',
				key: 'autoBlockDate',
				render: (_, record) => {
					return record.block_financial_date ? formatDate(record.block_financial_date) : '-'
				},
			},
			{
				title: '',
				dataIndex: 'actions',
				key: 'actions',
				accessKey: 'debts.parents_table.columns.actions',
				render: (_, record) => {
					const blockText = record.block_financial ? 'Разблокировать' : 'Заблокировать'
					return (
						<div className={'buttons-container'}>
							<AppPopoverConfirm
								placement={'rightTop'}
								cancelText={'Отмена'}
								icon={false}
								okText={blockText}
								okButtonProps={{
									danger: record.block_financial,
								}}
								title={
									<>
										{blockText} студента <br />
										<b>{` ${record.last_name} ${record.first_name} ${record.patronymic}`}</b>?
									</>
								}
								onConfirm={() => {
									onBlockClick(record)
								}}
							>
								<AppButton
									accessKey={'debts.parents_table.actions.block'}
									type={record.block_financial ? 'medium-danger' : 'medium-primary'}
									label={blockText}
								/>
							</AppPopoverConfirm>
							<AppPopoverConfirm
								placement={'rightTop'}
								cancelText={'Отмена'}
								icon={false}
								okText={'Уведомить'}
								okButtonProps={{
									icon: <AppFontIcon icon={'check-s'} />,
									disabled: !lastSelectedPopupBanDate?.length,
								}}
								onConfirm={() => {
									onNotifyClick?.(record, lastSelectedNotifyPopupMethod, lastSelectedPopupBanDate)
								}}
								key={record.id}
								title={
									<>
										Отправить уведомление для <br />
										<b>
											{` ${record?.parent?.last_name} ${record?.parent?.first_name} ${record?.parent?.patronymic} `}
										</b>
										c помощью <b>{lastSelectedNotifyPopupMethod}</b>? <br />
										<AppDatepicker
											defaultValue={lastSelectedPopupBanDate}
											dropdownClassName={'table-notify-datepicker'}
											label={'Дата автоматической блокировки'}
											className={'debts-notify-datepicker'}
											onChange={setLastSelectedPopupBanDate}
										/>
									</>
								}
							>
								<AppDropdownButton
									onClick={(event) => onDropdownSelectLocal(event, record?.block_financial_date)}
									title={'Уведомить'}
									items={AccessHelper.filterList(dropdownList)}
									accessKey={'debts.parents_table.actions.notify'}
								/>
							</AppPopoverConfirm>
						</div>
					)
				},
			},
		])
	}, [
		paginationLimit,
		pagination.currentPage,
		tariffList,
		lastSelectedPopupBanDate,
		lastSelectedNotifyPopupMethod,
		onBlockClick,
		onNotifyClick,
	])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isNeedFirstEmptyLoadingColumn
			isCanDeleteRow={false}
			isNeedNumbering={true}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={{
				total: pagination?.count,
				pageSize: paginationLimit,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				onChange: onPaginationChangeLocal,
				locale: { items_per_page: 'записей ' },
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			rowKey={(record) => record.id}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
			expandable={
				AccessHelper.checkAccessToContent('debts.parents_table.actions.show_payment_areas').isVisible
					? {
							columnWidth: '5%',
							onExpand: onItemExpand,
							expandedRowRender: (record) => (
								<ExpandedTable
									onPayedPaymentClick={(data) => {
										onPayedPaymentClick(data, record.id)
									}}
									skeletonCounter={record?.payment_count}
									list={paymentsData?.[record?.id]?.list}
									isLoading={paymentsData?.[record?.id]?.fetchStatus === FetchStatus.FETCHING}
								/>
							),
					  }
					: {}
			}
			className={'debts-main-table'}
		/>
	)
}

export default MainTable
