import { IParseQueryParamsToObject } from './parseQueryParamsToObject.types'
/**
 * @description  - из location.search готовит объект с данными, если в URL будет JSON - автоматически распарсит
 * @param search - query строка
 * @param paramsType - параметры которые нужно обработать
 */
const parseQueryParamsToObject = (search: string, paramsType: 'modals' | 'filters'): IParseQueryParamsToObject => {
	if (!search.length) return { [paramsType]: [] }
	const paramArr = search.split('&') || []
	const paramsConfig = {
		modals: {
			modals: [],
		},
		filters: {
			filters: null,
			currentPage: null,
			limit: null,
		},
	}
	const params = paramsConfig[paramsType]
	paramArr?.forEach((param) => {
		const [key, val] = param.replace('?', '').split('=')
		try {
			params[key] = JSON?.parse(decodeURIComponent(val.replace(/\+/g, '%20')))
		} catch (e) {
			params[key] = decodeURIComponent(val)
		}
	})
	return params
}

export default parseQueryParamsToObject
