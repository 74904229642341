const TOKEN_NAME = 'is_sidebar_collapsed'
/**
 * @description -
 * запоминаем должен ли быть сайдбар закрыт
 */
const SidebarService = {
	setCollapsed: (state: boolean): void => {
		localStorage.setItem(TOKEN_NAME, String(state))
	},
	getCollapsed: (): boolean => localStorage.getItem(TOKEN_NAME) === 'true' || false,
}

export default SidebarService
