import React, { FC, useEffect, useState } from 'react'
import { TaskForm } from '@components/tasks'
import { ITaskPopupProps } from './TaskPopup.types'
import { AppHardModal } from '@frontend-modules/ui-kit'
import { useQueryPopupsParams } from '@hooks'

/**
 * @description - компонент генерации формы в всплывашке
 * @param props
 * @constructor
 */
const TaskPopup: FC<ITaskPopupProps> = (props) => {
	const { urlProps, ...rest } = props

	const [id, setId] = useState(urlProps?.id)
	const { closeLastPopup, replaceLastPopup } = useQueryPopupsParams()

	useEffect(() => {
		if (urlProps?.id) {
			setId(urlProps?.id)
		}
	}, [urlProps])

	const onSuccessWithoutNext = () => {
		closeLastPopup()
	}

	const onSuccessWithNext = (nextTaskID) => {
		replaceLastPopup({
			type: 'task',
			id: nextTaskID,
		})
	}

	return (
		<AppHardModal {...rest} size={'large'}>
			<TaskForm
				id={id}
				onLoadError={closeLastPopup}
				onSuccessWithoutNext={onSuccessWithoutNext}
				onSuccessWithNext={onSuccessWithNext}
			/>
		</AppHardModal>
	)
}

export default TaskPopup
