import { BaseRestDataProvider } from './BaseRestDataProvider'
import Cookies from 'universal-cookie'
import { IFinishedProcessesPayload, IProcessesPayload } from '../store/types/processes'
import { ISchoolsPayload } from '../store/types/school'
import { getFilterParams } from '../utils/filters'
import { oldAxios } from './axios-init'

export class ProcessesDataProvider extends BaseRestDataProvider {
	loadProcesses = async (payload: IProcessesPayload) => {
		const csrftoken = new Cookies().get('csrftoken')
		const searchParams = {
			student: payload.searchStudent ? `&student=${payload.searchStudent}` : '',
			id: payload.idSearch ? `&id=${payload.idSearch}` : '',
			parent: payload.searchParent ? `&parent=${payload.searchParent}` : '',
			actions: payload?.searchActions?.length ? `&actions=${payload?.searchActions.join()}` : '',
			school: payload?.searchSchool?.length ? `&school=${payload?.searchSchool.join()}` : '',
			tariff: payload.searchTariff?.length ? `&education_cost=${payload.searchTariff.join()}` : '',
			format: payload?.searchForm?.length ? `&education_format=${payload?.searchForm.join()}` : '',
			staffUsers: payload?.searchStaffUsers?.length ? `&staff_users=${payload?.searchStaffUsers.join()}` : '',
			createdMonthSearch: payload?.searchCreatedMonth ? `&created_month=${payload?.searchCreatedMonth}` : '',
			processTypes: payload?.searchProcessTypes?.length
				? `&process_type=${payload?.searchProcessTypes.join()}`
				: '',
			grade: payload?.searchGrade ? `&grade=${payload?.searchGrade}` : '',
		}

		return await oldAxios
			.get(`${this.host}/api/processes/students/?page=${payload.page}${Object.values(searchParams).join('')}`, {
				headers: {
					'X-CSRFToken': `${csrftoken}`,
				},
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadFinishedProcesses = async (payload: IFinishedProcessesPayload) => {
		const searchParams = getFilterParams(payload.filter)
		return await oldAxios
			.get(`${this.host}/api/processes/finished/?page=${payload.page}${searchParams}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	addBuisnessProcess = async (payload: any) => {
		return await oldAxios
			.post(
				`${this.host}/api/processes/create/${payload.process}/`,
				{
					student_id: payload.id,
				},
				{
					xsrfCookieName: 'csrftoken',
					xsrfHeaderName: 'X-CSRFToken',
				},
			)
			.then((res) => res.data)
	}

	loadStudentCard = async (student_id: string) => {
		return await oldAxios
			.get(`${this.host}/api/processes/student/card/${student_id}/`)
			.then((res) => res.data)
			.catch(() => null)
	}

	loadParentCard = async (parent_id: string) => {
		return await oldAxios
			.get(`${this.host}/api/processes/parent/card/${parent_id}/`)
			.then((res) => res.data)
			.catch(() => null)
	}

	loadParentSmsStatuses = async (user_id: string) => {
		return await oldAxios
			.get(`${this.host}/sms-statuses/`, {
				params: {
					user: user_id,
				},
			})
			.then((res) => res.data)
			.catch(() => null)
	}

	loadParentLettersStatuses = async (user_id: string) => {
		return await oldAxios
			.get(`${this.host}/letter-statuses/`, {
				params: {
					user: user_id,
				},
			})
			.then((res) => res.data)
			.catch(() => null)
	}

	addParentCard = async (payload: any) => {
		return await oldAxios
			.post(`${this.host}/api/processes/parent/create/`, payload, {
				withCredentials: true,
				xsrfCookieName: 'csrftoken',
				xsrfHeaderName: 'X-CSRFToken',
			})
			.then((res) => res.data)
	}

	createProcess = async (process: string, id: number, parameters: any) => {
		const csrftoken = new Cookies().get('csrftoken')

		return await oldAxios
			.post(
				`${this.host}/api/processes/${id}/execute/`,
				{
					action_name: process,
					parameters,
				},
				{
					withCredentials: true,
					headers: {
						'X-CSRFToken': `${csrftoken}`,
					},
				},
			)
			.then((res) => res.data)
	}

	loadCourses = async (payload: any) => {
		const student = await this.loadStudentCard(payload).catch(() => {
			return { course: '' }
		})

		return oldAxios
			.get(`${this.host}/api/cources/${student.course ? `?query=${student.course}` : ''}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadSchool = async (payload: any) => {
		return oldAxios
			.get(`${this.host}/api/schools/?student_id=${payload}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadSchools = async (payload: ISchoolsPayload) => {
		const search = payload.search ? `?search=${payload.search}` : ''
		return oldAxios
			.get(`${this.host}/api/schools/${search}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadTrialDays = (student_id: number) => {
		return oldAxios
			.get(`${this.host}/api/processes/trialdays/${student_id}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	loadSpecialist = () => {
		return oldAxios
			.get(`${this.host}/users/?role=contract_department_specialist`, {
				withCredentials: true,
				params: {
					// todo: при переписывании страницы - сделать нормальную пагинацию в запросе
					// сейчас пока что хватит константы в 100 элементов
					limit: 100,
				},
			})
			.then((res) => res.data)
	}

	loadNotesByProcessId = (id: any) => {
		return oldAxios
			.get(`${this.host}/api/processes/get/note/${id}/`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	updateCard = (
		user_role: string,
		id: string,
		{
			first_name,
			snils,
			last_name,
			patronymic,
			passport_series,
			passport_date_issue,
			passport_number,
			passport_authority,
			is_russian_citizen,
			living_place,
			telephone,
			phone_numbers,
			email,
			birthday,
			foreign_citizen_doc,
			address,
			resident,
			gender,
			birth_certificate,
			learning_hours,
			learning_program,
			grade,
			citizenship,
			payment_by_card,
			content_data,
			precedent,
			trial_day_status,
			last_activity,
			school,
		}: any,
	) => {
		return oldAxios
			.patch(
				`${this.host}/api/processes/${user_role}/card/${id}/`,
				{
					first_name,
					snils,
					last_name,
					patronymic,
					passport_series,
					passport_date_issue,
					passport_number,
					passport_authority,
					is_russian_citizen,
					living_place,
					telephone,
					phone_numbers,
					email,
					birthday,
					foreign_citizen_doc,
					address,
					resident,
					gender,
					grade,
					birth_certificate,
					learning_hours,
					learning_program,
					citizenship,
					payment_by_card,
					content_data,
					precedent,
					trial_day_status,
					last_activity,
					school,
				},
				{
					withCredentials: true,
					xsrfCookieName: 'csrftoken',
					xsrfHeaderName: 'X-CSRFToken',
				},
			)
			.then((res) => res.data)
	}

	generateEnrollmentCertificate = async (payload: any) => {
		return await oldAxios.post(`${this.host}/generate-doc/enroll-statement/`, payload, {}).then((res) => res.data)
	}
}
