import React, { FC, useEffect } from 'react'
import { AppTable } from '@frontend-modules/ui-kit'
import { ILoadNotesParams, IUserCardPopupTableNotesTable } from './UserCardPopupTabNotesTable.types'
import { formatDate } from '@frontend-modules/frontend-utils'
import { dateFormat } from './UserCardPopupTabNotesTable.config'
import { PAGINATION_LIMIT } from './UserCardPopupTabNotesTable.config'

/**
 * @description - таблица заметок
 * @param props
 * @constructor
 */
const UserCardPopupTabNotesTable: FC<IUserCardPopupTableNotesTable> = (props) => {
	const { tableData, isLoading, onLoad, id, userType } = props
	const { results: list, pagination } = tableData

	const columns = [
		{
			title: 'Автор',
			dataIndex: 'author',
			key: 'author',
			width: '15%',
			render: (_, record) => {
				return `${record?.author?.lastName} ${record?.author?.firstName} ${record?.author?.patronymic}`
			},
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			key: 'comment',
		},
		{
			title: 'Дата',
			dataIndex: 'createAt',
			key: 'createAt',
			width: '20%',
			render: (_, record) => {
				return formatDate(record?.createAt, dateFormat)
			},
		},
	]

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		const params: ILoadNotesParams = {
			currentPage: 1,
			limit: limit || PAGINATION_LIMIT,
		}
		if (userType === 'parent') {
			params.parentId = id
		}
		if (userType === 'student') {
			params.studentId = id
		}
		onLoad?.(params)
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			const params: ILoadNotesParams = {
				limit: pagination?.paginationLimit || PAGINATION_LIMIT,
				currentPage: page,
			}
			if (userType === 'parent') {
				params.parentId = id
			}
			if (userType === 'student') {
				params.studentId = id
			}
			onLoad?.(params)
		}
	}

	useEffect(() => {
		const params: ILoadNotesParams = {
			currentPage: 1,
			limit: pagination?.paginationLimit || PAGINATION_LIMIT,
		}
		if (userType === 'parent') {
			params.parentId = id
		}
		if (userType === 'student') {
			params.studentId = id
		}
		onLoad?.(params)
	}, [userType, id])

	return (
		<AppTable
			isCanDeleteRow={false}
			isLoading={isLoading}
			pagination={{
				total: pagination?.count,
				pageSize: pagination?.limit || PAGINATION_LIMIT,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				locale: { items_per_page: 'записей ' },
				onChange: onPaginationChangeLocal,
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			locale={{ emptyText: 'Ничего не найдено' }}
			dataSource={list}
			columns={columns}
			rowKey={(record, index) => `${index}`}
			sortingConfig={{}}
			titleSortingType={'customSorting'}
		/>
	)
}

export default UserCardPopupTabNotesTable
