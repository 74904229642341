import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { clearForm, loadForm, loadFormError, loadFormSuccess, updateForm } from '../actions/form'
import { Action } from '../store'
import { FormState } from '../types/form'

const initialState: FormState = {
	data: {
		parent: {
			uuid: '',
			first_name: '',
			last_name: '',
			patronymic: '',
			passport_series: '',
			passport_number: '',
			passport_authority: '',
			passport_date_issue: '',
			is_russian_citizen: true,
			living_place: '',
			telephone: '',
			email: '',
			foreign_citizen_doc: '',
			birthday: '',
			address: '',
			resident: '',
			gender: '',
			students: [],
			phone_numbers: null,
			external_id: null,
			contracts: [],
			last_activity: '',
		},
		child: {
			external_uuid: '',
			first_name: '',
			last_name: '',
			patronymic: '',
			birthday: '',
			email: '',
			grade: 0,
			passport_series: '',
			passport_number: '',
			passport_authority: '',
			passport_date_issue: '',
			is_russian_citizen: true,
			living_place: '',
			foreign_citizen_doc: '',
			birth_certificate: '',
			gender: '',
			learning_hours: 0,
			learning_program: '',
			phone: '',
			external_id: null,
			content_data: null,
			parents: [],
			trial_day_status: 'did_not_book',
			lms_id: '',
		},
	},
	fetchStatus: FetchStatus.FETCHING,
	error: null,
}

export const form = (state: FormState = initialState, action: Action) => {
	switch (action.type) {
	case getType(updateForm): {
		return {
			...state,
			data: action.payload,
		}
	}

	case getType(clearForm): {
		return initialState
	}

	case getType(loadForm): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadFormSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
		}
	}

	case getType(loadFormError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return state
	}
	}
}
