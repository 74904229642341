import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	loadProcessesMonitoring,
	onLoadProcessesMonitoringError,
	onLoadProcessesMonitoringSuccess,
} from '@store/actions/processesMonitoring'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadProcessesMonitoringEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadProcessesMonitoring)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
				sorting: payload?.sorting,
			}
			return from(deps.tasksService.loadProcessesMonitoring(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadProcessesMonitoringSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadProcessesMonitoringError(err))
				}),
			)
		}),
	)
}

export const processesMonitoringEpics = combineEpics(loadProcessesMonitoringEpic)
