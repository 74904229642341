import React, { useCallback, useEffect, useState } from 'react'
import { AppCard, AppTab, AppText } from '@frontend-modules/ui-kit'
import { Switch, useHistory, useRouteMatch, Route } from 'react-router-dom'
import { tabList } from './Payments.config'
import { getCurrentActiveTab } from './Payments.utils'
import { Assignments, Pays } from '@pages'
import './Payments.scss'
const Payments = () => {
	const { path, url, isExact } = useRouteMatch()
	const history = useHistory()

	const [selectedTab, setSelectedTab] = useState(getCurrentActiveTab(history.location.pathname))

	const onTabChange = useCallback(
		(route) => {
			setSelectedTab(route)
			history.push(`${url}/${route.key}`)
		},
		[history, url],
	)

	// если перешли по роуту без дочернего юрл
	useEffect(() => {
		if (isExact) {
			onTabChange?.(tabList[0])
		}
	}, [isExact, onTabChange])

	return (
		<AppCard
			className={'payments-page'}
			title={<AppText textStyle={'Title'} style={{ margin: '0' }} text={'Платежи и платежные поручения'} />}
		>
			<AppTab isWhiteBackground onChange={onTabChange} activeKey={selectedTab?.key} items={tabList} />
			<Switch>
				<Route path={`${path}/assignments`} component={Assignments} />
				<Route path={`${path}/pays`} component={Pays} />
			</Switch>
		</AppCard>
	)
}

export default Payments
