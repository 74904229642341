import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'

export interface ISelectTariffProps {
    process: string;
    id: number;
}

export const SelectTariff: React.FC<ISelectTariffProps> = ({ process, id }) => {
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((state: State) => state.constants)
	const [selectedTariff, setSelectedTariff] = useState<number>()
	const dispatch = useDispatch()

	const changeSelectedTariff = useCallback((value) => {
		setSelectedTariff(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					education_cost: selectedTariff,
				},
			}),
		)
	}, [dispatch, id, process, selectedTariff])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Выбор тарифа</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
								{constantsFetchStatus === FetchStatus.FETCHED ? (
									<>
										<Form.Label>Выберите тариф</Form.Label>
										<Form.Control
											value={selectedTariff}
											onChange={(event) => changeSelectedTariff(event.target.value)}
											as="select"
											placeholder="Выберите тариф"
										>
											<option value={''} defaultChecked>
                                                Не выбрано
											</option>
											{constants.EDUCATION_COST &&
                                                Object.keys(constants.EDUCATION_COST).map((educationCost) => (
                                                	<option
                                                		value={educationCost}
                                                		key={educationCost}
                                                	>{`${constants.EDUCATION_COST[educationCost]} ${educationCost}`}</option>
                                                ))}
										</Form.Control>
									</>
								) : (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								)}
							</Form.Group>
						</div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedTariff} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
