import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadGlobalClasses, loadGlobalClassesError, loadGlobalClassesSuccess } from '../actions/globalClasses'

const loadGlobalClassesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadGlobalClasses)),
		switchMap(({}: ActionType<typeof loadGlobalClasses>) => {
			return from(deps.classesDataProvider.loadGlobalClasses()).pipe(
				switchMap(classes => {
					return of(loadGlobalClassesSuccess(classes))
				}),
				catchError(err => {
					return of(loadGlobalClassesError(err))
				})
			)
		})
	)
}

export const globalClassesEpics = combineEpics(loadGlobalClassesEpic)