import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadForm, loadFormError, loadFormSuccess } from '../actions/form'
import { showAlert } from '../actions/alert'

const loadFormEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadForm)),
		switchMap(({ payload }: ActionType<typeof loadForm>) => {
			return from(deps.formDataProvider.loadForm(payload)).pipe(
				switchMap((formData) => {
					return of(loadFormSuccess(formData))
				}),
				catchError((err) => {
					return of(
						loadFormError(err),
						showAlert({
							title: 'Не удалось загрузить данные формы!',
							text: JSON.stringify(err.response),
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

export const formEpics = combineEpics(loadFormEpic)
