import { useEffect, useRef } from 'react'

//  хук для хранения и сравнения предыдущего состояния c текущим
const usePrevState = (value) => {
	const prevValueRef = useRef()

	useEffect(() => {
		prevValueRef.current = value
	}, [value])

	return prevValueRef.current
}

export default usePrevState
