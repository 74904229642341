import {ActionType, createAction} from 'typesafe-actions'

export const addBuisnessProcessStart = createAction('@buisness-process/ADD/START')<any>()
export const addBuisnessProcessSuccess = createAction('@buisness-process/ADD/SUCCESS')<any>()
export const addBuisnessProcessError = createAction('@buisness-process/ADD/ERROR')<any>()


export type BuisnessActions =
    ActionType<typeof addBuisnessProcessStart>
    | ActionType<typeof addBuisnessProcessSuccess>
    | ActionType<typeof addBuisnessProcessError>;
