import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { createContract } from '../actions/contract'
import {
	createProcessError,
	createProcessStart,
	createProcessSuccess,
	downloadReportStart,
	finProcSearchChange,
	loadFinishedProcesses,
	loadFinishedProcessesError,
	loadFinishedProcessesSuccess,
	loadProcessesError,
	loadProcessesStart,
	loadProcessesSuccess,
	setCurrentPage,
	setFinProcPage,
	updateCreateProcessesId,
} from '../actions/processes'
import { Action } from '../store'
import { ProcessesState } from '../types/processes'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	processes: null,
	createProcessesId: [],
	currentPage: 1,
	totalCount: 0,
	finishedProcesses: {
		currentPage: 1,
		totalCount: 0,
		results: null,
	},
	finProcFetchStatus: FetchStatus.INITIAL,
	finProcError: null,
	finProcSearch: {
		student: null,
		processTypes: null,
	},
}

export const reducer = (state: ProcessesState = initialState, action: Action) => {
	switch (action.type) {
		case getType(loadProcessesSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				processes: action.payload.results,
				totalCount: action.payload.count,
			}
		}

		case getType(loadProcessesStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadProcessesError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		case getType(setCurrentPage): {
			return {
				...state,
				currentPage: action.payload,
			}
		}

		case getType(loadFinishedProcesses): {
			return {
				...state,
				finProcFetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadFinishedProcessesSuccess): {
			return {
				...state,
				finProcFetchStatus: FetchStatus.FETCHED,
				finishedProcesses: {
					...state.finishedProcesses,
					results: action.payload?.results,
					totalCount: action.payload?.count,
				},
			}
		}

		case getType(loadFinishedProcessesError): {
			return {
				...state,
				finProcFetchStatus: FetchStatus.ERROR,
				error: action.payload,
			}
		}

		case getType(setFinProcPage): {
			return {
				...state,
				finishedProcesses: {
					...state.finishedProcesses,
					currentPage: action.payload,
				},
			}
		}

		case getType(finProcSearchChange): {
			return {
				...state,
				finProcSearch: {
					...state.finProcSearch,
					[action.payload.type]: action.payload.value,
				},
			}
		}

		case getType(downloadReportStart):
		case getType(createContract): {
			return {
				...state,
				fetchStatus: !state.processes ? FetchStatus.FETCHING : FetchStatus.FETCHED,
			}
		}

		case getType(createProcessStart): {
			return {
				...state,
				createProcessesId: [...state.createProcessesId, action.payload.id],
			}
		}

		case getType(createProcessSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				createProcessesId: [...state.createProcessesId.filter((id) => id !== action.payload)],
			}
		}

		case getType(createProcessError): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
			}
		}

		case getType(updateCreateProcessesId): {
			return {
				...state,
				createProcessesId: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const processes: any = persistReducer(
	{
		key: 'AdminPanel::processes',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
