import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '../store'
import {
	loadSchool,
	loadSchoolError,
	loadSchoolsError,
	loadSchoolsStart,
	loadSchoolsSuccess,
	loadSchoolSuccess
} from '../actions/school'
import {SchoolState} from '../types/school'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	school: null,
	schoolsList: null
}

export const reducer = (state: SchoolState = initialState, action: Action) => {

	switch (action.type) {
	case getType(loadSchool): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadSchoolSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			school: action.payload
		}
	}

	case getType(loadSchoolError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}
	case getType(loadSchoolsStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadSchoolsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			schoolsList: action.payload
		}
	}

	case getType(loadSchoolsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}

export const school: any = persistReducer({
	key: 'AdminPanel::school',
	storage: indexedDBOldStore,
	blacklist: [],
	version: 1
}, reducer)
