import React from 'react'
import { AppSkeleton } from '@frontend-modules/ui-kit'

const GroupActionsSkeleton = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
			<AppSkeleton.Button active style={{ width: '100%', height: '800px' }} />
		</div>
	)
}

export default GroupActionsSkeleton
