import { ActionType, createAction } from 'typesafe-actions'

export const loadPays = createAction('@paymentsPays/LOAD_PAYS')<any>()
export const onLoadPaysSuccess = createAction('@paymentsPays/ON_LOAD_PAYS_MONITORING_SUCCESS')<any>()
export const onLoadPaysError = createAction('@paymentsPays/ON_LOAD_PAYS_MONITORING_ERROR')<any>()
export const loadMoreRegistries = createAction('@paymentsPays/LOAD_MORE_REGISTRIES')<any>()
export const onLoadMoreRegistriesSuccess = createAction('@paymentsPays/ON_LOAD_MORE_REGISTRIES_SUCCESS')<any>()
export const onLoadMoreRegistriesError = createAction('@paymentsPays/ON_LOAD_MORE_REGISTRIES_ERROR')<any>()
export const setPaysQuery = createAction('@paymentsPays/SET_PAYS_QUERY')<any>()
export const setPaysStartDate = createAction('@paymentsPays/SET_PAYS_START_DATE')<any>()
export const setPaysEndDate = createAction('@paymentsPays/SET_PAYS_END_DATE')<any>()

export const loadPayInfo = createAction('@paymentsPays/LOAD_PAY_INFO')<any>()
export const onLoadPayInfoSuccess = createAction('@paymentsPays/ON_LOAD_PAY_INFO_SUCCESS')<any>()
export const onLoadPayInfoError = createAction('@paymentsPays/ON_LOAD_PAYS_INFO_ERROR')<any>()

export const changePayInfo = createAction('@paymentsPays/CHANGE_PAY_INFO')<any>()
export const onChangePayInfoSuccess = createAction('@paymentsPays/ON_CHANGE_PAY_INFO_SUCCESS')<any>()
export const onChangePayInfoError = createAction('@paymentsPays/ON_CHANGE_PAYS_INFO_ERROR')<any>()

export type PaymentsPaysActions =
	| ActionType<typeof loadPays>
	| ActionType<typeof onLoadPaysSuccess>
	| ActionType<typeof onLoadPaysError>
	| ActionType<typeof loadMoreRegistries>
	| ActionType<typeof onLoadMoreRegistriesSuccess>
	| ActionType<typeof onLoadMoreRegistriesError>
	| ActionType<typeof loadPayInfo>
	| ActionType<typeof onLoadPayInfoSuccess>
	| ActionType<typeof onLoadPayInfoError>
	| ActionType<typeof changePayInfo>
	| ActionType<typeof onChangePayInfoSuccess>
	| ActionType<typeof onChangePayInfoError>
	| ActionType<typeof setPaysQuery>
	| ActionType<typeof setPaysStartDate>
	| ActionType<typeof setPaysEndDate>
