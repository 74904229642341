import React, { useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Field, Form } from 'react-final-form'

import { Messages } from './Messages/Message'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { State } from '../../store/store'
import { closeChat, loadChat, sendChatMessageStart } from '../../store/actions/chat'
import { ChatState } from '../../store/types/chat'
import './Chat.scss'
import Skeleton from 'react-loading-skeleton'
import { FetchStatus } from '../../common/types'

interface IChatProps extends ChatState {
	loadChat: (student_id: any) => void
	sendMessage: (student: any, message: string) => void
	closeChat: () => {}
	profile: any
}

export const Component: React.FC<IChatProps> = ({
	loadChat,
	fetchStatus,
	error,
	sendMessage,
	student_id,
	closeChat,
	chat,
	profile,
}) => {
	const params = new URLSearchParams(useLocation().search)

	useEffect(() => {
		if (student_id !== null) {
			loadChat(student_id)
		}
	}, [student_id])

	const onClickCloseChat = () => {
		closeChat()
	}

	const onSubmit = async (values: any, form: any) => {
		if (values.message !== '') {
			form.change('message', '')
			sendMessage(student_id, values.message)
		}
	}

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={`header-chat ${student_id && 'open'}`}>
					<div className="h-list-header">
						{fetchStatus === FetchStatus.FETCHED ? (
							<h6>{`${chat?.child?.last_name} ${chat?.child?.first_name} ${chat?.child?.patronymic}`}</h6>
						) : (
							<Skeleton width={'80%'} />
						)}
						<div onClick={onClickCloseChat} className="h-back-user-list">
							<i className="feather icon-x" />
						</div>
					</div>
					<div className="h-list-body">
						<div className="main-chat-cont">
							<PerfectScrollbar>
								<div className="main-friend-chat">
									{fetchStatus === FetchStatus.FETCHED && chat.messages ? (
										chat.messages.map((msg: any, index: number) => {
											return (
												<Messages
													key={index}
													type={msg.author.id !== profile?.id ? 1 : 0}
													message={msg}
													name={`${msg?.author?.last_name} ${msg?.author?.first_name} ${msg?.author?.patronymic}`}
													photo={msg?.author?.first_name}
												/>
											)
										})
									) : (
										<div className={'p-5'}>
											<Skeleton height={200} />
										</div>
									)}
								</div>
							</PerfectScrollbar>
						</div>
					</div>
					<div className="h-list-footer">
						<Form onSubmit={onSubmit}>
							{({ submitting, handleSubmit, values, form }) => {
								return (
									<form onSubmit={handleSubmit}>
										<div className="input-group">
											<Field name={'message'}>
												{({ input, name, onChange }) => (
													<input
														onKeyPress={(e) => e.key === 'Enter' && onSubmit(values, form)}
														value={input.value}
														onChange={input.onChange}
														type="text"
														className="form-control h-send-chat"
														placeholder="Ваше сообщение . ."
													/>
												)}
											</Field>
											<button
												type="submit"
												className="input-group-append btn-send btn btn-primary"
											>
												<i className="feather icon-message-circle" />
											</button>
										</div>
									</form>
								)
							}}
						</Form>
					</div>
				</div>
			</div>
		</>
	)
}

export const Chat = connect(
	({ chat, profile }: State) => ({ ...chat, profile: profile.profile }),
	(dispatch) => {
		return {
			loadChat: (student_id: any) => {
				return dispatch(loadChat({ student_id }))
			},
			sendMessage: (student: string, message: string) => {
				return dispatch(sendChatMessageStart({ student, message }))
			},
			closeChat: () => {
				return dispatch(closeChat())
			},
		}
	},
)(Component)

export default Chat
