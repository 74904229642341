import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	loadAdditionalCourses,
	onLoadAdditionalCoursesError,
	onLoadAdditionalCoursesSuccess,
	setAdditionalCoursesQuery,
} from '@store/actions/additionalCourses'
import { AdditionalCoursesState } from '@store/types/additionalCourses'
import { persistReducer } from 'redux-persist'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const additionalCoursesInitialState: AdditionalCoursesState = {
	fetchStatus: FetchStatus.INITIAL,
	list: [],
	errors: [],
	query: {},
}

export const additionalCoursesReducer = (state = additionalCoursesInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadAdditionalCourses): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadAdditionalCoursesSuccess): {
			return {
				...state,
				list: action.payload.results,
				pagination: action.payload.pagination,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadAdditionalCoursesError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(setAdditionalCoursesQuery): {
			return {
				...state,
				query: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedAdditionalCoursesReducer: any = persistReducer(
	{
		key: 'AdminPanel::additionalCourses',
		storage: indexedDBStore,
		whitelist: ['query'],
		version: 1,
	},
	additionalCoursesReducer,
)
