import { $BaseHttpClient, $HttpClient } from '@utils'

export class DebtsServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список студентов с долгом по оплате
	 */
	loadDebts = (params) => {
		return $HttpClient.post('payment/new/debtors/', params)
	}

	/**
	 *
	 * @description загружаем список платежей конкретного студента
	 */
	loadDebtsPayments = (id: number) => $HttpClient.get(`payment/new/debtors/${id}/`)

	/**
	 *
	 * @description обновляем статус платежа пользователя
	 */
	updateDebtsPaymentStatus = (id: number, data) =>
		$HttpClient.patch(`payment/new/debtors/payment_schedule/${id}/`, data)

	/**
	 *
	 * @description отправляем уведомление об оплате
	 */
	sendDebtsNotify = (data) => $HttpClient.post('/payment/debt_notify/', data)

	/**
	 *
	 * @description обновляем статус блокировки студента
	 */
	sendDebtsStudentBlock = (id, data) => $HttpClient.post(`/api/processes/student/card/${id}/block_financial/`, data)
}
