import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { loadDecree, onLoadDecreeError, onLoadDecreeSuccess } from '@store/actions/decree'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadDecreeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadDecree)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
				sorting: payload?.sorting,
			}
			return from(deps.decreeService.loadDecree(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadDecreeSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadDecreeError(err))
				}),
			)
		}),
	)
}

export const decreeEpics = combineEpics(loadDecreeEpic)
