import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { connect, useDispatch, useSelector, useStore } from 'react-redux'
import { FetchStatus } from '../../../common/types'
import { loadConstants } from '../../../store/actions/constants'
import { createProcessStart } from '../../../store/actions/processes'
import { Action, State } from '../../../store/store'

export const Component: React.FC<any> = ({ id, student_id, parent_id, fetchStatus, constants, action_name }) => {
	const [selectedCost, setSelectedCost] = useState<null | string>(null)
	const dispatch = useDispatch()
	const [error, setError] = useState([])

	useEffect(() => {
		dispatch(loadConstants())
	}, [])

	const setSelectedCostChangeCallback = useCallback(
		(event: any) => {
			setSelectedCost(event.target.value)
		},
		[selectedCost],
	)

	const submitButtonPressHandler = (e: any) => {
		const newErrState = []

		if (!selectedCost) {
			newErrState.push('selectedCost')
		}

		if (newErrState.length === 0) {
			dispatch(
				createProcessStart({
					process: action_name,
					id: id,
					parameters: {
						education_cost: selectedCost,
					},
				}),
			)
		}

		setError(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Укажите стоимость обучения</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '100%' }} className="mr-3" controlId="formBasicEmail">
								<Form.Label>Выберите стоимость обучения</Form.Label>
								{fetchStatus === FetchStatus.FETCHING ? (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								) : (
									<Form.Control
										isInvalid={error.includes('selectedCourse')}
										onChange={setSelectedCostChangeCallback}
										as="select"
										placeholder="Выберите специалиста"
									>
										<option value={undefined} defaultChecked>
                                            Не выбрано
										</option>
										{Object.keys(constants.EDUCATION_COST).map((cost, index: number) => (
											<option value={Number(cost)} key={cost}>
												{constants.EDUCATION_COST[cost]} - {cost}
											</option>
										))}
									</Form.Control>
								)}
							</Form.Group>
						</div>
						<div className="d-flex"></div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedCost} variant="primary">
                            Указать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const EducationCost = connect(
	({ constants }: State) => ({ ...constants }),
	(dispatch) => {},
)(Component)
