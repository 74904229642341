import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { Action } from '../store'

import { AnceteState } from '../types/ancete'
import {
	createAncete,
	createAnceteError,
	createAnceteSuccess,
	loadAncete,
	loadAnceteError,
	loadAnceteSuccess,
	updateAncete,
	updateAnceteError,
	updateAnceteSuccess,
} from '../actions/ancete'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	data: null,
}

export const ancete = (state: AnceteState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadAncete): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadAnceteSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
		}
	}

	case getType(loadAnceteError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
			data: null,
		}
	}

	case getType(updateAncete): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(updateAnceteSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
		}
	}

	case getType(updateAnceteError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(createAncete): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createAnceteSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			data: action.payload,
		}
	}

	case getType(createAnceteError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
