import React, { useState, useCallback } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const SetPencilData: React.FC<{ parent_id: any, id: any }> = ({ parent_id, id }) => {
	const [pencilCount, setPencilCount] = useState('')
	const [notebooksCount, setNotebooksCount] = useState('')
	const [deliveryCost, setDeliveryCost] = useState('')
	const [sendWithContract, setSendWithContract] = useState(false)

	const dispatch = useDispatch()

	const pencilCountChangeCallback = useCallback((e) => {
		setPencilCount(e.target.value)
	}, [pencilCount])

	const notebooksCountChangeCallback = useCallback((e) => {
		setNotebooksCount(e.target.value)
	}, [notebooksCount])

	const deliveryCostChangeCallback = useCallback((e) => {
		setDeliveryCost(e.target.value)
	}, [deliveryCost])

	const sendWithContractChangeCallback = useCallback((e) => {
		setSendWithContract(!sendWithContract)
	}, [sendWithContract])

	const onSubmit = () => {
		dispatch(createProcessStart({
			process: 'pencil_set_data',
			id: id,
			parameters: {
				parent_id: parent_id,
				pencils_quantity: pencilCount,
				notebooks_quantity: notebooksCount,
				delivery_cost: !deliveryCost ? 0 : deliveryCost,
				send_with_contract: sendWithContract
			}
		}))
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Добавить ручку родителю</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Количество ручек</Form.Label>
							<Form.Control value={pencilCount} onChange={pencilCountChangeCallback} type="number" placeholder="Количество" />
						</Form.Group>
                        
						<Form.Group>
							<Form.Label>Количество блокнотов</Form.Label>
							<Form.Control value={notebooksCount} onChange={notebooksCountChangeCallback} type="number" placeholder="Количество" />
						</Form.Group>

						<Form.Group>
							<Form.Label htmlFor={'sendWithContract'}>Отправить с договором</Form.Label>
							<Form.Control checked={sendWithContract} onChange={sendWithContractChangeCallback} id="sendWithContract" type="checkbox" />
						</Form.Group>

						{
							!sendWithContract && <Form.Group>
								<Form.Label>Стоимость доставки</Form.Label>
								<Form.Control value={deliveryCost} onChange={deliveryCostChangeCallback} type="number" placeholder="Стоимость" />
							</Form.Group>
						}

						<Button onClick={onSubmit} variant="primary">
                            Добавить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
