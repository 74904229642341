import { ActionType, createAction } from 'typesafe-actions'

export const loadDecree = createAction('@tasks/LOAD_DECREE')<any>()
export const onLoadDecreeSuccess = createAction('@tasks/ON_LOAD_DECREE_SUCCESS')<any>()
export const onLoadDecreeError = createAction('@tasks/ON_LOAD_DECREE_ERROR')<any>()
export const setDecreeQuery = createAction('@tasks/SET_DECREE_QUERY')<any>()

export type DecreeActions =
	| ActionType<typeof loadDecree>
	| ActionType<typeof onLoadDecreeSuccess>
	| ActionType<typeof onLoadDecreeError>
	| ActionType<typeof setDecreeQuery>
