import { ActionType, createAction } from 'typesafe-actions'
import { ILoadUserVisitsPayload, LoadManagerInfoPayload, ManagerInfoSearchChangePayload } from '../types/userLogging'

export const loadUserVisitsStart = createAction('@userVisits/LOAD/START')<ILoadUserVisitsPayload>()
export const loadUserVisitsSuccess = createAction('@userVisits/LOAD/SUCCESS')<any>()
export const loadUserVisitsError = createAction('@userVisits/LOAD/ERROR')<any>()
export const setUserVisitsCurrentPage = createAction('@userVisits/PAGE')<any>()

export const loadManagerInfo = createAction('@managerInfo/LOAD/START')<LoadManagerInfoPayload>()
export const loadManagerInfoSuccess = createAction('@managerInfo/LOAD/SUCCESS')<any>()
export const loadManagerInfoError = createAction('@managerInfo/LOAD/ERROR')<any>()
export const setManagerInfoCurrentPage = createAction('@managerInfo/PAGE')<number>()
export const managerInfoSearchChange = createAction('@managerInfo/SEARCH/CHANGE')<ManagerInfoSearchChangePayload>()

export type UserLoggingActions =
    | ActionType<typeof loadUserVisitsStart>
    | ActionType<typeof loadUserVisitsSuccess>
    | ActionType<typeof loadUserVisitsError>
    | ActionType<typeof setUserVisitsCurrentPage>
    | ActionType<typeof loadManagerInfo>
    | ActionType<typeof loadManagerInfoSuccess>
    | ActionType<typeof loadManagerInfoError>
    | ActionType<typeof setManagerInfoCurrentPage>
    | ActionType<typeof managerInfoSearchChange>;
