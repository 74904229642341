import React, { FC, useMemo } from 'react'
import {
	AppButton,
	AppDropdownButton,
	AppFontIcon,
	AppHardModalButton,
	AppTable,
	AppText,
} from '@frontend-modules/ui-kit'
import { IMainTableProps } from './MainTable.types'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import { Divider } from 'antd'

/**
 * @description - таблица доп курсов
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const { isLoading, list, paginationLimit, pagination, onPaginationChange, sortingConfig = {} } = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}
	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	const columns = useMemo(() => {
		return [
			{
				title: 'Ученик',
				dataIndex: 'student',
				key: 'student',
				width: '150px',
				render: (_, record) => {
					return (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.student?.lastName} ${record?.student?.firstName} ${record?.student?.patronymic}`}
							query={{ type: HARD_MODAL_ENUMS.USER_CARD, userType: 'student', id: record.student.id }}
						/>
					)
				},
			},
			{
				title: 'Курс',
				dataIndex: 'course',
				key: 'course',
				width: '150px',
			},
			{
				title: 'Группа',
				dataIndex: 'courseGroup',
				key: 'courseGroup',
				width: '150px',
			},
			{
				title: 'Стоимость',
				dataIndex: 'totalCost',
				key: 'totalCost',
				width: '150px',
				render: (_, record) => {
					return trimSeparator(record.totalCost)
				},
			},
			{
				title: 'Оплачено',
				dataIndex: 'alreadyPaid',
				key: 'alreadyPaid',
				width: '150px',
				render: (_, record) => {
					return trimSeparator(record.alreadyPaid)
				},
			},
			{
				title: 'Документы',
				dataIndex: 'documents',
				key: 'documents',
				width: '150px',
				render: (_, record) => {
					if (!record?.documents.length) return '-'
					const parsedItems = record?.documents?.map((item) => {
						return {
							key: item.url,
							label: item.title,
						}
					})
					return (
						<AppDropdownButton
							onClick={(a) => {
								window.open(a.key, '_blank')
							}}
							title={'Скачать'}
							items={parsedItems}
						/>
					)
				},
			},
		]
	}, [])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isCanDeleteRow={false}
			isNeedNumbering={true}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={
				pagination
					? {
							total: pagination?.count,
							pageSize: paginationLimit,
							defaultCurrent: 1,
							current: pagination?.currentPage,
							onChange: onPaginationChangeLocal,
							locale: { items_per_page: 'записей ' },
							onShowSizeChange: onPaginationSizeChangeLocal,
					  }
					: false
			}
			rowKey={(record) => `${record.id}`}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
		/>
	)
}

export default MainTable
