import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { getAccessToken } from '../../../common/token'
import { FetchStatus } from '../../../common/types'
import DocumentUpload from '../../../Components/Documents/DocumentUpload'
import { loadContract } from '../../../store/actions/contract'
import { createProcessStart } from '../../../store/actions/processes'
import { host, State } from '../../../store/store'
import { oldAxios } from '@/api/axios-init'

export const UploadCertificate: React.FC<any> = ({ id, student_id, parent_id, process }) => {
	const dispatch = useDispatch()

	const [file, setFile] = useState(null)
	const [selectedContract, setSelectedContract] = useState<any>(null)
	const { fetchStatus, contracts } = useSelector((store: State) => store.contract)

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	useEffect(() => {
		dispatch(
			loadContract({
				student: student_id,
				parent: parent_id,
				process_id: id,
			}),
		)
	}, [id, parent_id, student_id])

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				process,
				id: id,
				parameters: {
					file: file.id,
					contract_id: [selectedContract.value],
				},
			}),
		)
	}

	const uploadCourierPrice = async (documents: any) => {
		const document = documents[0]
		const file = new FormData()
		file.append('file', document, document.name)
		file.append('user', String(parent_id))

		const bill = await oldAxios
			.post(`${host}/api/files/`, file, {
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
				},
			})
			.then((res) => res.data)

		setFile(bill)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">
					{process === 'upload_certificate' ? 'Загрузить' : 'Изменить'} справку на зачисление
				</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					{fetchStatus === FetchStatus.FETCHED ? (
						<Form>
							<div className="mb-3">
								<div style={{ marginBottom: 5 }}>Справка о зачислении</div>
								{file ? (
									<div style={{ marginBottom: 20 }}>
										<a target="_blank" href={file?.file} rel="noreferrer">
											Справка
										</a>
									</div>
								) : (
									<DocumentUpload uploadDocument={uploadCourierPrice} title="Справка" />
								)}
							</div>
							<div className="d-flex mb-3">
								<Form.Group>
									<Form.Label>Выберите договор</Form.Label>
									<Select
										placeholder="Выберите договор"
										options={contracts.map((contract) => ({
											label: contract.number,
											value: contract.id,
										}))}
										value={selectedContract}
										onChange={selectedContractChangeCallback}
									/>
								</Form.Group>
							</div>
							<Button onClick={submitButtonPressHandler} variant="primary">
								Подтвердить
							</Button>
						</Form>
					) : (
						<div className="mb-3">
							<Skeleton height={50} />
						</div>
					)}
				</Col>
			</Row>
		</Col>
	)
}
