import React, { useEffect, useState } from 'react'
import { NestedFiltersPanel } from '@components/group-actions'
import NestedTable from '@components/group-actions/general/NestedTable'
import { cancelActionByType, editActionByType, loadActionsTypeNestedTable } from '@store/actions/groupActions'
import { useDispatch, useSelector } from 'react-redux'
import { PAGINATION_LIMIT } from '@components/group-actions/general/NestedTable/NestedTable.config'
import { normalizeDetailFiltersForFilterPanel } from '@store/normalizers/groupActions'
import { State } from '@/store/store'
import { createPortal } from 'react-dom'
import { AppButton } from '@frontend-modules/ui-kit'

const CollapseListItem = (props) => {
	const { type, data } = props
	const dispatch = useDispatch()

	const [selectedFilters, setSelectedFilters] = useState(data.data)
	const [isShowSaveButton, setShowSaveButton] = useState(false)
	const [isShowCancelButton, setShowCancelButton] = useState(false)
	const { entities } = useSelector((state: State) => state.meta)

	useEffect(() => {
		const isDiff =
			JSON.stringify(
				data.data?.filters?.map((item) => {
					return {
						field: item.field,
						label: item.label,
						option: item.option,
						value: item.value,
					}
				}),
			) !==
			JSON.stringify(
				selectedFilters?.filters?.map((item) => {
					return {
						field: item.field,
						label: item.label,
						option: item.option,
						value: item.value,
					}
				}),
			)
		setShowSaveButton(type === 'planned' && isDiff && !!selectedFilters?.filters?.length)
		setShowCancelButton(type === 'planned' && isDiff && !selectedFilters?.filters?.length)
	}, [data.data, selectedFilters.filters, type])

	const portalSaveRoot = document.getElementById(`groupActionSaveButtonContainer-${data.id}`)
	const portalCancelRoot = document.getElementById(`groupActionCancelButtonContainer-${data.id}`)

	const loadTable = (params) => {
		const parsedFilters = params.isNeedParseFilters
			? params.filters?.map((fil) => {
					return {
						field: fil.field,
						label: fil.option.children,
						option: fil.type,
						value: fil.value,
					}
			  })
			: params.filters
		const normalizedParams = {
			filters: parsedFilters,
			id: data.id,
			status: type,
			studentsId: data.studentIds,
			currentPage: params?.pagination?.currentPage || 1,
			limit: params?.pagination?.limit || PAGINATION_LIMIT,
			sorting: params.sorting || [],
		}
		setSelectedFilters({
			filters: parsedFilters,
			sorting: normalizedParams.sorting,
		})
		dispatch(loadActionsTypeNestedTable(normalizedParams))
	}

	const onFilterAdd = (filters) => {
		const newPagination = {
			currentPage: 1,
			limit: PAGINATION_LIMIT,
		}
		const params = {
			pagination: newPagination,
			sorting: selectedFilters.sorting,
			filters,
			isNeedParseFilters: true,
		}
		loadTable(params)
	}
	const onFilterRemove = (filters) => {
		const newPagination = {
			currentPage: 1,
			limit: PAGINATION_LIMIT,
		}
		const params = {
			pagination: newPagination,
			sorting: selectedFilters.sorting,
			filters,
			isNeedParseFilters: true,
		}
		loadTable(params)
	}
	const onFiltersClear = (filters) => {
		const newPagination = {
			currentPage: 1,
			limit: PAGINATION_LIMIT,
		}
		const params = {
			pagination: newPagination,
			sorting: selectedFilters.sorting,
			filters,
			isNeedParseFilters: true,
		}
		loadTable(params)
	}

	const normalizedFilters = normalizeDetailFiltersForFilterPanel(
		selectedFilters.filters,
		entities.groupActions.filters,
	)

	const onCancelClick = () => {
		const params = {
			sorting: data.data.sorting,
			pagination: { currentPage: 1, limit: PAGINATION_LIMIT },
			filters: data.data.filters,
		}
		loadTable(params)
	}

	const onSaveClick = () => {
		dispatch(
			editActionByType({
				groupAction: data.id,
				filters: selectedFilters.filters,
			}),
		)
	}

	return (
		<>
			{isShowSaveButton &&
				createPortal(<AppButton type={'outline'} label={'Сохранить'} onClick={onSaveClick} />, portalSaveRoot)}
			{isShowCancelButton &&
				createPortal(
					<AppButton type={'outline'} label={'отменить'} onClick={onCancelClick} />,
					portalCancelRoot,
				)}
			<NestedFiltersPanel
				type={type}
				filters={normalizedFilters}
				onFilterAdd={onFilterAdd}
				onFilterRemove={onFilterRemove}
				onFiltersClear={onFiltersClear}
			/>
			<NestedTable
				data={data}
				type={type}
				onLoadTable={(pagination) => {
					const params = {
						sorting: selectedFilters.sorting,
						pagination,
						filters: selectedFilters.filters,
					}
					loadTable(params)
				}}
			/>
		</>
	)
}

export default CollapseListItem
