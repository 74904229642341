import { persistReducer } from 'redux-persist'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	changeAssignmentInfo,
	loadAssignmentInfo,
	loadAssignments,
	loadMorePOFiles,
	onChangeAssignmentInfoError,
	onChangeAssignmentInfoSuccess,
	onLoadAssignmentInfoError,
	onLoadAssignmentInfoSuccess,
	onLoadAssignmentsError,
	onLoadAssignmentsSuccess,
	onLoadMorePOFilesSuccess,
	setAssignmentsEndDate,
	setAssignmentsQuery,
	setAssignmentsStartDate,
} from '@store/actions/paymentsAssignments'
import { PaymentsAssignmentsState } from '@store/types/paymentsAssignments'
import { arrayToObjWithValue } from '@utils'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const paymentsAssignmentsInitialState: PaymentsAssignmentsState = {
	POTableFetchStatus: FetchStatus.INITIAL,
	POFilesFetchStatus: FetchStatus.INITIAL,
	errors: [],
	query: {},
	POTable: null,
	POFiles: null,
	summary: null,
	assignmentsInfo: {},
}

export const paymentsAssignmentsReducer = (state = paymentsAssignmentsInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadAssignments): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHING)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(onLoadAssignmentsSuccess): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHED)
			return {
				...state,
				summary: action.payload.summary,
				POFiles: action.payload.POFiles,
				POTable: action.payload.POTable,
				...newFetchingTypes,
			}
		}
		case getType(loadMorePOFiles): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHING_MORE)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(onLoadMorePOFilesSuccess): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHED)
			return {
				...state,
				summary: action.payload.summary,
				POFiles: {
					...action.payload.POFiles,
					results: [...state.POFiles.results, ...action.payload.POFiles.results],
				},
				POTable: action.payload.POTable,
				...newFetchingTypes,
			}
		}
		case getType(onLoadAssignmentsError): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.ERROR)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(loadAssignmentInfo): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.id]: {
						...state?.assignmentsInfo?.[action.payload.id],
						fetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onLoadAssignmentInfoSuccess): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.id]: {
						...state.assignmentsInfo?.[action.payload.id],
						fetchStatus: FetchStatus.FETCHED,
						data: {
							...state.assignmentsInfo?.[action.payload.id].data,
							...action.payload.response,
						},
					},
				},
			}
		}
		case getType(onLoadAssignmentInfoError): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.id]: {
						...state.assignmentsInfo?.[action.payload.id],
						fetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(changeAssignmentInfo): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.paymentAssignmentId]: {
						...state?.assignmentsInfo?.[action.payload.paymentAssignmentId],
						changeFetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onChangeAssignmentInfoSuccess): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.id]: {
						...state.assignmentsInfo?.[action.payload.id],
						changeFetchStatus: FetchStatus.FETCHED,
					},
				},
			}
		}
		case getType(onChangeAssignmentInfoError): {
			return {
				...state,
				assignmentsInfo: {
					[action.payload.id]: {
						...state.assignmentsInfo?.[action.payload.id],
						changeFetchStatus: FetchStatus.ERROR,
						errors: action.payload.response.extra.fields,
					},
				},
			}
		}
		case getType(setAssignmentsQuery): {
			return {
				...state,
				query: action.payload,
			}
		}

		case getType(setAssignmentsStartDate): {
			return {
				...state,
				startDate: action.payload,
			}
		}
		case getType(setAssignmentsEndDate): {
			return {
				...state,
				endDate: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedPaymentsAssignmentsReducer: any = persistReducer(
	{
		key: 'AdminPanel::payments-assignments',
		storage: indexedDBStore,
		whitelist: ['startDate', 'endDate', 'query'],
		version: 1,
	},
	paymentsAssignmentsReducer,
)
