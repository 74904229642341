import { notification } from 'antd'
import { ArgsProps as IToastProps } from 'antd/lib/notification/interface'

/**
 * @description - toast на основе antd
 * @param {IToastProps} config
 */
const showToast = (config: IToastProps) => {
	notification.open(config)
}

/**
 * @description - success toast
 * @param {IToastProps} config
 */
const showSuccessToast = (config: IToastProps) => {
	showToast({ ...config, type: 'success' })
}
/**
 * @description - error toast
 * @param {IToastProps} config
 */
const showErrorToast = (config: IToastProps) => {
	showToast({ ...config, type: 'error' })
}

/**
 * @description - warning toast
 * @param {IToastProps} config
 */
const showWarningToast = (config: IToastProps) => {
	showToast({ ...config, type: 'warning' })
}

/**
 * @description - info toast
 * @param {IToastProps} config
 */
const showInfoToast = (config: IToastProps) => {
	showToast({ ...config, type: 'info' })
}

export { showErrorToast, showInfoToast, showSuccessToast, showToast, showWarningToast }
