import { TaskPopup } from '@components/tasks'
import { UserCardPopup } from '@components/user'

// сделать из этого один общий enum для всех URL параметров
export const URL_SEARCH_ENUM = {
	MODALS: 'modals',
}

// соблюдай одинаковую нотацию _ между key и value!
export const HARD_MODAL_ENUMS = {
	TASK: 'task',
	USER_CARD: 'user_card',
}

export const mappedPopups = {
	[HARD_MODAL_ENUMS.TASK]: (props) => TaskPopup(props),
	[HARD_MODAL_ENUMS.USER_CARD]: (props) => UserCardPopup(props),
}
