import { ActionType, createAction } from 'typesafe-actions'

export const loadGlobalClasses = createAction('@globalClasses/load/start')()
export const loadGlobalClassesSuccess = createAction('@globalClasses/load/success')<any>()
export const loadGlobalClassesError = createAction('@globalClasses/load/error')<any>()

export type GlobalClassesActions = 
    ActionType<typeof loadGlobalClasses> |
    ActionType<typeof loadGlobalClassesSuccess> |
    ActionType<typeof loadGlobalClassesError>;
    