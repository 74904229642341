import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AppCard, AppText } from '@frontend-modules/ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import {
	loadDebts,
	loadDebtsPayments,
	sendDebtsNotify,
	sendDebtsStudentBlock,
	setDebtsQuery,
	updateDebtsPaymentStatus,
} from '@store/actions/debts'
import { State } from '@/store/store'
import { MainTable } from '@components/debts'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'

import { FetchStatus } from '@/common/types'
import './Debts.scss'
import { IDebtStudent } from '@store/types/debts'
import { useQueryFiltersParams, parseQueryFiltersParamsToObject, trimSeparator } from '@frontend-modules/frontend-utils'
import { useLocation } from 'react-router-dom'
import { PAGINATION_LIMIT, SERVICE_NAME } from './Debts.config'
import { FiltersPanel } from '@frontend-modules/filters-generator'

/**
 * @description - Страница "Учет долгов"
 * @constructor
 */
export const Debts: FC = () => {
	const { fetchStatus, list, paymentsData, pagination, query } = useSelector((state: State) => state.debts)
	const { constants } = useSelector((state: State) => state.constants)

	const { entities, catalog, fetchStatus: metaFetchStatus } = useSelector((state: State) => state.meta)
	const [initQuery, changeQueryParams] = useQueryFiltersParams()
	const [selectedFilters, setSelectedFilters] = useState(initQuery || [])

	const dispatch = useDispatch()
	const location = useLocation()

	const loadDebtsData = useCallback(
		(params) => {
			const normalizedParams = {
				filters: params?.filters || [],
				pagination: {
					currentPage: params?.pagination?.currentPage || 1,
					limit: params?.pagination?.limit || PAGINATION_LIMIT,
				},
				sorting: params.sorting || [],
			}
			dispatch(setDebtsQuery(normalizedParams))
			dispatch(loadDebts(normalizedParams))
			setSelectedFilters(normalizedParams)
			changeQueryParams(normalizedParams)
		},
		[changeQueryParams, dispatch],
	)

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: pagination?.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadDebtsData(params)
		}
		const onSortingClear = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: selectedFilters.pagination.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadDebtsData(params)
		}

		return {
			availableList: entities?.debts?.sorting,
			selectedList: selectedFilters.sorting,
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
	}, [entities?.debts?.sorting, loadDebtsData, pagination?.limit, selectedFilters])

	const onPaginationChange = ({ page, limit }) => {
		const newPagination = {
			currentPage: page,
			limit,
		}
		loadDebtsData({
			filters: selectedFilters?.filters,
			sorting: selectedFilters?.sorting,
			pagination: newPagination,
		})
	}

	/**
	 * @description реагируем на добавление фильтра
	 */
	const onFilterAdd = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: pagination?.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadDebtsData(params)
	}

	/**
	 * @description реагируем на удаление фильтра
	 */
	const onFilterRemove = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: selectedFilters.pagination.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadDebtsData(params)
	}
	/**
	 * @description реагируем на полную очистку фильтров
	 */
	const onFiltersClear = () => {
		const params = {
			filters: [],
			sorting: selectedFilters.sorting,
			pagination: {
				currentPage: 1,
				limit: selectedFilters?.pagination?.limit,
			},
		}
		loadDebtsData(params)
	}

	const onItemExpand = (isOpening, record) => {
		if (isOpening) {
			dispatch(loadDebtsPayments(record.id))
		}
	}

	/**
	 * @description - обработчик нажатия кнопки "Зафиксировать"
	 * в всплывашке подтвеждения Платежа
	 * @param record
	 * @param studentID
	 */
	const onPayedPaymentTableClick = (record, studentID) => {
		const payload = {
			data: {
				paid: true,
			},
			paymentID: record.id,
			studentID,
		}
		dispatch(updateDebtsPaymentStatus(payload))
	}

	/**
	 * @description - запрос на уведомление родителя
	 * @param data
	 * @param key
	 * @param date
	 */
	const onNotifyTableClick = (data: IDebtStudent, key: string, date: string) => {
		dispatch(sendDebtsNotify({ user: data?.parent?.id, date, type: key }))
	}

	/**
	 * @description - запрос на блокировку\разблокировку студента
	 * @param data
	 */
	const onBlockTableClick = (data) => {
		dispatch(sendDebtsStudentBlock(data))
	}

	useEffect(() => {
		const debtsParams = {
			filters: selectedFilters.filters,
			pagination: selectedFilters.pagination,
			sorting: selectedFilters.sorting,
		}
		const currentQuery = parseQueryFiltersParamsToObject(location.search)

		// нужно для загрузки query параметров из стор перзистного
		if (
			query &&
			(!currentQuery?.filters || currentQuery?.filters?.length <= 0) &&
			(!currentQuery?.sorting || currentQuery?.sorting?.length <= 0)
		) {
			loadDebtsData(query)
		} else {
			loadDebtsData(debtsParams)
		}
		// ничего не добавлять в deps кроме dispatch!
	}, [dispatch])

	return (
		<AppCard
			className={'debts-page'}
			title={
				<>
					<AppText textStyle={'Title'} style={{ margin: '0' }} text={'Учет долгов'} />
					<AppText
						className={'counter'}
						textStyle={'DesktopH4'}
						text={`Всего записей: <b>${trimSeparator(pagination?.count) || 0}</b>`}
					/>
				</>
			}
		>
			<div className={'filters-container'}>
				<FiltersPanel
					isFetching={metaFetchStatus === FetchStatus.FETCHING}
					availableFilters={entities?.debts?.filters}
					selectsApiConfig={filtersApiConfig}
					selectedFiltersList={selectedFilters.filters}
					onFilterAdd={onFilterAdd}
					onFilterRemove={onFilterRemove}
					onFiltersClear={onFiltersClear}
					serviceName={SERVICE_NAME}
				/>
			</div>
			<MainTable
				isLoading={fetchStatus === FetchStatus.FETCHING}
				list={list}
				pagination={{ count: pagination?.count, currentPage: selectedFilters?.pagination?.currentPage }}
				paginationLimit={selectedFilters?.pagination?.limit || PAGINATION_LIMIT}
				tariffList={constants?.EDUCATION_COST}
				paymentsData={paymentsData}
				onPaginationChange={onPaginationChange}
				onItemExpand={onItemExpand}
				onPayedPaymentClick={onPayedPaymentTableClick}
				onNotifyClick={onNotifyTableClick}
				onBlockClick={onBlockTableClick}
				sortingConfig={sortingConfig}
			/>
		</AppCard>
	)
}

export default Debts
