import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { addParentNote, loadParentCardTabNotes } from '@store/actions/parentCard'
import { AppButton, AppPopover, AppSpecialButton, AppText, AppTextareaInput } from '@frontend-modules/ui-kit'
import './UserCardPopupTabNotes.scss'
import { UserCardPopupTabNotesTable } from '@components/user'
import { IUserCardPopupTabNotesProps } from '@components/user/user-card/tabs/notes/UserCardPopupTabNotes/UserCardPopupTabNotes.types'
import { usePrevState } from '@/hooks'
import { addStudentNote, loadStudentCardTabNotes } from '@store/actions/studentCard'

/**
 * @description - вкладка с заметками
 * @param props
 * @constructor
 */
const UserCardPopupTabNotes: FC<IUserCardPopupTabNotesProps> = (props) => {
	const { userType, id } = props
	const dispatch = useDispatch()

	const notes = useSelector((state: State) => state?.[`${userType}CardNew`]?.cards?.[id]?.notes)
	const notesData = notes?.data
	const addNoteFetchStatus = notes?.addFetchStatus
	const prevAddNoteFetchStatus = usePrevState(addNoteFetchStatus)
	const isLoading = notes?.fetchStatus === FetchStatus.FETCHING
	const isAddLoading = addNoteFetchStatus === FetchStatus.FETCHING

	const [comment, setComment] = useState('')
	const [isPopupShow, setPopupShow] = useState(false)

	useEffect(() => {
		// очищаем и закрываем окно успешной после отправки комментария
		if (
			prevAddNoteFetchStatus === FetchStatus.FETCHING &&
			addNoteFetchStatus === FetchStatus.FETCHED &&
			!!comment.length
		) {
			setComment('')
			setPopupShow?.(false)
		}
	}, [addNoteFetchStatus, comment.length, prevAddNoteFetchStatus])

	const popoverContent = useMemo(() => {
		const onAddClick = () => {
			if (userType === 'parent') {
				dispatch(
					addParentNote({
						parentId: id,
						addComment: comment,
					}),
				)
			}
			if (userType === 'student') {
				dispatch(
					addStudentNote({
						studentId: id,
						addComment: comment,
					}),
				)
			}
		}

		return (
			<>
				<AppTextareaInput defaultValue={comment} onChange={setComment} />
				<AppButton
					isLoading={isAddLoading}
					isDisabled={!comment.length}
					icon={'plus-l'}
					style={{ marginTop: 8 }}
					label={'Добавить'}
					onClick={onAddClick}
				/>
			</>
		)
	}, [userType, comment, dispatch, id, isAddLoading])

	const onTableLoad = (params) => {
		if (userType === 'parent') {
			dispatch(loadParentCardTabNotes(params))
		}
		if (userType === 'student') {
			dispatch(loadStudentCardTabNotes(params))
		}
	}

	return (
		<div className={'user-card-popup-tab-notes'}>
			<AppText
				className={'user-card-popup-tab-notes-title'}
				textStyle={'DesktopH3'}
				text={`Список заметок по ${userType === 'parent' ? 'родителю' : 'ученику'}`}
			/>
			<div className={'user-card-popup-tab-notes-control'}>
				<AppPopover
					overlayInnerStyle={{ width: 500 }}
					title={<AppText text={'Добавьте заметку'} />}
					trigger={'click'}
					open={isPopupShow}
					onOpenChange={setPopupShow}
					content={popoverContent}
				>
					<AppSpecialButton icon={'plus-l'} type={'dotted'} />
				</AppPopover>
			</div>
			<div className={'user-card-popup-tab-notes-content'}>
				<UserCardPopupTabNotesTable
					id={id}
					userType={userType}
					isLoading={isLoading}
					onLoad={onTableLoad}
					tableData={notesData || []}
				/>
			</div>
		</div>
	)
}

export default UserCardPopupTabNotes
