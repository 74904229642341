import React, { FC, useMemo } from 'react'
import { AppText, AppTable, AppPopover, AppHardModalButton, AppFontIcon } from '@frontend-modules/ui-kit'
import { IMainTableProps } from './MainTable.types'
import './MainTable.scss'
import { AccessHelper } from '@frontend-modules/access-controller'
import { TableContentCell } from '@components/processes-monitoring'
import { StatusHint } from './MainTable.config'
import { parseTimeSpent } from './MainTable.utils'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'

/**
 * @description - таблица мониторинга роцессов
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const {
		isLoading,
		list,
		paginationLimit,
		pagination,
		onPaginationChange,
		sortingConfig = {},
		isRenderParent = true,
	} = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}
	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	const columns = useMemo(() => {
		const renderStatus = (status) => (
			<AppPopover content={StatusHint[status]}>
				<button className={`status-button ${status}`} />
			</AppPopover>
		)

		return AccessHelper.filterList([
			{
				title: 'Родитель',
				dataIndex: 'parent',
				// если карточка родителя - то не выводим ключ
				accessKey: isRenderParent
					? 'processes_monitoring.process_status.columns.parent'
					: 'some-key-for-no-render',
				key: 'parent',
				width: '150px',
				render: (_, record) => {
					return record?.parent ? (
						<AppHardModalButton
							buttonType={'link'}
							label={`${record?.parent?.last_name} ${record?.parent?.first_name} ${record?.parent?.patronymic}`}
							query={{
								type: HARD_MODAL_ENUMS.USER_CARD,
								id: record?.parent?.id,
								userType: 'parent',
							}}
						/>
					) : (
						'-'
					)
				},
			},
			{
				title: 'Процесс / Стадия',
				accessKey: 'processes_monitoring.process_status.columns.process',
				dataIndex: 'process_state',
				key: 'process_state',
				render: (_, record) => {
					return (
						<TableContentCell
							left={<AppText style={{ fontWeight: 'bold' }} text={`${record?.title} /`} />}
							center={record?.state?.map((item, index) => {
								return (
									<div className={'content-cell'} key={item.id}>
										<AppText
											text={`${index + 1}) ${item.title} ${item?.assignets
												.map((assignet) => assignet.fullname)
												.join(', ')}`}
										/>
										{item.comments.length ? (
											<AppPopover content={`${item.comments.map((com) => `${com}`)}`}>
												<AppFontIcon icon={'homework-m'} color={'rgb(22, 119, 255)'} />
											</AppPopover>
										) : null}
									</div>
								)
							})}
						/>
					)
				},
			},
			{
				title: 'Затраченное время',
				dataIndex: 'timeSpent',
				accessKey: 'processes_monitoring.process_status.columns.timeSpent',
				key: 'timeSpent',
				render: (_, record) => {
					return (
						<TableContentCell
							left={
								<AppText style={{ fontWeight: 'bold' }} text={`${parseTimeSpent(record.timeSpent)}`} />
							}
							center={record?.state?.map((item, index) => (
								<div className={'content-cell'} key={item.id}>
									<AppText text={`${index + 1}) ${parseTimeSpent(item.timeSpent)}`} />
								</div>
							))}
							right={
								<div>
									{renderStatus(record?.indicator)}
									{record?.state?.map((item) => {
										return (
											<div className={'content-cell'} key={item.id}>
												{renderStatus(item.indicator)}
											</div>
										)
									})}
								</div>
							}
						/>
					)
				},
			},
		])
	}, [paginationLimit, pagination?.currentPage])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isCanDeleteRow={false}
			isNeedNumbering={true}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={
				pagination
					? {
							total: pagination?.count,
							pageSize: paginationLimit,
							defaultCurrent: 1,
							current: pagination?.currentPage,
							onChange: onPaginationChangeLocal,
							locale: { items_per_page: 'записей ' },
							onShowSizeChange: onPaginationSizeChangeLocal,
					  }
					: false
			}
			rowKey={(record) => `${record.id}`}
			className={'process-monitoring-main-table'}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
		/>
	)
}

export default MainTable
