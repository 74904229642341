import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const GenerateExpulsionOrder: React.FC<any> = ({ id }) => {
	const [number, setNumber] = useState('')
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

	const dispatch = useDispatch()

	const numberChangeCallback = useCallback((e: any) => {
		setNumber(e.target.value)
	}, [])

	const dateChangeCallback = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				process: 'generate_expulsion_order',
				parameters: {
					number,
					date: moment(date).format('YYYY-MM-DD'),
				},
				id,
			}),
		)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Сгенерировать приказ об отчислении</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<div>
						<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
							<Form.Label>Номер</Form.Label>
							<Form.Control
								value={number}
								onChange={numberChangeCallback}
								placeholder="Номер"
								type="number"
							/>
						</Form.Group>
					</div>
					<div>
						<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
							<Form.Label>Дата</Form.Label>
							<Form.Control value={date} onChange={dateChangeCallback} placeholder="Дата" type="date" />
						</Form.Group>
					</div>
					<Button onClick={submitButtonPressHandler} variant="primary">
                        Сформировать
					</Button>
				</Col>
			</Row>
		</Col>
	)
}
