import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	loadAdditionalCourses,
	onLoadAdditionalCoursesError,
	onLoadAdditionalCoursesSuccess,
} from '@store/actions/additionalCourses'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadAdditionalCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAdditionalCourses)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
				sorting: payload?.sorting,
			}
			return from(deps.coursesService.loadAdditionalCourses(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadAdditionalCoursesSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadAdditionalCoursesError(err))
				}),
			)
		}),
	)
}

export const additionalCoursesEpics = combineEpics(loadAdditionalCoursesEpic)
