const communicationTabList = [
	{
		label: 'Email оповещения',
		key: 'email',
	},
	{
		label: 'СМС оповещения',
		key: 'sms',
	},
]

export { communicationTabList }
