import React from 'react'
import { Pagination } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import './ScreenPagination.scss'

interface IScreenPaginationProps {
    setCurrentPage: any;
    currentPage: number;
    pages: any[];
    pagesCount: number;
}

export const ScreenPagination: React.FC<IScreenPaginationProps> = ({
	setCurrentPage,
	currentPage,
	pages,
	pagesCount,
}) => {
	const dispatch = useDispatch()

	return (
		<div className="screenPagination__wrapper">
			<Pagination>
				<Pagination.First disabled={currentPage === 1} onClick={() => dispatch(setCurrentPage(1))} />
				<Pagination.Prev
					disabled={currentPage <= 1}
					onClick={() => dispatch(setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage))}
				/>
				{pages.map((page, index) => {
					return (
						<Pagination.Item
							key={`page-${index + 1}`}
							active={page === currentPage}
							onClick={() => dispatch(setCurrentPage(page))}
						>
							{page}
						</Pagination.Item>
					)
				})}
				<Pagination.Next
					disabled={currentPage == pagesCount}
					onClick={() => dispatch(setCurrentPage(currentPage < pagesCount ? currentPage + 1 : currentPage))}
				/>
				<Pagination.Last
					disabled={currentPage == pagesCount}
					onClick={() => dispatch(setCurrentPage(pagesCount))}
				/>
			</Pagination>
		</div>
	)
}
