import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadGlobalClasses, loadGlobalClassesError, loadGlobalClassesSuccess } from '../actions/globalClasses'
import { Action } from '../store'
import { GlobalClassesState } from '../types/globalClasses'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	classes: [],
}

export const globalClasses = (state: GlobalClassesState = initialState, action: Action) => {

	switch (action.type) {

	case getType(loadGlobalClasses): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING
		}
	}

	case getType(loadGlobalClassesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			classes: action.payload
		}
	}
        
	case getType(loadGlobalClassesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
