import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const DocumentsSent: React.FC<any> = ({ id }) => {
	const dispatch = useDispatch()
	const [link, setLink] = useState('')

	const linkChangeCallback = useCallback((e: any) => {
		setLink(e.target.value)
	}, [])

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				process: 'documents_sent',

				id: id,
				parameters: {
					tracking_link: link,
				},
			}),
		)
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Укажите ссылку для отслеживания</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Ссылка для отслеживания</Form.Label>
							<Form.Control
								value={link}
								onChange={linkChangeCallback}
								type="text"
								placeholder="Введите ссылку"
							/>
						</Form.Group>
						<Button onClick={submitButtonPressHandler} disabled={!link} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
