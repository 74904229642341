import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'
import { loadDocumentList } from '../../../store/actions/document'
import { DocumentState } from '../../../store/types/document'
import DownloadButton from '../../../Components/DownloadButton'

interface IDocumentsReceivedProps extends DocumentState {
    createProcess: (payload: any) => void;
    loadDocuments: (payload: any) => void;
    student_id: string;
    id: number;
    parent_id: string;
}

export const Component: React.FC<IDocumentsReceivedProps> = ({
	createProcess,
	student_id,
	id,
	loadDocuments,
	documents,
	fetchStatus,
}) => {
	const [selectedDocuments, setSelectedDocuments] = useState([])

	useEffect(() => {
		loadDocuments({ student: student_id, required_origin: 'true', original: 'false' })
	}, [])

	useEffect(() => {
		if (documents.length) {
			setSelectedDocuments(documents.filter((document) => document.original).map((document) => document.id))
		}
	}, [documents])

	const onSubmit = () => {
		createProcess({
			process: 'confirm_documents_received',
			id: id,
			parameters: {
				document_ids: selectedDocuments,
			},
		})
	}

	const downloadDocuments = (files) => {
		for (const { file } of files) {
			window.open(file, '_blank')
		}
	}

	const onChange = (id: number) => {
		if (selectedDocuments.includes(id)) {
			setSelectedDocuments((prev) => prev.filter((docId) => docId !== id))
		} else {
			setSelectedDocuments((prev) => [...prev, id])
		}
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Полученные документы</Popover.Title>
			</Row>

			<Row>
				<Col>
					<Table responsive hover>
						<thead>
							<th>Получен</th>
							<th>Тип документа</th>
							<th>Действие</th>
						</thead>
						<tbody>
							{fetchStatus === FetchStatus.FETCHED ? (
								documents.map((document) => (
									<tr onClick={() => onChange(document.id)}>
										<td>
											<Form.Control
												checked={selectedDocuments.includes(document.id)}
												readOnly
												type="checkbox"
											/>
										</td>
										<td>{document.upload_doc_type?.name}</td>
										<td>
											<div onClick={() => downloadDocuments(document.files)}>
												<DownloadButton />
											</div>
										</td>
									</tr>
								))
							) : (
								<tr className="table-item unread mb-3">
									<td>
										<Skeleton />
									</td>
									<td>
										<Skeleton />
									</td>
									<td>
										<Skeleton />
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					<div>
						<Button onClick={onSubmit} type="submit" variant="primary">
                            Подтвердить
						</Button>
					</div>
				</Col>
			</Row>
		</Col>
	)
}

export const DocumentsReceived = connect(
	({ document }: State) => ({ ...document }),
	(dispatch) => {
		return {
			createProcess: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadDocuments: (payload: any) => {
				return dispatch(loadDocumentList(payload))
			},
		}
	},
)(Component)
