import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import {
	AppButton,
	AppCard,
	AppModal,
	AppSearchSelect,
	AppSkeleton,
	AppSpecialButton,
	AppTable,
	AppText,
} from '@frontend-modules/ui-kit'
import './PayModal.scss'
import { PayDistribution } from '@components/payments'
import { formatDate, trimSeparator } from '@frontend-modules/frontend-utils'
import { useDispatch, useSelector } from 'react-redux'
import { changePayInfo, loadPayInfo } from '@store/actions/paymentsPays'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { PaymentsService } from '@services'
import { IAssignmentTypeEnum } from '@store/types/paymentsAssignments'
import { assignmentColor } from '@pages/payments/Assignments/Assignments.config'
import { IPayModalProps } from './PayModal.types'

const paymentsService = new PaymentsService()

/**
 * @description - модалка управления платежом
 * @param props
 * @constructor
 */
const PayModal: FC<IPayModalProps> = (props) => {
	const { isShow, onSetShow, id } = props

	const paysInfo = useSelector((state: State) => state.paymentsPays?.paysInfo) || {}
	const { fetchStatus, changeFetchStatus, data } = paysInfo[id] || {}

	const [isWasChanges, setWasChanges] = useState(false)

	const isLoading = fetchStatus === FetchStatus.FETCHING || changeFetchStatus === FetchStatus.FETCHING
	const dispatch = useDispatch()

	useEffect(() => {
		if (changeFetchStatus === FetchStatus.FETCHED) {
			setWasChanges(true)
		}
	}, [changeFetchStatus])

	const onSetShowLocal = (state) => {
		onSetShow(state, isWasChanges)
	}

	const dispatchChangePayInfo = useCallback(
		(payload) => {
			dispatch(changePayInfo(payload))
		},
		[dispatch],
	)

	const onPaymentAssignmentRemove = () => {
		dispatchChangePayInfo({ id, update: { paymentOrder: null } })
	}

	const onPersonalAccountSave = (payload) => {
		dispatchChangePayInfo({ id, update: payload })
	}

	const paymentsAssignmentColumns = [
		{
			title: 'Номер',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: 'Дата',
			dataIndex: 'paymentOrderDate',
			key: 'paymentOrderDate',
			render: (_, record) => {
				return `${formatDate(record?.paymentOrderDate)}`
			},
		},
		{
			title: 'Сумма',
			dataIndex: 'amount',
			key: 'amount',
			render: (_, record) => {
				return `${trimSeparator(record?.amount)}`
			},
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
			render: (_, record) => {
				return <AppText text={IAssignmentTypeEnum[record.type]} color={assignmentColor[record.type]} />
			},
		},
		{
			title: 'Назначение',
			dataIndex: 'purpose',
			key: 'purpose',
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'action',
			render: () => {
				return (
					<AppSpecialButton
						icon={'delete-l'}
						color={'red'}
						type={'bordered'}
						onClick={onPaymentAssignmentRemove}
					/>
				)
			},
		},
	]

	const [paymentAssignment, setPaymentAssignment] = useState(null)
	const [paymentAssignmentList, setPaymentAssignmentList] = useState(null)
	const [paymentAssignmentFetchingStatus, setPaymentAssignmentFetchingStatus] = useState(FetchStatus.INITIAL)

	const onConnectPaymentAssignment = () => {
		if (paymentAssignment?.id) {
			dispatch(changePayInfo({ id, update: { paymentOrder: paymentAssignment.id } }))
		}
	}

	const loadPaymentAssignmentList = useCallback(
		(params, isMore) => {
			setPaymentAssignmentFetchingStatus(isMore ? FetchStatus.FETCHING_MORE : FetchStatus.FETCHING)
			paymentsService.findPaymentAssignment(params).then((res: any) => {
				setPaymentAssignmentFetchingStatus(FetchStatus.FETCHED)
				if (isMore) {
					const newPagination = res.pagination
					const newList = [...(paymentAssignmentList?.results || []), ...res.results]
					setPaymentAssignmentList({ pagination: newPagination, results: newList })
				} else {
					setPaymentAssignmentList(res)
				}
			})
		},
		[paymentAssignmentList],
	)

	const loadMorePaymentAssignmentList = useCallback(
		(params) => {
			loadPaymentAssignmentList?.(params, true)
		},
		[loadPaymentAssignmentList],
	)

	useEffect(() => {
		dispatch(loadPayInfo({ id: id }))
	}, [id, dispatch])

	return (
		<AppModal
			zIndex={9}
			title={`Платеж №${data?.number || '-'} на сумму ${
				data?.amount !== undefined ? trimSeparator(data?.amount) : '-'
			} р`}
			width={1400}
			isShow={isShow}
			className={'pay-modal'}
			setShow={onSetShowLocal}
		>
			<div className="pay-modal-content">
				<AppCard>
					{isLoading && <AppSkeleton active />}
					{!isLoading && (
						<div className={'pay-modal-content__requisites'}>
							<div>
								<AppText text={'Сумма'} />
								<AppText text={trimSeparator(data?.amount)} textStyle={'DesktopH4'} />
							</div>
							<div>
								<AppText text={'Дата'} />
								<AppText
									text={data?.paymentDate ? formatDate(data?.paymentDate) : '-'}
									textStyle={'DesktopH4'}
								/>
							</div>
							<div>
								<AppText text={'Реквизиты'} />
								{!data?.requisites?.length && <AppText text={'Отсутствуют'} textStyle={'DesktopH4'} />}
								{!!data?.requisites?.length &&
									data?.requisites?.map((item, index) => (
										<Fragment key={index}>
											<AppText text={`${item.label}`} textStyle={'DesktopH4'} />
											<AppText text={`${item.value}`} textStyle={'DesktopFontBody'} />
											{index < data?.requisites.length - 1 && <hr />}
										</Fragment>
									))}
							</div>
						</div>
					)}
				</AppCard>
				<div className={'pay-modal-content__tables'}>
					<AppCard title={'Платежное поручение'}>
						{!data?.paymentOrder && (
							<div className={'payment-assignment-select'}>
								<AppSearchSelect
									label={'Платежное поручение'}
									placeholder={'Введите номер ПП/ назначение ПП'}
									results={paymentAssignmentList?.results}
									pagination={paymentAssignmentList?.pagination}
									selectedValue={paymentAssignment}
									onSetSelectedValue={setPaymentAssignment}
									labelFields={['title']}
									isFetching={paymentAssignmentFetchingStatus === FetchStatus.FETCHING}
									onLoad={loadPaymentAssignmentList}
									onLoadMore={loadMorePaymentAssignmentList}
									isFetchingMore={paymentAssignmentFetchingStatus === FetchStatus.FETCHING_MORE}
								/>
								<AppButton
									label={'Привязать'}
									disabled={!paymentAssignment}
									onClick={onConnectPaymentAssignment}
								/>
							</div>
						)}
						<AppTable
							isLoading={isLoading}
							columns={paymentsAssignmentColumns}
							dataSource={data?.paymentOrder ? [data?.paymentOrder] : []}
							isCanDeleteRow={false}
							isNeedNumbering={false}
							locale={{ emptyText: 'Платежное поручение не установлено' }}
							rowKey={(record, index) => `${index}`}
							sortingConfig={{}}
							pagination={false}
							titleSortingType={'customSorting'}
							skeletonRowCount={1}
						/>
					</AppCard>
					<PayDistribution
						data={data?.distributions || []}
						isNeedRenderSum={false}
						isMotherCapital={!!data?.isMotherCapital}
						onSave={onPersonalAccountSave}
						personalAccount={data?.personalAccount}
						changeFetchStatus={changeFetchStatus}
					/>
				</div>
			</div>
		</AppModal>
	)
}
export default PayModal
