import {persistReducer} from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import {getType} from 'typesafe-actions'
import {config} from '@/common/config'
import {
	changeLayout,
	collapseMenu,
	collapseToggle,
	fullScreen,
	fullScreenExit,
	navCollapseLeave,
	navContentLeave
} from '../actions/navBar'
import {Action} from '../store'
import {NavBarState} from '../types/navBar'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState = {
	isOpen: [], //for active default menu
	isTrigger: [], //for active default menu, set blank for horizontal
	...config,
	isFullScreen: false, // static can't change
}

const reducer = (state: NavBarState = initialState, action: Action) => {
	let trigger: any = []
	let open: any = []

	switch (action.type) {
	case (getType(collapseMenu)): {
		return {
			...state,
			collapseMenu: !state.collapseMenu
		}
	}

	case getType(collapseToggle):
		if (action.payload.menu.type === 'sub') {
			open = state.isOpen
			trigger = state.isTrigger

			const triggerIndex = trigger.indexOf(action.payload.menu.id)
			if (triggerIndex > -1) {
				open = open.filter((item: any) => item !== action.payload.menu.id)
				trigger = trigger.filter((item: any) => item !== action.payload.menu.id)
			}

			if (triggerIndex === -1) {
				open = [...open, action.payload.menu.id]
				trigger = [...trigger, action.payload.menu.id]
			}
		} else {
			open = state.isOpen
			const triggerIndex = (state.isTrigger).indexOf(action.payload.menu.id)
			trigger = (triggerIndex === -1) ? [action.payload.menu.id] : []
			open = (triggerIndex === -1) ? [action.payload.menu.id] : []
		}

		return {
			...state,
			isOpen: open,
			isTrigger: trigger
		}
	case getType(navContentLeave):
		return {
			...state,
			isOpen: open,
			isTrigger: trigger,
		}
	case getType(navCollapseLeave):
		if (action.payload.menu.type === 'sub') {
			open = state.isOpen
			trigger = state.isTrigger

			const triggerIndex = trigger.indexOf(action.payload.menu.id)
			if (triggerIndex > -1) {
				open = open.filter((item: any) => item !== action.payload.menu.id)
				trigger = trigger.filter((item: any) => item !== action.payload.menu.id)
			}
			return {
				...state,
				isOpen: open,
				isTrigger: trigger,
			}
		}
		return {...state}
	case getType(fullScreen) :
		return {
			...state,
			isFullScreen: !state.isFullScreen
		}
	case getType(fullScreenExit):
		return {
			...state,
			isFullScreen: false
		}
	case getType(changeLayout):
		return {
			...state,
			layout: action.payload.layout
		}
	default:
		return state
	}
}

export const navBar: any = persistReducer({
	key: 'AdminPane::navBar',
	storage: indexedDBOldStore,
	blacklist: [],
	version: 1
}, reducer)
