import { ActionType, createAction } from 'typesafe-actions'

// TODO: разобраться с any
export const loadDebts = createAction('@debts/LOAD_DEBTS')<any>()
export const onLoadDebtsSuccess = createAction('@debts/ON_LOAD_DEBTS_SUCCESS')<any>()
export const onLoadDebtsError = createAction('@debts/ON_LOAD_DEBTS_ERROR')<any>()
export const setDebtsQuery = createAction('@debts/SET_DEBTS_QUERY')<any>()
export const loadDebtsPayments = createAction('@debts/LOAD_DEBTS_PAYMENTS')<number>()
export const onLoadDebtsPaymentsSuccess = createAction('@debts/ON_LOAD_DEBTS__PAYMENTS_SUCCESS')<any>()
export const onLoadDebtsPaymentsError = createAction('@debts/ON_LOAD_DEBTS__PAYMENTS_ERROR')<any>()
export const updateDebtsPaymentStatus = createAction('@debts/UPDATE_DEBTS_PAYMENT_STATUS')<{
    studentID: number;
    paymentID: number;
    data: { paid: boolean };
}>()
export const onUpdateDebtsPaymentStatusSuccess = createAction('@debts/ON_UPDATE_DEBTS_PAYMENT_STATUS_SUCCESS')<any>()
export const onUpdateDebtsPaymentStatusError = createAction('@debts/ON_UPDATE_DEBTS_PAYMENT_STATUS_ERROR')<any>()
export const sendDebtsNotify = createAction('@debts/SEND_DEBTS_NOTIFY')<{ user: number; date: string; type: string }>()
export const onSendDebtsNotifySuccess = createAction('@debts/ON_SEND_DEBTS_NOTIFY_SUCCESS')<any>()
export const onSendDebtsNotifyError = createAction('@debts/ON_SEND_DEBTS_NOTIFY_ERROR')<any>()
export const sendDebtsStudentBlock = createAction('@debts/SEND_DEBTS_STUDENT_BLOCK')<any>()
export const onSendDebtsStudentBlockSuccess = createAction('@debts/ON_SEND_DEBTS_STUDENT_BLOCK_SUCCESS')<any>()
export const onSendDebtsStudentBlockError = createAction('@debts/ON_SEND_DEBTS_STUDENT_BLOCK_ERROR')<any>()

export type DebtsActions =
    | ActionType<typeof loadDebts>
    | ActionType<typeof onLoadDebtsSuccess>
    | ActionType<typeof onLoadDebtsError>
    | ActionType<typeof setDebtsQuery>
    | ActionType<typeof loadDebtsPayments>
    | ActionType<typeof onLoadDebtsPaymentsSuccess>
    | ActionType<typeof onLoadDebtsPaymentsError>
    | ActionType<typeof updateDebtsPaymentStatus>
    | ActionType<typeof onUpdateDebtsPaymentStatusSuccess>
    | ActionType<typeof onUpdateDebtsPaymentStatusError>
    | ActionType<typeof sendDebtsNotify>
    | ActionType<typeof onSendDebtsNotifySuccess>
    | ActionType<typeof onSendDebtsNotifyError>
    | ActionType<typeof sendDebtsStudentBlock>
    | ActionType<typeof onSendDebtsStudentBlockSuccess>
    | ActionType<typeof onSendDebtsStudentBlockError>;
