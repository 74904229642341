const TABLE_PAGINATION_LIMIT = 20
const REGISTRIES_PAGINATION_LIMIT = 10
const SERVICE_NAME = 'PaymentsAssignments'

export const assignmentColor = {
	incoming: 'rgb(129, 186, 113)',
	outgoing: 'rgb(193, 60, 55)',
}

export { TABLE_PAGINATION_LIMIT, REGISTRIES_PAGINATION_LIMIT, SERVICE_NAME }
