import React, { useState } from 'react'
import { Button, Col, Popover, Row, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const HeadTeacherReject = ({ id }) => {
	const dispatch = useDispatch()
	const [comment, setComment] = useState('')

	const onSubmit = () => {
        

		dispatch(createProcessStart({
			id: id,
			process: 'head_teacher_reject',
			parameters: {
				comment: comment
			}
		}))
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Укажите причину отказа</Popover.Title>
			</Row>

			<Row>
				<Col>
					<div>
						<Form.Group>
							<Form.Control
								type="text"
								placeholder="Комментарий"
								value={comment}
								onChange={e => setComment(e.target.value)}
							/>
						</Form.Group>
					</div>
					<div>
						<Button onClick={onSubmit} type="submit" variant="primary">
                            Отказать
						</Button>
					</div>
				</Col>
			</Row>
		</Col>
	)
}
