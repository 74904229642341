import React, { useCallback } from 'react'
import { ProcessesSearchType } from '../../../store/types/search'
import { Col, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import { processesSearchChange } from '../../../store/actions/search'
import { setCurrentPage } from '../../../store/actions/processes'

export const TariffFilter: React.FC = () => {
	const dispatch = useDispatch()
	const constants = useSelector((state: State) => state.constants.constants)
	const { tariffSearch } = useSelector((state: State) => state.search)

	const tariffSearchChange = useCallback(
		(value) => {
			dispatch(setCurrentPage(1))
			dispatch(
				processesSearchChange({
					value: value,
					type: ProcessesSearchType.TARIFF,
				}),
			)
		},
		[dispatch],
	)

	const handleFilterItemClick = useCallback(
		(tariff) => {
			tariffSearchChange(
				tariffSearch.find((storeTariff) => storeTariff === tariff)
					? tariffSearch.filter((storeTariff) => storeTariff !== tariff)
					: [...tariffSearch, tariff],
			)
		},
		[tariffSearch, tariffSearchChange],
	)

	return (
		<Col className="p-3 tariff-filter__wrapper" md={12} xl={12}>
			<Row className="mb-3 justify-content-between tariff-filter__header">
				<Popover.Title as="h5">Выберите тариф</Popover.Title>

				<div onClick={tariffSearchChange.bind(null, [])} className={'searchItem-clear'}>
                    &times;
				</div>
			</Row>

			<Row style={{ maxHeight: 200, overflowY: 'scroll' }}>
				<Col md={12}>
					{Object.keys(constants.EDUCATION_COST).map((cost) => {
						const tariff = constants.EDUCATION_COST[cost]
						const isChecked = tariffSearch.includes(tariff)

						return (
							<div style={{ paddingTop: 5, paddingBottom: 5 }} className="d-flex" key={cost}>
								<div style={{ marginRight: 10 }}>
									<input
										id={cost}
										type="checkbox"
										name="tariff"
										onChange={() => handleFilterItemClick(tariff)}
										checked={isChecked}
									/>
								</div>
								<label htmlFor={cost}>{tariff}</label>
							</div>
						)
					})}
				</Col>
			</Row>
		</Col>
	)
}
