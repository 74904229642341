import { getUid, isExist } from '@frontend-modules/frontend-utils'

const normalizeDetailFiltersForFilterPanel = (filters, actions) => {
	const newFilters = filters?.map((item, index) => {
		const { option, value, field, label } = item
		const userData = actions?.filter((item) => item.field === field)[0]
		if (userData) {
			const { name, field, type, api } = userData
			return {
				id: index.toString(),
				name: name,
				field,
				type: option,
				value,
				typeField: type,
				isHaveApi: isExist(api?.url),
				option: {
					children: label,
					key: getUid(),
					value: value,
				},
			}
		}
	})

	return newFilters?.filter((item) => !!item)
}

export { normalizeDetailFiltersForFilterPanel }
