import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { FocusedContext } from '../../../Screens/Home/SidebarContent/SearchItem/SearchItem'
import { processesSearchChange } from '../../../store/actions/search'
import { State } from '../../../store/store'
import { ConstantsState } from '../../../store/types/constants'
import { ProcessesSearchType, SearchState } from '../../../store/types/search'
import { setCurrentPage } from '../../../store/actions/processes'

export const Component: React.FC<{
    search: SearchState;
    constants: ConstantsState;
    actionSearchChange: (value: any) => void;
}> = ({ search, constants: { constants }, actionSearchChange }) => {
	const [actionsArr, setActionsArr] = useState(Object.keys(constants.ACTIONS))
	const searchRef = useRef(null)
	const isFocused = useContext(FocusedContext)

	const filterActionsArr = useCallback(
		(e) => {
			setActionsArr(
				Object.keys(constants.ACTIONS).filter(
					(action) => constants.ACTIONS[action].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
				),
			)
		},
		[actionsArr],
	)

	useEffect(() => {
		setTimeout(() => {
			searchRef.current?.focus()
		}, 0)
	}, [isFocused])

	return (
		<Col className="p-3 actions-filter__wrapper" md={12} xl={12}>
			<Row className="mb-3 justify-content-between actions-filter__header">
				<Popover.Title as="h5">
                    Выберите действия
					<br /> которые хотите найти
				</Popover.Title>

				<div onClick={() => actionSearchChange([])} className={'searchItem-clear'}>
                    &times;
				</div>
			</Row>
			<Form.Control
				className="actions-filter__search"
				onChange={filterActionsArr}
				ref={searchRef}
				type="text"
				placeholder="Поиск"
			/>
			<Row style={{ maxHeight: 200, overflowY: 'scroll' }}>
				<Col md={12}>
					{actionsArr
						.sort((a, b) => {
							const aIsChecked = search.actionsSearch.includes(a)
							const bIsChecked = search.actionsSearch.includes(b)

							return bIsChecked - aIsChecked
						})
						.map((action) => {
							const isChecked = search.actionsSearch.includes(action)

							return (
								<div
									style={{ cursor: 'pointer', paddingTop: 5, paddingBottom: 5 }}
									className="d-flex"
									key={action}
									onClick={() => {
										if (isChecked) {
											actionSearchChange(
												search.actionsSearch.filter((storeAction) => storeAction !== action),
											)
										} else {
											actionSearchChange([...search.actionsSearch, action])
										}
									}}
								>
									<div style={{ marginRight: 10 }}>
										<input type="checkbox" readOnly checked={isChecked} />
									</div>
									<div>{constants.ACTIONS[action]}</div>
								</div>
							)
						})}
				</Col>
			</Row>
		</Col>
	)
}

export const ActionsFilter = connect(
	({ search, constants }: State) => ({ search, constants }),
	(dispatch) => {
		return {
			actionSearchChange: (value: any) => {
				dispatch(setCurrentPage(1))
				return dispatch(
					processesSearchChange({
						value: value,
						type: ProcessesSearchType.ACTIONS,
					}),
				)
			},
		}
	},
)(Component)
