import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parseQueryString } from '../helpers/parseQueryString'

export const useUrlQueryParams = (queryName: string, initialValue: string): [string, (newValue: string) => void] => {
	const history = useHistory()

	const queryParams = parseQueryString(history.location.search)
	const [value, setValue] = useState<string>(queryParams[queryName] || initialValue)

	const historyReplace = (newValue: string, queryObj = parseQueryString(history.location.search)) => {
		history.replace({
			pathname: history.location.pathname,
			search:
                '?' +
                new URLSearchParams({
                	...queryObj,
                	[queryName]: encodeURIComponent(newValue),
                }).toString(),
		})
	}

	const setValueModify = (newValue: string) => {
		setValue(newValue)
		historyReplace(newValue)
	}

	useEffect(() => {
		if (!queryParams[queryName]) historyReplace(value)
	}, [])

	return [value, setValueModify]
}
