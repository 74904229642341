import { FuncEpic } from '../../common/types'
import { combineEpics, ofType } from 'redux-observable'
import { ActionType, getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	addDocumentTypeError,
	addDocumentTypeStart,
	addDocumentTypeSuccess,
	createDocumentTypeError,
	createDocumentTypeStart,
	createDocumentTypeSuccess,
	loadDocumentTypesError,
	loadDocumentTypesStart,
	loadDocumentTypesSuccess,
	loadParentStudentDocumentTypes,
	loadParentStudentDocumentTypesError,
	loadParentStudentDocumentTypesSuccess, removeDocumentTypeStart,
} from '../actions/documentTypes'
import { showAlert } from '../actions/alert'

const loadDocumentTypesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadDocumentTypesStart)),
		switchMap(() => {
			return from(deps.documentTypesDataProvider.loadDocumentTypes()).pipe(
				switchMap((documentTypes) => {
					return of(loadDocumentTypesSuccess(documentTypes))
				}),
				catchError((err) => {
					return of(loadDocumentTypesError(err))
				}),
			)
		}),
	)
}

const loadParentStudentDocumentTypesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParentStudentDocumentTypes)),
		switchMap(({ payload }: ActionType<typeof loadParentStudentDocumentTypes>) => {
			return from(
				deps.documentTypesDataProvider.loadParentStudentDocumentTypes(payload?.student_id, payload?.parent_id),
			).pipe(
				switchMap((documentTypes) => {
					return of(loadParentStudentDocumentTypesSuccess(documentTypes))
				}),
				catchError((err) => {
					return of(loadParentStudentDocumentTypesError(err))
				}),
			)
		}),
	)
}

const addDocumentTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addDocumentTypeStart)),
		switchMap(({ payload }: ActionType<typeof addDocumentTypeStart>) => {
			return from(
				payload.refers_to_student
					? deps.documentTypesDataProvider.addDocumentType(payload)
					: deps.documentTypesDataProvider.addUserDocumentType(payload),
			).pipe(
				switchMap((student) => {
					return of(
						addDocumentTypeSuccess(student),
						loadParentStudentDocumentTypes({
							student_id: payload.student_id,
							parent_id: payload.parent_id,
						}),
						showAlert({
							type: 'success',
							title: 'Тип документа успешно добавлен!',
							text: '',
						}),
					)
				}),
				catchError((err) => {
					return of(
						addDocumentTypeError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Тип документа не был добавлен',
						}),
					)
				}),
			)
		}),
	)
}

const removeDocumentTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(removeDocumentTypeStart)),
		switchMap(({ payload }: ActionType<typeof removeDocumentTypeStart>) => {
			return from(
				payload.type === 'student' ?
					deps.documentTypesDataProvider.removeStudentDocumentType(payload) :
					deps.documentTypesDataProvider.removeParentDocumentType(payload)
			).pipe(
				switchMap(() => {
					return of(
						showAlert({
							type: 'success',
							title: 'Успех!',
							text: 'Тип документа успешно удалён!',
						}),
					)
				}),
				catchError((err) => {
					return of(
						addDocumentTypeError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Тип документа не был удалён',
						}),
					)
				}),
			)
		}),
	)
}

const createDocumentTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createDocumentTypeStart)),
		switchMap(({ payload }: ActionType<typeof createDocumentTypeStart>) => {
			return from(deps.documentTypesDataProvider.createDocumentType(payload)).pipe(
				switchMap((documentType) => {
					return of(
						createDocumentTypeSuccess(documentType),
						showAlert({
							type: 'success',
							title: 'Тип документа успешно создан!',
							text: '',
						}),
					)
				}),
				catchError((err) => {
					return of(
						createDocumentTypeError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Тип документа не был создан.',
						}),
					)
				}),
			)
		}),
	)
}

export const documentTypesEpics = combineEpics(
	loadDocumentTypesEpic,
	addDocumentTypeEpic,
	createDocumentTypeEpic,
	loadParentStudentDocumentTypesEpic,
	removeDocumentTypeEpic
)
