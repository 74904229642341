import React, { useEffect, useState } from 'react'
import { AppButton } from '@frontend-modules/ui-kit'
import { FormGeneratorPopup } from '@frontend-modules/form-generator'
import { showSuccessToast } from '@utils'
import './UserCardPopupActions.scss'

/**
 * @description - компонент доступных действий
 * @param props
 * @constructor
 */
const UserCardPopupActions = (props) => {
	const { id, userType, actions, onCardRefresh } = props
	const [isPopupShow, setPopupShow] = useState(false)

	const [localMetaData, setLocalMetaData] = useState(null)

	useEffect(() => {
		setLocalMetaData(actions)
	}, [actions])

	/**
	 * @description при нажатии на кнопки выбора в модалке, смотрим есть ли в текущем шаге данные для следующего шага,
	 *              если есть, то делаем следующий шаг текущим и так пока шаги не закончатся,
	 *              после чего отправляем все данные пришедшие из модалки
	 * @param data
	 */
	const onControlClickSuccess = (data) => {
		const nextPopupData = localMetaData?.availableActions?.[data?.actions?.value]
		if (nextPopupData) {
			setLocalMetaData(nextPopupData)
		} else {
			showSuccessToast({ message: 'Действие успешно выполнено' })
			setPopupShow(false)
			// обнуляем стейт действий
			setLocalMetaData(actions)
			if (data.type === 'refresh') {
				onCardRefresh?.()
			}
		}
	}

	return (
		<FormGeneratorPopup
			actionType={'returnThenRequest'}
			metaData={localMetaData}
			trigger={'click'}
			open={isPopupShow}
			placement={'bottomLeft'}
			onOpenChange={(open) => {
				setPopupShow(open)
				setLocalMetaData(actions)
			}}
			onControlClickSuccess={onControlClickSuccess}
			rootClassName={'user-card-popup-actions'}
		>
			<AppButton
				type={'medium-primary'}
				isDisabled={!Object.keys(localMetaData || {})?.length}
				label={'Доступные действия'}
				onClick={() => {
					setPopupShow(true)
				}}
			/>
		</FormGeneratorPopup>
	)
}

export default UserCardPopupActions
