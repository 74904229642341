import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	createAncete,
	createAnceteError,
	createAnceteSuccess,
	loadAncete,
	loadAnceteError,
	loadAnceteSuccess,
	updateAncete,
	updateAnceteError,
	updateAnceteSuccess,
} from '../actions/ancete'
import { showErrorToast, showSuccessToast } from '@utils'

const loadAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAncete)),
		switchMap(({ payload }) => {
			return from(deps.anceteDataProvider.loadAncete(payload)).pipe(
				switchMap((res) => {
					return of(loadAnceteSuccess(res))
				}),
				catchError((err) => {
					return of(loadAnceteError(err))
				}),
			)
		}),
	)
}

const updateAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(updateAncete)),
		switchMap(({ payload: { data, id } }) => {
			return from(deps.anceteDataProvider.updateAncete(id, data)).pipe(
				switchMap((res) => {
					showSuccessToast({
						message: 'Анкета успешно обновлена',
					})
					return of(updateAnceteSuccess(res))
				}),
				catchError((err) => {
					showErrorToast({
						message: 'Проверьте введённые данные!',
					})
					return of(updateAnceteError(err))
				}),
			)
		}),
	)
}

const createAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createAncete)),
		switchMap(({ payload }) => {
			return from(deps.anceteDataProvider.createAncete(payload)).pipe(
				switchMap((res) => {
					showSuccessToast({
						message: 'Анкета успешно создана',
					})
					return of(createAnceteSuccess(res))
				}),
				catchError((err) => {
					showErrorToast({
						message: 'Проверьте введённые данные!',
					})
					return of(createAnceteError(err))
				}),
			)
		}),
	)
}

export const anceteEpics = combineEpics(loadAnceteEpic, updateAnceteEpic, createAnceteEpic)
