import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	loadManagerInfo,
	loadManagerInfoError,
	loadManagerInfoSuccess,
	loadUserVisitsError,
	loadUserVisitsStart,
	loadUserVisitsSuccess,
} from '../actions/userLogging'

const loadUserVisits: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadUserVisitsStart)),
		switchMap(({ payload }: ActionType<typeof loadUserVisitsStart>) => {
			return from(deps.userLoggingDataProvider.loadUserVisits(payload)).pipe(
				switchMap((userVisits) => {
					return of(loadUserVisitsSuccess(userVisits))
				}),
				catchError((err) => {
					return of(loadUserVisitsError(err))
				}),
			)
		}),
	)
}

const loadManagerInfoEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadManagerInfo)),
		switchMap(({ payload }: ActionType<typeof loadManagerInfo>) => {
			return from(deps.userLoggingDataProvider.loadManagerInfo(payload)).pipe(
				switchMap((managerInfo) => {
					return of(loadManagerInfoSuccess(managerInfo))
				}),
				catchError((err) => {
					return of(loadManagerInfoError(err))
				}),
			)
		}),
	)
}

export const userLoggingEpics = combineEpics(loadUserVisits, loadManagerInfoEpic)
