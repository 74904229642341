import { ActionType, createAction } from 'typesafe-actions'

export const loadContracts = createAction('@contracts/load/start')<any>()
export const loadContractsSuccess = createAction('@contracts/load/success')<any>()
export const loadContractsError = createAction('@contracts/load/error')<any>()

export const contractsPageChange = createAction('@contracts/page/change')<any>()

export const contractsSearchChange = createAction('@contracts/search/change')<any>()

export type ContractsActions = ActionType<typeof loadContracts> | ActionType<typeof loadContractsSuccess> | ActionType<typeof loadContractsError> | ActionType<typeof contractsPageChange> | ActionType<typeof contractsSearchChange>;