export const parseDataToSelectObj = (id, title) => {
	return {
		id: id,
		value: id,
		label: title,
		children: title,
		title: title,
		key: id,
	}
}

export const isExistPayWithTarget = (list) => list?.find((contract) => contract.id)
