import { ActionType, createAction } from 'typesafe-actions'

// TODO: разобраться с any
export const loadProcessesMonitoring = createAction('@tasks/LOAD_PROCESSES_MONITORING')<any>()
export const onLoadProcessesMonitoringSuccess = createAction('@tasks/ON_LOAD_PROCESSES_MONITORING_SUCCESS')<any>()
export const onLoadProcessesMonitoringError = createAction('@tasks/ON_LOAD_PROCESSES_MONITORING_ERROR')<any>()
export const setProcessesMonitoringQuery = createAction('@tasks/SET_PROCESSES_MONITORING_QUERY')<any>()

export type ProcessesMonitoringActions =
	| ActionType<typeof loadProcessesMonitoring>
	| ActionType<typeof onLoadProcessesMonitoringSuccess>
	| ActionType<typeof onLoadProcessesMonitoringError>
	| ActionType<typeof setProcessesMonitoringQuery>
