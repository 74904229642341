import { ActionType, createAction } from 'typesafe-actions'
import {DeletePaymentStartPayload, IdentificateStartPayload, IPaymentSitesPayload} from '../types/payment'

export const loadVerPaymentsStart = createAction('@payments/VER/LOAD/START')<any>()
export const loadVerPaymentsSuccess = createAction('@payments/VER/LOAD/SUCCESS')<any>()
export const loadVerPaymentsError = createAction('@payments/VER/LOAD/ERROR')<any>()

export const loadUnverPaymentsStart = createAction('@payments/UNVER/LOAD/START')<any>()
export const loadUnverPaymentsSuccess = createAction('@payments/UNVER/LOAD/SUCCESS')<any>()
export const loadUnverPaymentsError = createAction('@payments/UNVER/LOAD/ERROR')<any>()

export const loadPaymentSitesStart = createAction('@paymentSites/LOAD/START')<IPaymentSitesPayload>()
export const loadPaymentSitesSuccess = createAction('@paymentSites/LOAD/SUCCESS')<any>()
export const loadPaymentSitesError = createAction('@paymentSites/LOAD/ERROR')<any>()
export const setCurrentDebtsPage = createAction('@paymentSites/PAGE')<any>()

export const identificateStart = createAction('@payment/IDENTIFICATE/START')<IdentificateStartPayload>()
export const identificateSuccess = createAction('@payment/IDENTIFICATE/SUCCESS')<any>()
export const identificateError = createAction('@payment/IDENTIFICATE/ERROR')<any>()

export const deletePaymentStart = createAction('@payment/DELETE/START')<DeletePaymentStartPayload>()
export const deletePaymentSuccess = createAction('@payment/DELETE/SUCCESS')<any>()
export const deletePaymentError = createAction('@payment/DELETE/ERROR')<any>()

export const setUnverifiedPaymentCurrentPage = createAction('@payment/SET/UNVERIFIED/PAYMENT/CURRENT/PAGE')<any>()
export const setVerifiedPaymentCurrentPage = createAction('@payment/SET/VERIFIED/PAYMENT/CURRENT/PAGE')<any>()

export const setUnverifiedQueryString = createAction('@payment/SET/UNVERIFIED/QUERY/STRING')<string>()
export const setVerifiedQueryString = createAction('@payment/SET/VERIFIED/QUERY/STRING')<string>()

export type PaymentActions =
    | ActionType<typeof loadUnverPaymentsStart>
    | ActionType<typeof loadUnverPaymentsSuccess>
    | ActionType<typeof loadUnverPaymentsError>
    | ActionType<typeof loadVerPaymentsStart>
    | ActionType<typeof loadVerPaymentsSuccess>
    | ActionType<typeof loadVerPaymentsError>
    | ActionType<typeof identificateStart>
    | ActionType<typeof identificateSuccess>
    | ActionType<typeof identificateError>
    | ActionType<typeof loadPaymentSitesStart>
    | ActionType<typeof loadPaymentSitesSuccess>
    | ActionType<typeof loadPaymentSitesError>
    | ActionType<typeof setUnverifiedPaymentCurrentPage>
    | ActionType<typeof setVerifiedPaymentCurrentPage>
    | ActionType<typeof setCurrentDebtsPage>
    | ActionType<typeof deletePaymentStart>
    | ActionType<typeof deletePaymentSuccess>
    | ActionType<typeof deletePaymentError>
    | ActionType<typeof setUnverifiedQueryString>
    | ActionType<typeof setVerifiedQueryString>
