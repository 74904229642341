import { ActionType, createAction } from 'typesafe-actions'
import { FinProcSearchChangePayload, IFinishedProcessesPayload } from '../types/processes'

export const loadProcessesStart = createAction('@tasks/LOAD/START')<any>()
export const loadProcessesSuccess = createAction('@tasks/LOAD/SUCCESS')<any>()
export const loadProcessesError = createAction('@tasks/LOAD/ERROR')<any>()
export const setCurrentPage = createAction('@tasks/PAGE')<any>()

export const loadFinishedProcesses = createAction('@tasks/LOAD_FINISHED/START')<IFinishedProcessesPayload>()
export const loadFinishedProcessesSuccess = createAction('@tasks/LOAD_FINISHED/SUCCESS')<any>()
export const loadFinishedProcessesError = createAction('@tasks/LOAD_FINISHED/ERROR')<any>()
export const finProcSearchChange = createAction('@tasks/SEARCH_FINISHED/CHANGE')<FinProcSearchChangePayload>()
export const setFinProcPage = createAction('@tasks/PAGE_FINISHED')<number>()

export const createProcessStart = createAction('@tasks/CREATE/START')<{
	id: number
	process: string
	parameters?: any
}>()
export const createProcessSuccess = createAction('@tasks/CREATE/SUCCESS')<any>()
export const createProcessError = createAction('@tasks/CREATE/ERROR')<any>()

export const downloadReportStart = createAction('@tasks/DOWNLOAD/REPORT/START')<any>()
export const downloadReportSuccess = createAction('@tasks/DOWNLOAD/REPORT/SUCCESS')<any>()
export const downloadReportError = createAction('@tasks/DOWNLOAD/REPORT/ERROR')<any>()

export const updateCreateProcessesId = createAction('@tasks/UPDATE')<Array<any>>()

export type ProcessesActions =
	| ActionType<typeof downloadReportError>
	| ActionType<typeof downloadReportStart>
	| ActionType<typeof downloadReportSuccess>
	| ActionType<typeof loadProcessesError>
	| ActionType<typeof loadProcessesStart>
	| ActionType<typeof loadProcessesSuccess>
	| ActionType<typeof createProcessError>
	| ActionType<typeof createProcessStart>
	| ActionType<typeof createProcessSuccess>
	| ActionType<typeof updateCreateProcessesId>
	| ActionType<typeof setCurrentPage>
	| ActionType<typeof loadFinishedProcesses>
	| ActionType<typeof loadFinishedProcessesSuccess>
	| ActionType<typeof loadFinishedProcessesError>
	| ActionType<typeof setFinProcPage>
	| ActionType<typeof finProcSearchChange>
