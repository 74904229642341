const TOKEN_NAME = 'last_open_url_service'
/**
 * @description -
 * запоминаем последнюю открытую юрлу при необходимости
 * и записываем ее в localStorage
 */
const LastUrlService = {
	save: (url: string): void => {
		localStorage.setItem(TOKEN_NAME, url)
	},
	remove: (): void => {
		localStorage.removeItem(TOKEN_NAME)
	},
	get: (): string => localStorage.getItem(TOKEN_NAME) || '',
}

export default LastUrlService
