import { persistReducer } from 'redux-persist'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	changePayInfo,
	loadMoreRegistries,
	loadPayInfo,
	loadPays,
	onChangePayInfoError,
	onChangePayInfoSuccess,
	onLoadMoreRegistriesSuccess,
	onLoadPayInfoError,
	onLoadPayInfoSuccess,
	onLoadPaysError,
	onLoadPaysSuccess,
	setPaysEndDate,
	setPaysQuery,
	setPaysStartDate,
} from '@store/actions/paymentsPays'
import { PaymentsPaysState } from '@store/types/paymentsPays'
import { arrayToObjWithValue } from '@utils'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const paymentsPaysInitialState: PaymentsPaysState = {
	tableFetchStatus: FetchStatus.INITIAL,
	registriesFetchStatus: FetchStatus.INITIAL,
	errors: [],
	query: {},
	table: null,
	registries: null,
	summary: null,
	paysInfo: {},
}

export const paymentsPaysReducer = (state = paymentsPaysInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadPays): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHING)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(onLoadPaysSuccess): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHED)
			return {
				...state,
				summary: action.payload.summary,
				registries: action.payload.registries,
				table: action.payload.table,
				...newFetchingTypes,
			}
		}
		case getType(onLoadPaysError): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.ERROR)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(loadMoreRegistries): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHING_MORE)
			return {
				...state,
				...newFetchingTypes,
			}
		}
		case getType(onLoadMoreRegistriesSuccess): {
			const newFetchingTypes = arrayToObjWithValue(action.payload.fetchingTypes, FetchStatus.FETCHED)
			return {
				...state,
				summary: action.payload.summary,
				registries: {
					...action.payload.registries,
					results: [...state.registries.results, ...action.payload.registries.results],
				},
				table: action.payload.table,
				...newFetchingTypes,
			}
		}
		case getType(loadPayInfo): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state?.paysInfo?.[action.payload.id],
						fetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onLoadPayInfoSuccess): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state.paysInfo?.[action.payload.id],
						fetchStatus: FetchStatus.FETCHED,
						data: {
							...state.paysInfo?.[action.payload.id].data,
							...action.payload.response,
						},
					},
				},
			}
		}
		case getType(onLoadPayInfoError): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state.paysInfo?.[action.payload.id],
						fetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(changePayInfo): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state?.paysInfo?.[action.payload.id],
						changeFetchStatus: FetchStatus.FETCHING,
					},
				},
			}
		}
		case getType(onChangePayInfoSuccess): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state.paysInfo?.[action.payload.id],
						changeFetchStatus: FetchStatus.FETCHED,
						data: {
							...state.paysInfo?.[action.payload.id].data,
							...action.payload.response,
						},
					},
				},
			}
		}
		case getType(onChangePayInfoError): {
			return {
				...state,
				paysInfo: {
					[action.payload.id]: {
						...state.paysInfo?.[action.payload.id],
						changeFetchStatus: FetchStatus.ERROR,
					},
				},
			}
		}
		case getType(setPaysQuery): {
			return {
				...state,
				query: action.payload,
			}
		}
		case getType(setPaysStartDate): {
			return {
				...state,
				startDate: action.payload,
			}
		}
		case getType(setPaysEndDate): {
			return {
				...state,
				endDate: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedPaymentsPaysReducer: any = persistReducer(
	{
		key: 'AdminPanel::payments-pays',
		storage: indexedDBStore,
		whitelist: ['startDate', 'endDate', 'query'],
		version: 1,
	},
	paymentsPaysReducer,
)
