import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'
import Select from 'react-select'
import { getScheduleString } from './BookingToTrialDays.utils'
import './BookingToTrialDays.scss'

export const BookingToTrialDays: React.FC<any> = ({ id, student }) => {
	const dispatch = useDispatch()
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((state: State) => state.constants)
	const scheduleObj: any = Object.entries(constants?.TRIAL_DAYS_TIMESLOTS).find(
		([_, timeslot]: any) => timeslot?.grade === student?.grade,
	)
	const [schedule, setSchedule] = useState(
		scheduleObj
			? {
				label: getScheduleString(scheduleObj[1]),
				value: scheduleObj[0],
			}
			: null,
	)

	const handleScheduleChange = useCallback((value) => {
		setSchedule(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				process: 'booking_to_trial_day',
				id,

				parameters: {
					timeslot_key: schedule?.value,
				},
			}),
		)
	}, [dispatch, id, schedule?.value])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Записать на пробные дни</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						{schedule ? (
							<Select
								placeholder="Выберите расписание"
								isLoading={constantsFetchStatus === FetchStatus.FETCHING}
								value={schedule}
								onChange={handleScheduleChange}
								options={Object.entries(constants?.TRIAL_DAYS_TIMESLOTS)
									.filter(([_, timeslot]: any) => timeslot?.grade === student?.grade)
									.map(([key, item]: any) => {
										return {
											label: getScheduleString(item),
											value: key,
										}
									})}
								className="bookingToTrial__select"
							/>
						) : (
							<p>Отсутствуют пробные для {student?.grade} класса</p>
						)}

						<Button disabled={!schedule} onClick={submitButtonPressHandler} variant="primary">
                            Записать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
