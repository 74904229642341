import React from 'react'
import { Toast } from 'react-bootstrap'
import { connect } from 'react-redux'
import { closeAlert } from '../../store/actions/alert'
import { State } from '../../store/store'
import { AlertState } from '../../store/types/alert'
import './Alert.css'

interface IAlertProps extends AlertState {
    closeAlert: (payload: any) => {}
}

const Component: React.FC<IAlertProps> = ({ alerts, closeAlert }) => {
	return (
		<div className="toast__container">
			{
				alerts.map(({ uid, title, text, show, type }) => (
					<Toast className={type} key={uid} show={show} onClose={() => { closeAlert(uid) }} autohide delay={15000} animation>
						<Toast.Header>
							<img className="rounded mr-2" alt="" />
							<strong className="mr-auto">
								{
									title
								}
							</strong>
						</Toast.Header>
						<Toast.Body>
							<div style={{ wordWrap: 'break-word' }}>
								{
									text
								}
							</div>
						</Toast.Body>
					</Toast>
				))
			}
		</div>
	)
}

export const Alert = connect(
	({ alert }: State) => ({ ...alert }),
	(dispatch) => {
		return {
			closeAlert: (payload: any) => {
				return dispatch(closeAlert(payload))
			}
		}
	}
)(Component)
