import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadUnfrozenPayment, loadUnfrozenPaymentError, loadUnfrozenPaymentSuccess } from '../actions/unfrozenPayment'

const loadUnfrozenPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadUnfrozenPayment)),
		switchMap(({ payload }: ActionType<typeof loadUnfrozenPayment>) => {
			return from(deps.paymentDataProvider.loadUnfrozenPayment(payload)).pipe(
				switchMap(payments => {
					return of(loadUnfrozenPaymentSuccess(payments))
				}),
				catchError(err => {
					return of(loadUnfrozenPaymentError(err))
				})
			)
		})
	)
}

export const unfrozenPaymentEpics = combineEpics(loadUnfrozenPaymentEpic)