import { Action, State } from '../store/store'
import { Epic } from 'redux-observable'
import { EpicDeps } from '../store/store'

export type FuncEpic = Epic<Action, Action, State, EpicDeps>;

export type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];
export type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? K : T[K] extends Function | undefined ? K : never;
}[keyof T];

export type TStateProps<T, TOwnProps = {}> = Pick<
    T,
    Exclude<NonFunctionPropertyNames<T>, NonFunctionPropertyNames<TOwnProps>>
>;
export type TDispatchProps<T, TOwnProps = {}> = Pick<
    T,
    Exclude<FunctionPropertyNames<T>, FunctionPropertyNames<TOwnProps>>
>;

export enum FetchStatus {
    FETCHING = 'FETCHING',
    FETCHING_MORE = 'FETCHING_MORE',
    FETCHED = 'FETCHED',
    ERROR = 'ERROR',
    INITIAL = 'INITIAL',
}

export interface Student {
    id: number;
    first_name: string;
    last_name: string;
    patronymic: string;
    parents: Array<{
        id: number;
        first_name: string;
        last_name: string;
        patronymic: string;
    }> | null;
}

export interface LoadedStudent {
    id: number;
    moodle_id: number;
    first_name: string;
    last_name: string;
    patronymic: string;
    photo: string;
    grade: string;
    point: string;
    role: string;
    years_old: {
        birthday: string;
        age: number;
    };
    email: string;
    place: {
        city: string;
        state: string;
    };
    documents: Array<any>;
}

export interface DocumentItem {
    id: number;
    document: string;
    uploaded_by: string;
    state: string;
    pub_date: string;
    hidden: boolean;
    user: number;
    student: number;
    upload_doc_type: any;
    files: Array<any>;
    original: boolean;
    comment: string;
}

export type DocumentList = Array<DocumentItem>;
