import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadTrialDays, loadTrialDaysError, loadTrialDaysSuccess } from '../actions/trialDays'
import { Action } from '../store'
import { TrialDaysState } from '../types/trialDays'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	trialDays: null,
}

export const trialDays = (state: TrialDaysState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadTrialDays): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadTrialDaysSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			trialDays: action.payload,
		}
	}

	case getType(loadTrialDaysError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
