import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'

import { FetchStatus } from '@/common/types'
import './Create.scss'
import {
	useQueryFiltersParams,
	parseQueryFiltersParamsToObject,
	customEventEmitter,
	getUid,
	isExist,
	trimSeparator,
} from '@frontend-modules/frontend-utils'
import { useLocation } from 'react-router-dom'
import { PAGINATION_LIMIT, SERVICE_NAME } from './Create.config'
import { FiltersPanel } from '@frontend-modules/filters-generator'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'
import { AvailableActionsPopup, MainTable } from '@components/group-actions'
import { createGroupAction, loadActionsList, loadGroupActions, setGroupActionsQuery } from '@store/actions/groupActions'
import { IGroupActionTableItem } from '@store/types/groupActions'
import { AppText } from '@frontend-modules/ui-kit'

/**
 * @description - Под-Страница "Создание группвого действия"
 * @constructor
 */
export const Create: FC = () => {
	const { fetchStatus, list, pagination, query } = useSelector((state: State) => state.groupActions?.create)
	const availableActionsList = useSelector((state: State) => state.groupActions?.create?.createModal?.list)

	const { entities, catalog, fetchStatus: metaFetchStatus } = useSelector((state: State) => state.meta)
	const [initQuery, changeQueryParams] = useQueryFiltersParams()
	const [selectedFilters, setSelectedFilters] = useState(initQuery || [])

	const dispatch = useDispatch()
	const location = useLocation()

	const onFinalStep = (actionData) => {
		const params = {
			...actionData,
			filters: selectedFilters.filters,
			sorting: selectedFilters.sorting,
		}
		dispatch(createGroupAction(params))
	}

	const loadGroupActionsData = useCallback(
		(params) => {
			const normalizedParams = {
				filters: params?.filters || [],
				pagination: {
					currentPage: params?.pagination?.currentPage || 1,
					limit: params?.pagination?.limit || PAGINATION_LIMIT,
				},
				sorting: params.sorting || [],
			}
			dispatch(setGroupActionsQuery(normalizedParams))
			dispatch(loadGroupActions(normalizedParams))
			setSelectedFilters(normalizedParams)
			changeQueryParams(normalizedParams)
		},
		[changeQueryParams, dispatch],
	)

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: pagination?.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadGroupActionsData(params)
		}
		const onSortingClear = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: selectedFilters.pagination.limit,
			}
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			loadGroupActionsData(params)
		}

		return {
			availableList: entities?.groupActions?.sorting,
			selectedList: selectedFilters.sorting,
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
	}, [entities?.groupActions?.sorting, loadGroupActionsData, pagination?.limit, selectedFilters])

	const onPaginationChange = ({ page, limit }) => {
		const newPagination = {
			currentPage: page,
			limit,
		}
		loadGroupActionsData({
			filters: selectedFilters?.filters,
			sorting: selectedFilters?.sorting,
			pagination: newPagination,
		})
	}

	/**
	 * @description реагируем на добавление фильтра
	 */
	const onFilterAdd = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: pagination?.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadGroupActionsData(params)
	}

	/**
	 * @description реагируем на удаление фильтра
	 */
	const onFilterRemove = (newFilters) => {
		const newPagination = {
			currentPage: 1,
			limit: selectedFilters.pagination.limit,
		}
		const params = {
			filters: newFilters,
			pagination: newPagination,
			sorting: selectedFilters.sorting,
		}
		loadGroupActionsData(params)
	}
	/**
	 * @description реагируем на полную очистку фильтров
	 */
	const onFiltersClear = () => {
		const params = {
			filters: [],
			sorting: selectedFilters.sorting,
			pagination: {
				currentPage: 1,
				limit: selectedFilters?.pagination?.limit,
			},
		}
		loadGroupActionsData(params)
	}

	/**
	 * @description на основе данных ученика и actions из meta собираем объект фильтра, для его добавления
	 *              эмитим событие on_filter_apply, которое прослушивает FilterPanel, и добавляет новый фильтр
	 * @param record
	 */
	const onDeleteItemClick = (record: IGroupActionTableItem) => {
		const userData = entities?.groupActions?.filters?.find((item) => item.field === 'id')
		if (userData) {
			const { name, field, type, api } = userData || {}
			const payload = {
				id: getUid(),
				name: name,
				field: field,
				type: 'isNot',
				value: record?.id,
				typeField: type,
				isHaveApi: isExist(api?.url),
				option: {
					children: `${record?.lastName ? `${record?.lastName} ` : ''}${
						record?.firstName ? `${record?.firstName} ` : ' '
					}${record?.patronymic ? `${record?.patronymic}` : ''}`,
					key: getUid(),
					value: record?.id,
				},
			}
			try {
				const observer = customEventEmitter.Instance
				observer.emit('on_filter_apply', payload || null)
			} catch (e) {
				console.log(e)
			}
		}
	}

	useEffect(() => {
		dispatch(loadActionsList({}))
		const groupActionsParams = {
			filters: selectedFilters.filters,
			pagination: selectedFilters.pagination,
			sorting: selectedFilters.sorting,
		}
		const currentQuery = parseQueryFiltersParamsToObject(location.search)
		// нужно для загрузки query параметров из стор перзистного
		if (
			query &&
			(!currentQuery?.filters || currentQuery?.filters?.length <= 0) &&
			(!currentQuery?.sorting || currentQuery?.sorting?.length <= 0)
		) {
			loadGroupActionsData(query)
		} else {
			loadGroupActionsData(groupActionsParams)
		}
		// ничего не добавлять в deps кроме dispatch!
	}, [dispatch])

	return (
		<div className={'group-actions-create-page'}>
			<div className={'filters-container'}>
				<FiltersPanel
					isFetching={metaFetchStatus === FetchStatus.FETCHING}
					availableFilters={entities?.groupActions?.filters}
					selectsApiConfig={filtersApiConfig}
					selectedFiltersList={selectedFilters.filters}
					onFilterAdd={onFilterAdd}
					onFilterRemove={onFilterRemove}
					onFiltersClear={onFiltersClear}
					serviceName={SERVICE_NAME}
				/>
				<AvailableActionsPopup
					actions={availableActionsList}
					isButtonDisabled={!selectedFilters?.filters?.length}
					onFinalStep={onFinalStep}
				/>
			</div>
			<AppText
				className={'counter'}
				textStyle={'DesktopH4'}
				style={{ marginBottom: 10 }}
				text={`${
					pagination?.count
						? `Всего записей: ${trimSeparator(pagination?.count) || 0}`
						: 'Для начала работы выберите фильтры'
				}`}
			/>
			<div>
				<MainTable
					isLoading={fetchStatus === FetchStatus.FETCHING}
					list={list}
					pagination={{ count: pagination?.count, currentPage: selectedFilters?.pagination?.currentPage }}
					paginationLimit={selectedFilters?.pagination?.limit || PAGINATION_LIMIT}
					onPaginationChange={onPaginationChange}
					sortingConfig={sortingConfig}
					onDeleteItemClick={onDeleteItemClick}
				/>
			</div>
		</div>
	)
}

export default Create
