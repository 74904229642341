import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Col, Table } from 'react-bootstrap'
import '../../../node_modules/font-awesome/scss/font-awesome.scss'

import { TableItem } from '../../Components/Table/TableItem/TableItem'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../store/store'
import { loadProcessesStart, setCurrentPage } from '../../store/actions/processes'
import { IProcessesPayload, ProcessesState } from '../../store/types/processes'
import { FetchStatus } from '../../common/types'
import Skeleton from 'react-loading-skeleton'
import { loadConstants } from '../../store/actions/constants'
import { loadContract } from '../../store/actions/contract'
import { ConstantsState } from '../../store/types/constants'
import { actionSearchChange } from '../../store/actions/search'
import { createPages } from '../../utils/processes'
import './Home.scss'
import { getEducationCostNum } from '../Debts/utils'
import { loadUnreadMessages } from '../../store/actions/chat'
import { ScreenPagination } from '../../Components/ScreenPagination'
import { ProcessesFilters } from '../../Components/ProcessesFilters'
import { useHistory, useLocation } from 'react-router-dom'
import { getUrlFilterParams } from '../../utils/getUrlFilterParams'

interface IHomeProps extends ProcessesState {
	loadProcesses: (payload: IProcessesPayload) => {}
	studentSearchChange: (payload: string) => void
	parentSearchChange: (payload: string) => void
	actionSearchChange: (payload: string) => void
	tariffSearch: string[]
	loadConstants: () => void
	loadContracts: (payload: any) => void
	constants: ConstantsState
	parentSearch: string
	studentSearch: string
	idSearch: string
	schoolSearch: number[]
	actionsSearch: any
	formatSearch: string[]
	staffUsersSearch: number[]
	createdMonthSearch: string
	processTypesSearch: string[]
	gradeSearch: string
}

const pageSize = 20

const tdSkeletonArr = Array.from(Array(4).keys())

const Home: React.FC<IHomeProps> = ({
	loadProcesses,
	constants,
	createProcessesId,
	fetchStatus,
	processes,
	parentSearch,
	studentSearch,
	idSearch,
	actionsSearch,
	tariffSearch,
	schoolSearch,
	formatSearch,
	loadConstants,
	staffUsersSearch,
	createdMonthSearch,
	processTypesSearch,
	gradeSearch,
}) => {
	const [searchItems, setSearchItems] = useState<any>(null)
	const currentPage = useSelector((state: State) => state.processes.currentPage)
	const totalCount = useSelector((state: State) => state.processes.totalCount)
	const contracts = useSelector((state: State) => state.contract.contracts)
	const pagesCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount])
	const pages = useMemo(() => createPages(pagesCount, currentPage), [pagesCount, currentPage])
	const [isFilterClicked, setIsFilterClicked] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		dispatch(loadUnreadMessages())
		if (constants.fetchStatus !== FetchStatus.FETCHED) loadConstants()
	}, [])

	useEffect(() => {
		if (!contracts) {
			dispatch(loadContract({}))
		}
	}, [dispatch, contracts])

	useEffect(() => {
		if (fetchStatus === FetchStatus.FETCHED) {
			setSearchItems(processes)
		}
	}, [fetchStatus, processes])

	const handleUrlFilterParams = useCallback(() => {
		const params = getUrlFilterParams({
			studentSearch: studentSearch || undefined,
			idSearch: idSearch || undefined,
			parentSearch: parentSearch || undefined,
			actionsSearch: actionsSearch?.length ? actionsSearch.join() : undefined,
			schoolSearch: schoolSearch?.length ? schoolSearch.join() : undefined,
			tariffSearch: tariffSearch?.length ? tariffSearch.join() : undefined,
			formatSearch: formatSearch?.length ? formatSearch.join() : undefined,
			staffUsersSearch: staffUsersSearch?.length ? staffUsersSearch.join() : undefined,
			createdMonthSearch: createdMonthSearch || undefined,
			processTypesSearch: processTypesSearch?.length ? processTypesSearch.join() : undefined,
			gradeSearch: gradeSearch || undefined,
		})
		history.replace({ pathname: location.pathname, search: params })
	}, [
		actionsSearch,
		createdMonthSearch,
		formatSearch,
		gradeSearch,
		history,
		idSearch,
		location.pathname,
		parentSearch,
		processTypesSearch,
		schoolSearch,
		staffUsersSearch,
		studentSearch,
		tariffSearch,
	])

	useEffect(() => {
		if (
			(parentSearch.length > 3 ||
				parentSearch.length === 0 ||
				studentSearch.length > 3 ||
				studentSearch.length === 0) &&
			!!constants.constants?.EDUCATION_COST
		) {
			handleUrlFilterParams()
			const timeout = setTimeout(
				() =>
					loadProcesses({
						page: currentPage,
						searchStudent: studentSearch,
						idSearch: idSearch,
						searchParent: parentSearch,
						searchActions: actionsSearch,
						searchTariff: constants.constants?.EDUCATION_COST
							? tariffSearch.map((tariff) =>
									getEducationCostNum(constants.constants?.EDUCATION_COST, tariff),
							  )
							: [],
						searchSchool: schoolSearch,
						searchForm: formatSearch,
						searchStaffUsers: staffUsersSearch,
						searchCreatedMonth: createdMonthSearch,
						searchProcessTypes: processTypesSearch,
						searchGrade: gradeSearch,
					}),
				500,
			)
			return () => clearTimeout(timeout)
		}
	}, [
		parentSearch,
		gradeSearch,
		studentSearch,
		idSearch,
		currentPage,
		tariffSearch,
		schoolSearch,
		formatSearch,
		staffUsersSearch,
		createdMonthSearch,
		processTypesSearch,
		constants.constants?.EDUCATION_COST,
	])

	useEffect(() => {
		const flag = !!actionsSearch?.length
		setIsFilterClicked(flag)
		if (flag || isFilterClicked) {
			handleUrlFilterParams()
			loadProcesses({
				page: currentPage,
				searchStudent: studentSearch,
				idSearch: idSearch,
				searchParent: parentSearch,
				searchActions: actionsSearch,
				searchTariff: constants.constants.EDUCATION_COST
					? tariffSearch.map((tariff) => getEducationCostNum(constants.constants.EDUCATION_COST, tariff))
					: [],
				searchSchool: schoolSearch,
				searchForm: formatSearch,
				searchStaffUsers: staffUsersSearch,
				searchCreatedMonth: createdMonthSearch,
				searchProcessTypes: processTypesSearch,
				searchGrade: gradeSearch,
			})
		}
	}, [actionsSearch])

	useEffect(() => {
		window.scrollTo({
			top: 0,
		})
	}, [currentPage])

	return (
		<Col className="processes__wrapper" md={12} xl={12}>
			<Card className="Recent-Users">
				<Card.Header style={{ zIndex: 1, display: 'flex', justifyContent: 'space-between' }}>
					<Card.Title as="h5">Процессы</Card.Title>

					<div>Общее количество процессов: {totalCount}</div>
				</Card.Header>
				<ProcessesFilters />
				<Card.Body className="py-2 processes__card" style={{ zIndex: 1 }}>
					<Table className="processes__table" responsive hover>
						<thead>
							<tr>
								<th>№</th>
								<th></th>
								<th>Контакты</th>
								{/*<th>Ученик</th>*/}
								{/*<th>Состояние процесса</th>*/}
								<th>Возможные действия</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{fetchStatus === FetchStatus.FETCHED && constants.fetchStatus === FetchStatus.FETCHED ? (
								searchItems !== null &&
								searchItems
									.filter((process) => process?.parents?.length)
									.map((el: any, index: number) => {
										if (
											!createProcessesId.some((id) => {
												return !!el.processes.some((process: any) => process.id === id)
											})
										) {
											return (
												<TableItem
													page={currentPage}
													pageSize={pageSize}
													index={index}
													{...el}
													key={el.id}
													constants={constants.constants}
												/>
											)
										} else {
											return (
												<tr key={index} className="table-item unread mb-3">
													{tdSkeletonArr.map((item, skeletonIndex) => (
														<td key={`skeleton-${skeletonIndex}`}>
															<Skeleton />
														</td>
													))}
												</tr>
											)
										}
									})
							) : (
								<tr className="table-item unread mb-3">
									{tdSkeletonArr.map((item, skeletonIndex) => (
										<td key={`skeleton-${skeletonIndex}`}>
											<Skeleton />
										</td>
									))}
								</tr>
							)}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<ScreenPagination
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				pages={pages}
				pagesCount={pagesCount}
			/>
		</Col>
	)
}

export default connect(
	({ processes, search, constants }: State) => ({ ...processes, ...search, constants }),
	(dispatch) => {
		return {
			loadProcesses: (payload: IProcessesPayload) => {
				return dispatch(
					loadProcessesStart({
						page: payload.page,
						searchStudent: payload.searchStudent,
						idSearch: payload.idSearch,
						searchParent: payload.searchParent,
						searchActions: payload.searchActions,
						searchTariff: payload.searchTariff,
						searchSchool: payload.searchSchool,
						searchForm: payload.searchForm,
						searchStaffUsers: payload.searchStaffUsers,
						searchCreatedMonth: payload.searchCreatedMonth,
						searchProcessTypes: payload.searchProcessTypes,
						searchGrade: payload.searchGrade,
					}),
				)
			},
			actionSearchChange: (payload: string) => {
				return dispatch(actionSearchChange(payload))
			},
			loadConstants: () => {
				return dispatch(loadConstants())
			},
		}
	},
)(Home)
