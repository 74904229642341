import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import { StudentCardState } from '@store/types/studentCard'
import { getType } from 'typesafe-actions'
import {
	loadStudentCardTab,
	onLoadStudentCardTabSuccess,
	onLoadStudentCardTabError,
	loadStudentCardTabContracts,
	onLoadStudentCardTabContractsSuccess,
	onLoadStudentCardTabContractsError,
	loadStudentCardTabProcesses,
	onLoadStudentCardTabProcessesError,
	onLoadStudentCardTabProcessesSuccess,
	addStudentNote,
	loadStudentCardTabNotes,
	onAddStudentNoteError,
	onAddStudentNoteSuccess,
	onLoadStudentCardTabNotesError,
	onLoadStudentCardTabNotesSuccess,
} from '@store/actions/studentCard'
import { normalizeOfferContractsList } from '@frontend-modules/elements'

const studentCardInitialState: StudentCardState = {
	fetchStatus: FetchStatus.INITIAL,
	errors: [],
}

export const studentCardReducer = (state = studentCardInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadStudentCardTab): {
			return {
				...state,
				cards: {
					...state.cards,
					[action.payload.studentId]: {
						...state.cards?.[action.payload.studentId],
						[action.payload.tabName]: {
							...state.cards?.[action.payload.studentId]?.[action.payload.tabName],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabSuccess): {
			const payload = action.payload.payload
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[payload.studentId]: {
						...state.cards[payload.studentId],
						[payload.tabName]: {
							...state.cards[payload.studentId][payload.tabName],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabError): {
			const payload = action.payload.payload
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[payload.studentId]: {
						...state.cards[payload.studentId],
						[payload.tabName]: {
							...state.cards[payload.studentId][payload.tabName],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadStudentCardTabContracts): {
			const studentId = action.payload.student
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards?.[studentId],
						['contracts']: {
							...state.cards?.[studentId]?.['contracts'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabContractsSuccess): {
			const studentId = action.payload.payload.student
			const response = normalizeOfferContractsList(action.payload.response || [])
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['contracts']: {
							...state.cards[studentId]?.['contracts'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabContractsError): {
			const studentId = action.payload.payload.student
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['contracts']: {
							...state.cards[studentId]?.['contracts'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadStudentCardTabProcesses): {
			const studentId = action.payload.filters[0].value
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards?.[studentId],
						['processes']: {
							...state.cards?.[studentId]?.['processes'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabProcessesSuccess): {
			const studentId = action.payload.payload.filters[0].value
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['processes']: {
							...state.cards[studentId]?.['processes'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabProcessesError): {
			const studentId = action.payload.studentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['processes']: {
							...state.cards[studentId]?.['processes'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(loadStudentCardTabNotes): {
			const studentId = action.payload.studentId
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards?.[studentId],
						['notes']: {
							...state.cards?.[studentId]?.['notes'],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabNotesSuccess): {
			const studentId = action.payload.payload.studentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['notes']: {
							...state.cards[studentId]?.['notes'],
							fetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onLoadStudentCardTabNotesError): {
			const studentId = action.payload.payload.studentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['notes']: {
							...state.cards[studentId]?.['notes'],
							fetchStatus: FetchStatus.ERROR,
							errors: error,
						},
					},
				},
			}
		}
		case getType(addStudentNote): {
			const studentId = action.payload.studentId
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards?.[studentId],
						['notes']: {
							...state.cards?.[studentId]?.['notes'],
							addFetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(onAddStudentNoteSuccess): {
			const studentId = action.payload.payload.studentId
			const response = action.payload.response
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['notes']: {
							...state.cards[studentId]?.['notes'],
							addFetchStatus: FetchStatus.FETCHED,
							data: response,
						},
					},
				},
			}
		}
		case getType(onAddStudentNoteError): {
			const studentId = action.payload.payload.studentId
			const error = action.payload.error
			return {
				...state,
				cards: {
					...state.cards,
					[studentId]: {
						...state.cards[studentId],
						['notes']: {
							...state.cards[studentId]?.['notes'],
							addFetchStatus: FetchStatus.ERROR,
							addErrors: error,
						},
					},
				},
			}
		}
		default: {
			return {
				...state,
			}
		}
	}
}
