import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'

export const RefuseOfTrialDays: React.FC<any> = ({ id }) => {
	const dispatch = useDispatch()

	const [refuseReason, setRefuseReason] = useState('')

	const handleRefuseReasonChange = useCallback((event) => {
		setRefuseReason(event.target.value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				process: 'refuse_of_trial_days',
				id,

				parameters: {
					refuse_reason: refuseReason,
				},
			}),
		)
	}, [dispatch, id, refuseReason])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Отказаться от пробных дней</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
							<Form.Label>Введите причину отказа</Form.Label>
							<Form.Control
								value={refuseReason}
								onChange={handleRefuseReasonChange}
								placeholder="Введите причину отказа"
							/>
						</Form.Group>

						<Button disabled={!refuseReason} onClick={submitButtonPressHandler} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
