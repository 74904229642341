import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { getType } from 'typesafe-actions'
import { FuncEpic } from '@/common/types'
import {
	loadMoreParents,
	loadParents,
	onLoadMoreParentsError,
	onLoadMoreParentsSuccess,
	onLoadParentsError,
	onLoadParentsSuccess,
} from '@store/actions/parents'

const loadParentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadParents)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentList(payload)).pipe(
				switchMap((response) => {
					return of(onLoadParentsSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadParentsError(err))
				}),
			)
		}),
	)
}

const loadMoreParentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMoreParents)),
		switchMap(({ payload }) => {
			return from(deps.parentService.loadParentList(payload)).pipe(
				switchMap((response) => {
					return of(onLoadMoreParentsSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadMoreParentsError(err))
				}),
			)
		}),
	)
}

export const parentsEpics = combineEpics(loadParentsEpic, loadMoreParentsEpic)
