import { useEffect, useState } from 'react'
import { HARD_MODAL_ENUMS, URL_SEARCH_ENUM } from './Modals.config'
import { useLocation } from 'react-router-dom'
let timeout

/**
 * @description - хук получения состояния сложной модалки
 */
export const useGetPopupsState = () => {
	const { search } = useLocation()

	const [rawPopups, setRawPopups] = useState([])

	const [mountedPopups, setMountedPopups] = useState(rawPopups)

	useEffect(() => {
		const query = new URLSearchParams(search)
		const queryList = JSON.parse(query.get(URL_SEARCH_ENUM.MODALS)) || []
		const currentPopups = queryList?.map((item) => {
			if (HARD_MODAL_ENUMS[item.type.toUpperCase()]) {
				return item
			}
		})
		setRawPopups(currentPopups)
	}, [search])

	useEffect(() => {
		if (rawPopups) {
			timeout && clearTimeout(timeout)
			setMountedPopups(rawPopups)
		} else {
			timeout = setTimeout(() => {
				setMountedPopups([])
			}, 300)
		}
	}, [rawPopups])

	useEffect(() => {
		return () => {
			timeout && clearTimeout(timeout)
		}
	}, [])

	return {
		mountedPopups,
		popups: rawPopups,
	}
}
