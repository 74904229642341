import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap, } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { loadTrialDays, loadTrialDaysError, loadTrialDaysSuccess } from '../actions/trialDays'

const loadTrialDaysEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadTrialDays)),
		switchMap(({ payload: { student_id } }: ActionType<typeof loadTrialDays>) => {
			return from(deps.processesDataProvider.loadTrialDays(student_id)).pipe(
				switchMap(trialDays => {
					return of(loadTrialDaysSuccess(trialDays))
				}),
				catchError(err => {
					return of(loadTrialDaysError(err))
				})
			)
		})
	)
}

export const trialDaysEpics = combineEpics(loadTrialDaysEpic)