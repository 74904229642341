import React from 'react'

import './DownloadButton.scss'

const DownloadButton: React.FC<{ url?: string }> = ({url}) => <a className="icon--green" download={true} target="_blank" href={url} rel="noreferrer">
	<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.68489 6.2508H15V19.0611C15 19.582 14.5852 19.9968 14.0643 19.9968H0.935691C0.414791 19.9968 0 19.582 0 19.0611V0.935691C0 0.414791 0.414791 0 0.935691 0H8.7492V5.31511C8.7492 5.82637 9.17363 6.2508 9.68489 6.2508ZM11.2958 12.5016H8.7492V9.3762C8.7492 9.02894 8.46945 8.7492 8.12219 8.7492H6.86817C6.5209 8.7492 6.24116 9.02894 6.24116 9.3762V12.5016H3.70418C3.14469 12.5016 2.86495 13.1768 3.26045 13.5723L7.02251 17.3151C7.28296 17.5756 7.69775 17.5756 7.9582 17.3151L11.7203 13.5723C12.135 13.1768 11.8553 12.5016 11.2958 12.5016ZM15 4.76527V5.00643H10.0032V0H10.2444C10.4952 0 10.7363 0.096463 10.91 0.270096L14.7299 4.09968C14.9035 4.27331 15 4.51447 15 4.76527Z"
			fill="#78C057"/>
	</svg>
</a>

export default DownloadButton
