import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import {
	addStudentsToCourseGroupError,
	addStudentsToCourseGroupStart,
	addStudentsToCourseGroupSuccess,
	bookAdditionalCoursesError,
	bookAdditionalCoursesStart,
	bookAdditionalCoursesSuccess,
	changeCourseGroupError,
	changeCourseGroupStart,
	changeCourseGroupSuccess,
	createCourseGroupError,
	createCourseGroupStart,
	createCourseGroupSuccess,
	loadAdditionalCoursesError,
	loadAdditionalCoursesStart,
	loadAdditionalCoursesSuccess,
	loadAllCourseGroupsError,
	loadAllCourseGroupsStart,
	loadAllCourseGroupsSuccess,
	loadAvailableCoursesError,
	loadAvailableCoursesStart,
	loadAvailableCoursesSuccess,
	loadCourseGroupsError,
	loadCourseGroupsStart,
	loadCourseGroupsSuccess,
	loadCourses,
	loadCoursesError,
	loadCoursesSuccess,
	loadEducationPlansError,
	loadEducationPlansStart,
	loadEducationPlansSuccess,
	loadForeignLanguagesCourses,
	loadForeignLanguagesCoursesError,
	loadForeignLanguagesCoursesSuccess,
	loadLmsAddCoursesError,
	loadLmsAddCoursesStart,
	loadLmsAddCoursesSuccess,
	loadLmsCoursesError,
	loadLmsCoursesStart,
	loadLmsCoursesSuccess,
	loadTeachersError,
	loadTeachersStart,
	loadTeachersSuccess,
	patchAdditionalCourseError,
	patchAdditionalCourseStart,
	patchAdditionalCourseSuccess,
	removeStudentsCourseGroup,
	removeStudentsCourseGroupError,
	removeStudentsCourseGroupSuccess,
} from '../actions/courses'
import { Action } from '../store'
import {
	AdditionalCoursesState,
	AvailableCoursesState,
	CoursesState,
	ForeignLanguagesCoursesState,
} from '../types/courses'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const initialState: CoursesState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	courses: null,
	lmsCourses: [],
	addStudentsToCourseGroupStatus: FetchStatus.INITIAL,
	addStudentsToCourseGroupError: null,
}

export const reducer = (state: CoursesState = initialState, action: Action) => {
	switch (action.type) {
	case getType(addStudentsToCourseGroupStart): {
		return {
			...state,
			addStudentsToCourseGroupStatus: FetchStatus.FETCHING,
		}
	}
	case getType(addStudentsToCourseGroupSuccess): {
		return {
			...state,
			addStudentsToCourseGroupStatus: FetchStatus.FETCHED,
		}
	}
	case getType(addStudentsToCourseGroupError): {
		return {
			...state,
			addStudentsToCourseGroupStatus: FetchStatus.ERROR,
			addStudentsToCourseGroupError: action.payload,
		}
	}

	case getType(loadLmsCoursesStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}
	case getType(loadLmsCoursesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			lmsCourses: action.payload,
		}
	}
	case getType(loadLmsCoursesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(loadCourses): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadCoursesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			courses: action.payload,
		}
	}

	case getType(loadCoursesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const availableCourses = (state: AvailableCoursesState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadAvailableCoursesStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadAvailableCoursesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			courses: action.payload,
		}
	}

	case getType(loadAvailableCoursesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const foreignLanguagesCourses = (state: ForeignLanguagesCoursesState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadForeignLanguagesCourses): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadForeignLanguagesCoursesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			courses: action.payload,
		}
	}

	case getType(loadForeignLanguagesCoursesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

const additionalCoursesInitialState: AdditionalCoursesState = {
	fetchStatus: FetchStatus.INITIAL,
	error: null,
	courses: null,
	lmsCourses: null,
	loadLmsCoursesStatus: FetchStatus.INITIAL,
	loadLmsCoursesError: null,
	patchCourseStatus: FetchStatus.INITIAL,
	patchCourseError: null,
	loadCourseGroupsStatus: FetchStatus.INITIAL,
	courseGroups: null,
	loadCourseGroupsError: null,
	bookAdditionalCoursesStatus: FetchStatus.INITIAL,
	bookAdditionalCoursesError: null,
	bookAdditionalCoursesUrls: undefined,
	allCourseGroups: null,
	loadAllCourseGroupsStatus: FetchStatus.INITIAL,
	loadAllCourseGroupsError: null,
	educationPlans: null,
	loadEducationPlansStatus: FetchStatus.INITIAL,
	loadEducationPlansError: null,
	changeCourseGroupStatus: FetchStatus.INITIAL,
	changeCourseGroupError: null,
	createCourseGroupStatus: FetchStatus.INITIAL,
	createCourseGroupError: null,
	teachers: null,
	loadTeachersStatus: FetchStatus.INITIAL,
	loadTeachersError: null,
	removeStudentsCourseGroupStatus: FetchStatus.INITIAL,
	removeStudentsCourseGroupError: null,
}

export const additionalCourses = (state = additionalCoursesInitialState, action: Action) => {
	switch (action.type) {
	case getType(loadAdditionalCoursesStart): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadAdditionalCoursesSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			courses: action.payload,
			error: null,
		}
	}

	case getType(loadAdditionalCoursesError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(loadLmsAddCoursesStart): {
		return {
			...state,
			loadLmsCoursesStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadLmsAddCoursesSuccess): {
		return {
			...state,
			loadLmsCoursesStatus: FetchStatus.FETCHED,
			lmsCourses: action.payload,
			loadLmsCoursesError: null,
		}
	}

	case getType(loadLmsAddCoursesError): {
		return {
			...state,
			loadLmsCoursesStatus: FetchStatus.ERROR,
			loadLmsCoursesError: action.payload,
		}
	}

	case getType(loadEducationPlansStart): {
		return {
			...state,
			loadEducationPlansStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadEducationPlansSuccess): {
		return {
			...state,
			loadEducationPlansStatus: FetchStatus.FETCHED,
			educationPlans: action.payload,
			loadEducationPlansError: null,
		}
	}

	case getType(loadEducationPlansError): {
		return {
			...state,
			loadEducationPlansStatus: FetchStatus.ERROR,
			loadEducationPlansError: action.payload,
		}
	}

	case getType(changeCourseGroupStart): {
		return {
			...state,
			patchCourseStatus: FetchStatus.FETCHING,
		}
	}

	case getType(changeCourseGroupSuccess): {
		return {
			...state,
			changeCourseGroupStatus: FetchStatus.FETCHED,
			changeCourseGroupError: null,
		}
	}

	case getType(changeCourseGroupError): {
		return {
			...state,
			changeCourseGroupStatus: FetchStatus.ERROR,
			changeCourseGroupError: action.payload,
		}
	}

	case getType(removeStudentsCourseGroup): {
		return {
			...state,
			removeStudentsCourseGroupStatus: FetchStatus.FETCHING,
		}
	}

	case getType(removeStudentsCourseGroupSuccess): {
		return {
			...state,
			courseGroups: state.courseGroups.map((group) =>
				action.payload.courseGroups.includes(group.id)
					? {
						...group,
						students: group.students.filter(
							(student) => !action.payload.students.includes(student.externalId),
						),
						studentsQuantity: group.studentsQuantity - action.payload.students?.length,
					}
					: group,
			),
			removeStudentsCourseGroupStatus: FetchStatus.FETCHED,
			removeStudentsCourseGroupError: null,
		}
	}

	case getType(removeStudentsCourseGroupError): {
		return {
			...state,
			removeStudentsCourseGroupStatus: FetchStatus.ERROR,
			removeStudentsCourseGroupError: action.payload,
		}
	}

	case getType(createCourseGroupStart): {
		return {
			...state,
			createCourseGroupStatus: FetchStatus.FETCHING,
		}
	}

	case getType(createCourseGroupSuccess): {
		return {
			...state,
			createCourseGroupStatus: FetchStatus.FETCHED,
			createCourseGroupError: null,
		}
	}

	case getType(createCourseGroupError): {
		return {
			...state,
			createCourseGroupStatus: FetchStatus.ERROR,
			createCourseGroupError: action.payload,
		}
	}

	case getType(loadTeachersStart): {
		return {
			...state,
			loadTeachersStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadTeachersSuccess): {
		return {
			...state,
			loadTeachersStatus: FetchStatus.FETCHED,
			loadTeachersError: null,
			teachers: action.payload,
		}
	}

	case getType(loadTeachersError): {
		return {
			...state,
			loadTeachersStatus: FetchStatus.ERROR,
			loadTeachersError: action.payload,
		}
	}

	case getType(patchAdditionalCourseStart): {
		return {
			...state,
			patchCourseStatus: FetchStatus.FETCHING,
		}
	}

	case getType(patchAdditionalCourseSuccess): {
		return {
			...state,
			patchCourseStatus: FetchStatus.FETCHED,
			patchCourseError: null,
			courses: state.courses.map((course) => (course?.id === action.payload?.id ? action.payload : course)),
		}
	}

	case getType(patchAdditionalCourseError): {
		return {
			...state,
			patchCourseStatus: FetchStatus.ERROR,
			patchCourseError: action.payload,
		}
	}

	case getType(loadCourseGroupsStart): {
		return {
			...state,
			loadCourseGroupsStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadCourseGroupsSuccess): {
		return {
			...state,
			loadCourseGroupsStatus: FetchStatus.FETCHED,
			loadCourseGroupsError: null,
			courseGroups: action.payload,
		}
	}

	case getType(loadCourseGroupsError): {
		return {
			...state,
			loadCourseGroupsStatus: FetchStatus.ERROR,
			loadCourseGroupsError: action.payload,
		}
	}

	case getType(loadAllCourseGroupsStart): {
		return {
			...state,
			loadAllCourseGroupsStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadAllCourseGroupsSuccess): {
		return {
			...state,
			loadAllCourseGroupsStatus: FetchStatus.FETCHED,
			loadAllCourseGroupsError: null,
			allCourseGroups: action.payload,
		}
	}

	case getType(loadAllCourseGroupsError): {
		return {
			...state,
			loadAllCourseGroupsStatus: FetchStatus.ERROR,
			loadAllCourseGroupsError: action.payload,
		}
	}

	case getType(bookAdditionalCoursesStart): {
		return {
			...state,
			bookAdditionalCoursesStatus: FetchStatus.FETCHING,
		}
	}

	case getType(bookAdditionalCoursesSuccess): {
		return {
			...state,
			bookAdditionalCoursesStatus: FetchStatus.FETCHED,
			bookAdditionalCoursesError: null,
			bookAdditionalCoursesUrls: action.payload
		}
	}

	case getType(bookAdditionalCoursesError): {
		return {
			...state,
			bookAdditionalCoursesStatus: FetchStatus.ERROR,
			bookAdditionalCoursesError: action.payload,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const courses: any = persistReducer(
	{
		key: 'AdminPanel::courses',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	reducer,
)
