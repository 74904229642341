import { FetchStatus } from '../../common/types'

export interface ContractsState {
    fetchStatus: FetchStatus;
    count: number;
    page: number;
    contracts: Array<any>;
    error: unknown;
    search: {
        counterparty: any;
        student: any;
        parent_seen: null | boolean;
        kontur_signed: null | boolean;
        number: any | null;
        status: null | any;
        education_cost: any | null;
        agreed_month: { value: string } | null;
        termination_date: string
    };
    deleteContractStatus: FetchStatus;
}

export enum ContractStatus {
    TERMINATED = 'terminated',
    IN_PROGRESS = 'in_progress',
    PROLONG = 'prolong',
    ACTIVE = 'active',
}
