/** @description - очищаем базу данных */
export const deleteAllDatabases = async () => {
	if (!(indexedDB as any).databases) {
		console.error("Your browser doesn't support indexedDB.databases()")
		return
	}

	try {
		const databases = await (indexedDB as any).databases()
		databases.forEach((dbInfo) => {
			indexedDB.deleteDatabase(dbInfo.name)
		})
	} catch (err) {
		console.error('Error fetching database list:', err)
	}
}
