import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	loadActionsList,
	onLoadActionsListError,
	onLoadActionsListSuccess,
	loadGroupActions,
	onLoadGroupActionsError,
	onLoadGroupActionsSuccess,
	createGroupAction,
	onCreateGroupActionSuccess,
	onCreateGroupActionError,
	loadActionsTypeList,
	onLoadActionsTypeListSuccess,
	onLoadActionsTypeListError,
	loadActionsTypeNestedTable,
	onLoadActionsTypeNestedTableSuccess,
	onLoadActionsTypeNestedTableError,
	cancelActionByType,
	onCancelActionByTypeSuccess,
	onCancelActionByTypeError,
	editActionByType,
	onEditActionByTypeSuccess,
	onEditActionByTypeError,
} from '@store/actions/groupActions'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'
import { showSuccessToast } from '@utils'
import { PAGINATION_LIMIT } from '@components/group-actions/general/CollapseList/CollapseList.config'

const loadGroupActionsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadGroupActions)),
		switchMap(({ payload }) => {
			if (!payload.filters.length) {
				return of(
					onLoadGroupActionsSuccess({
						pagination: { count: 0, currentPage: 1, limit: payload.pagination.limit, maxPage: 1 },
						results: [],
					}),
				)
			}
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
				sorting: payload?.sorting,
			}
			return from(deps.groupActionsService.loadGroupActionsTable(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadGroupActionsSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadGroupActionsError(err))
				}),
			)
		}),
	)
}
const loadActionsListEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadActionsList)),
		switchMap(() => {
			return from(deps.groupActionsService.loadActionsList()).pipe(
				switchMap((response) => {
					return of(onLoadActionsListSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadActionsListError(err))
				}),
			)
		}),
	)
}
const createGroupActionEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createGroupAction)),
		switchMap(({ payload }) => {
			const { filters, ...otherParams } = payload
			const normalizeFilters = filters?.map((item) => {
				return {
					field: item.field,
					value: item.option?.value || item?.value,
					label: item.option?.children || item?.value,
					option: item.type,
					condition: item?.condition,
				}
			})
			const normalizeParams = { filters: normalizeFilters, ...otherParams }
			return from(deps.groupActionsService.createGroupAction(normalizeParams)).pipe(
				switchMap((response) => {
					showSuccessToast({ message: 'Действие успешно создано' })
					return of(onCreateGroupActionSuccess(response))
				}),
				catchError((err) => {
					return of(onCreateGroupActionError(err))
				}),
			)
		}),
	)
}
const loadActionsTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadActionsTypeList)),
		switchMap(({ payload }) => {
			return from(deps.groupActionsService.loadActionsByStatus(payload)).pipe(
				switchMap((response) => {
					return of(
						onLoadActionsTypeListSuccess({
							payload,
							response,
						}),
					)
				}),
				catchError((err) => {
					return of(
						onLoadActionsTypeListError({
							payload,
							err,
						}),
					)
				}),
			)
		}),
	)
}
const loadActionsTypeNestedTableEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadActionsTypeNestedTable)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: payload.filters,
				sorting: payload?.sorting,
				limit: payload?.limit,
				currentPage: payload?.currentPage,
				studentsId: payload?.studentsId,
				id: payload?.id,
			}
			return from(deps.groupActionsService.loadGroupActionsTable(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(
						onLoadActionsTypeNestedTableSuccess({
							payload,
							response,
						}),
					)
				}),
				catchError((err) => {
					return of(
						onLoadActionsTypeNestedTableError({
							payload,
							err,
						}),
					)
				}),
			)
		}),
	)
}

const cancelActionByTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(cancelActionByType)),
		switchMap(({ payload }) => {
			return from(deps.groupActionsService.createGroupAction(payload)).pipe(
				switchMap((response) => {
					showSuccessToast({ message: 'Действие успешно отменено' })
					return of(
						onCancelActionByTypeSuccess({
							payload,
							response,
						}),
						loadActionsTypeList({
							status: 'planned',
							limit: 20,
							currentPage: 1,
							isMore: false,
						}),
					)
				}),
				catchError((err) => {
					return of(
						onCancelActionByTypeError({
							payload,
							err,
						}),
					)
				}),
			)
		}),
	)
}

const editActionByTypeEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(editActionByType)),
		switchMap(({ payload }) => {
			return from(deps.groupActionsService.createGroupAction(payload)).pipe(
				switchMap((response) => {
					showSuccessToast({ message: 'Действие успешно изменено' })
					return of(
						onEditActionByTypeSuccess({
							payload,
							response,
						}),
					)
				}),
				catchError((err) => {
					return of(
						onEditActionByTypeError({
							payload,
							err,
						}),
					)
				}),
			)
		}),
	)
}

export const groupActionsEpics = combineEpics(
	loadGroupActionsEpic,
	loadActionsListEpic,
	createGroupActionEpic,
	loadActionsTypeEpic,
	loadActionsTypeNestedTableEpic,
	cancelActionByTypeEpic,
	editActionByTypeEpic,
)
