import React, { useEffect, useState } from 'react'
import { NotAllowedModal } from './NotAllowedModal'

import { NewAxiosResponder } from '@utils/http/HttpClient/HttpClient'
import { OldAxiosResponder } from '@/api/axios-init'

const HttpInterceptor = () => {
	const [isShowNotAllowedModal, setIsShowNotAllowedModal] = useState(false)

	const on403Logout = () => {
		setIsShowNotAllowedModal(true)
	}

	useEffect(() => {
		NewAxiosResponder(on403Logout)
		OldAxiosResponder(on403Logout)
	}, [])

	return (
		<>
			{isShowNotAllowedModal ? (
				<NotAllowedModal setIsShowModal={setIsShowNotAllowedModal} isShowModal={isShowNotAllowedModal} />
			) : null}
		</>
	)
}

export default HttpInterceptor
