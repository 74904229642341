import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadStaffUsers, loadStaffUsersError, loadStaffUsersSuccess } from '../actions/staffUsers'
import { Action } from '../store'
import { StaffUsersState } from '../types/staffUsers'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	users: null
}

export const staffUsers = (state: StaffUsersState = initialState, action: Action) => {

	switch (action.type) {
	case getType(loadStaffUsers): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadStaffUsersSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			users: action.payload
		}
	}

	case getType(loadStaffUsersError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
