import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	changeAssignmentInfo,
	loadAssignmentInfo,
	loadAssignments,
	loadMorePOFiles,
	onChangeAssignmentInfoError,
	onChangeAssignmentInfoSuccess,
	onLoadAssignmentInfoError,
	onLoadAssignmentInfoSuccess,
	onLoadAssignmentsError,
	onLoadAssignmentsSuccess,
	onLoadMorePOFilesError,
	onLoadMorePOFilesSuccess,
} from '@store/actions/paymentsAssignments'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadPaymentsAssignmentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAssignments)),
		switchMap(({ payload }) => {
			const { fetchingTypes, ...restAssignmentLoad } = payload
			const normalizedAssignmentPayload = {
				...restAssignmentLoad,
				filters: normalizeFilterPayload(restAssignmentLoad.filters),
			}
			return from(deps.paymentsService.loadAssignmentsList(normalizedAssignmentPayload)).pipe(
				switchMap((response) => {
					return of(onLoadAssignmentsSuccess({ ...response, fetchingTypes }))
				}),
				catchError((err) => {
					return of(onLoadAssignmentsError({ ...err, fetchingTypes }))
				}),
			)
		}),
	)
}

const loadMorePaymentsPOFilesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMorePOFiles)),
		switchMap(({ payload }) => {
			const { fetchingTypes, ...restAssignmentLoad } = payload
			const normalizedAssignmentPayload = {
				...restAssignmentLoad,
				filters: normalizeFilterPayload(restAssignmentLoad.filters),
			}
			return from(deps.paymentsService.loadAssignmentsList(normalizedAssignmentPayload)).pipe(
				switchMap((response) => {
					return of(onLoadMorePOFilesSuccess({ ...response, fetchingTypes }))
				}),
				catchError((err) => {
					return of(onLoadMorePOFilesError({ ...err, fetchingTypes }))
				}),
			)
		}),
	)
}

const loadPaymentsAssignmentsInfoEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAssignmentInfo)),
		switchMap(({ payload }) => {
			return from(deps.paymentsService.loadAssignmentInfo(payload.id, payload)).pipe(
				switchMap((response) => {
					return of(onLoadAssignmentInfoSuccess({ id: payload.id, response }))
				}),
				catchError((err) => {
					return of(onLoadAssignmentInfoError({ id: payload.id, err }))
				}),
			)
		}),
	)
}

const changePaymentsAssignmentsInfoEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(changeAssignmentInfo)),
		switchMap(({ payload }) => {
			const { paymentAssignmentId } = payload
			return from(deps.paymentsService.crudPay(payload)).pipe(
				switchMap((response) => {
					if (response?.response?.status === 400) {
						return of(
							onChangeAssignmentInfoError({
								id: paymentAssignmentId,
								response: response?.response?.data,
							}),
						)
					} else {
						return of(onChangeAssignmentInfoSuccess({ id: paymentAssignmentId }))
					}
				}),
				catchError((err) => {
					return of(onChangeAssignmentInfoError({ id: paymentAssignmentId, err }))
				}),
			)
		}),
	)
}

export const paymentsAssignmentsEpics = combineEpics(
	loadPaymentsAssignmentsEpic,
	loadPaymentsAssignmentsInfoEpic,
	changePaymentsAssignmentsInfoEpic,
	loadMorePaymentsPOFilesEpic,
)
