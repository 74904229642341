import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadContract } from '../../../store/actions/contract'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { ContractStatus } from '../../../store/types/contracts'

export const Component: React.FC<any> = ({ setConturUrl, id, student_id, action, parent_id }) => {
	const [contur, setContur] = useState('')
	const dispatch = useDispatch()
	const { fetchStatus, contracts } = useSelector((store: State) => store.contract)
	const [selectedContract, setSelectedContract] = useState<any>(null)

	useEffect(() => {
		dispatch(
			loadContract({
				student: student_id,
				parent: parent_id,
				process_id: id,
			}),
		)
	}, [id, parent_id, student_id])

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	const submitButtonPressHandler = () => {
		setConturUrl({
			process: action,
			id: id,
			parameters: {
				kontur_sign_url: contur,
				id: selectedContract?.value,
			},
		})
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Указать ссылку на контур</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex mb-3">
							<Form.Control
								value={contur}
								onChange={(e) => setContur(e.target.value)}
								type="text"
								placeholder="Введите ссылку"
							/>
						</div>

						{fetchStatus === FetchStatus.FETCHED ? (
							<div className="d-flex mb-3">
								<Form.Group>
									<Form.Label>Выберите договор</Form.Label>
									<Select
										placeholder="Выберите договор"
										options={contracts
											.filter(
												(contract) =>
													!contract?.kontur_sign_url &&
                                                    contract?.status !== ContractStatus.PROLONG,
											)
											.map((contract) => ({
												label: contract.number,
												value: contract.id,
											}))}
										value={selectedContract}
										onChange={selectedContractChangeCallback}
									/>
								</Form.Group>
							</div>
						) : (
							<div className="mb-3">
								<Skeleton height={50} />
							</div>
						)}
						<Button
							disabled={!selectedContract?.value || !contur}
							onClick={submitButtonPressHandler}
							variant="primary"
						>
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const SetConturUrl = connect(
	({ processes }: State, ownProps: any) => ({ ...ownProps }),
	(dispatch) => {
		return {
			setConturUrl: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
		}
	},
)(Component)
