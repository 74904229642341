import React, { useEffect, useMemo } from 'react'
import { AppHardModalButton, AppTable } from '@frontend-modules/ui-kit'
import { formatDate } from '@frontend-modules/frontend-utils'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import { PAGINATION_LIMIT } from './UserCardPopupFrozenContractsTable.config'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'

/**
 * @description - таблица графика заморозок раздела "лицевой счет"
 * @param props
 * @constructor
 */
const UserCardPopupFrozenContractsTable = (props) => {
	const { tableData, id, isLoading, onLoad } = props
	const { results: list, pagination } = tableData

	const columns = useMemo(() => {
		return [
			{
				title: 'Дата',
				dataIndex: 'date',
				key: 'date',
				render: (_, record) => {
					return `${formatDate(record?.date) || '-'}`
				},
			},
			{
				title: 'Сумма',
				dataIndex: 'amount',
				key: 'amount',
				render: (_, record) => {
					return `${trimSeparator(record?.amount) || '-'}`
				},
			},
			{
				title: 'Договор',
				dataIndex: 'contract',
				key: 'contract',
			},
			{
				title: 'Ученик',
				dataIndex: 'student',
				key: 'student',
				render: (_, record) => {
					return (
						<AppHardModalButton
							query={{ type: HARD_MODAL_ENUMS.USER_CARD, id: record?.studentId, userType: 'student' }}
							buttonType={'link'}
							label={record?.student}
						/>
					)
				},
			},
		]
	}, [])

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		const params = {
			parentId: id,
			currentPage: 1,
			limit: limit || PAGINATION_LIMIT,
		}
		onLoad?.(params)
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			const params = {
				parentId: id,
				limit: pagination?.paginationLimit || PAGINATION_LIMIT,
				currentPage: page,
			}
			onLoad?.(params)
		}
	}

	useEffect(() => {
		const params = {
			parentId: id,
			currentPage: 1,
			limit: pagination?.paginationLimit || PAGINATION_LIMIT,
		}
		onLoad?.(params)
	}, [id])

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isCanDeleteRow={false}
			isNeedNumbering={true}
			dataSource={list}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={{
				total: pagination?.count,
				pageSize: pagination?.limit || PAGINATION_LIMIT,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				locale: { items_per_page: 'записей ' },
				onChange: onPaginationChangeLocal,
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			rowKey={(record, index) => `${index}`}
			sortingConfig={{}}
			titleSortingType={'customSorting'}
		/>
	)
}

export default UserCardPopupFrozenContractsTable
