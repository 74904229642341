import { ActionType, createAction } from 'typesafe-actions'

export const resetProfileState = createAction('@profile/RESET')<any>()
export const loadProfile = createAction('@profile/LOAD/START')<any>()
export const loadProfileSuccess = createAction('@profile/LOAD/SUCCESS')<any>()
export const loadProfileError = createAction('@profile/LOAD/ERROR')<any>()

export type ProfileActions =
	| ActionType<typeof loadProfile>
	| ActionType<typeof loadProfileSuccess>
	| ActionType<typeof loadProfileError>
	| ActionType<typeof resetProfileState>
