import React, { FC, useEffect, useMemo } from 'react'
import { AppHardModalButton, AppTable, AppText } from '@frontend-modules/ui-kit'
import { INestedTableProps } from './NestedTable.types'
import './NestedTable.scss'
import { AccessHelper } from '@frontend-modules/access-controller'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'
import { PAGINATION_LIMIT } from './NestedTable.config'
import { FetchStatus } from '@/common/types'
import { trimSeparator } from '@frontend-modules/frontend-utils'
/**
 * @description - вложенная таблица групповых действий
 * @constructor
 */
const NestedTable: FC<INestedTableProps> = (props) => {
	const { data, type, onLoadTable } = props

	const { fetchStatus, list, pagination } =
		useSelector((state: State) => state.groupActions?.[type]?.tables?.[data?.id]) || {}
	const { entities } = useSelector((state: State) => state.meta)

	const loadNestedTableData = (newPagination) => {
		const payload = { id: data.id, status: type, studentsId: data.studentIds, ...newPagination }
		onLoadTable(payload)
	}

	useEffect(() => {
		onLoadTable({ currentPage: 1, limit: PAGINATION_LIMIT })
	}, [])

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			loadNestedTableData?.({ currentPage: page, limit: pagination.limit })
		}
	}

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: pagination?.limit,
			}
			const params = {
				// filters: data.selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			// loadGroupActionsData(params)
		}
		const onSortingClear = (newSorting) => {
			const newPagination = {
				currentPage: 1,
				limit: PAGINATION_LIMIT,
			}
			const params = {
				// filters: selectedFilters.filters,
				sorting: newSorting,
				pagination: newPagination,
			}
			// loadGroupActionsData(params)
		}

		return {
			availableList: entities?.groupActions?.sorting,
			selectedList: [],
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
		// }, [entities?.groupActions?.sorting, loadGroupActionsData, pagination?.limit, selectedFilters])
	}, [entities?.groupActions?.sorting, pagination?.limit])

	const columns = useMemo(() => {
		return AccessHelper.filterList([
			{
				title: 'Ученик',
				dataIndex: 'student',
				key: 'student',
				render: (_, record) => (
					<AppHardModalButton
						buttonType={'link'}
						label={`${record?.lastName || ''} ${record?.firstName || ''} ${record?.patronymic || ''}`}
						query={{
							type: HARD_MODAL_ENUMS.USER_CARD,
							id: record?.id,
							userType: 'student',
						}}
					/>
				),
			},
			{
				title: 'Родитель',
				dataIndex: 'parent',
				key: 'parent',
				render: (_, record) => (
					<div>
						{record?.parents?.map((item, index) => (
							<AppHardModalButton
								buttonType={'link'}
								key={item.id}
								label={`${index + 1}. ${item?.lastName || ''} ${item?.firstName || ''} ${
									item?.patronymic || ''
								}`}
								query={{
									type: HARD_MODAL_ENUMS.USER_CARD,
									id: item?.id,
									userType: 'parent',
								}}
							/>
						))}
					</div>
				),
			},
			{
				title: 'Параллель',
				dataIndex: 'parallel',
				key: 'parallel',
			},
			{
				title: 'Класс',
				dataIndex: 'class',
				key: 'class',
				render: (_, record) => (
					<div>
						{record?.studentGroups?.map((item, index) => (
							<AppText text={`${index + 1}. ${item.label}`} key={`${item.value}-${index}`} />
						))}
					</div>
				),
			},
			{
				title: 'Школа',
				dataIndex: 'currentSchool',
				key: 'currentSchool',
			},
			{
				title: 'Тариф',
				dataIndex: 'tariff',
				key: 'tariff',
				render: (_, record) => (
					<div>
						{record?.tariffs?.map((item, index) => (
							<AppText text={`${index + 1}. ${item.title}`} key={`${item.id}-${index}`} />
						))}
					</div>
				),
			},
		])
	}, [])

	const listWithKey = useMemo(
		() =>
			list?.map((item) => {
				return {
					...item,
					key: item.id,
				}
			}),
		[list],
	)

	return (
		<>
			<AppText
				className={'counter'}
				textStyle={'DesktopH4'}
				text={`Всего записей: ${
					fetchStatus === FetchStatus.FETCHING && !pagination?.count
						? ''
						: trimSeparator(pagination?.count) || 0
				}`}
			/>
			<AppTable
				isLoading={fetchStatus === FetchStatus.FETCHING}
				columns={columns}
				isCanDeleteRow={type === 'planned'}
				isNeedNumbering={true}
				dataSource={listWithKey}
				locale={{ emptyText: 'Ничего не найдено' }}
				pagination={{
					total: pagination?.count,
					pageSize: pagination?.limit || PAGINATION_LIMIT,
					defaultCurrent: 1,
					current: pagination?.currentPage,
					onChange: onPaginationChangeLocal,
					showSizeChanger: false,
					locale: { items_per_page: 'записей ' },
				}}
				rowKey={(record) => record.id}
				sortingConfig={sortingConfig}
				titleSortingType={'customSorting'}
				className={'group-actions-nested-table'}
			/>
		</>
	)
}

export default NestedTable
