import React, { FC, useMemo } from 'react'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import { AppSkeleton, AppText } from '@frontend-modules/ui-kit'
import './UserCardPopupTabPersonalAccount.scss'
import { UserCardPopupFrozenContractsTable, UserCardPopupHistoryTable } from '@components/user'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import {
	loadParentCardTabPersonalAccountBalance,
	loadParentCardTabPersonalAccountHistory,
} from '@store/actions/parentCard'
import { TUserCardPopupTabPersonalAccount } from './UserCardPopupTabPersonalAccount.types'

/**
 * @description - таб Лицевой счет
 * @param props
 * @constructor
 */
const UserCardPopupTabPersonalAccount: FC<TUserCardPopupTabPersonalAccount> = (props) => {
	const { id } = props

	const dispatch = useDispatch()

	const personalAccount = useSelector((state: State) => state.parentCardNew?.cards[id]?.personalAccount)

	const isLoadingBalance = personalAccount?.balance?.fetchStatus === FetchStatus.FETCHING
	const isLoadingHistory = personalAccount?.history?.fetchStatus === FetchStatus.FETCHING

	const { body: balanceData, ...frozenTableData } = personalAccount?.balance?.data || {}

	const onFrozenContractsTableLoad = (params) => {
		dispatch(loadParentCardTabPersonalAccountBalance(params))
	}

	const onHistoryTableLoad = (params) => {
		dispatch(loadParentCardTabPersonalAccountHistory(params))
	}

	const data = useMemo(
		() => [
			{
				label: 'Баланс',
				value: trimSeparator(balanceData?.balanceAvailable + balanceData?.frozen),
			},
			{
				label: 'Доступный баланс',
				value: trimSeparator(balanceData?.balanceAvailable),
			},
			{
				label: 'Заморожено',
				value: trimSeparator(balanceData?.frozen),
			},
			{
				label: 'Поступило всего',
				value: trimSeparator(balanceData?.paymentsTotal),
			},
			{
				label: 'Всего списаний',
				value: trimSeparator(balanceData?.realizationTotal),
			},
		],
		[balanceData],
	)

	return (
		<div className={'user-card-popup-tab-personal-account'}>
			<div className={'user-card-popup-tab-personal-account-left'}>
				<div className={'user-card-popup-tab-personal-account-title'}>
					<AppText textStyle={'DesktopH3'} text={'На сегодняшний день'} />
				</div>
				<div className={'user-card-popup-tab-personal-account-data'}>
					<div className={'user-card-popup-tab-personal-account-data-list'}>
						{data?.map((item) => {
							return (
								<div key={item.label} className={'user-card-popup-tab-personal-account-data-list-item'}>
									<AppText text={item.label} />
									{isLoadingBalance ? (
										<AppSkeleton.Button style={{ height: 20 }} size={'small'} active={true} />
									) : (
										<AppText textStyle={'DesktopH4'} text={item.value} />
									)}
								</div>
							)
						})}
					</div>
				</div>
				<div className={'user-card-popup-tab-personal-account-title'}>
					<AppText textStyle={'DesktopH3'} text={'График заморозок (платежей)'} />
				</div>
				<div className={'user-card-popup-tab-personal-account-data'}>
					<UserCardPopupFrozenContractsTable
						id={id}
						isLoading={isLoadingBalance}
						tableData={frozenTableData}
						onLoad={onFrozenContractsTableLoad}
					/>
				</div>
			</div>
			<div className={'user-card-popup-tab-personal-account-right'}>
				<div className={'user-card-popup-tab-personal-account-title'}>
					<AppText textStyle={'DesktopH3'} text={'История'} />
				</div>
				<div className={'user-card-popup-tab-personal-account-data'}>
					<UserCardPopupHistoryTable
						id={id}
						isLoading={isLoadingHistory}
						tableData={personalAccount?.history?.data || {}}
						onLoad={onHistoryTableLoad}
					/>
				</div>
			</div>
		</div>
	)
}

export default UserCardPopupTabPersonalAccount
