import React, { FC } from 'react'
import './StatisticCard.scss'
import { AppCard, AppFontIcon, AppText } from '@frontend-modules/ui-kit'
import { IStatisticCardProps } from './StatisticCard.types'
import { trimSeparator } from '@frontend-modules/frontend-utils'

const StatisticCard: FC<IStatisticCardProps> = (props) => {
	const { type, data } = props
	return (
		<AppCard className={'statistic-card'}>
			<div className={'statistic-card-content'}>
				<div className={'statistic-card-info'}>
					<div className={'statistic-card-info-item main'}>
						<div className={'title'}>
							{type === 'pays' ? 'Всего платежей' : 'Всего платежных поручений'}
						</div>
						<AppText text={`${trimSeparator(data?.total) || '0'}`} textStyle={'DesktopH4'} />
					</div>
					<div className={'statistic-card-info-item'}>
						<div className={'title'}>Входящие</div>
						<AppText
							text={`${trimSeparator(data?.incoming) || '0'}`}
							textStyle={'DesktopH4'}
							color={'#81ba71'}
						/>
						<div className={'statistic-card-info-item__ruble'}>
							<AppText
								text={`${trimSeparator(data?.incomingValue) || '0'}`}
								textStyle={'DesktopH4'}
								color={'#81ba71'}
							/>
							<AppFontIcon icon={'ruble-l'} />
						</div>
					</div>
					<div className={'statistic-card-info-item'}>
						<div className={'title'}>Исходящие</div>
						<AppText
							text={`${trimSeparator(data?.outgoing) || '0'}`}
							textStyle={'DesktopH4'}
							color={'#0088E9'}
						/>
						<div className={'statistic-card-info-item__ruble'}>
							<AppText
								text={`${trimSeparator(data?.outgoingValue) || '0'}`}
								textStyle={'DesktopH4'}
								color={'#0088E9'}
							/>
							<AppFontIcon icon={'ruble-l'} />
						</div>
					</div>
					<div className={'statistic-card-info-item'}>
						<div className={'title'}>
							<AppFontIcon
								icon={'exclamation-in-circle-l'}
								className={'statistic-card-info-item__icon'}
								color={'#C13C37'}
							/>
							{type === 'pays'
								? 'Платежи без платежных поручений/ЛС'
								: 'Платежные поручения без платежей'}
						</div>
						<AppText
							text={`${trimSeparator(data?.red) || '0'}`}
							textStyle={'DesktopH4'}
							color={'#C13C37'}
						/>
					</div>
					{type !== 'pays' && (
						<div className={'statistic-card-info-item'}>
							<div className={'title'}>
								<AppFontIcon
									icon={'exclamation-in-circle-l'}
									className={'statistic-card-info-item__icon'}
									color={'#ff8725'}
								/>
								Несоответствие сумм ПП и платежа
							</div>
							<AppText
								text={`${trimSeparator(data?.orange) || '0'}`}
								textStyle={'DesktopH4'}
								color={'#ff8725'}
							/>
						</div>
					)}
				</div>
			</div>
		</AppCard>
	)
}

export default StatisticCard
