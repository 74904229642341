import { ActionType, createAction } from 'typesafe-actions'

export const fetchActiveParents = createAction('@analytics/fetch/activeParents/start')<any>()
export const fetchActiveParentsSuccess = createAction('@analytics/fetch/activeParents/success')<any>()
export const fetchActiveParentsError = createAction('@analytics/fetch/activeParents/error')<any>()

export const fetchActiveParentsWithContracts = createAction('@analytics/fetch/activeParentsWithContracts/start')<any>()
export const fetchActiveParentsWithContractsSuccess = createAction(
	'@analytics/fetch/activeParentsWithContracts/success',
)<any>()
export const fetchActiveParentsWithContractsError = createAction(
	'@analytics/fetch/activeParentsWithContracts/error',
)<any>()

export const fetchPaymentCount = createAction('@analytics/fetch/paymentCount/start')<any>()
export const fetchPaymentCountSuccess = createAction('@analytics/fetch/paymentCount/success')<any>()
export const fetchPaymentCountError = createAction('@analytics/fetch/paymentCount/error')<any>()

export type AnalyticsActions =
    | ActionType<typeof fetchActiveParents>
    | ActionType<typeof fetchActiveParentsSuccess>
    | ActionType<typeof fetchActiveParentsError>
    | ActionType<typeof fetchActiveParentsWithContracts>
    | ActionType<typeof fetchActiveParentsWithContractsSuccess>
    | ActionType<typeof fetchActiveParentsWithContractsError>
    | ActionType<typeof fetchPaymentCount>
    | ActionType<typeof fetchPaymentCountSuccess>
    | ActionType<typeof fetchPaymentCountError>;
