import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form as BForm, Popover, Row} from 'react-bootstrap'
import {connect} from 'react-redux'
import {createProcessStart} from '../../../store/actions/processes'
import {State} from '../../../store/store'

import {Form, Field} from 'react-final-form'
import {loadParentCard} from '../../../store/actions/parentCard'
import {UserCardState} from '../../../store/types/card'
import {FetchStatus} from '../../../common/types'
import Skeleton from 'react-loading-skeleton'


interface IMissingDocumentsProps {
    createProcess: (payload: any) => void;
    student_id: string;
    id: number;
    parent_id: string;
}

export const Component: React.FC<IMissingDocumentsProps> = ({ createProcess, student_id, id, parent_id, }) => {
	const [documents, setDocuments] = useState([])
    
	const onChange = (e, index) => {
		const newDocuments = JSON.parse(JSON.stringify(documents))

		newDocuments[index] = e.target.value

		setDocuments(newDocuments)
	}

	const onSubmit = () => {
		createProcess({
			process: 'set_missing_documents',
			id: id,
			parameters: {
				document_ids: documents
			}
		})
	}

	const addDocument = () => {
		const newDocuments = JSON.parse(JSON.stringify(documents))

		newDocuments.push('')

		setDocuments(newDocuments)
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Введите номера<br/> недостающих документов</Popover.Title>
			</Row>

			<Row>
				<Col>
					<div>
						{
							documents.map((value, index) => (
								<BForm.Group>
									<BForm.Control
										type="text"
										as="input"
										value={value}
										onChange={e => onChange(e, index)}
									/>
								</BForm.Group>
							))
						}
					</div>
					<div>
						<Button onClick={onSubmit} type="submit" variant="primary">
                            Подтвердить
						</Button>
						<Button onClick={addDocument}>+</Button>
					</div>
				</Col>
			</Row>
		</Col>
	)
}


export const MissingDocuments = connect(
	({}: State, ownProps: any) => ({}),
	(dispatch) => {
		return {
			createProcess: (payload: any) => {
				return dispatch(createProcessStart(payload))
			}
		}
	}
)(Component)
