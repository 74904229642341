import { $BaseHttpClient, $HttpClient } from '@utils'

export class PaymentsService extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список платежей
	 */
	loadPaysList = (params) => {
		return $HttpClient.post('payment/overview/', params)
	}
	/**
	 *
	 * @description работа с платежом
	 */
	crudPay = (params) => {
		return $HttpClient.post('payment/handle/', params)
	}
	/**
	 *
	 * @description загружаем список платежный поручений
	 */
	loadAssignmentsList = (params) => {
		return $HttpClient.post('payment-order/overview/', params)
	}
	/**
	 *
	 * @description получение с платежным поручением
	 */
	loadAssignmentInfo = (id, params) => {
		return $HttpClient.get(`payment-order/${id}/`, {}, params)
	}
	/**
	 *
	 * @description поиск Лицевых счетов
	 */
	findPersonalAccount = (params) => {
		return $HttpClient.get('personal-account/', params)
	}
	/**
	 *
	 * @description поиск договоров
	 */
	findContract = (params) => {
		return $HttpClient.get('contract/', params)
	}
	/**
	 *
	 * @description поиск платежных поручений
	 */
	findPaymentAssignment = (params) => {
		return $HttpClient.get('payment-order/', params)
	}
	/**
	 *
	 * @description поиск платежей
	 */
	findPays = (params) => {
		return $HttpClient.get('payment/search/', params)
	}

	/**
	 *
	 * @description загрузка реестра
	 */
	uploadRegistry = (params) => {
		return $HttpClient.post('registry/', params)
	}
	/**
	 *
	 * @description загрузка файла банковской выписки
	 */
	uploadPOFile = (params) => {
		return $HttpClient.post('payment-order-file/', params)
	}
}
