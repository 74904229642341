import React, { FC, useMemo } from 'react'
import { AppHardModalButton, AppTable, AppText } from '@frontend-modules/ui-kit'
import { IMainTableProps } from './MainTable.types'
import './MainTable.scss'
import { AccessHelper } from '@frontend-modules/access-controller'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
/**
 * @description - таблица групповых действий
 * @param {IMainTableProps} props
 * @constructor
 */
const MainTable: FC<IMainTableProps> = (props) => {
	const { isLoading, list, onPaginationChange, paginationLimit, pagination, sortingConfig, onDeleteItemClick } = props

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
	}

	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== pagination.currentPage) {
			onPaginationChange?.({ page, limit: paginationLimit })
		}
	}

	const columns = useMemo(() => {
		return AccessHelper.filterList([
			{
				title: 'Ученик',
				dataIndex: 'student',
				key: 'student',
				render: (_, record) => (
					<AppHardModalButton
						buttonType={'link'}
						label={`${record?.lastName || ''} ${record?.firstName || ''} ${record?.patronymic || ''}`}
						query={{
							type: HARD_MODAL_ENUMS.USER_CARD,
							id: record?.id,
							userType: 'student',
						}}
					/>
				),
			},
			{
				title: 'Родитель',
				dataIndex: 'parent',
				key: 'parent',
				render: (_, record) => (
					<div>
						{record?.parents?.map((item, index) => (
							<AppHardModalButton
								buttonType={'link'}
								key={item.id}
								label={`${index + 1}. ${item?.lastName || ''} ${item?.firstName || ''} ${
									item?.patronymic || ''
								}`}
								query={{
									type: HARD_MODAL_ENUMS.USER_CARD,
									id: item?.id,
									userType: 'parent',
								}}
							/>
						))}
					</div>
				),
			},
			{
				title: 'Параллель',
				dataIndex: 'parallel',
				key: 'parallel',
			},
			{
				title: 'Класс',
				dataIndex: 'class',
				key: 'class',
				render: (_, record) => (
					<div>
						{record?.studentGroups?.map((item, index) => (
							<AppText text={`${index + 1}. ${item.label}`} key={`${item.value}-${index}`} />
						))}
					</div>
				),
			},
			{
				title: 'Школа',
				dataIndex: 'currentSchool',
				key: 'currentSchool',
			},
			{
				title: 'Тариф',
				dataIndex: 'tariff',
				key: 'tariff',
				render: (_, record) => (
					<div>
						{record?.tariffs?.map((item, index) => (
							<AppText text={`${index + 1}. ${item.title}`} key={`${item.id}-${index}`} />
						))}
					</div>
				),
			},
		])
	}, [])

	const listWithKey = useMemo(
		() =>
			list?.map((item) => {
				return {
					...item,
					key: item.id,
				}
			}),
		[list],
	)

	return (
		<AppTable
			isLoading={isLoading}
			columns={columns}
			isCanDeleteRow={true}
			isNeedNumbering={true}
			dataSource={listWithKey}
			locale={{ emptyText: 'Ничего не найдено' }}
			pagination={{
				total: pagination?.count,
				pageSize: paginationLimit,
				defaultCurrent: 1,
				current: pagination?.currentPage,
				onChange: onPaginationChangeLocal,
				locale: { items_per_page: 'записей ' },
				onShowSizeChange: onPaginationSizeChangeLocal,
			}}
			rowKey={(record) => record.id}
			sortingConfig={sortingConfig}
			titleSortingType={'customSorting'}
			onDeleteRowClick={onDeleteItemClick}
			className={'group-actions-main-table'}
		/>
	)
}

export default MainTable
