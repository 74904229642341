const normalizeCatalog = (catalog, url) => {
	return catalog
}

/**
 * @description - переформатируем settings массив в объект
 * @param list
 */
const normalizeMetaOptionsList = (list) => {
	const normalizedObj = {}

	const parseSettingsList = (list) => {
		const newObj = {}
		list.forEach((key) => {
			newObj[key] = true
		})
		return newObj
	}

	Object.keys(list).forEach((key) => {
		normalizedObj[key] = {
			...normalizedObj[key],
			access: list[key].access,
			sorting: list[key].sorting || [],
			filters: (list[key].filters || []).map((item) => {
				return {
					...item,
					settings: parseSettingsList(item.settings),
				}
			}),
		}
	})
	return normalizedObj
}

export { normalizeCatalog, normalizeMetaOptionsList }
