import { ActionType, createAction } from 'typesafe-actions'

export const loadProblemStudents = createAction('@problemStudents/load/start')<any>()
export const loadProblemStudentsSuccess = createAction('@problemStudents/load/success')<any>()
export const loadProblemStudentsError = createAction('@problemStudents/load/error')<any>()

export type ProblemStudentsActions =
    | ActionType<typeof loadProblemStudents>
    | ActionType<typeof loadProblemStudentsSuccess>
    | ActionType<typeof loadProblemStudentsError>;
