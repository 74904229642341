import axios, { AxiosError } from 'axios'
import { getAccessToken } from '@/common/token'
import { onResponseErrorHandler } from '@/common/axios'

const oldAxios = axios.create()
const ssoAxios = axios.create({
	baseURL: `${process.env.REACT_APP_SSO_HOST}/`,
	withCredentials: true,
})

oldAxios.interceptors.request.use(
	(config) => {
		const token = getAccessToken()
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	(error) => {
		Promise.reject(error).catch((res: { status: string }) => {
			throw res?.status
		})
	},
)

const OldAxiosResponder = (on403Callback) => {
	oldAxios.interceptors.response.use(
		(response) => {
			return response
		},
		async (error: AxiosError) => {
			const { type, data } = (await onResponseErrorHandler(error, on403Callback)) || {}

			if (type === 'config' && data) {
				return oldAxios(data)
			}

			return Promise.reject(error).catch((error: AxiosError) => {
				throw error
			})
		},
	)
}

export { oldAxios, ssoAxios, OldAxiosResponder }
