import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	onLoadDebtsSuccess,
	loadDebts,
	onLoadDebtsError,
	loadDebtsPayments,
	onLoadDebtsPaymentsSuccess,
	onLoadDebtsPaymentsError,
	updateDebtsPaymentStatus,
	onUpdateDebtsPaymentStatusSuccess,
	onUpdateDebtsPaymentStatusError,
	sendDebtsNotify,
	onSendDebtsNotifySuccess,
	onSendDebtsNotifyError,
	sendDebtsStudentBlock,
	onSendDebtsStudentBlockSuccess,
	onSendDebtsStudentBlockError,
} from '@store/actions/debts'
import { showErrorToast, showSuccessToast } from '@utils'
import { normalizeFilterPayload } from '@frontend-modules/filters-generator'

const loadDebtsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadDebts)),
		switchMap(({ payload }) => {
			const normalizedPayload = {
				filters: normalizeFilterPayload(payload.filters),
				limit: payload?.pagination?.limit,
				currentPage: payload?.pagination?.currentPage,
			}
			return from(deps.debtsService.loadDebts(normalizedPayload)).pipe(
				switchMap((response) => {
					return of(onLoadDebtsSuccess(response))
				}),
				catchError((err) => {
					return of(onLoadDebtsError(err))
				}),
			)
		}),
	)
}
const loadDebtsPaymentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadDebtsPayments)),
		switchMap(({ payload }) => {
			return from(deps.debtsService.loadDebtsPayments(payload)).pipe(
				switchMap((response) => {
					return of(onLoadDebtsPaymentsSuccess({ id: payload, list: response }))
				}),
				catchError((err) => {
					return of(onLoadDebtsPaymentsError(err))
				}),
			)
		}),
	)
}
const updateDebtsPaymentStatusEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(updateDebtsPaymentStatus)),
		switchMap(({ payload }) => {
			const { studentID, paymentID, data } = payload
			return from(deps.debtsService.updateDebtsPaymentStatus(paymentID, data)).pipe(
				switchMap(() => {
					showSuccessToast({
						message: 'Платеж зафиксирован',
					})
					const isLastPayment = store$.value.debts.paymentsData?.[studentID]?.list?.length === 1
					if (isLastPayment) {
						const isLastPage =
							store$.value.debts.pagination.maxPage === store$.value.debts.pagination.currentPage
						if (isLastPage) {
							const isLastStudentInPage = store$.value.debts.list.length === 1
							if (isLastStudentInPage) {
								const payloadData = store$.value.debts.query
								// если удалили последний элемент на последней странице
								// то запрашиваем предидущую страницу
								return of(
									loadDebts({
										pagination: {
											...payloadData.pagination,
											currentPage: payloadData.pagination.currentPage - 1,
										},
										filters: payloadData.filters,
									}),
								)
							} else {
								return of(onUpdateDebtsPaymentStatusSuccess({ paymentID, studentID }))
							}
						} else {
							// если удалили последний платеж у студента
							// нужно догрузить следующуещего студента
							// -> перезапрашиваем текущую страницу
							const payloadData = store$.value.debts.query
							return of(
								loadDebts({
									pagination: payloadData.pagination,
									filters: payloadData.filters,
								}),
							)
						}
					}
					return of(onUpdateDebtsPaymentStatusSuccess({ paymentID, studentID }))
				}),
				catchError((err) => {
					showErrorToast({
						message: 'Что-то пошло не так...',
					})
					return of(onUpdateDebtsPaymentStatusError(err))
				}),
			)
		}),
	)
}
const sendDebtsNotifyEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(sendDebtsNotify)),
		switchMap(({ payload }) => {
			return from(deps.debtsService.sendDebtsNotify(payload)).pipe(
				switchMap((response) => {
					showSuccessToast({
						message: 'Уведомление отправлено',
					})
					return of(onSendDebtsNotifySuccess(response))
				}),
				catchError((err) => {
					showErrorToast({
						message: 'Что-то пошло не так...',
					})
					return of(onSendDebtsNotifyError(err))
				}),
			)
		}),
	)
}
const sendDebtsStudentBlockEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(sendDebtsStudentBlock)),
		switchMap(({ payload }) => {
			const { id, block_financial } = payload
			const isNewBlockState = !block_financial
			return from(deps.debtsService.sendDebtsStudentBlock(id, { block_financial: isNewBlockState })).pipe(
				switchMap((response) => {
					showSuccessToast({
						message: `Студент ${isNewBlockState ? 'заблокирован' : 'разблокирован'}`,
					})
					return of(onSendDebtsStudentBlockSuccess(response))
				}),
				catchError((err) => {
					showErrorToast({
						message: 'Что-то пошло не так...',
					})
					return of(onSendDebtsStudentBlockError(err))
				}),
			)
		}),
	)
}

export const debtsEpics = combineEpics(
	loadDebtsEpic,
	loadDebtsPaymentsEpic,
	updateDebtsPaymentStatusEpic,
	sendDebtsNotifyEpic,
	sendDebtsStudentBlockEpic,
)
