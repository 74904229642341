import { AppSpecialButton, AppText } from '@frontend-modules/ui-kit'
import React from 'react'
const dateFormat = 'DD.MM.YYYY HH:mm:ss'
const emailColumns = [
	{
		title: 'Адрес',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Тема письма',
		dataIndex: 'title',
		key: 'title',
		render: (_, record) => {
			return (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '350px 30px',
						gridColumnGap: '5px',
						alignItems: 'flex-start',
						maxWidth: 500,
					}}
				>
					<AppText text={record.title} />
					{record.text && (
						<AppSpecialButton
							onClick={() => {
								window.open(record.text)
							}}
							icon={'noteblank-m'}
						/>
					)}
				</div>
			)
		},
	},
]
const smsColumns = [
	{
		title: 'Номер',
		dataIndex: 'phone_number',
		key: 'phone_number',
	},
	{
		title: 'Текст сообщения',
		dataIndex: 'text',
		key: 'text',
	},
]

export { smsColumns, emailColumns, dateFormat }
