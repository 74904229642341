import { DebtsSearchType } from '../../store/types/search'

export const getEducationCostNum = (educationCost, search): number => {
	const resultsArr = []
	if (search) {
		Object.keys(educationCost).forEach((key) => {
			if (educationCost[key].indexOf(search) !== -1) resultsArr.push(key)
		})
		return (
			+resultsArr.find(
				(key) => educationCost[key].length === Math.min(...resultsArr.map((el) => educationCost[el].length)),
			) || 0
		)
	}
	return 0
}

export const getFilteredStudentsArr = (students, activeFilterType, searchParams, parents, schools, paymentSites) => {
	switch (activeFilterType) {
	case DebtsSearchType.PARENT:
		return students.filter((student) =>
			student.parents.some((parent) => parents.some((el) => el.id === parent)),
		)
	case DebtsSearchType.SCHOOL:
		return searchParams.school
			? students.filter((student) => schools.some((school) => school.id === student.school))
			: students
	case DebtsSearchType.PAYMENT_SITE:
		return searchParams.paymentSite
			? students.filter((student) => paymentSites.some((paymentSite) => paymentSite.student === student.id))
			: students
	default:
		return students
	}
}
