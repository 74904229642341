import { persistReducer } from 'redux-persist'
import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	loadMeta,
	loadMetaCatalog,
	loadMoreMetaCatalog,
	onLoadMetaCatalogError,
	onLoadMetaCatalogSuccess,
	onLoadMetaError,
	onLoadMetaSuccess,
	onLoadMoreMetaCatalogError,
	onLoadMoreMetaCatalogSuccess,
} from '@store/actions/meta'
import { MetaState } from '@store/types/meta'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const metaInitialState: MetaState = {
	fetchStatus: FetchStatus.INITIAL,
	errors: [],
	entities: {},
	catalog: {},
}

export const metaReducer = (state = metaInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadMeta): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadMetaSuccess): {
			return {
				...state,
				entities: action.payload,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadMetaError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(loadMetaCatalog): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.catalogData.url]: {
							...state.catalog.api?.[action.payload.catalogData.url],
							fetchStatus: FetchStatus.FETCHING,
						},
					},
				},
			}
		}
		case getType(loadMoreMetaCatalog): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.catalogData.url]: {
							...state.catalog.api?.[action.payload.catalogData.url],
							fetchStatus: FetchStatus.FETCHING_MORE,
						},
					},
				},
			}
		}
		case getType(onLoadMetaCatalogSuccess): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.url]: {
							list: action.payload.list,
							pagination: action.payload.pagination,
							fetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}
		case getType(onLoadMetaCatalogError): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.url]: {
							...state.catalog.api?.[action.payload.url],
							fetchStatus: FetchStatus.ERROR,
						},
					},
				},
			}
		}

		case getType(onLoadMoreMetaCatalogSuccess): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.url]: {
							list: [...state.catalog.api[action.payload.url].list, ...action.payload.list],
							pagination: action.payload.pagination,
							fetchStatus: FetchStatus.FETCHED,
						},
					},
				},
			}
		}
		case getType(onLoadMoreMetaCatalogError): {
			return {
				...state,
				catalog: {
					...state.catalog,
					api: {
						...state.catalog.api,
						[action.payload.url]: {
							...state.catalog.api?.[action.payload.url],
							fetchStatus: FetchStatus.ERROR,
						},
					},
				},
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedMetaReducer: any = persistReducer(
	{
		key: 'AdminPanel::meta',
		storage: indexedDBStore,
		blacklist: [],
		version: 1,
	},
	metaReducer,
)
