import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { hiddenActions } from '../../../common/constants'
import TableItemAction from '../TableItemAction/TableItemAction'
import './TableItem.scss'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { openChat } from '../../../store/actions/chat'
import { State } from '../../../store/store'
import { StaffUsers } from '../../StaffUsers/StaffUsers'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { loadProfile } from '../../../store/actions/profile'
import { ProcessParent } from '../../../store/types/processes'
import { getDefaultParent } from '../../../utils/getDefaultParent'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { AppHardModalButton } from '@frontend-modules/ui-kit'

interface ITableItemProps {
	id: number
	first_name: string
	last_name: string
	patronymic: string
	parents: ProcessParent[]
	processes: {
		id: number
		student: {
			id: number
			first_name: string
			last_name: string
			patronymic: string
			grade?: number
			parents: [
				{
					id: number
					first_name: string
					last_name: string
					patronymic: string
				},
			]
		}
		state: string
		process_type: string
		available_actions: string[]
		signals: string[]
		state_notes: string[]
		parent: any
		display_name: string
		staff_users: Array<any>
	}[]
	education_cost: number
	school: any
	index: number
	page: number
	pageSize: number
	constants: any
}

export const TableItem: React.FC<ITableItemProps> = memo(
	({
		first_name,
		last_name,
		patronymic,
		parents,
		processes,
		id,
		education_cost,
		school,
		index,
		page,
		pageSize,
		constants,
	}) => {
		const [show, setShow] = useState(true)
		const { unread } = useSelector((store: State) => store.chat)
		const { profile, roles } = useSelector((store: State) => store.profile)
		const defaultParent = useMemo(() => getDefaultParent(parents), [parents])

		const dispatch = useDispatch()

		useEffect(() => {
			if (!profile || !roles) {
				dispatch(loadProfile({}))
			}
		}, [])

		const student = useMemo(
			() => ({
				id: id,
				first_name: first_name,
				last_name: last_name,
				patronymic: patronymic,
				parents: defaultParent?.user,
				education_cost: education_cost,
				school: school,
				grade: processes?.[0]?.student.grade,
			}),
			[defaultParent?.user, education_cost, first_name, id, last_name, patronymic, processes, school],
		)

		const mailClickHandler = () => {
			dispatch(openChat(id))
		}

		const isShowChangeCallback = useCallback(() => {
			setShow(!show)
		}, [show])

		const { staff_users, processId } = useMemo(() => {
			if (processes && processes?.length) {
				return {
					staff_users:
						processes?.find((process) => process?.state !== 'finished')?.staff_users ||
						processes[0].staff_users,
					processId: processes?.find((process) => process?.state !== 'finished')?.id || processes[0].id,
				}
			}

			return { staff_users: [], processId: null }
		}, [processes])

		const isUserInThisProcess = useMemo(() => {
			if (profile?.id) {
				return staff_users.includes(profile?.id)
			}
			return false
		}, [profile, staff_users])

		return (
			<>
				<tr className="table-item unread mb-3">
					<td>{pageSize * (page - 1) + index + 1}</td>
					<td>
						<div className="staffUsers">
							<OverlayTrigger
								rootClose
								trigger={'click'}
								placement="auto"
								overlay={
									<Popover id={`staffUsers-${processId}`}>
										<StaffUsers staffUsers={staff_users} processId={processId} students={[id]} />
									</Popover>
								}
							>
								<i
									className={'feather icon-user'}
									style={
										isUserInThisProcess
											? {
													color: 'green',
											  }
											: {}
									}
								/>
							</OverlayTrigger>
						</div>
					</td>
					<td>
						<div className="table-item-name__container">
							<h6 style={{ marginBottom: 0 }} className="table-item-name__title">
								Родитель:
							</h6>
							<div
								className="table-item-name"
								data-name={`${defaultParent?.user?.last_name || ''} ${
									defaultParent?.user?.first_name || ''
								} ${defaultParent?.user?.patronymic || ''}`}
							>
								<AppHardModalButton
									buttonType={'link'}
									label={`${defaultParent?.user?.last_name || ''} ${
										defaultParent?.user?.first_name ? defaultParent?.user?.first_name[0] + '.' : ''
									} ${
										defaultParent?.user?.patronymic ? defaultParent?.user?.patronymic[0] + '.' : ''
									}`}
									query={{
										type: HARD_MODAL_ENUMS.USER_CARD,
										id: defaultParent?.user?.id,
										userType: 'parent',
									}}
								/>
							</div>
						</div>
						<div className="table-item-name__container">
							<h6 style={{ marginBottom: 0 }} className="table-item-name__title">
								Ученик:
							</h6>
							<div className={'table-item-student'}>
								<div onClick={mailClickHandler} className={'mail'}>
									{!unread[id] ? (
										<i className="icon feather icon-message-circle" />
									) : (
										<i className="icon feather icon-message-circle unread" />
									)}
								</div>
								<div
									className="table-item-name"
									data-name={`${last_name || ''} ${first_name || ''} ${patronymic || ''}`}
								>
									<Link
										to={`/card/student/?student_id=${id}&parent_id=${defaultParent?.user?.id}`}
										style={{ cursor: 'pointer' }}
									>
										<h6
											onClick={isShowChangeCallback}
											style={{ cursor: 'pointer', marginBottom: 0 }}
										>
											{`${last_name || ''} ${first_name ? first_name[0] + '.' : ''} ${
												patronymic ? patronymic[0] + '.' : ''
											}`}
										</h6>
									</Link>
								</div>
							</div>
						</div>
						<div className="table-item-name__container">
							<h6 style={{ marginBottom: 0 }} className="table-item-name__title">
								Тариф:
							</h6>
							<div>
								<h6>
									{constants.EDUCATION_COST[education_cost]} - {education_cost}
								</h6>
							</div>
						</div>
						<div className="table-item-name__container">
							<h6 style={{ marginBottom: 0 }} className="table-item-name__title">
								Школа:
							</h6>
							<div>
								<h6 style={{ marginBottom: 0 }}>{school?.name}</h6>
							</div>
						</div>
					</td>
					{/*<td>*/}
					{/*    {tasks.map(({ signals, display_name }) => (*/}
					{/*        <div>*/}
					{/*            {!!signals?.length && <h6 className="text-muted f-16 mb-3 mt-3">{display_name}</h6>}*/}
					{/*            <div className="table-item__grid">*/}
					{/*                {signals.map((el, index) => (*/}
					{/*                    <h6 key={index} className="process-signal-item">*/}
					{/*                        <i className="fa fa-circle text-c-red f-10 m-r-10" />*/}
					{/*                        {el}*/}
					{/*                    </h6>*/}
					{/*                ))}*/}
					{/*            </div>*/}
					{/*        </div>*/}
					{/*    ))}*/}
					{/*</td>*/}
					<td>
						{processes.map(({ available_actions, id, display_name, state, signals }) => {
							if (state !== 'finished') {
								return (
									<div>
										<div className="table-item__grid">
											<h6 className="text-muted f-16 mb-3 mt-3">{display_name}</h6>
											{available_actions.map((action, index) => {
												if (!hiddenActions[action]) {
													return (
														<TableItemAction
															parent={defaultParent?.user}
															student={student}
															education_cost={education_cost}
															className={''}
															index={index}
															id={id}
															action={action}
															key={index}
														/>
													)
												} else {
													return null
												}
											})}
											{signals
												.filter(
													(signal) =>
														signal.includes('Запись на') ||
														signal.includes('Ожидание документов') ||
														signal.includes('Все документы загружены') ||
														signal.includes('Родитель уведомлен о') ||
														signal.includes('Ученик был отчислен из класса для Пробных'),
												)
												.map((el, index) => (
													<h6 key={index} className="process-signal-item">
														<i className="fa fa-circle text-c-red f-10 m-r-10" />
														{el}
													</h6>
												))}
										</div>
									</div>
								)
							} else {
								return null
							}
						})}
					</td>
				</tr>
			</>
		)
	},
)
