import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { useHistory } from 'react-router-dom'
import { loadTask } from '@store/actions/tasks'
import { AppCard, AppText, AppLoader } from '@frontend-modules/ui-kit'
import { FetchStatus } from '@/common/types'
import { ITaskFormProps } from './TaskForm.types'
import { FormGenerator } from '@frontend-modules/form-generator'
import { useQueryPopupsParams } from '@hooks'
/**
 * @description - компонент генерации формы выполнения задачи
 * @param props
 * @constructor
 */
const TaskForm: FC<ITaskFormProps> = (props) => {
	const { id, onLoadError, onSuccessWithNext, onSuccessWithoutNext } = props
	const [taskID, setTaskID] = useState(id)

	const { tasksData } = useSelector((state: State) => state.tasks)
	const currentTaskData = useMemo(() => tasksData[taskID], [tasksData, taskID])

	const { openPopup } = useQueryPopupsParams()

	const dispatch = useDispatch()
	const router = useHistory()
	const [isCannotLeave, setIsCannotLeave] = useState(true)

	const onSpecialLinkClick = (linkConfig) => {
		openPopup(linkConfig)
	}

	/**
	 * @description - функция для показа сообщения при выходе. Нужен ref для апдейта внутренней переменной стейта
	 * @param event
	 */
	const beforeUnloadListener = (event) => {
		if (isCannotLeave) {
			event.preventDefault()
			return (event.returnValue = 'Вы уверен, что хотите покинуть страницу? Введенные данные могут быть утеряны')
		}
	}
	const beforeUnloadListenerRef = useRef(beforeUnloadListener)
	useEffect(() => {
		beforeUnloadListenerRef.current = beforeUnloadListener
	})

	/**
	 * @description - хук вызывает event, который вызывает системное предупреждение при закрытии страницы или обновления
	 * Нужен для того, чтобы пользователь понимал, что уходя со страницы может потерять прогресс заполнения формы
	 */
	useEffect(() => {
		const bu = (e) => beforeUnloadListenerRef.current(e) // then use most recent cb value
		const nameInput = document.querySelector('div')
		nameInput.addEventListener('input', () => {
			addEventListener('beforeunload', bu, { capture: true })
		})

		return () => {
			setIsCannotLeave(false)
		}
	}, [isCannotLeave])

	/**
	 * @description - стратегии для ответов при удачной отправке запроса для движения задачи
	 */
	const successStrategies = useMemo(
		() => [
			// если нам пришла nextTask, значит нужно сделать редирект
			{
				condition: (payload) => {
					return Boolean(payload?.nextTask)
				},
				action: (response: { nextTask?: number }) => {
					if (onSuccessWithNext) {
						onSuccessWithNext?.(response?.nextTask)
					} else {
						setTaskID(String(response.nextTask))
						void router.replace(`/new/tasks/${response.nextTask}/`)
					}
				},
			},
			// условие для отправки на предыдущую страницу
			{
				condition: (payload) => !payload?.nextTask,
				action: () => {
					if (onSuccessWithoutNext) {
						onSuccessWithoutNext?.()
					} else {
						setIsCannotLeave(false)
						void router.push('/new/tasks')
					}
				},
			},
		],
		[onSuccessWithNext, onSuccessWithoutNext, router],
	)

	const onControlClickSuccess = (response) => {
		const matchingStrategy = successStrategies.find((strategy) => strategy.condition(response))

		if (matchingStrategy) {
			return matchingStrategy.action(response)
		}
	}

	useEffect(() => {
		dispatch(loadTask(taskID))
	}, [dispatch, taskID])

	useEffect(() => {
		if (currentTaskData?.fetchStatus === FetchStatus.ERROR) {
			setTimeout(() => {
				router.goBack()
			}, 1000)
		}
	}, [currentTaskData?.fetchStatus])

	return (
		<AppCard
			className={'tasks-task-page'}
			title={
				<AppText
					textStyle={'Title'}
					style={{ margin: '0' }}
					text={currentTaskData?.data?.label || 'Загрузка...'}
				/>
			}
		>
			{currentTaskData?.fetchStatus === FetchStatus.FETCHING && <AppLoader />}
			{currentTaskData?.fetchStatus === FetchStatus.FETCHED && (
				<FormGenerator
					metaData={currentTaskData?.data}
					onControlClickSuccess={onControlClickSuccess}
					onSpecialLinkClick={onSpecialLinkClick}
				/>
			)}
		</AppCard>
	)
}

export default TaskForm
