import { $BaseHttpClient, $HttpClient } from '@utils'

export class GroupActionsServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список по групповым действиям
	 */
	loadGroupActionsTable = (params) => {
		return $HttpClient.post('api/v1/group-action/overview/', params)
	}
	/**
	 *
	 * @description загружаем список доступных действий
	 */
	loadActionsList = () => {
		return $HttpClient.get('api/v1/group-action/list/')
	}
	/**
	 *
	 * @description создает групповое десствие
	 */
	createGroupAction = (params) => {
		return $HttpClient.post('api/v1/group-action/create/', params)
	}
	/**
	 *
	 * @description Список действий по статусу
	 */
	loadActionsByStatus = (params) => {
		return $HttpClient.post('api/v1/group-action/progress/', params)
	}
}
