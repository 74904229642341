import { BaseRestDataProvider } from './BaseRestDataProvider'
import { IAddDocumentTypePayload, IDocumentType, IRemoveDocumentTypePayload } from '../store/types/documentTypes'
import { oldAxios } from './axios-init'

export class DocumentTypesDataProvider extends BaseRestDataProvider {
	loadDocumentTypes = () => {
		return oldAxios.get(`${this.host}/api/document_types/`).then((res) => res.data)
	}

	createDocumentType = (payload: IDocumentType) => {
		return oldAxios.post(`${this.host}/api/document_types/`, payload).then((res) => res.data)
	}

	removeParentDocumentType = (payload: IRemoveDocumentTypePayload) => {
		return oldAxios
			.patch(`${this.host}/api/remove_user_document_type/${payload.id}/`, {
				document_type: payload.documentTypeId,
			})
			.then((response) => response.data)
	}

	removeStudentDocumentType = (payload: IRemoveDocumentTypePayload) => {
		return oldAxios
			.patch(`${this.host}/api/students/${payload.id}/remove_document_type/`, {
				document_type: payload.documentTypeId,
			})
			.then((response) => response.data)
	}

	addDocumentType = (payload: IAddDocumentTypePayload) => {
		return oldAxios
			.post(`${this.host}/api/students/${payload.student_id}/add_document_type/`, {
				document_type: payload.document_type,
				parent: payload.parent_id,
			})
			.then((res) => res.data)
	}

	addUserDocumentType = (payload: IAddDocumentTypePayload) => {
		return oldAxios
			.post(`${this.host}/api/add_user_document_type/${payload?.parent_id}/`, {
				document_type: payload.document_type,
			})
			.then((res) => res.data)
	}

	loadParentStudentDocumentTypes = async (student_id: number, parent_id: number) => {
		const studentTypes = await oldAxios
			.get(`${this.host}/api/document_types/student_document_types_list/${student_id}/`)
			.then((res) => res.data)
			.catch(() => [])

		const parentTypes = await oldAxios
			.get(`${this.host}/api/document_types/parent_document_types_list/${parent_id}/`)
			.then((res) => res.data)
			.catch(() => [])

		return [...studentTypes, ...parentTypes]
	}
}
