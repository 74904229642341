const dropdownList = [
	{
		label: 'по SMS',
		key: 'sms',
		accessKey: 'debts.parents_table.actions.notify.sms',
	},
	{
		label: 'по Email',
		key: 'email',
		accessKey: 'debts.parents_table.actions.notify.email',
	},
]

export { dropdownList }
