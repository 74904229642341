import React, { useCallback } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { isStateInModal } from '../../../common/constants'
import { DistributeOnCourseFinal } from '../../../Screens/Process/DistributeOnCourseFinal/DistributeOnCourseFinal'
import { RecordTrial } from '../../../Screens/Process/RecordTrial/RecordTrial'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { ContractState } from '../../../store/types/contract'
import { DistributeToSchool } from '../../../Screens/Process/DistributeToSchool/DistributeToSchool'
import { TransferToDoSpecialist } from '../../../Screens/Process/TransferToDoSpecialist/TransferToDoSpecialist'
import { NeedToChange } from '../../../Screens/Process/NeedToChange/NeedToChange'
import { GetAddress } from '../../../Screens/Process/GetAddress/GetAddress'
import { SendCourier } from '../../../Screens/Process/SendCourier/SendCourier'
import { MissingDocuments } from '../../../Screens/Process/MissingDocuments/MissingDocuments'
import { DocumentsReceived } from '../../../Screens/Process/DocumentsReceived/DocumentsReceived'
import { ChangeTrial } from '../../../Screens/Process/ChangeTrial/ChangeTrial'
import { ChangeSchool } from '../../../Screens/Process/ChangeSchool/ChangeSchool'
import { ChangeDo } from '../../../Screens/Process/ChangeDo/ChangeDo'
import { SetConturUrl } from '../../../Screens/Process/SetConturUrl/SetConturUrl'
import { UploadCourierBill } from '../../../Screens/Process/UploadCourierBill/UploadCourierBill'
import { EducationCost } from '../../../Screens/Process/EducationCost/EducationCost'
import { SetPencilData } from '../../../Screens/Process/SetPencilData/SetPencilData'
import { SetTrackingLink } from '../../../Screens/Process/SetTrackingLink/SetTrackingLink'
import { AddExistingContract } from '../../../Screens/Process/AddExistingContract/AddExistingContract'
import { HeadTeacherReject } from '../../../Screens/Process/HeadTeacherReject/HeadTeacherReject'
import { BookGrade } from '../../../Screens/Process/BookGrade/BookGrade'
import { BookForeignLanguage } from '../../../Screens/Process/BookForeignLanguage/BookForeignLanguage'
import { ConstantsState } from '../../../store/types/constants'
import { BookingToTrialDays } from '../../../Screens/Process/BookingToTrialDays/BookingToTrialDays'
import { SendStatementToParent } from '../../../Screens/Process/SendStatementToParent/SendStatementToParent'
import { SignAgreementEdc } from '../../../Screens/Process/SignAgreementEdc/SignAgreementEdc'
import { ManualConfirmCourierPayment } from '../../../Screens/Process/ManualConfirmCourierPayment/ManualConfirmCourierPayment'
import { ManualConfirmCourierSign } from '../../../Screens/Process/ManualConfirmContractSign/ManualConfirmContractSign'
import { ChangeConturUrl } from '../../../Screens/Process/ChangeConturUrl/ChangeConturUrl'
import { GenerateAdditionalAgreement } from '../../../Screens/Process/GenerateAdditionalAgreement/GenerateAdditionalAgreement'
import { DocumentsSent } from '../../../Screens/Process/DocumentsSent/DocumentsSent'
import { TerminateInOneClick } from '../../../Screens/Process/TerminateInOneClick/TerminateInOneClick'
import { SkipGenerateContract } from '../../../Screens/Process/SkipGenerateContract/SkipGenerateContract'
import { GenerateOrder } from '../../../Screens/Process/GenerateOrder/GenerateOrder'
import { GenerateExpulsionOrder } from '../../../Screens/Process/GenerateExpulsionOrder/GenerateExpulsionOrder'
import { UploadCertificate } from '../../../Screens/Process/UploadCertificate/UploadCertificate'
import { ChooseTariff } from '../../../Screens/Process/ChooseTariff'
import { ChooseSchool } from '../../../Screens/Process/ChooseSchool'
import { CreateContractWithInProgress } from '../../../Screens/Process/CreateContractWithInProgress'
import { PutInCloset } from '../../../Screens/Process/PutInCloset/PutInCloset'
import { RefuseOfTrialDays } from '../../../Screens/Process/RefuseOfTrialDays'
import { ConfirmTrialDayCompletion } from '../../../Screens/Process/ConfirmTrialDayCompletion'
import { SelectTariff } from '../../../Screens/Process/SelectTariff'
import { SelectParallel } from '../../../Screens/Process/SelectParallel'
import { SelectEducationPlan } from '../../../Screens/Process/SelectEducationPlan'
import { NotifyOldSchool } from '../../../Screens/Process/NotifyOldSchool'

interface ITableItemActionProps {
    createProcess: (process: string, id: number) => {};
    action: any;
    id: number;
    index: number;
    className: string;
    student: any;
    parent: any;
    education_cost: number;
    constants: ConstantsState;
    contract: ContractState;
}

const TableItemAction: React.FC<ITableItemActionProps> = ({
	action,
	id,
	className,
	student,
	parent,
	education_cost,
	constants,
	contract,
}) => {
	const dispatch = useDispatch()
	const student_id = student?.id
	const parent_id = parent?.id

	const getOverlayComponent = (action: string) => {
		switch (action) {
		case 'distribute_on_course_final': {
			return <DistributeOnCourseFinal student_id={student_id} id={id} />
		}

		case 'change_specialist': {
			return <ChangeDo student_id={student_id} id={id} />
		}

		case 'record_trial': {
			return <RecordTrial student_id={student_id} id={id} />
		}

		case 'distribute_to_school': {
			return <DistributeToSchool student_id={student_id} id={id} />
		}

		case 'set_school_action': {
			return <DistributeToSchool student_id={student_id} id={id} />
		}

		case 'change_school': {
			return <ChangeSchool action_name="change_school" student={student} id={id} />
		}

		case 'change_school_from_other_process': {
			return <ChangeSchool action_name="change_school_from_other_process" student_id={student_id} id={id} />
		}

		case 'set_specialist': {
			return <TransferToDoSpecialist student_id={student_id} id={id} />
		}

		case 'request_contract_changes': {
			return <NeedToChange student_id={student_id} id={id} />
		}

		case 'confirm_address': {
			return <GetAddress parent_id={parent_id} student_id={student_id} id={id} />
		}

		case 'upload_courier_bill': {
			return <UploadCourierBill parent_id={parent_id} student_id={student_id} id={id} />
		}

		case 'send_courier': {
			return <SendCourier process={'send_courier'} parent_id={parent_id} student_id={student_id} id={id} />
		}

		case 'send_internal_courier': {
			return (
				<SendCourier
					process={'send_internal_courier'}
					parent_id={parent_id}
					student_id={student_id}
					id={id}
				/>
			)
		}

		case 'send_copy_to_parent': {
			return (
				<SendCourier
					process={'send_copy_to_parent'}
					parent_id={parent_id}
					student_id={student_id}
					id={id}
				/>
			)
		}

		case 'set_missing_documents': {
			return <MissingDocuments parent_id={parent_id} student_id={student_id} id={id} />
		}

		case 'confirm_documents_received': {
			return <DocumentsReceived parent_id={parent_id} student_id={student_id} id={id} />
		}

		case 'change_trial': {
			return <ChangeTrial student_id={student_id} id={id} />
		}

		case 'set_kontur_url': {
			return <SetConturUrl action="set_kontur_url" student_id={student_id} id={id} parent_id={parent_id} />
		}

		case 'change_kontur_url': {
			return (
				<ChangeConturUrl action="change_kontur_url" student_id={student_id} id={id} parent_id={parent_id} />
			)
		}

		case 'change_education_cost': {
			return (
				<EducationCost
					action_name="change_education_cost"
					parent_id={parent_id}
					student_id={student_id}
					id={id}
				/>
			)
		}

		case 'set_education_cost': {
			return (
				<EducationCost
					action_name="set_education_cost"
					parent_id={parent_id}
					student_id={student_id}
					id={id}
				/>
			)
		}

		case 'pencil_set_data': {
			return <SetPencilData id={id} parent_id={parent_id} />
		}

		case 'pencil_set_tracking_link': {
			return <SetTrackingLink action_name={'pencil_set_tracking_link'} id={id} parent_id={parent_id} />
		}

		case 'pencil_change_tracking_link': {
			return <SetTrackingLink action_name={'pencil_change_tracking_link'} id={id} parent_id={parent_id} />
		}

		case 'add_existing_contract': {
			return <AddExistingContract id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'student_reject': {
			return <HeadTeacherReject id={id} />
		}

		case 'book_grade': {
			return <BookGrade id={id} student_id={student_id} />
		}

		case 'book_foreign_language': {
			return <BookForeignLanguage id={id} student_id={student_id} />
		}

		// case 'set_documents_list': {
		//     return <SetDocumentList id={id} student_id={student_id} parent_id={parent_id} />;
		// }

		case 'booking_to_trial_day': {
			return <BookingToTrialDays id={id} student={student} />
		}

		case 'send_statement_to_parent': {
			return <SendStatementToParent parent_id={parent_id} id={id} />
		}

		case 'sign_agreement_EDC': {
			return <SignAgreementEdc id={id} />
		}

		case 'manual_confirm_courier_payment': {
			return <ManualConfirmCourierPayment id={id} parent_id={parent_id} student_id={student_id} />
		}

		// case "confirm_contract_courier_readiness": {
		//     return <ConfirmContractCourierReadiness id={id} parent_id={parent_id} student_id={student_id}/>
		// }

		case 'manual_confirm_contract_sign': {
			return <ManualConfirmCourierSign id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'generate_additional_agreement': {
			return <GenerateAdditionalAgreement id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'documents_sent': {
			return <DocumentsSent id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'terminate_in_one_click': {
			return <TerminateInOneClick id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'skip_generate_contract': {
			return <SkipGenerateContract id={id} parent_id={parent_id} student_id={student_id} />
		}

		case 'generate_order': {
			return <GenerateOrder action_name="generate_order" id={id} />
		}

		case 'generate_order_existing_contract': {
			return <GenerateOrder action_name="generate_order_existing_contract" id={id} />
		}

		case 'generate_expulsion_order': {
			return <GenerateExpulsionOrder id={id} />
		}

		case 'upload_certificate': {
			return (
				<UploadCertificate
					id={id}
					parent_id={parent_id}
					student_id={student_id}
					process="upload_certificate"
				/>
			)
		}

		case 'change_certificate': {
			return (
				<UploadCertificate
					id={id}
					parent_id={parent_id}
					student_id={student_id}
					process="change_certificate"
				/>
			)
		}

		case 'select_education_cost_action': {
			return (
				<ChooseTariff
					parentId={parent_id}
					studentId={student_id}
					process={'select_education_cost_action'}
					id={id}
				/>
			)
		}
		case 'select_school_action': {
			return <ChooseSchool process={'select_school_action'} id={id} />
		}
		case 'create_contract': {
			return (
				<CreateContractWithInProgress
					id={id}
					student_id={student_id}
					parent_id={parent_id}
					education_cost={education_cost}
					parent={parent}
					student={student}
				/>
			)
		}
		case 'put_in_closet': {
			return <PutInCloset id={id} process="put_in_closet" />
		}
		case 'refuse_of_trial_days': {
			return <RefuseOfTrialDays id={id} />
		}
		case 'confirm_trial_day_completion': {
			return <ConfirmTrialDayCompletion id={id} student_id={student_id} />
		}
		case 'select_tariff': {
			return <SelectTariff id={id} process={'select_tariff'} />
		}
		case 'select_parallel': {
			return <SelectParallel id={id} process={'select_parallel'} />
		}
		case 'book_on_education_plan': {
			return <SelectEducationPlan id={id} process={'book_on_education_plan'} student_id={student_id} />
		}
		case 'notify_old_school': {
			return <NotifyOldSchool id={id} process={'notify_old_school'} student_id={student_id} />
		}
		}
	}

	const handlePutInClosetClick = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process: 'put_in_closet',
				parameters: {},
			}),
		)
	}, [dispatch, id])

	if (action === 'put_in_closet') {
		return (
			<span
				style={{ cursor: 'pointer' }}
				onClick={handlePutInClosetClick}
				className={'label theme-bg text-white f-14'}
			>
				{!!constants?.constants?.ACTIONS && constants.constants.ACTIONS[action]}
			</span>
		)
	}

	if (
		action === 'create_contract' &&
        contract.contracts?.length &&
        contract.contracts?.filter(
        	(contract) =>
        		contract?.student?.id.toString() === String(student_id) &&
                contract?.status.toString() === 'in_progress',
        ).length
	) {
		return (
			<OverlayTrigger
				rootClose
				trigger={'click'}
				placement="auto"
				overlay={<Popover id={action}>{getOverlayComponent(action)}</Popover>}
			>
				<a id={action} style={{ cursor: 'pointer' }} className={'label theme-bg text-white f-14'}>
					{!!constants?.constants?.ACTIONS && constants.constants.ACTIONS[action]}
				</a>
			</OverlayTrigger>
		)
	}

	return (
		<div className={className}>
			{isStateInModal[action] ? (
				<OverlayTrigger
					rootClose
					trigger={'click'}
					placement="auto"
					overlay={<Popover id={action}>{getOverlayComponent(action)}</Popover>}
				>
					<a id={action} style={{ cursor: 'pointer' }} className={'label theme-bg text-white f-14'}>
						{!!constants?.constants?.ACTIONS && constants.constants.ACTIONS[action]}
					</a>
				</OverlayTrigger>
			) : (
				<Link
					to={`/process/${action}/?process=${action}&id=${id}&student_id=${student_id}&parent_id=${parent_id}&education_cost=${education_cost}&parent_name=${parent.last_name} ${parent.first_name} ${parent.patronymic}&student_name=${student.last_name} ${student.first_name} ${student.patronymic}`}
					style={{ cursor: 'pointer' }}
					className={'label theme-bg text-white f-14'}
				>
					{!!constants?.constants?.ACTIONS && constants?.constants?.ACTIONS[action]}
				</Link>
			)}
		</div>
	)
}

export default connect(
	({ constants, contract }: State) => ({ constants, contract }),
	(dispatch) => {
		return {
			createProcess: (process: string, id: number) => {
				return dispatch(
					createProcessStart({
						id,
						process,
					}),
				)
			},
		}
	},
)(TableItemAction)
