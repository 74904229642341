import React, { useCallback } from 'react'
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit'
import './UserCardPopupHeader.scss'
import { UserCardPopupActions, UserCardPopupButtons } from '@components/user'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'

const titleConfig = {
	parent: {
		title: 'Родитель',
		icon: 'parent-l',
	},
	student: {
		title: 'Ученик',
		icon: 'people-l',
	},
}

const UserCardPopupHeader = (props) => {
	const { userType, id, onLoadTab } = props

	const cardData = useSelector((state: State) => state?.[`${userType}CardNew`].cards?.[id])

	const actionsData = cardData?.personalData?.data?.actions
	const personalData = cardData?.personalData?.data?.info
	const phonesData = cardData?.personalData?.data?.info?.phones

	const onMessagesLoad = useCallback(() => {
		if (userType === 'parent') {
			const params = {
				parentId: id,
				tabName: 'communication',
			}
			onLoadTab(params)
		}
	}, [userType, id, onLoadTab])

	const onCardRefresh = () => {
		const params = {
			[`${userType}Id`]: id,
			tabName: 'personalData',
		}
		onLoadTab(params)
	}

	return (
		<div className={'user-card-popup-header'}>
			<div className={'user-card-popup-header-left'}>
				<div className={'user-card-popup-header-left-title'}>
					<AppFontIcon icon={titleConfig[userType].icon} />
					<AppText
						text={`${titleConfig[userType].title} ${personalData?.last_name || ''} ${
							personalData?.first_name || ''
						} ${personalData?.patronymic || ''}`}
					/>
				</div>
			</div>
			<div className={'user-card-popup-header-right'}>
				<UserCardPopupButtons
					id={id}
					userType={userType}
					phoneList={phonesData}
					onMessagesLoad={onMessagesLoad}
				/>
				<UserCardPopupActions id={id} userType={userType} actions={actionsData} onCardRefresh={onCardRefresh} />
			</div>
		</div>
	)
}

export default UserCardPopupHeader
