import React, { useEffect } from 'react'
import { AppButton, AppModal } from '@frontend-modules/ui-kit'
import './NotAllowedModal.scss'
import { INotAllowedModalProps } from './NotAllowedModal.types'
import { onLogout } from '@/common/logout'
/**
 * @param props
 */
const NotAllowedModal = (props: INotAllowedModalProps) => {
	const { isShowModal, setIsShowModal } = props

	const onCloseModal = () => {
		setIsShowModal(false)
		history.back()
	}

	const onReAuthClick = () => {
		onLogout()
		setIsShowModal(false)
	}

	return (
		<AppModal
			style={{ marginTop: 200 }}
			isShow={isShowModal}
			setShow={onCloseModal}
			title={'Недостаточно прав'}
			description={'У текущего аккаунта недостаточно прав для использования сервиса'}
			footer={
				<div className="not-allowed-modal-footer">
					<AppButton type={'outline'} label={'Назад'} onClick={onCloseModal} />
					<AppButton type={'primary'} label={'Сменить аккаунт'} onClick={onReAuthClick} />
				</div>
			}
		/>
	)
}

export default NotAllowedModal
