import { Accordion, Card } from 'react-bootstrap'
import { SearchButton } from '../../../../Components/SearchButton'
import React, { useCallback, useState } from 'react'
import './SearchItem.scss'
import { ISearchItem } from './SearchItem.types'

export const FocusedContext = React.createContext(false)

export const SearchItem: React.FC<ISearchItem> = ({ children, eventKey, title, clearable, clearSearch }) => {
	const [isFocused, setIsFocused] = useState(false)

	const isFocusedChangeCallback = useCallback(
		(value) => {
			setIsFocused(value)
		},
		[setIsFocused],
	)

	return (
		<Card
			className={`search-item__wrapper${isFocused ? ' search-item__wrapper-focused' : ''} ${
				clearable ? 'search-item__wrapper-clearable' : ''
			}`}
			style={{ zIndex: 100 - Number(eventKey) }}
		>
			<FocusedContext.Provider value={isFocused}>
				<Accordion.Toggle
					style={{ cursor: 'pointer' }}
					as={Card.Header}
					onClick={isFocusedChangeCallback}
					eventKey={eventKey}
					className="search-item__header"
				>
					<p>{title}</p>
					{clearable ? (
						<div onClick={clearSearch} className={'search-item__clear'}>
                            &times;
						</div>
					) : (
						<SearchButton />
					)}
				</Accordion.Toggle>

				<Accordion.Collapse
					eventKey={eventKey}
					onEnter={isFocusedChangeCallback.bind(null, true)}
					onExit={isFocusedChangeCallback.bind(null, false)}
				>
					<Card.Body>{children}</Card.Body>
				</Accordion.Collapse>
			</FocusedContext.Provider>
		</Card>
	)
}
