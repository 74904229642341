import { ISidebarListItem } from './Sidebar.types'
export const sidebarList: Array<ISidebarListItem> = [
	{
		id: 'dashboard',
		title: 'Процессы',
		url: '/',
		icon: 'process-l',
		accessKey: 'oldProcesses',
	},
	{
		id: 'processes-collapse',
		title: 'Процессы (+)',
		icon: 'process-l',
		children: [
			{
				id: 'tasks',
				title: 'Задачи (НОВЫЕ)',
				url: '/new/tasks',
				accessKey: 'tasks',
			},
			{
				id: 'processes-monitoring',
				title: 'Мониторинг процессов',
				url: '/processes-monitoring',
				accessKey: 'processes_monitoring',
			},
		],
	},
	{
		id: 'student',
		title: 'Ученики',
		url: '/choose/student',
		icon: 'people-l',
		accessKey: 'studentCard',
	},
	{
		id: 'parent',
		title: 'Родители',
		url: '/parents',
		icon: 'parent-l',
		accessKey: 'parentCard',
	},
	{
		id: 'create-process',
		title: 'Создать бизнес процесс',
		url: '/create-process',
		icon: 'process-add-l',
		accessKey: 'oldProcessCreate',
	},
	{
		id: 'contracts',
		title: 'Договоры',
		url: '/contracts',
		icon: 'treaties-l',
		accessKey: 'oldProcessCreate',
	},
	{
		id: 'finished-tasks',
		title: 'Завершенные процессы',
		url: '/finished-tasks',
		icon: 'check-l',
		accessKey: 'oldTasksFinished',
	},
	{
		id: 'additional-courses-old',
		title: 'Доп. курсы (старые)',
		url: '/additional-courses',
		icon: 'book-l',
		accessKey: 'oldAddEd',
	},
	{
		id: 'additional-courses',
		title: 'Доп. курсы (новые)',
		url: '/additional-courses-new',
		accessKey: 'addEdMonitor',
		icon: 'book-l',
	},
	{
		id: 'payment-collapse',
		title: 'Платежи',
		icon: 'wallet-l',
		children: [
			{
				id: 'freezed-payment',
				title: 'Замороженные платежи',
				url: '/frozen-payment',
				accessKey: 'oldFreezed',
			},
			{
				id: 'payment',
				title: 'Платежи',
				url: '/payment',
				accessKey: 'oldPayment',
			},
			{
				id: 'debts',
				title: 'Учёт долгов',
				url: '/debts',
				accessKey: 'debts',
			},
			{
				id: 'payments',
				title: 'Платежи и ПП',
				url: '/payments',
				accessKey: 'payments',
			},
		],
	},
	{
		id: 'group-actions',
		accessKey: 'groupActions',
		title: 'Групповые действия',
		url: '/group-actions',
		icon: 'table-l',
	},
	{
		id: 'classes-collapse',
		title: 'Классы',
		icon: 'classes-l',
		children: [
			{
				id: 'classes',
				title: 'Классы',
				url: '/classes',
				accessKey: 'studentGroups',
			},
			{
				id: 'course-groups',
				title: 'Курс группы',
				url: '/course-groups',
				accessKey: 'courseGroups',
			},
		],
	},
	{
		id: 'decree',
		title: 'Приказы',
		url: '/decree',
		icon: 'pencil-in-circle-l',
		accessKey: 'groupOrders',
	},
	{
		id: 'userVisits',
		title: 'История запросов',
		url: '/user-visits',
		icon: 'timer-l',
		closeAccessToRoles: ['admin'],
		accessKey: 'userVisits',
	},
	{
		id: 'managerInfo',
		title: 'Статистика менеджеров',
		url: '/manager-info',
		icon: 'user-arrow-l',
		closeAccessToRoles: ['admin'],
		accessKey: 'managerInfo',
	},
	{
		id: 'analytics',
		title: 'Аналитика',
		url: '/analytics',
		icon: 'statistics-l',
		closeAccessToRoles: ['accountant'],
		accessKey: 'analytics',
	},
	{
		id: 'userRemove',
		title: 'Удалить со всех процессов',
		url: '/remove',
		icon: 'delete-l',
		closeAccessToRoles: ['admin'],
		accessKey: 'userRemove',
	},
	{
		id: 'message',
		title: 'Сообщения',
		url: '/message',
		icon: 'mail-l',
		accessKey: 'messages',
	},
]
