import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '@/common/types'
import { loadConstants, loadConstantsError, loadConstantsSuccess } from '../actions/constants'

const loadConstantsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadConstants)),
		switchMap(({}: ActionType<typeof loadConstants>) => {
			const currentConstants = store$?.value?.constants?.constants
			// если константы уже загружены, повторно не вызываем запрос
			const isLoadedYet = !!(Object.keys(currentConstants) || []).length
			if (isLoadedYet) {
				return of(loadConstantsSuccess(currentConstants))
			}
			return from(deps.paymentDataProvider.loadConstants()).pipe(
				switchMap((response) => {
					return of(loadConstantsSuccess(response))
				}),
				catchError((err) => {
					return of(loadConstantsError(err))
				}),
			)
		}),
	)
}

export const constantsEpics = combineEpics(loadConstantsEpic)
