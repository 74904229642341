import { combineReducers } from 'redux'
import { State } from '../store'
import { navBar } from './navBar'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { processes } from './processes'
import { courses, availableCourses, foreignLanguagesCourses, additionalCourses } from './courses'
import { trialDays } from './trialDays'
import { form } from './form'
import { card } from './card'
import { alert } from './alert'
import { document, generatedDocuments } from './document'
import { parentCard } from './parentCard'
import { studentCard } from './studentCard'
import { profile } from './profile'
import { school } from './school'
import { specialist } from './specialist'
import { contract } from './contract'
import { message } from './message'
import { chat } from './chat'
import { buisness } from './buisness'
import { note } from './note'
import { payment, paymentSites } from './payment'
import { constants } from './constants'
import { debtsSearch, search, userVisitsSearch } from './search'
import { students } from './students'
import { users } from './users'
import { documentTypes, createDocType } from './documentTypes'
import { classes, deleteClass } from './classes'
import { frozenPayment } from './frozenPayment'
import { globalClasses } from './globalClasses'
import { contracts } from './contracts'
import { staffUsers } from './staffUsers'
import { unfrozenPayment } from './unfrozenPayment'
import { managerInfo, userVisits } from './userLogging'
import { ancete } from './ancete'
import { problemStudents } from './problemStudents'
import { analytics } from './analytics'
// если нужен персистинг данных, то импортить persisted значение
import { persistedDebtsReducer } from '@store/reducers/debts'
import { persistedTasksReducer } from '@store/reducers/tasks'
import { persistedMetaReducer } from '@store/reducers/meta'
import { persistedProcessesMonitoringReducer } from '@store/reducers/processesMonitoring'
import { parentCardReducer } from '@store/reducers/parentCard'
import { parentsReducer } from '@store/reducers/parents'
import { persistedPaymentsPaysReducer } from '@store/reducers/paymentsPays'
import { persistedPaymentsAssignmentsReducer } from '@store/reducers/paymentsAssignments'
import { studentCardReducer } from '@store/reducers/studentCard'
import { loginReducer } from '@store/reducers/login'
import { persistedAdditionalCoursesReducer } from '@store/reducers/additionalCourses'
import { persistedGroupActionsReducer } from '@store/reducers/groupActions'
import { persistedDecreeReducer } from '@store/reducers/decree'

export const history = createBrowserHistory()

export const reducers = combineReducers<State>({
	navBar,
	router: connectRouter(history),
	processes,
	courses,
	availableCourses,
	trialDays,
	students,
	form,
	card,
	alert,
	document,
	parentCard,
	studentCard,
	documentTypes,
	generatedDocuments,
	createDocType,
	profile,
	school,
	specialist,
	contract,
	message,
	chat,
	buisness,
	users,
	note,
	payment,
	constants,
	search,
	debtsSearch,
	paymentSites,
	classes,
	deleteClass,
	frozenPayment,
	globalClasses,
	foreignLanguagesCourses,
	additionalCourses,
	contracts,
	staffUsers,
	unfrozenPayment,
	userVisits,
	userVisitsSearch,
	ancete,
	problemStudents,
	analytics,
	managerInfo,
	meta: persistedMetaReducer,
	debts: persistedDebtsReducer,
	tasks: persistedTasksReducer,
	processesMonitoring: persistedProcessesMonitoringReducer,
	parentCardNew: parentCardReducer,
	studentCardNew: studentCardReducer,
	parents: parentsReducer,
	paymentsPays: persistedPaymentsPaysReducer,
	paymentsAssignments: persistedPaymentsAssignmentsReducer,
	login: loginReducer,
	additionalCoursesNew: persistedAdditionalCoursesReducer,
	groupActions: persistedGroupActionsReducer,
	decree: persistedDecreeReducer,
})
