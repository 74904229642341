import { $BaseHttpClient, $HttpClient } from '@utils'

export class CoursesService extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список платежей
	 */
	loadAdditionalCourses = (params) => {
		return $HttpClient.post('additional-education/', params)
	}
}
