import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

interface PutInClosetProps {
    id: number;
    process: string;
}

export const PutInCloset: React.FC<PutInClosetProps> = ({ id, process }) => {
	const [school, setSchool] = useState('')
	const dispatch = useDispatch()

	const handleChangeSchool = useCallback((e) => {
		setSchool(e.target.value)
	}, [])

	const handleSubmit = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					school_came_from: school,
				},
			}),
		)
	}, [dispatch, id, process, school])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3" style={{ display: 'block' }}>
				<Popover.Title as="h5">Убрать в шкаф</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex mb-3">
							<Form.Group>
								<Form.Label>Введите школу, из которой прибыл ученик</Form.Label>
								<Form.Control
									name="school_came_from"
									onChange={handleChangeSchool}
									value={school}
									type="text"
									placeholder="Введите школу"
								/>
							</Form.Group>
						</div>
						<Button disabled={!school} onClick={handleSubmit} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
