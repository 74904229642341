import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	addStudentsToCourseGroupError,
	addStudentsToCourseGroupStart,
	addStudentsToCourseGroupSuccess,
	bookAdditionalCoursesError,
	bookAdditionalCoursesStart,
	bookAdditionalCoursesSuccess,
	changeCourseGroupError,
	changeCourseGroupStart,
	changeCourseGroupSuccess,
	createCourseGroupError,
	createCourseGroupStart,
	createCourseGroupSuccess,
	loadAdditionalCoursesError,
	loadAdditionalCoursesStart,
	loadAdditionalCoursesSuccess,
	loadAllCourseGroupsError,
	loadAllCourseGroupsStart,
	loadAllCourseGroupsSuccess,
	loadAvailableCoursesError,
	loadAvailableCoursesStart,
	loadAvailableCoursesSuccess,
	loadCourseGroupsError,
	loadCourseGroupsStart,
	loadCourseGroupsSuccess,
	loadCourses,
	loadCoursesError,
	loadCoursesSuccess,
	loadEducationPlansError,
	loadEducationPlansStart,
	loadEducationPlansSuccess,
	loadForeignLanguagesCourses,
	loadForeignLanguagesCoursesError,
	loadForeignLanguagesCoursesSuccess,
	loadLmsAddCoursesError,
	loadLmsAddCoursesStart,
	loadLmsAddCoursesSuccess,
	loadLmsCoursesError,
	loadLmsCoursesStart,
	loadLmsCoursesSuccess,
	loadTeachersError,
	loadTeachersStart,
	loadTeachersSuccess,
	patchAdditionalCourseError,
	patchAdditionalCourseStart,
	patchAdditionalCourseSuccess,
	removeStudentsCourseGroup,
	removeStudentsCourseGroupError,
	removeStudentsCourseGroupSuccess,
} from '../actions/courses'
import { showAlert } from '../actions/alert'
import { loadGeneratedDocumentsStart } from '../actions/document'

const addStudentsToCourseGroupEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(addStudentsToCourseGroupStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.addStudentsToCourseGroup(payload)).pipe(
				switchMap((courses) => {
					return of(
						addStudentsToCourseGroupSuccess(courses),
						loadCourseGroupsStart({
							filter: {
								course: Number(payload.courseId),
							},
						}),
						showAlert({
							type: 'success',
							title: 'Успех!',
							text: 'Студент добавлен в курс группу.',
						}),
					)
				}),
				catchError((err) => {
					return of(addStudentsToCourseGroupError(err))
				}),
			)
		}),
	)
}

const loadLmsCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadLmsCoursesStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadLmsCourses(payload)).pipe(
				switchMap((courses) => {
					return of(loadLmsCoursesSuccess(courses))
				}),
				catchError((err) => {
					return of(loadLmsCoursesError(err))
				}),
			)
		}),
	)
}

const coursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadCourses)),
		switchMap(({ payload }) => {
			return from(deps.processesDataProvider.loadCourses(payload)).pipe(
				switchMap((courses) => {
					return of(loadCoursesSuccess(courses))
				}),
				catchError((err) => {
					return of(loadCoursesError(err))
				}),
			)
		}),
	)
}

const loadAvailableCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAvailableCoursesStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadAvailableCourses(payload)).pipe(
				switchMap((courses) => {
					return of(loadAvailableCoursesSuccess(courses))
				}),
				catchError((err) => {
					return of(loadAvailableCoursesError(err))
				}),
			)
		}),
	)
}

const loadForeignLanguageCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadForeignLanguagesCourses)),
		switchMap(() => {
			return from(deps.coursesDataProvider.loadForeignLanguageCourses()).pipe(
				switchMap((courses) => {
					return of(loadForeignLanguagesCoursesSuccess(courses))
				}),
				catchError((err) => {
					return of(loadForeignLanguagesCoursesError(err))
				}),
			)
		}),
	)
}

const changeCourseGroupEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(changeCourseGroupStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.changeCourseGroup(payload)).pipe(
				switchMap((res) => {
					return of(
						changeCourseGroupSuccess(res),
						showAlert({
							title: 'Группа успешно изменена!',
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(
						changeCourseGroupError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Группа не была изменена.',
						}),
					)
				}),
			)
		}),
	)
}

const removeStudentsCourseGroupEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(removeStudentsCourseGroup)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.removeStudentsCourseGroup(payload)).pipe(
				switchMap(() => {
					return of(
						removeStudentsCourseGroupSuccess(payload),
						showAlert({
							title: 'Ученик успешно удален из курс группы!',
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(
						removeStudentsCourseGroupError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Ученик не был удален из курс группы.',
						}),
					)
				}),
			)
		}),
	)
}

const createCourseGroupEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createCourseGroupStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.createCourseGroup(payload)).pipe(
				switchMap((res) => {
					return of(
						createCourseGroupSuccess(res),
						showAlert({
							title: 'Группа успешно создана!',
							type: 'success',
						}),
					)
				}),
				catchError((err) => {
					return of(
						createCourseGroupError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Группа не была создана.',
						}),
					)
				}),
			)
		}),
	)
}

const loadTeachersEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadTeachersStart)),
		switchMap(() => {
			return from(deps.coursesDataProvider.loadTeachers()).pipe(
				switchMap((courses) => {
					return of(loadTeachersSuccess(courses))
				}),
				catchError((err) => {
					return of(loadTeachersError(err))
				}),
			)
		}),
	)
}

const loadAdditionalCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAdditionalCoursesStart)),
		switchMap(() => {
			return from(deps.coursesDataProvider.loadAdditionalCourses()).pipe(
				switchMap((courses) => {
					return of(loadAdditionalCoursesSuccess(courses))
				}),
				catchError((err) => {
					return of(loadAdditionalCoursesError(err))
				}),
			)
		}),
	)
}

const loadLmsAddCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadLmsAddCoursesStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadLmsAdditionalCourses(payload)).pipe(
				switchMap((res) => {
					return of(loadLmsAddCoursesSuccess(res))
				}),
				catchError((err) => {
					return of(loadLmsAddCoursesError(err))
				}),
			)
		}),
	)
}

const loadEducationPlansEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadEducationPlansStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadEducationPlans(payload)).pipe(
				switchMap((res) => {
					return of(loadEducationPlansSuccess(res))
				}),
				catchError((err) => {
					return of(loadEducationPlansError(err))
				}),
			)
		}),
	)
}

const patchAdditionalCourseEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(patchAdditionalCourseStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.patchAdditionalCourse(payload)).pipe(
				switchMap((res) => {
					return of(patchAdditionalCourseSuccess(res))
				}),
				catchError((err) => {
					return of(
						patchAdditionalCourseError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Курс не был изменен.',
						}),
					)
				}),
			)
		}),
	)
}

const loadCourseGroupsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadCourseGroupsStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadCourseGroups(payload)).pipe(
				switchMap((res) => {
					return of(loadCourseGroupsSuccess(res))
				}),
				catchError((err) => {
					return of(loadCourseGroupsError(err))
				}),
			)
		}),
	)
}

const loadAllCourseGroupsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadAllCourseGroupsStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.loadCourseGroups(payload)).pipe(
				switchMap((res) => {
					return of(loadAllCourseGroupsSuccess(res))
				}),
				catchError((err) => {
					return of(loadAllCourseGroupsError(err))
				}),
			)
		}),
	)
}

const bookAdditionalCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(bookAdditionalCoursesStart)),
		switchMap(({ payload }) => {
			return from(deps.coursesDataProvider.bookAdditionalCourses(payload)).pipe(
				switchMap((res) => {
					Object.values(res).forEach((urlValue: string) => {
						window.open(urlValue)
					})
					return of(
						bookAdditionalCoursesSuccess(res),
						showAlert({
							title: 'Ученик успешно записан!',
							type: 'success',
						}),
						loadGeneratedDocumentsStart({
							page: store$?.value?.generatedDocuments?.currentPage,
							filter: {
								page_size: 50,
								type: 'contract_add_courses_prepay,contract_add_courses_installment',
							},
						}),
					)
				}),
				catchError((err) => {
					return of(
						bookAdditionalCoursesError(err),
						showAlert({
							type: 'error',
							title: 'Ошибка!',
							text: 'Ученик не был записан.',
						}),
					)
				}),
			)
		}),
	)
}

export const coursesEpics = combineEpics(
	addStudentsToCourseGroupEpic,
	loadLmsCoursesEpic,
	coursesEpic,
	changeCourseGroupEpic,
	loadAvailableCoursesEpic,
	loadForeignLanguageCoursesEpic,
	loadAdditionalCoursesEpic,
	patchAdditionalCourseEpic,
	loadCourseGroupsEpic,
	bookAdditionalCoursesEpic,
	loadAllCourseGroupsEpic,
	loadLmsAddCoursesEpic,
	loadEducationPlansEpic,
	createCourseGroupEpic,
	loadTeachersEpic,
	removeStudentsCourseGroupEpic,
)
