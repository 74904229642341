import { BaseRestDataProvider } from './BaseRestDataProvider'
import { AxiosError, AxiosResponse } from 'axios'
import {
	BookAdditionalCoursesPayload,
	ChangeCourseGroupPayload,
	CreateCourseGroupPayload,
	IAddStudentsToCourseGroupPayload,
	IAvailableCoursesPayload,
	LoadCourseGroupsPayload,
	LoadEducationPlanPayload,
	LoadLmsAddCoursesPayload,
	PatchAdditionalCoursePayload,
	RemoveStudentsCourseGroupPayload,
} from '../store/types/courses'
import { getFilterParams } from '../utils/filters'
import { oldAxios } from './axios-init'

export class CoursesDataProvider extends BaseRestDataProvider {
	addStudentsToCourseGroup = (payload: IAddStudentsToCourseGroupPayload) => {
		return oldAxios
			.patch(`${this.host}/api/lms/proxy/?path=course-groups/external/${payload.courseGroupId}/add-students/`, {
				students: payload.students,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadAvailableCourses = (payload: IAvailableCoursesPayload) => {
		const searchParam = payload.start_year ? `?start_year=${payload.start_year}` : ''
		return oldAxios
			.get(`${this.host}/api/educational_classes/available_courses/${searchParam}`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadForeignLanguageCourses = () => {
		return oldAxios
			.get(`${this.host}/api/moodle/limit_courses/?lang_courses=1&all_courses=0`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadAdditionalCourses = () => {
		return oldAxios
			.get(`${this.host}/api/processes/additional_courses/`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadLmsCourses = (payload: LoadLmsAddCoursesPayload) => {
		const searchParam = getFilterParams(payload?.filter)
		return oldAxios
			.get(`${this.host}/api/lms/proxy/?path=course/external/${searchParam}`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadLmsAdditionalCourses = (payload: LoadLmsAddCoursesPayload) => {
		const searchParam = getFilterParams(payload?.filter)
		return oldAxios
			.get(`${this.host}/api/lms/proxy/?path=course/external/&course_type=additional${searchParam}`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	changeCourseGroup = (payload: ChangeCourseGroupPayload) => {
		return oldAxios
			.patch(`${this.host}/api/lms/proxy/?path=course-groups/external/change/`, payload)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	removeStudentsCourseGroup = (payload: RemoveStudentsCourseGroupPayload) => {
		return oldAxios
			.patch(`${this.host}/api/lms/proxy/?path=course-groups/external/remove-students/`, payload)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	createCourseGroup = (payload: CreateCourseGroupPayload) => {
		return oldAxios
			.post(`${this.host}/api/lms/proxy/?path=course-groups/external/bulk-create/`, [payload])
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadTeachers = () => {
		return oldAxios
			.get(`${this.host}/api/lms/proxy/?path=staff-profile/external/`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	patchAdditionalCourse = (payload: PatchAdditionalCoursePayload) => {
		return oldAxios
			.patch(`${this.host}/api/processes/additional_courses/${payload.course_id}/`, payload.requestBody)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadCourseGroups = (payload: LoadCourseGroupsPayload) => {
		const searchParam = getFilterParams(payload?.filter)
		return oldAxios
			.get(
				`${this.host}/api/lms/proxy/?path=course-groups/external/${
					searchParam ? '?' + searchParam.slice(1) : ''
				}`,
			)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	bookAdditionalCourses = (payload: BookAdditionalCoursesPayload) => {
		return oldAxios
			.post(`${this.host}/api/processes/book_additional_courses/`, payload)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadEducationPlans = (payload: LoadEducationPlanPayload) => {
		const searchParam = getFilterParams(payload?.filter)
		return oldAxios
			.get(`${this.host}/api/lms/proxy/?path=educational-plan/external/${searchParam}`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}
}
