import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadUnfrozenPayment, loadUnfrozenPaymentError, loadUnfrozenPaymentSuccess } from '../actions/unfrozenPayment'
import { Action } from '../store'
import { UnfrozenPaymentState } from '../types/unfrozenPayment'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	payments: [],
	count: 0,
	currentPage: 1,
}

export const unfrozenPayment = (state: UnfrozenPaymentState = initialState, action: Action) => {

	switch (action.type) {

	case getType(loadUnfrozenPayment): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING
		}
	}

	case getType(loadUnfrozenPaymentSuccess): {
		return { 
			...state,
			fetchStatus: FetchStatus.FETCHED,
			payments: action.payload.results,
			count: action.payload.count,
		}
	}
        
	case getType(loadUnfrozenPaymentError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload
		}
	}

	default: {
		return {
			...state
		}
	}
	}
}
