import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadStaffUsers } from '../../../store/actions/staffUsers'

interface StaffUsersFilterProps {
    staffUsersSearch: number[] | string[];
    searchChangeCallback: (value: number[]) => void;
}

export const StaffUsersFilter: React.FC<StaffUsersFilterProps> = ({ staffUsersSearch, searchChangeCallback }) => {
	const dispatch = useDispatch()
	const { fetchStatus: staffUsersFetchStatus, users: staffUsers } = useSelector((store: State) => store.staffUsers)
	const { roles } = useSelector((store: State) => store.profile)

	useEffect(() => {
		if (roles && roles.length && !staffUsers) {
			dispatch(loadStaffUsers({ roles: roles.join(',') }))
		}
	}, [roles])

	return (
		<Select
			placeholder="Выберите пользователя"
			isLoading={staffUsersFetchStatus === FetchStatus.FETCHING && !staffUsers?.length}
			value={
				staffUsersSearch && staffUsersSearch.length
					? staffUsersSearch
						.map((user) => {
							const staffUser = staffUsers?.find((storeStaffUser) => storeStaffUser?.id === user)
							if (staffUser)
								return {
									label: `${staffUser?.last_name || ''} ${staffUser?.first_name || ''} ${
										staffUser?.patronymic || ''
									}`,
									value: staffUser?.id,
								}
							else if (user === 'None') {
								return { label: 'Нет пользователей в процессе', value: 'None' }
							} else return null
						})
						.filter((item) => item !== null)
					: null
			}
			isMulti
			onChange={(value) => searchChangeCallback(value.map((item) => item.value))}
			options={[{ label: 'Нет пользователей в процессе', value: 'None' }].concat(
				staffUsers?.map((el) => {
					return {
						label: `${el.last_name} ${el.first_name} ${el.patronymic}`,
						value: el.id,
					}
				}) || [],
			)}
		/>
	)
}
