import {combineEpics, ofType} from 'redux-observable'
import {from, of} from 'rxjs'
import {catchError, switchMap,} from 'rxjs/operators'
import {getType} from 'typesafe-actions'
import {FuncEpic} from '../../common/types'
import {sendMessage, sendMessageError, sendMessageSuccess} from '../actions/message'
import {showAlert} from '../actions/alert'

const sendMessageEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(sendMessage)),
		switchMap(({payload}) => {
			return from(deps.messageDataProvider.sendMessage(payload)).pipe(
				switchMap(message => {
					return of(sendMessageSuccess(message), showAlert({
						title: 'Успех!',
						text: 'Сообщение успешно отправлено!',
						type: 'success'
					}))
				}),
				catchError(err => {
					return of(sendMessageError(err), showAlert({title: 'Ошибка!', text: JSON.stringify(err.response).slice(0, 50), type: 'error' }))
				})
			)
		})
	)
}

export const messageEpics = combineEpics(sendMessageEpic)
