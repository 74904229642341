import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import './SearchItem.scss'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { SearchItemTypes } from './types'
import { InputSearchOverlay } from './SearchItemOverlay/InputSearchOverlay/InputSearchOverlay'
import { DateRangeSearchOverlay } from './SearchItemOverlay/DateRangeSearchOverlay/DateRangeSearchOverlay'
import { FocusedContext } from '../../Screens/Home/SidebarContent/SearchItem/SearchItem'

interface ISearchItemProps {
    onChange: (e: any) => void;
    title: string;
    value: string;
    onClick: () => void;
    type?: SearchItemTypes;
}

export const SearchItem: React.FC<ISearchItemProps> = ({
	onChange,
	title,
	value,
	onClick,
	type = SearchItemTypes.Input,
}) => {
	const searchChangeCallback = useCallback(
		(e: any) => {
			onChange(e)
		},
		[value],
	)

	const clearSearch = () => {
		onChange({ target: { value: '' } })
		onClick()
	}

	const getSearchItemOverlay = useCallback(
		(type) => {
			switch (type) {
			case SearchItemTypes.Input:
				return (
					<InputSearchOverlay
						searchChangeCallback={searchChangeCallback}
						value={value}
						onClick={onClick}
					/>
				)
			case SearchItemTypes.DateRangePicker:
				return (
					<DateRangeSearchOverlay
						searchChangeCallback={searchChangeCallback}
						onClick={onClick}
						value={value}
					/>
				)
			}
		},
		[searchChangeCallback, value, onClick],
	)

	return (
		<div className="d-flex justify-content-between align-items-center">
			{title}
			<div className={'d-flex align-items-center searchItem-btn'}>
				{value && (
					<div onClick={clearSearch} className={'searchItem-clear'}>
                        &times;
					</div>
				)}
				{getSearchItemOverlay(type)}
			</div>
		</div>
	)
}

export const Search: React.FC<any> = ({ search, searchChangeCallback }) => {
	const searchRef: any = useRef(null)
	const isFocused = useContext(FocusedContext)

	useEffect(() => {
		setTimeout(() => {
			searchRef.current?.focus()
		}, 0)
	}, [isFocused])

	return (
		<Form.Control
			autoFocus
			value={search || ''}
			ref={searchRef}
			onChange={searchChangeCallback}
			type="text"
			placeholder="Поиск"
		/>
	)
}
