import { combineEpics, ofType } from 'redux-observable'
import { FuncEpic } from '@/common/types'
import { getType } from 'typesafe-actions'
import { catchError, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {
	loadMeta,
	onLoadMetaSuccess,
	onLoadMetaError,
	loadMetaCatalog,
	onLoadMetaCatalogSuccess,
	onLoadMetaCatalogError,
	loadMoreMetaCatalog,
	onLoadMoreMetaCatalogSuccess,
	onLoadMoreMetaCatalogError,
} from '@store/actions/meta'

import { normalizeCatalog, normalizeMetaOptionsList } from '@store/normalizers/meta'
const loadMetaEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMeta)),
		switchMap(() => {
			return from(deps.metaService.loadMeta()).pipe(
				switchMap((response) => {
					const normalizedRes = normalizeMetaOptionsList(response)
					return of(onLoadMetaSuccess(normalizedRes))
				}),
				catchError((err) => {
					return of(onLoadMetaError(err))
				}),
			)
		}),
	)
}
const loadMetaCatalogEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMetaCatalog)),
		switchMap(({ payload }) => {
			const { catalogData, searchValue, isNeedFetching } = payload
			const currentCatalogList = store$?.value?.meta?.catalog.api?.[catalogData?.url]?.list || []
			const isLoadedYet = !!currentCatalogList.length
			// если такой каталог уже загружен (в таком же фильтре) и не нужно вручную обновить, то повторно не вызываем запрос
			if (isLoadedYet && !isNeedFetching) {
				return of(onLoadMetaCatalogSuccess({ list: currentCatalogList, url: catalogData?.url }))
			}
			const requestConfig = {
				method: catalogData?.method || 'get',
				url: catalogData?.url,
				params: {
					[catalogData?.query]: searchValue,
					limit: catalogData?.limit,
					currentPage: 1,
				},
			}
			return from(deps.metaService.loadMetaCatalog(requestConfig)).pipe(
				switchMap((response) => {
					const normalizedRes = normalizeCatalog(response.results, catalogData?.url)
					return of(
						onLoadMetaCatalogSuccess({
							url: catalogData?.url,
							list: normalizedRes,
							pagination: response.pagination,
						}),
					)
				}),
				catchError((err) => {
					return of(onLoadMetaCatalogError(err))
				}),
			)
		}),
	)
}

const loadMoreMetaCatalogEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadMoreMetaCatalog)),
		switchMap(({ payload }) => {
			const { catalogData, searchValue, page } = payload
			const requestConfig = {
				method: catalogData?.method || 'get',
				url: catalogData?.url,
				params: {
					[catalogData?.query]: searchValue,
					currentPage: page,
					limit: catalogData?.limit,
				},
			}
			return from(deps.metaService.loadMetaCatalog(requestConfig)).pipe(
				switchMap((response) => {
					const normalizedRes = normalizeCatalog(response.results, catalogData?.url)
					return of(
						onLoadMoreMetaCatalogSuccess({
							url: catalogData?.url,
							list: normalizedRes,
							pagination: response.pagination,
						}),
					)
				}),
				catchError((err) => {
					return of(onLoadMoreMetaCatalogError(err))
				}),
			)
		}),
	)
}

export const metaEpics = combineEpics(loadMetaEpic, loadMetaCatalogEpic, loadMoreMetaCatalogEpic)
