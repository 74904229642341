import { FetchStatus } from '@/common/types'
import { IPayInfo } from '@store/types/paymentsPays'

export enum IAssignmentTypeEnum {
	'incoming' = 'Входящий',
	'outgoing' = 'Исходящий',
}

export interface ISummaryData {
	total: number
	incoming: number
	incomingCoins: number
	outgoing: number
	outgoingCoins: number
	POWithoutPayments: number
	sumMismatch: number
}

export interface IFile {
	id: number
	title: string
	status: string
	uploadErrors: number
	newIncomingCount: number
	incomingCount: number
	outgoingCount: number
	newOutgoingCount: number
	errorsDetail: Array<string>
	fileUrl: string
	fileExt: string
}

export interface IAssignmentInfo {
	id: number
	type: 'incoming'
	number: 'string'
	amount: number
	amountPayments: number
	requisites: [{ label: string; value: string }]
	payments: Array<IPayInfo>
}

export interface IAssignment {
	fetchStatus: FetchStatus
	changeFetchStatus?: FetchStatus
	data: IAssignmentInfo
}

export interface PaymentsAssignmentsState {
	POTableFetchStatus: FetchStatus
	POFilesFetchStatus: FetchStatus
	startDate?: string
	endDate?: string
	assignmentsInfo?: {
		[id: number]: IAssignment
	}
	POFiles?: {
		results: Array<IFile>
		pagination?: {
			maxPage: number
			currentPage: number
			limit: number
			count: number
		}
	}
	POTable?: {
		results: Array<IAssignmentInfo>
		pagination?: {
			maxPage: number
			currentPage: number
			limit: number
			count: number
		}
	}
	summary?: ISummaryData
	query?: {
		filters?: Array<any>
		pagination?: {
			currentPage: number
			limit: number
		}
	}
	errors?: Array<string>
}
