import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const Component: React.FC<any> = ({ sendCourier, student_id, parent_id, id, process = 'send_courier' }) => {
	const [link, setLink] = useState('')

	const linkChangeCallback = useCallback(
		(e: any) => {
			setLink(e.target.value)
		},
		[link],
	)

	const submitButtonPressHandler = () => {
		sendCourier(id, process, {
			tracking_link: link,
		})
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Отправить курьера</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Ссылка для отслеживания</Form.Label>
							<Form.Control
								value={link}
								onChange={linkChangeCallback}
								type="text"
								placeholder="Введите ссылку"
							/>
						</Form.Group>
						<Button onClick={submitButtonPressHandler} disabled={!link} variant="primary">
							Отправить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const SendCourier = connect(
	({}) => ({}),
	(dispatch) => {
		return {
			sendCourier: (id: number, process: string, parameters: any) => {
				return dispatch(
					createProcessStart({
						id,
						process,
						parameters,
					}),
				)
			},
		}
	},
)(Component)

export default SendCourier
