import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { TrialDaysState } from '../../../store/types/trialDays'
import { loadStudentCard } from '../../../store/actions/studentCard'
import { CardState, UserCardState } from '../../../store/types/card'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'

interface IChangeTrialProps {
    trialDays: TrialDaysState;
    id: number;
    student_id: number;
    studentCard: UserCardState;
    recordTrial: (payload: any) => {};
    loadStudentCard: (payload: any) => {};
}

export const Component: React.FC<IChangeTrialProps> = ({ recordTrial, id, student_id, studentCard, loadStudentCard }) => {
    
	const [selectedCourse, setSelectedCourse] = useState<null | string>(null)
	const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))
	const [errors, setErrors] = useState<Array<string>>([])

	useEffect(() => {
		loadStudentCard(student_id)
	}, [student_id])

	useEffect(() => {
		if (studentCard.fetchStatus === FetchStatus.FETCHED) {
			setSelectedCourse(studentCard.card.course)
		}
	}, [studentCard.fetchStatus])

	const startDateChangeCallback = useCallback((e: React.ChangeEvent<any> ) => {
		setErrors([])
		setStartDate(moment(e.target.value).toDate())
	}, [startDate])

	const selectedCourseChangeCallback = useCallback((event: any) => {
		setErrors([])
		setSelectedCourse(event.target.value)
	}, [selectedCourse])


	const submitButtonPressHandler = () => {
		const newErrState = []

		if (!selectedCourse) {
			newErrState.push('selectedCourse')
		}

		if (newErrState.length === 0) {
			recordTrial({
				process: 'change_trial',
				id: id,
				parameters: {
					date: moment(startDate).format('YYYY-MM-DD'),
					course: selectedCourse
				}
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Изменить запись на пробные дни</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					{
						studentCard.fetchStatus === FetchStatus.FETCHED ?
							<Form>
								<div className="d-flex">
									<Form.Group style={{ width: '40%' }} className="mr-3" controlId="formBasicEmail">
										<Form.Label style={{ height: 50 }}>Выберите паралель</Form.Label>
										<Form.Control isInvalid={errors.includes('selectedCourse')} value={selectedCourse == null ? undefined : selectedCourse} onChange={selectedCourseChangeCallback} as="select" placeholder="Выберите паралель" >
											<option>Не выбрано</option>
											{
												[5,6,7,8,9,10,11].map((el: any, index: number) => (<option value={el} key={index}>{el}</option>))
											}
										</Form.Control>
									</Form.Group>

									<Form.Group style={{ width: '50%' }} className="mr-3" controlId="formBasicEmail">
										<Form.Label style={{ height: 50 }}>Выберите дату начала пробных дней</Form.Label>
										<Form.Control value={moment(startDate).format('YYYY-MM-DD')} onChange={startDateChangeCallback} type="date" placeholder="Выберите дату начала пробных дней" />
									</Form.Group>
								</div>
								<div className="d-flex">
								</div>
								<Button onClick={submitButtonPressHandler} disabled={!!errors.length} variant="primary">
                                Записать на пробные дни
								</Button>
							</Form>
							:
							<Skeleton width={100} height={50} />
					}
				</Col>
			</Row>
		</Col>
	)
}


export const ChangeTrial = connect(
	({ courses, trialDays, studentCard }: State, ownProps: any) => ({ trialDays, ...ownProps, studentCard }),
	(dispatch) => {
		return {
			recordTrial: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadStudentCard: (payload: any) => {
				return dispatch(loadStudentCard(payload))
			}
		}
	}
)(Component)
