/**
 * пример
 * из array ['fetching1', 'fetching2']
 * и value 'FETCHING'
 * получаем объект
 * {
 *     fetching1: 'FETCHING',
 *     fetching2: 'FETCHING',
 * }
 * @param array
 * @param value
 */
export const arrayToObjWithValue = (array, value) => {
	const outputObject = {}
	array.forEach((item) => {
		outputObject[item] = value
	})

	return outputObject
}
