import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { IAxiosResponse, IBaseHttpClient, IBaseHttpClientOptions } from './BaseHttpClient.types'

/**
 * @description Базовый класс для работы с сервером
 * НИКОГДА НЕ ИСПОЛЬЗУЙ НАПРЯМУЮ, ТОЛЬКО ЧЕРЕЗ НАСЛЕДОВАНИЕ!
 * @return {Promise<IAxiosResponse>}
 */
export class $BaseHttpClient implements IBaseHttpClient {
	readonly instance: AxiosInstance

	constructor(options?: IBaseHttpClientOptions) {
		this.instance = axios.create({
			baseURL: options?._baseURL,
			headers: options?._headers,
		})

		this.initializeResponseInterceptor()
	}

	private initializeResponseInterceptor() {
		// в случае необходимости можно указать глобальные интерсепеторы для всех экземпляров HttpClient
		// this.instance.interceptors.response.use()
	}

	/**
	 * @description удаляет пустые свойства объекта
	 * @param {any} params
	 * @param {boolean} delEmptyParamsEnable
	 * @return {object | undefined}
	 * @protected
	 */
	protected delEmptyParams(params?: any, delEmptyParamsEnable?: boolean): object | undefined {
		if (delEmptyParamsEnable) {
			for (const key in params) {
				if (params[key] === undefined || params[key] === '') {
					delete params[key]
				}
			}
		}
		return params
	}

	/**
	 * @description - универсальный запрос который регулируется через конфиг
	 * @param config
	 */
	async request(config: AxiosRequestConfig): Promise<IAxiosResponse> {
		return await this.instance.request(config)
	}

	async get(resource: string, params?: object, isDelEmptyParams = true): Promise<IAxiosResponse> {
		return await this.instance.get(resource, {
			params: this.delEmptyParams(params, isDelEmptyParams),
		})
	}

	async post(resource: string, body?: object, params?: object, isDelEmptyParams = true): Promise<IAxiosResponse> {
		return await this.instance.post(resource, this.delEmptyParams(body, isDelEmptyParams), {
			params: this.delEmptyParams(params, isDelEmptyParams),
		})
	}

	async delete(resource: string, body?: object, params?: object, isDelEmptyParams = true): Promise<IAxiosResponse> {
		return await this.instance.delete(resource, {
			params: this.delEmptyParams(params, isDelEmptyParams),
		})
	}

	async put(resource: string, body?: object, params?: object, isDelEmptyParams = true): Promise<IAxiosResponse> {
		return await this.instance.put(resource, this.delEmptyParams(body, isDelEmptyParams), {
			params: this.delEmptyParams(body, isDelEmptyParams),
		})
	}

	async patch(resource: string, body?: object, params?: object, isDelEmptyParams = true): Promise<IAxiosResponse> {
		return await this.instance.patch(resource, this.delEmptyParams(body, isDelEmptyParams), {
			params: this.delEmptyParams(params, isDelEmptyParams),
		})
	}
}
