import { $BaseHttpClient, $HttpClient } from '@utils'

export class StudentServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем таб карточки студента
	 */
	loadStudentCard = (params) => {
		return $HttpClient.post('student-card/', params)
	}
	/**
	 *
	 * @description загружаем / (добавляем) заметки по ребенку
	 */
	loadStudentCardNotes = (params) => {
		return $HttpClient.post('notes/student/', params)
	}
}
