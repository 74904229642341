export const PAGINATION_LIMIT = 20
export const CollapseListTagConfig = {
	error: {
		colors: {
			main: 'rgb(234, 89, 89)',
			back: 'rgb(249, 204, 204)',
		},
		text: 'Ошибка',
	},
	completed: {
		colors: {
			main: 'rgb(47, 146, 154)',
			back: 'rgb(209, 230, 232)',
		},
		text: 'Выполнено',
	},
	cancel: {
		colors: {
			main: 'rgb(229, 161, 57)',
			back: 'rgb(249, 233, 204)',
		},
		text: 'Отменено',
	},
	in_progress: {
		colors: {
			main: '#0088E9',
			back: '#EEF4FB',
		},
		text: 'Выполняется',
	},
}
