import { ActionType, createAction } from 'typesafe-actions'
import { SetStudentsStaffUsersPayload } from '../types/staffUsers'

export const loadStaffUsers = createAction('@staffUsers/load/start')<any>()
export const loadStaffUsersSuccess = createAction('@staffUsers/load/success')<any>()
export const loadStaffUsersError = createAction('@staffUsers/load/error')<any>()

export const updateUsersToProcess = createAction('@staffUsers/update/start')<any>()
export const updateUsersToProcessSuccess = createAction('@staffUsers/update/success')<any>()
export const updateUsersToProcessError = createAction('@staffUsers/update/error')<any>()

export const setStudentsStaffUsers = createAction('@staffUsers/set/start')<SetStudentsStaffUsersPayload>()
export const setStudentsStaffUsersSuccess = createAction('@staffUsers/set/success')<any>()
export const setStudentsStaffUsersError = createAction('@staffUsers/set/error')<any>()

export type StaffUsersActions =
    | ActionType<typeof loadStaffUsers>
    | ActionType<typeof loadStaffUsersSuccess>
    | ActionType<typeof loadStaffUsersError>
    | ActionType<typeof updateUsersToProcess>
    | ActionType<typeof updateUsersToProcessSuccess>
    | ActionType<typeof updateUsersToProcessError>
    | ActionType<typeof setStudentsStaffUsers>
    | ActionType<typeof setStudentsStaffUsersSuccess>
    | ActionType<typeof setStudentsStaffUsersError>;
