import {FuncEpic} from '../../common/types'
import {combineEpics, ofType} from 'redux-observable'
import {ActionType, getType} from 'typesafe-actions'
import {catchError, switchMap} from 'rxjs/operators'
import {from, of} from 'rxjs'
import {loadUsersListError, loadUsersListStart, loadUsersListSuccess} from '../actions/users'

const loadUsersListEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadUsersListStart)),
		switchMap(({payload}: ActionType<typeof loadUsersListStart>) => {
			return from(deps.usersDataProvider.loadUsersList(payload)).pipe(
				switchMap(users => {
					return of(loadUsersListSuccess(users))
				}),
				catchError(err => {
					return of(loadUsersListError(err))
				})
			)
		})
	)
}

export const usersEpics = combineEpics(loadUsersListEpic)
