import {ActionType, createAction} from 'typesafe-actions'


export const loadChat = createAction('@chat/LOAD/START')<{ student_id: number }>()
export const loadChatSuccess = createAction('@chat/LOAD/SUCCESS')<any>()
export const loadChatError = createAction('@chat/LOAD/ERROR')<any>()

export const loadUnreadMessages = createAction('@chat/load/unread/start')()
export const loadUnreadMessagesSuccess = createAction('@chat/load/unread/success')<any>()
export const loadUnreadMessagesError = createAction('@chat/load/unread/error')<any>()

export const sendChatMessageStart = createAction('@chat/SEND/MESSAGE/START')<any>()
export const sendChatMessageSuccess = createAction('@chat/SEND/MESSAGE/SUCCESS')<any>()
export const sendChatMessageError = createAction('@chat/SEND/MESSAGE/ERROR')<any>()

export const openChat = createAction('@chat/OPEN')<any>()
export const closeChat = createAction('@chat/CLOSE')()

export type ChatActions =
    ActionType<typeof loadChat>
    | ActionType<typeof loadChatSuccess>
    | ActionType<typeof loadChatError>
    | ActionType<typeof sendChatMessageStart>
    | ActionType<typeof sendChatMessageSuccess>
    | ActionType<typeof sendChatMessageError>
    | ActionType<typeof openChat>
    | ActionType<typeof closeChat>
    | ActionType<typeof loadUnreadMessages>
    | ActionType<typeof loadUnreadMessagesSuccess>
    | ActionType<typeof loadUnreadMessagesError>;
