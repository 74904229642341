import React, { useEffect, useMemo } from 'react'
import {
	AppCollapse,
	AppDropdownButton,
	AppFontIcon,
	AppSpecialButton,
	AppTag,
	AppText,
} from '@frontend-modules/ui-kit'
import './CollapseList.scss'
import { formatDate, isEmptyList } from '@frontend-modules/frontend-utils'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { CollapseListTagConfig, PAGINATION_LIMIT } from './CollapseList.config'
import { ScrollPaginateContainer } from '@ui'
import { FetchStatus } from '@/common/types'
import { cancelActionByType, loadActionsTypeList, setGroupActionsQuery } from '@store/actions/groupActions'
import { CollapseListItem, GroupActionsSkeleton } from '@components/group-actions'
import { Empty } from 'antd'
import { useHistory } from 'react-router-dom'
import { normalizeDetailFiltersForFilterPanel } from '@store/normalizers/groupActions'
import { truncateString } from '@utils/string'

const CollapseList = (props) => {
	const { type, ...restProps } = props
	const { fetchStatus, list: items, pagination } = useSelector((state: State) => state.groupActions?.[type])

	const history = useHistory()
	const { entities } = useSelector((state: State) => state.meta)

	const isLoading = fetchStatus === FetchStatus.FETCHING
	const isLoadingMore = fetchStatus === FetchStatus.FETCHING_MORE
	const isCollapseListEmpty = isEmptyList(items)

	const dispatch = useDispatch()

	const loadData = (page) => {
		if (!isLoading) {
			dispatch(loadActionsTypeList({ status: type, limit: PAGINATION_LIMIT, currentPage: page, isMore: false }))
		}
	}

	const loadMoreData = (page) => {
		if (
			!isLoading &&
			!isLoadingMore &&
			pagination?.currentPage &&
			pagination?.maxPage &&
			pagination?.currentPage < pagination?.maxPage
		) {
			dispatch(loadActionsTypeList({ status: type, limit: PAGINATION_LIMIT, currentPage: page, isMore: true }))
		}
	}

	const onLoadMore = () => {
		loadMoreData(pagination?.currentPage + 1 || 1)
	}

	useEffect(() => {
		loadData(1)
	}, [dispatch])

	const memoizedItems = useMemo(
		() =>
			items?.map((item) => {
				const onCancelClick = () => {
					dispatch(
						cancelActionByType({
							groupAction: item.id,
							cancel: true,
						}),
					)
				}

				const onRepeatClick = (filters) => {
					const normalizedFitlers = normalizeDetailFiltersForFilterPanel(
						filters,
						entities.groupActions.filters,
					)
					dispatch(setGroupActionsQuery({ filters: normalizedFitlers }))
					history.push('/group-actions/create')
				}

				return {
					...item,
					title: (
						<div className={`group-action-collapse-table-item ${item.status}`}>
							{item.status !== 'planned' && (
								<div className={'group-action-collapse-table__status'}>
									<AppTag
										color={CollapseListTagConfig?.[item.status]?.colors}
										text={CollapseListTagConfig?.[item.status]?.text}
									/>
									<div>
										<AppFontIcon icon={'progress-s'} />
										{item.percent || 0} %
									</div>
								</div>
							)}
							<div className={'group-action-collapse-table__title'}>
								<AppText text={item.title} textStyle={'DesktopH4'} />
								<div className={'group-action-collapse-table__title-footer'}>
									<div className={'group-action-collapse-table__title-footer-item'}>
										<AppFontIcon icon={'calendar-s'} />
										<AppText text={formatDate(item.runDateTime)} textStyle={'DesktopFootNotes'} />
									</div>
									<div className={'group-action-collapse-table__title-footer-item'}>
										<AppFontIcon icon={'time-s'} />
										<AppText
											text={formatDate(item.runDateTime, 'HH:mm')}
											textStyle={'DesktopFootNotes'}
										/>
									</div>
									<div className={'group-action-collapse-table__title-footer-item'}>
										<AppFontIcon icon={'user-s'} />
										<AppText text={item.author} textStyle={'DesktopFootNotes'} />
									</div>
								</div>
							</div>
							<div
								className={'group-action-collapse-table__controls'}
								onClick={(e) => e.stopPropagation()}
							>
								{!!item.files.length && (
									<AppDropdownButton
										onClick={(a) => {
											window.open(a.key, '_blank')
										}}
										items={item.files?.map((file) => {
											return { key: file.url, label: truncateString(file.title, 25, 7) }
										})}
										title={'Скачать'}
									/>
								)}
								{type === 'planned' && (
									<div>
										<div id={`groupActionSaveButtonContainer-${item.id}`}></div>
										<div id={`groupActionCancelButtonContainer-${item.id}`}></div>
									</div>
								)}
								<AppSpecialButton
									type={'dotted'}
									icon={'timer-l'}
									onClick={() => onRepeatClick(item.data.filters)}
								/>
								{type === 'planned' && (
									<AppSpecialButton
										type={'dotted'}
										icon={'delete-l'}
										color={'red'}
										onClick={onCancelClick}
									/>
								)}
							</div>
						</div>
					),
					content: (
						<div className={'group-action-collapse-table__content'}>
							<CollapseListItem data={item} type={type} />
						</div>
					),
				}
			}),
		[dispatch, entities.groupActions.filters, history, items, type],
	)

	return (
		<div className={'group-action-collapse-wrapper'}>
			{isLoading && isCollapseListEmpty && <GroupActionsSkeleton />}
			{!isCollapseListEmpty && (
				<ScrollPaginateContainer
					isLoadingMore={isLoadingMore}
					onEndReached={onLoadMore}
					onEndReachedThreshold={0.9}
				>
					<AppCollapse
						className={'group-action-collapse-table'}
						panelProps={{ showArrow: false }}
						items={memoizedItems}
						{...restProps}
					/>
				</ScrollPaginateContainer>
			)}
			{!isLoading && isCollapseListEmpty && <Empty description={'Действия не найдены'} />}
		</div>
	)
}

export default CollapseList
