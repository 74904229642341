import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import {
	fetchActiveParents,
	fetchActiveParentsError,
	fetchActiveParentsSuccess,
	fetchActiveParentsWithContracts,
	fetchActiveParentsWithContractsError,
	fetchActiveParentsWithContractsSuccess,
	fetchPaymentCount,
	fetchPaymentCountError,
	fetchPaymentCountSuccess,
} from '../actions/analytics'

const fetchActiveParentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(fetchActiveParents)),
		switchMap(({ payload: { start, end } }) => {
			return from(deps.usersDataProvider.loadActiveParents(start, end)).pipe(
				switchMap((parents) => {
					return of(fetchActiveParentsSuccess(parents))
				}),
				catchError((err) => {
					return of(fetchActiveParentsError(err))
				}),
			)
		}),
	)
}

const fetchActiveParentsWithContractsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(fetchActiveParentsWithContracts)),
		switchMap(({ payload: { start, end } }) => {
			return from(deps.usersDataProvider.loadActiveParentsWithContracts(start, end)).pipe(
				switchMap((parents) => {
					return of(fetchActiveParentsWithContractsSuccess(parents))
				}),
				catchError((err) => {
					return of(fetchActiveParentsWithContractsError(err))
				}),
			)
		}),
	)
}

const fetchPaymentCountEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(fetchPaymentCount)),
		switchMap(({ payload: { start, end } }) => {
			return from(deps.paymentDataProvider.fetchPaymentCount(start, end)).pipe(
				switchMap((parents) => {
					return of(fetchPaymentCountSuccess(parents))
				}),
				catchError((err) => {
					return of(fetchPaymentCountError(err))
				}),
			)
		}),
	)
}

export const analyticsEpics = combineEpics(
	fetchActiveParentsEpic,
	fetchActiveParentsWithContractsEpic,
	fetchPaymentCountEpic,
)
