import React, { useCallback, useState } from 'react'
import { AppHardModalButton, AppCard, AppText, AppSearchSelect } from '@frontend-modules/ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import { loadMoreParents, loadParents } from '@store/actions/parents'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import './Parents.scss'

/**
 * @description - страница "Родители"
 * @constructor
 */
const Parents = () => {
	const { fetchStatus, data } = useSelector((state: State) => state.parents)
	const { results, pagination } = data || {}

	const [selectedParent, setSelectedParent] = useState(null)

	const dispatch = useDispatch()

	const loadParentList = useCallback(
		(params) => {
			const payload = {
				limit: params.limit,
				currentPage: params.currentPage,
				fullname: params.search,
			}
			dispatch(loadParents(payload))
		},
		[dispatch],
	)

	const loadMoreParentList = (params) => {
		const payload = {
			limit: params.limit,
			currentPage: params.currentPage,
			fullname: params.search,
		}
		dispatch(loadMoreParents(payload))
	}

	return (
		<AppCard title={<AppText textStyle={'Title'} style={{ margin: '0' }} text={'Выбор родителя'} />}>
			<div className={'parent-page'}>
				<AppSearchSelect
					results={results}
					pagination={pagination}
					label={'Выберите родителя'}
					placeholder={'Введите ФИО родителя'}
					selectedValue={selectedParent}
					onSetSelectedValue={setSelectedParent}
					labelFields={['lastName', 'firstName', 'patronymic']}
					isFetching={fetchStatus === FetchStatus.FETCHING}
					onLoad={loadParentList}
					onLoadMore={loadMoreParentList}
					isFetchingMore={fetchStatus === FetchStatus.FETCHING_MORE}
				/>
				<AppHardModalButton
					query={{ type: HARD_MODAL_ENUMS.USER_CARD, userType: 'parent', id: selectedParent?.value }}
					disabled={!selectedParent}
					label={'Перейти в карточку'}
				/>
			</div>
		</AppCard>
	)
}

export default Parents
