import React, { FC, useCallback, useEffect, useState } from 'react'
import {
	AppButton,
	AppCard,
	AppHardModalButton,
	AppNumberInput,
	AppSearchSelect,
	AppSpecialButton,
	AppSwitch,
	AppText,
} from '@frontend-modules/ui-kit'

import { PaymentsService } from '@services'
import { FetchStatus } from '@/common/types'
import { usePrevState } from '@/hooks'
import './PayDistribution.scss'
import { parseDataToSelectObj, isExistPayWithTarget } from './PayDistribution.utils'
import { HARD_MODAL_ENUMS } from '@components/layouts/Modals/Modals.config'
import { showErrorToast } from '@utils'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import { IPayDistributionProps } from './PayDistribution.types'
const paymentsService = new PaymentsService()

const PayDistribution: FC<IPayDistributionProps> = (props) => {
	const {
		inModal = false,
		onSave,
		onCancel,
		changeFetchStatus,
		data,
		personalAccount,
		title = 'Распределение платежа',
		defaultSum,
		isMotherCapital,
		isSumDisabled = true,
		isNeedRenderSum = true,
	} = props

	const [sum, setSum] = useState(defaultSum)
	const [personalAccountLocal, setPersonalAccountLocal] = useState(null)
	const [personalAccountLocalList, setPersonalAccountLocalList] = useState(null)
	const [personalAccountLocalFetchingStatus, setPersonalAccountLocalFetchingStatus] = useState(FetchStatus.INITIAL)
	const [contract, setContract] = useState(null)
	const [selectedContractList, setSelectedContractList] = useState([])
	const [contractList, setContractList] = useState(null)
	const [removedDistributionList, setRemovedDistributionList] = useState([])
	const [contractFetchStatus, setContractFetchingStatus] = useState(FetchStatus.INITIAL)
	const [isTargetPay, setTargetPay] = useState(true)
	const [isMotherCapitalLocal, setMotherCapitalLocal] = useState(isMotherCapital)
	const [isEditState, setEditState] = useState(inModal)

	const isPersonalAccountLocalDisabled = isExistPayWithTarget(selectedContractList)

	const isSaveButtonDisabled =
		!personalAccountLocal ||
		(!sum && isNeedRenderSum) ||
		(isTargetPay && (!selectedContractList.length || !!selectedContractList.find((con) => !con.amount)))

	useEffect(() => {
		setDefaultContractList()
		setDefaultTargetPay()
	}, [data])

	const setDefaultPersonalAccountLocal = () => {
		if (personalAccount?.id && personalAccount.user) {
			const title = `ЛС ${personalAccount.user.lastName} ${personalAccount.user.firstName} ${personalAccount.user.patronymic}`
			setPersonalAccountLocal(parseDataToSelectObj(personalAccount.id, title))
		} else {
			setPersonalAccountLocal(null)
		}
	}

	const setDefaultTargetPay = () => {
		const isTarget = isExistPayWithTarget(data)
		setTargetPay(isTarget)
		if (!isTarget) {
			setMotherCapitalLocal(false)
		}
	}

	const setDefaultContractList = () => {
		const listWithId = data.map((item) => {
			return {
				...item,
				distributionId: item.id,
			}
		})
		setSelectedContractList(listWithId)
	}

	useEffect(() => {
		if (inModal) {
			setSum(defaultSum)
		}
	}, [inModal, defaultSum])

	useEffect(() => {
		setDefaultPersonalAccountLocal()
	}, [personalAccount])

	const prevChangeFetchStatus = usePrevState(changeFetchStatus)

	const loadPersonalAccountLocalList = useCallback(
		(params, isMore) => {
			setPersonalAccountLocalFetchingStatus(isMore ? FetchStatus.FETCHING_MORE : FetchStatus.FETCHING)
			paymentsService.findPersonalAccount(params).then((res: any) => {
				setPersonalAccountLocalFetchingStatus(FetchStatus.FETCHED)
				if (isMore) {
					const newPagination = res.pagination
					const newList = [...(personalAccountLocalList?.results || []), ...res.results]
					setPersonalAccountLocalList({ pagination: newPagination, results: newList })
				} else {
					setPersonalAccountLocalList(res)
				}
			})
		},
		[personalAccountLocalList],
	)

	const loadMorePersonalAccountLocalList = useCallback(
		(params) => {
			loadPersonalAccountLocalList?.(params, true)
		},
		[loadPersonalAccountLocalList],
	)

	const loadContractList = useCallback(
		(params, isMore) => {
			setContractFetchingStatus(isMore ? FetchStatus.FETCHING_MORE : FetchStatus.FETCHING)
			paymentsService.findContract(params).then((res: any) => {
				setContractFetchingStatus(FetchStatus.FETCHED)
				if (isMore) {
					const newPagination = res.pagination
					const newList = [...(contractList?.results || []), ...res.results]
					setContractList({ pagination: newPagination, results: newList })
				} else {
					setContractList(res)
				}
			})
		},
		[contractList],
	)

	const loadMoreContractList = useCallback(
		(params) => {
			loadContractList?.(params, true)
		},
		[loadContractList],
	)

	const onContractSelect = useCallback(
		(val) => {
			setContract(undefined)
			if (val) {
				const isContractExistInList = selectedContractList.find((con) => con.contractId === val.id)
				if (isContractExistInList) {
					showErrorToast({ message: 'Этот договор уже есть в списке распределений' })
					return
				}
				const { personalAccountId, personalAccountTitle } = val
				if (personalAccountId && personalAccountTitle) {
					if (personalAccountLocal?.id) {
						if (personalAccountId !== personalAccountLocal.id) {
							showErrorToast({ message: 'Выбранный договор не относится к выбранному ЛС' })
							return
						}
					}
					setPersonalAccountLocal(parseDataToSelectObj(personalAccountId, personalAccountTitle))
				}
				const valWithContractId = { ...val, contractId: val.id }
				const newList = [...(selectedContractList || []), valWithContractId]
				setSelectedContractList(newList)
			}
		},
		[personalAccountLocal, selectedContractList],
	)

	const onDistributionRemove = (distribution) => {
		const filteredContractList = selectedContractList.filter((item) => item.id !== distribution.id)
		if (distribution.distributionId) {
			setRemovedDistributionList([...removedDistributionList, distribution])
		}
		setSelectedContractList(filteredContractList)
	}

	const onContractSumChange = (con, newSum) => {
		const changedSumInArray = selectedContractList.map((item) => {
			if (item.id === con.id) {
				return {
					...item,
					amount: newSum,
				}
			}
			return item
		})
		setSelectedContractList(changedSumInArray)
	}

	const onSaveLocal = () => {
		const payload: any = {
			personalAccount: personalAccountLocal?.id || null,
			isMotherCapital: isMotherCapitalLocal,
		}
		const onlyContractList = selectedContractList.filter((con) => con.contractId && !con.distributionId)
		payload.addContracts = onlyContractList?.map((con) => {
			return { amount: con.amount || 0, contract: con.contractId }
		})
		payload.deleteDistributions = removedDistributionList.map((con) => con.distributionId)
		if (!isSumDisabled) {
			payload.amount = sum
		}
		onSave?.(payload)
	}

	const reInitData = () => {
		setDefaultContractList()
		setDefaultTargetPay()
		setDefaultPersonalAccountLocal()
		setPersonalAccountLocalList(null)
		setContract(null)
		setContractList(null)

		setRemovedDistributionList([])
		setEditState(inModal)
		setSum(defaultSum)

		setPersonalAccountLocalFetchingStatus(FetchStatus.INITIAL)
		setContractFetchingStatus(FetchStatus.INITIAL)
	}

	useEffect(() => {
		if (prevChangeFetchStatus === FetchStatus.FETCHING && changeFetchStatus === FetchStatus.FETCHED) {
			reInitData?.()
		}
	}, [prevChangeFetchStatus, changeFetchStatus, reInitData])

	return (
		<AppCard
			title={
				<>
					{title}
					{!isEditState && (
						<AppSpecialButton
							icon={'pencil-l'}
							onClick={() => {
								setEditState(true)
							}}
						/>
					)}
				</>
			}
			className={'pay-distribution'}
		>
			{isEditState ? (
				<>
					<div className={'pay-distribution-content'}>
						{isNeedRenderSum && (
							<AppNumberInput
								label={'Сумма'}
								disabled={isSumDisabled}
								defaultValue={sum}
								min={-999999999999999}
								className={'number-input'}
								onChange={setSum}
							/>
						)}
						<div className={'pay-distribution-content__switches'}>
							<AppSwitch
								disabled={isPersonalAccountLocalDisabled}
								label={'Целевой платеж'}
								value={isTargetPay}
								defaultValue={isTargetPay}
								onChange={(isCheck) => {
									setTargetPay(isCheck)
									if (!isCheck) {
										setMotherCapitalLocal(false)
									}
								}}
							/>
							<AppSwitch
								disabled={!isTargetPay}
								label={'Мат. Капитал'}
								value={isMotherCapitalLocal}
								defaultValue={isMotherCapitalLocal}
								onChange={setMotherCapitalLocal}
							/>
						</div>
						{isTargetPay && (
							<AppSearchSelect
								label={'Номер договора'}
								placeholder={'Введите номер договора/ФИО родителя'}
								results={contractList?.results}
								pagination={contractList?.pagination}
								selectedValue={contract}
								onSetSelectedValue={onContractSelect}
								labelFields={['title']}
								isFetching={contractFetchStatus === FetchStatus.FETCHING}
								onLoad={loadContractList}
								onLoadMore={loadMoreContractList}
								isFetchingMore={contractFetchStatus === FetchStatus.FETCHING_MORE}
							/>
						)}
						{!!selectedContractList.length && (
							<div className={'pay-distribution-content__contracts'}>
								<AppText
									className={'pay-distribution-content__contracts-title'}
									color={'rgb(145, 147, 153)'}
									text={'Распределения'}
								/>
								{selectedContractList?.map((item, index) => (
									<div
										key={`${item.id}-${index}-${item.distributionId}`}
										className={'pay-distribution-content__contracts-item'}
									>
										<AppText textStyle={'DesktopH4'} text={`${index + 1}. ${item.title}`} />
										<div className={'pay-distribution-content__contracts-item__controls'}>
											<AppNumberInput
												defaultValue={item.amount}
												onChange={(val) => {
													onContractSumChange(item, val)
												}}
												disabled={item.distributionId || !item.id}
												placeholder={'Сумма начисления'}
											/>
											<AppSpecialButton
												icon={'delete-l'}
												color={'red'}
												isDisabled={!item.id}
												type={'bordered'}
												onClick={() => {
													onDistributionRemove(item)
												}}
											/>
										</div>
									</div>
								))}
							</div>
						)}
						<AppSearchSelect
							label={'Лицевой счет'}
							placeholder={'Введите номер ЛС/ФИО родителя или ученика'}
							results={personalAccountLocalList?.results}
							pagination={personalAccountLocalList?.pagination}
							selectedValue={personalAccountLocal}
							onSetSelectedValue={setPersonalAccountLocal}
							disabled={isPersonalAccountLocalDisabled}
							labelFields={['title']}
							isFetching={personalAccountLocalFetchingStatus === FetchStatus.FETCHING}
							onLoad={loadPersonalAccountLocalList}
							onLoadMore={loadMorePersonalAccountLocalList}
							isFetchingMore={personalAccountLocalFetchingStatus === FetchStatus.FETCHING_MORE}
						/>
					</div>
					<div className={'pay-distribution-footer'}>
						<AppButton
							type={'outline'}
							label={'Отменить'}
							onClick={() => {
								if (inModal) {
									onCancel?.()
									reInitData()
								} else {
									setEditState(false)
								}
							}}
						/>
						<AppButton
							label={'Сохранить'}
							isDisabled={isSaveButtonDisabled}
							isLoading={changeFetchStatus === FetchStatus.FETCHING}
							onClick={onSaveLocal}
						/>
					</div>
				</>
			) : (
				<div className={'pay-distribution-view'}>
					<div className={'pay-distribution-view-item'}>
						Лицевой счет
						{personalAccount ? (
							<AppHardModalButton
								buttonType={'link'}
								label={`${personalAccount?.user?.lastName} ${personalAccount?.user?.firstName} ${personalAccount?.user?.patronymic}`}
								query={{
									type: HARD_MODAL_ENUMS.USER_CARD,
									id: String(personalAccount?.user?.id),
									userType: 'parent',
								}}
							/>
						) : (
							<AppText text={'Не установлен'} textStyle={'DesktopH4'} />
						)}
					</div>
					<div className={'pay-distribution-view-item'}>
						<AppText text={'Тип платежа'} />
						<AppText
							text={
								isTargetPay
									? `Целевой ✅ ${isMotherCapital ? '(Мат. Капитал 👩‍👧‍👦)' : ''}`
									: 'Нецелевой ❌'
							}
							textStyle={'DesktopH4'}
						/>
					</div>
					<div className={'pay-distribution-view-item'}>
						<AppText text={'Распределения'} />
						{!data?.length && <AppText text={'Отсутствуют'} textStyle={'DesktopH4'} />}
						{data?.map((cont, index) => {
							return (
								<AppText
									key={index}
									text={`${index + 1}. ${cont.title} (${trimSeparator(cont.amount)} р)`}
									textStyle={'DesktopH4'}
								/>
							)
						})}
					</div>
				</div>
			)}
		</AppCard>
	)
}

export default PayDistribution
