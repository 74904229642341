import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import {
	identificateStart,
	identificateSuccess,
	identificateError,
	loadPaymentSitesStart,
	loadPaymentSitesSuccess,
	loadPaymentSitesError,
	setUnverifiedPaymentCurrentPage,
	setVerifiedPaymentCurrentPage,
	setCurrentDebtsPage,
	loadVerPaymentsStart,
	loadVerPaymentsSuccess,
	loadVerPaymentsError,
	loadUnverPaymentsStart,
	loadUnverPaymentsSuccess,
	loadUnverPaymentsError,
	setUnverifiedQueryString,
	setVerifiedQueryString,
} from '../actions/payment'
import { Action } from '../store'
import { PaymentSitesState, PaymentState } from '../types/payment'

const paymentInitialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	verified: [],
	unverified: [],
	paymentsLoading: [],
	unverifiedCurrentPage: 1,
	unverifiedTotalCount: 0,
	unverifiedQueryString: '',
	verifiedCurrentPage: 1,
	verifiedTotalCount: 0,
	verifiedQueryString: '',
}

export const payment = (state: PaymentState = paymentInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadVerPaymentsStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadVerPaymentsSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				verified: action.payload.results,
				verifiedTotalCount: action.payload.count,
			}
		}

		case getType(loadVerPaymentsError): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				error: action.payload,
			}
		}

		case getType(loadUnverPaymentsStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadUnverPaymentsSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				unverified: action.payload.results,
				unverifiedTotalCount: action.payload.count,
			}
		}

		case getType(loadUnverPaymentsError): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				error: action.payload,
			}
		}

		case getType(identificateStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(identificateSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
			}
		}

		case getType(identificateError): {
			return {
				...state,
				error: action.payload,
			}
		}

		case getType(setUnverifiedPaymentCurrentPage): {
			return {
				...state,
				unverifiedCurrentPage: action.payload,
			}
		}

		case getType(setVerifiedPaymentCurrentPage): {
			return {
				...state,
				verifiedCurrentPage: action.payload,
			}
		}

		case getType(setUnverifiedQueryString): {
			return {
				...state,
				unverifiedQueryString: action.payload,
			}
		}

		case getType(setVerifiedQueryString): {
			return {
				...state,
				verifiedQueryString: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

const paymentSitesInitialState: PaymentSitesState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	paymentSites: null,
	currentPage: 1,
	totalCount: 0,
}

export const paymentSites = (state: PaymentSitesState = paymentSitesInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadPaymentSitesStart): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}

		case getType(loadPaymentSitesSuccess): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				paymentSites: action.payload.results,
				totalCount: action.payload.count,
			}
		}

		case getType(loadPaymentSitesError): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHED,
				error: action.payload,
			}
		}
		case getType(setCurrentDebtsPage): {
			return {
				...state,
				currentPage: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}
