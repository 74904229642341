import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { showAlert } from '../actions/alert'
import {
	approveDocumentError,
	approveDocumentStart,
	approveDocumentSuccess,
	deleteDocument,
	deleteDocumentError,
	deleteDocumentSuccess,
	loadDocumentList,
	loadDocumentListSuccess,
	loadGeneratedDocumentsError,
	loadGeneratedDocumentsStart,
	loadGeneratedDocumentsSuccess,
	uploadDocument,
	uploadDocumentError,
	uploadDocumentSuccess,
} from '../actions/document'

const uploadDocumentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(uploadDocument)),
		switchMap(
			({ payload: { student, file, uploaded_by, user, upload_doc_type } }: ActionType<typeof uploadDocument>) => {
				return from(
					deps.documentsDataProvider.uploadDocuments(student, file, user, uploaded_by, upload_doc_type),
				).pipe(
					switchMap((response) => {
						return of(
							uploadDocumentSuccess(response),
							loadDocumentList({ student, user }),
							showAlert({ title: 'Успех!', text: 'Файл успешно загружен.', type: 'success' }),
						)
					}),
					catchError((err) => {
						return of(uploadDocumentError(err))
					}),
				)
			},
		),
	)
}

const loadDocumentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadDocumentList)),
		switchMap(({ payload }: ActionType<typeof loadDocumentList>) => {
			return from(deps.documentsDataProvider.loadDocuments(payload)).pipe(
				switchMap((response) => {
					return of(loadDocumentListSuccess(response.results))
				}),
			)
		}),
	)
}

const approveDocumentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(approveDocumentStart)),
		switchMap(({ payload: { document_id, student_id } }: ActionType<typeof approveDocumentStart>) => {
			return from(deps.documentsDataProvider.approveDocument(document_id)).pipe(
				switchMap((response) => {
					return of(approveDocumentSuccess(response), loadDocumentList({ student_id }))
				}),
				catchError((err) => {
					return of(approveDocumentError(err))
				}),
			)
		}),
	)
}

const loadGeneratedDocumentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadGeneratedDocumentsStart)),
		switchMap(({ payload }) => {
			return from(deps.documentsDataProvider.loadGeneratedDocuments(payload)).pipe(
				switchMap((response) => {
					return of(loadGeneratedDocumentsSuccess(response))
				}),
				catchError((err) => {
					return of(loadGeneratedDocumentsError(err))
				}),
			)
		}),
	)
}

const deleteDocumentsEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(deleteDocument)),
		switchMap(({ payload }: ActionType<typeof deleteDocument>) => {
			return from(deps.documentsDataProvider.deleteDocument(payload)).pipe(
				switchMap(() => {
					return of(
						deleteDocumentSuccess(payload),
						showAlert({ title: 'Успех!', text: 'Файл успешно удален.', type: 'success' }),
					)
				}),
				catchError((err) => {
					return of(
						deleteDocumentError(err),
						showAlert({ title: 'Ошибка!', text: 'Файл не был удален.', type: 'error' }),
					)
				}),
			)
		}),
	)
}

export const documentEpics = combineEpics(
	uploadDocumentEpic,
	loadDocumentEpic,
	approveDocumentEpic,
	loadGeneratedDocumentsEpic,
	deleteDocumentsEpic,
)
