import { $BaseHttpClient, $HttpClient } from '@utils'

export class DecreeServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список приказов
	 */
	loadDecree = (params) => {
		return $HttpClient.post('api/v1/group-orders/', params)
	}
}
