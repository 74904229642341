import { ActionType, createAction } from 'typesafe-actions'
import {
	BookAdditionalCoursesPayload,
	ChangeCourseGroupPayload,
	Course,
	CreateCourseGroupPayload,
	IAddStudentsToCourseGroupPayload,
	IAvailableCoursesPayload,
	LoadCourseGroupsPayload,
	LoadEducationPlanPayload,
	LoadLmsAddCoursesPayload,
	PatchAdditionalCoursePayload,
	RemoveStudentsCourseGroupPayload,
} from '../types/courses'

export const addStudentsToCourseGroupStart = createAction(
	'@courses/ADD_STUDENTS_TO_COURSE_GROUP/START',
)<IAddStudentsToCourseGroupPayload>()
export const addStudentsToCourseGroupSuccess = createAction('@courses/ADD_STUDENTS_TO_COURSE_GROUP/SUCCESS')<unknown>()
export const addStudentsToCourseGroupError = createAction('@courses/ADD_STUDENTS_TO_COURSE_GROUP/ERROR')<unknown>()

export const loadLmsCoursesStart = createAction('@courses/LOAD_LMS_COURSES/START')<LoadLmsAddCoursesPayload>()
export const loadLmsCoursesSuccess = createAction('@courses/LOAD_LMS_COURSES/SUCCESS')<Course[]>()
export const loadLmsCoursesError = createAction('@courses/LOAD_LMS_COURSES/ERROR')<unknown>()

export const loadCourses = createAction('@courses/LOAD/START')<any>()
export const loadCoursesSuccess = createAction('@courses/LOAD/SUCCESS')<any>()
export const loadCoursesError = createAction('@courses/LOAD/ERROR')<any>()

export const changeCourseGroupStart = createAction(
	'@coursesAdditional/CHANGE_COURSE_GROUP/START',
)<ChangeCourseGroupPayload>()
export const changeCourseGroupSuccess = createAction('@coursesAdditional/CHANGE_COURSE_GROUP/SUCCESS')<any>()
export const changeCourseGroupError = createAction('@coursesAdditional/CHANGE_COURSE_GROUP/ERROR')<any>()

export const removeStudentsCourseGroup = createAction(
	'@coursesAdditional/REMOVE_STUDENTS_COURSE_GROUP/START',
)<RemoveStudentsCourseGroupPayload>()
export const removeStudentsCourseGroupSuccess = createAction(
	'@coursesAdditional/REMOVE_STUDENTS_COURSE_GROUP/SUCCESS',
)<any>()
export const removeStudentsCourseGroupError = createAction(
	'@coursesAdditional/REMOVE_STUDENTS_COURSE_GROUP/ERROR',
)<any>()

export const createCourseGroupStart = createAction(
	'@coursesAdditional/CREATE_COURSE_GROUP/START',
)<CreateCourseGroupPayload>()
export const createCourseGroupSuccess = createAction('@coursesAdditional/CREATE_COURSE_GROUP/SUCCESS')<any>()
export const createCourseGroupError = createAction('@coursesAdditional/CREATE_COURSE_GROUP/ERROR')<any>()

export const loadAvailableCoursesStart = createAction('@coursesAvailable/LOAD/START')<IAvailableCoursesPayload>()
export const loadAvailableCoursesSuccess = createAction('@coursesAvailable/LOAD/SUCCESS')<any>()
export const loadAvailableCoursesError = createAction('@coursesAvailable/LOAD/ERROR')<any>()

export const loadForeignLanguagesCourses = createAction('@coursesForeignLanguages/load/start')<any>()
export const loadForeignLanguagesCoursesSuccess = createAction('@coursesForeignLanguages/load/success')<any>()
export const loadForeignLanguagesCoursesError = createAction('@coursesForeignLanguages/load/error')<any>()

export const loadAdditionalCoursesStart = createAction('@coursesAdditional/LOAD/START')()
export const loadAdditionalCoursesSuccess = createAction('@coursesAdditional/LOAD/SUCCESS')<any>()
export const loadAdditionalCoursesError = createAction('@coursesAdditional/LOAD/ERROR')<any>()

export const loadTeachersStart = createAction('@coursesAdditional/LOAD_TEACHERS/START')()
export const loadTeachersSuccess = createAction('@coursesAdditional/LOAD_TEACHERS/SUCCESS')<any>()
export const loadTeachersError = createAction('@coursesAdditional/LOAD_TEACHERS/ERROR')<any>()

export const loadLmsAddCoursesStart = createAction('@coursesAdditional/LOAD_LMS/START')<LoadLmsAddCoursesPayload>()
export const loadLmsAddCoursesSuccess = createAction('@coursesAdditional/LOAD_LMS/SUCCESS')<any>()
export const loadLmsAddCoursesError = createAction('@coursesAdditional/LOAD_LMS/ERROR')<any>()

export const loadEducationPlansStart = createAction(
	'@coursesAdditional/LOAD_EDUCATION_PLANS/START',
)<LoadEducationPlanPayload>()
export const loadEducationPlansSuccess = createAction('@coursesAdditional/LOAD_EDUCATION_PLANS/SUCCESS')<any>()
export const loadEducationPlansError = createAction('@coursesAdditional/LOAD_EDUCATION_PLANS/ERROR')<any>()

export const patchAdditionalCourseStart = createAction('@coursesAdditional/PUT/START')<PatchAdditionalCoursePayload>()
export const patchAdditionalCourseSuccess = createAction('@coursesAdditional/PUT/SUCCESS')<any>()
export const patchAdditionalCourseError = createAction('@coursesAdditional/PUT/ERROR')<any>()

export const loadCourseGroupsStart = createAction(
	'@coursesAdditional/LOAD_COURSE_GROUPS/START',
)<LoadCourseGroupsPayload>()
export const loadCourseGroupsSuccess = createAction('@coursesAdditional/LOAD_COURSE_GROUPS/SUCCESS')<any>()
export const loadCourseGroupsError = createAction('@coursesAdditional/LOAD_COURSE_GROUPS/ERROR')<any>()

export const loadAllCourseGroupsStart = createAction('@coursesAdditional/LOAD_ALL_COURSE_GROUPS/START')<LoadCourseGroupsPayload>()
export const loadAllCourseGroupsSuccess = createAction('@coursesAdditional/LOAD_ALL_COURSE_GROUPS/SUCCESS')<any>()
export const loadAllCourseGroupsError = createAction('@coursesAdditional/LOAD_ALL_COURSE_GROUPS/ERROR')<any>()

export const bookAdditionalCoursesStart = createAction('@coursesAdditional/BOOK/START')<BookAdditionalCoursesPayload>()
export const bookAdditionalCoursesSuccess = createAction('@coursesAdditional/BOOK/SUCCESS')<any>()
export const bookAdditionalCoursesError = createAction('@coursesAdditional/BOOK/ERROR')<any>()

export type CoursesActions =
    | ActionType<typeof addStudentsToCourseGroupStart>
    | ActionType<typeof addStudentsToCourseGroupError>
    | ActionType<typeof addStudentsToCourseGroupSuccess>
    | ActionType<typeof loadLmsCoursesStart>
    | ActionType<typeof loadLmsCoursesError>
    | ActionType<typeof loadLmsCoursesSuccess>
    | ActionType<typeof loadCourses>
    | ActionType<typeof loadCoursesError>
    | ActionType<typeof loadCoursesSuccess>
    | ActionType<typeof changeCourseGroupStart>
    | ActionType<typeof changeCourseGroupSuccess>
    | ActionType<typeof changeCourseGroupError>
    | ActionType<typeof loadAvailableCoursesStart>
    | ActionType<typeof loadAvailableCoursesSuccess>
    | ActionType<typeof loadAvailableCoursesError>
    | ActionType<typeof loadForeignLanguagesCourses>
    | ActionType<typeof loadForeignLanguagesCoursesSuccess>
    | ActionType<typeof loadForeignLanguagesCoursesError>
    | ActionType<typeof loadAdditionalCoursesStart>
    | ActionType<typeof loadAdditionalCoursesSuccess>
    | ActionType<typeof loadAdditionalCoursesError>
    | ActionType<typeof patchAdditionalCourseStart>
    | ActionType<typeof patchAdditionalCourseSuccess>
    | ActionType<typeof patchAdditionalCourseError>
    | ActionType<typeof loadCourseGroupsStart>
    | ActionType<typeof loadCourseGroupsSuccess>
    | ActionType<typeof loadCourseGroupsError>
    | ActionType<typeof bookAdditionalCoursesStart>
    | ActionType<typeof bookAdditionalCoursesSuccess>
    | ActionType<typeof bookAdditionalCoursesError>
    | ActionType<typeof loadAllCourseGroupsStart>
    | ActionType<typeof loadAllCourseGroupsSuccess>
    | ActionType<typeof loadAllCourseGroupsError>
    | ActionType<typeof loadLmsAddCoursesStart>
    | ActionType<typeof loadLmsAddCoursesSuccess>
    | ActionType<typeof loadLmsAddCoursesError>
    | ActionType<typeof loadEducationPlansStart>
    | ActionType<typeof loadEducationPlansSuccess>
    | ActionType<typeof loadEducationPlansError>
    | ActionType<typeof createCourseGroupStart>
    | ActionType<typeof createCourseGroupSuccess>
    | ActionType<typeof createCourseGroupError>
    | ActionType<typeof loadTeachersStart>
    | ActionType<typeof loadTeachersSuccess>
    | ActionType<typeof loadTeachersError>
    | ActionType<typeof removeStudentsCourseGroup>
    | ActionType<typeof removeStudentsCourseGroupSuccess>
    | ActionType<typeof removeStudentsCourseGroupError>;
