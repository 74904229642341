import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { addBuisnessProcessStart } from '../../../store/actions/buisness'

export const ConfirmTrialDayCompletion: React.FC<any> = ({ id, student_id }) => {
	const dispatch = useDispatch()

	const [signContract, setSignContract] = useState(false)

	const toggleSignContract = useCallback(() => {
		setSignContract((prevState) => !prevState)
	}, [signContract])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				process: 'confirm_trial_day_completion',
				id,
				parameters: {},
			}),
		)
		if (signContract) {
			dispatch(
				addBuisnessProcessStart({
					process: 'contract_extension_process',
					id: student_id,
				}),
			)
		}
	}, [dispatch, id, signContract, student_id])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Подтвердить завершение прохождения пробных дней</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div style={{ paddingTop: 5, paddingBottom: 5 }} className="d-flex">
							<div style={{ marginRight: 10 }}>
								<input
									id={'signContract'}
									type="checkbox"
									name="signContract"
									onChange={toggleSignContract}
									checked={signContract}
								/>
							</div>
							<label htmlFor={'signContract'}>Желает заключить контракт</label>
						</div>

						<Button onClick={submitButtonPressHandler} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
