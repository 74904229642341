/**
 *
 * @param user
 */
export const setChatWootUser = (user: any) => {
	const { id, email, roles, first_name, last_name, patronymic, avatar } = user

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.$chatwoot?.setUser?.(id, {
		name: `${last_name} ${first_name} ${patronymic}`,
		email: email || '',
		avatar_url: avatar || '',
		userRoles: roles || [],
		link: '',
	})
}
