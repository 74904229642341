import { ActionType, createAction } from 'typesafe-actions'

export const loadFrozenPayment = createAction('@frozenPayment/load/start')<any>()
export const loadFrozenPaymentSuccess = createAction('@frozenPayment/load/success')<any>()
export const loadFrozenPaymentError = createAction('@frozenPayment/load/error')<any>()

export const loadCurrentFrozenPayment = createAction('@frozenPayment/current/load/start')<any>()
export const loadCurrentFrozenPaymentSuccess = createAction('@frozenPayment/current/load/success')<any>()
export const loadCurrentFrozenPaymentError = createAction('@frozenPayment/current/load/error')<any>()


export const unfrozePayment = createAction('@frozenPayment/unfroze/start')<any>()
export const unfrozePaymentSuccess = createAction('@frozenPayment/unfroze/success')<any>()
export const unfrozePaymentError = createAction('@frozenPayment/unfroze/error')<any>()

export const unfrozeAllPayment = createAction('@frozenPayment/unfroze/all/start')<any>()
export const unfrozeAllPaymentSuccess = createAction('@frozenPayment/unfroze/all/success')<any>()
export const unfrozeAllPaymentError = createAction('@frozenPayment/unfroze/all/error')<any>()

export const setFrozenPaymentCurrentPage = createAction('@frozenPayment/set/current/page')<any>()

export type FrozenPaymentActions = 
    ActionType<typeof loadFrozenPayment> | 
    ActionType<typeof loadFrozenPaymentSuccess> | 
    ActionType<typeof loadFrozenPaymentError> |
    ActionType<typeof unfrozePayment> |
    ActionType<typeof unfrozePaymentSuccess> |
    ActionType<typeof unfrozePaymentError> |
    ActionType<typeof unfrozeAllPayment> |
    ActionType<typeof unfrozeAllPaymentSuccess> |
    ActionType<typeof unfrozeAllPaymentError> |
    ActionType<typeof setFrozenPaymentCurrentPage> |
    ActionType<typeof loadCurrentFrozenPayment> |
    ActionType<typeof loadCurrentFrozenPaymentSuccess> |
    ActionType<typeof loadCurrentFrozenPaymentError>;