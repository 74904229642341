import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Layout, Menu } from 'antd'
import { AppButton, AppFontIcon, AppText } from '@frontend-modules/ui-kit'
import './Sidebar.scss'
import Logo from '@assets/brand/logo.svg'
import { sidebarList } from './Sidebar.mock'
import { useSelector } from 'react-redux'
import { State } from '@/store/store'
import { ISidebarProps, TMenuItem } from './Sidebar.types'
import { useLocation } from 'react-router-dom'
import { AccessHelper } from '@frontend-modules/access-controller'

const { Sider } = Layout

/**
 * @description - компонент с основным меню ERP
 * @param props
 * @constructor
 */
const Sidebar: React.FC<ISidebarProps> = (props) => {
	const { isCollapsed, setCollapsedMenu, onMenuItemClick, onLogout } = props
	const profile = useSelector((state: State) => state.profile)
	const location = useLocation()

	const [isSupportShow, setSupportShow] = useState(false)
	const [currentRoute, setCurrentRoute] = useState(location.pathname)

	const onSupportToggle = () => {
		setSupportShow(!isSupportShow)
		const widgetButton = document.getElementsByClassName('woot-widget-bubble woot-elements--right')[0]
		;(widgetButton as any)?.click()
	}

	const onMenuItemClickLocal = (menuItem) => {
		setCurrentRoute(menuItem.key)
		onMenuItemClick?.(menuItem)
	}

	useEffect(() => {
		if (location) {
			if (currentRoute !== location.pathname) {
				setCurrentRoute(location.pathname)
			}
		}
	}, [location, currentRoute, setCurrentRoute])

	const userName = useMemo(
		() =>
			`${profile?.profile?.last_name || ''} ${
				profile?.profile?.first_name ? profile?.profile?.first_name?.[0] + '.' : ''
			} ${profile?.profile?.patronymic ? profile?.profile?.patronymic?.[0] + '.' : ''}`,
		[profile],
	)

	const accessSidebarList = AccessHelper.filterList(sidebarList)

	/**
	 * @description - формируем список меню
	 */
	const items = useMemo(() => {
		const getItem = (
			label: React.ReactNode,
			key: React.Key,
			icon?: React.ReactNode,
			children?: TMenuItem[],
			type?: 'group',
		): TMenuItem => {
			return {
				key,
				icon,
				children,
				label,
				type,
			} as TMenuItem
		}
		/**
		 * @description -
		 * filter 1 - фильтруем в зависимости от текущей роли
		 * filter 2 - фильтруем в зависимости от АПИ Ролевого доступа
		 * map 1 - фильтруем вложенные роуты в в зависимости от АПИ Ролевого доступа
		 * map 2  - формируем в необходимый для Menu формат
		 */
		return accessSidebarList
			.filter((filterItem) => {
				if (filterItem?.closeAccessToRoles) {
					return profile?.roles?.some((currentRole) =>
						filterItem?.closeAccessToRoles.some((closeRole) => closeRole === currentRole),
					)
				}
				return filterItem
			})
			.map((filterItem) => {
				if (filterItem.children) {
					return {
						...filterItem,
						children: AccessHelper.filterList(filterItem?.children),
					}
				}
				return filterItem
			})
			.map((item) => {
				return getItem(
					item.title,
					item.url,
					<AppFontIcon icon={item.icon} size={24} />,
					item?.children?.map((subitem) => {
						return getItem(subitem.title, subitem.url)
					}),
				)
			})
	}, [profile.roles, accessSidebarList])

	return (
		<Sider
			className={`sidebar-new ${isCollapsed && 'collapsed'}`}
			collapsible
			theme="light"
			collapsed={isCollapsed}
			onCollapse={setCollapsedMenu}
			trigger={null}
			width={260}
		>
			<div className={'sidebar-header'}>
				{!isCollapsed && (
					<img
						onClick={() => {
							onMenuItemClickLocal({ key: '/' })
						}}
						src={Logo}
						alt={'Логотип'}
						className={'sidebar-header__logo'}
					/>
				)}
				<AppFontIcon
					onClick={() => setCollapsedMenu(!isCollapsed)}
					className={'sidebar-header__button'}
					icon={'menu-l'}
				/>
			</div>
			<Divider className={'sidebar-menu__divider'} />
			{!isCollapsed && (
				<div className={'sidebar-username'}>
					<AppText text={userName} />
				</div>
			)}
			<Divider className={'sidebar-menu__divider'} />
			<div className={'sidebar-scrollable-menu-container'}>
				<Menu
					mode="inline"
					items={items}
					onClick={onMenuItemClickLocal}
					className={'sidebar-menu'}
					selectedKeys={[currentRoute]}
				/>
			</div>
			<Divider className={'sidebar-menu__divider-footer'} />
			<div className={'sidebar-footer'}>
				<AppButton
					type={'medium-primary'}
					icon={isSupportShow ? 'tech-sup-close-l' : 'tech-sup-l'}
					className={'sidebar-logout'}
					onClick={onSupportToggle}
					label={`${
						!isCollapsed ? (isSupportShow ? 'Закрыть Тех. Поддержку' : 'Открыть Тех. Поддержку') : ''
					}`}
				/>
				<AppButton
					label={`${!isCollapsed ? 'Выйти' : ''}`}
					icon={'out-l'}
					className={'sidebar-logout'}
					onClick={onLogout}
					type={'medium-outline'}
				/>
			</div>
		</Sider>
	)
}

export default Sidebar
