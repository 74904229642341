import { ssoPath } from '@/api/path/ssoPath'

export const UntraceableApiList = [
	{
		url: 'api/auth/login/',
		statuses: [400],
	},
	{
		url: ssoPath.token,
		statuses: [400],
	},
	{
		url: '/task/:id/next/',
		statuses: [400],
	},
	{
		url: 'process/task/overview/:id',
		statuses: [400],
		alert: true,
	},
	{
		url: 'api/v1/generate-document/',
		statuses: [400],
	},
	{
		url: 'student-card/',
		statuses: [400],
		alert: true,
	},
	{
		url: 'parent-card/:id/actions/',
		statuses: [400],
	},
	{
		url: 'parent-card/:id/update/',
		statuses: [400],
	},
	{
		url: 'student-card/:id/actions/',
		statuses: [400],
	},
	{
		url: 'student-card/:id/update/',
		statuses: [400],
	},
	{
		url: 'contract-tree/',
		statuses: [400],
		alert: true,
	},
	{
		url: 'notes/student/',
		statuses: [400],
		alert: true,
	},
	{
		url: 'notes/parent/',
		statuses: [400],
		alert: true,
	},
	{
		url: 'parent-card/personal-account/today/',
		statuses: [400],
		alert: true,
	},
	{
		url: 'api/v1/personal-account-history/',
		statuses: [400],
		alert: true,
	},
]
