import { AppText } from '@frontend-modules/ui-kit'
import React, { FC } from 'react'
import './PageTemplate.scss'
import { formatDate } from '@frontend-modules/frontend-utils'
import { IPageTemplateProps } from './PageTemplate.types'

/**
 * @description - шаблон страницы
 * @param props
 * @constructor
 */
const PageTemplate: FC<IPageTemplateProps> = (props) => {
	const { periodStart, periodEnd, filters, infoStatistic, infoDocumentList, table, type } = props

	return (
		<div className={'payments-template-page'}>
			<div className={'payments-template-page-period'}>
				<AppText text={'Период'} textStyle={'DesktopH3'} />
				<div className={'payments-template-page-period-inputs'}>
					{periodStart}
					{periodEnd}
				</div>
			</div>
			<div className={'payments-template-page-filters'}>{filters}</div>
			<div className={'payments-template-page-info'}>
				<AppText
					text={`${type === 'pays' ? 'Платежи' : 'Платежные поручения'} за период ${formatDate(
						periodStart?.props?.defaultValue,
					)} - ${formatDate(periodEnd?.props?.defaultValue)}`}
					textStyle={'DesktopH3'}
				/>
				<div className={'payments-template-page-info-container'}>
					{infoStatistic}
					{infoDocumentList}
				</div>
			</div>
			<div className={'payments-template-page-table'}>{table}</div>
		</div>
	)
}

export default PageTemplate
