import React, { useCallback, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const SetTrackingLink: React.FC<{ parent_id: any, id: any, action_name: string }> = ({ parent_id, id, action_name }) => {
	const dispatch = useDispatch()
	const [trackingLink, setTrackingLink] = useState('')
    
	const trackingLinkChangeCallback = useCallback((e) => {
		setTrackingLink(e.target.value)
	}, [trackingLink])

	const onSubmit = () => {
		dispatch(createProcessStart({
			process: action_name,
			id: id,
			parameters: {
				tracking_link: trackingLink
			}
		}))
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Cсылка для отслеживания</Popover.Title>
			</Row>
			<Row>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Ссылка для отслеживания</Form.Label>
							<Form.Control value={trackingLink} onChange={trackingLinkChangeCallback} type="text" placeholder="Ссылка" />
						</Form.Group>

						<Button disabled={!trackingLink} onClick={onSubmit} variant="primary">
                            Добавить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
