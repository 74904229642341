import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form as BForm, Popover, Row} from 'react-bootstrap'
import {connect} from 'react-redux'
import {createProcessStart} from '../../../store/actions/processes'
import {State} from '../../../store/store'

import {Form, Field} from 'react-final-form'


interface INeedToChangeProps {
    createProcess: (payload: any) => void;
    student_id: string;
    id: string;
}

export const Component: React.FC<INeedToChangeProps> = ({
	createProcess,
	children,
	student_id,
	id
}) => {

	const onSubmit = async (values: any) => {
		createProcess({
			process: 'request_contract_changes',
			id: id,
			parameters: {
				note: values.note
			}
		})
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Требуются изменения в договоре</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form onSubmit={onSubmit}>
						{
							({submitting, handleSubmit}) => (
								<form onSubmit={handleSubmit}>
									<div>
										<Field name={'note'}>
											{
												({input, name, onChange}) => (
													<BForm.Group style={{width: '100%'}}>
														<BForm.Label>Какие изменения требуется внести</BForm.Label>
														<BForm.Control
															type="text"
															as="textarea"
															value={input.value}
															onChange={input.onChange}
														/>
													</BForm.Group>
												)
											}
										</Field>
									</div>
									<Button disabled={submitting} type="submit" variant="primary">
                                        Подтвердить
									</Button>
								</form>
							)
						}
					</Form>
				</Col>
			</Row>
		</Col>
	)
}


export const NeedToChange = connect(
	({}: State, ownProps: any) => ({}),
	(dispatch) => {
		return {
			createProcess: (payload: any) => {
				return dispatch(createProcessStart(payload))
			}
		}
	}
)(Component)
