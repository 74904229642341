import React, { FC, useCallback, useEffect, useState } from 'react'
import { AppCard, AppTab, AppText } from '@frontend-modules/ui-kit'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { getCurrentActiveTab } from './GroupActions.utils'
import { tabList } from './GroupActions.config'
import { Create, Active, Planned, History } from '@pages'
import './GroupActions.scss'

/**
 * @description - Страница "Групповые действия"
 * @constructor
 */
export const GroupActions: FC = () => {
	const { path, url, isExact } = useRouteMatch()
	const history = useHistory()

	const [selectedTab, setSelectedTab] = useState(getCurrentActiveTab(history.location.pathname))

	const onTabChange = useCallback(
		(route) => {
			setSelectedTab(route)
			history.push(`${url}/${route.key}`)
		},
		[history, url],
	)

	useEffect(() => {
		history.listen((location) => {
			setSelectedTab(getCurrentActiveTab(location.pathname))
		})
	}, [history])

	// если перешли по роуту без дочернего юрл
	useEffect(() => {
		if (isExact) {
			onTabChange?.(tabList[0])
		}
	}, [isExact, onTabChange])

	return (
		<AppCard
			className={'group-actions-page'}
			title={<AppText textStyle={'Title'} style={{ margin: '0' }} text={'Групповые действия'} />}
		>
			<AppTab isWhiteBackground onChange={onTabChange} activeKey={selectedTab?.key} items={tabList} />
			<Switch>
				<Route path={`${path}/create`} component={Create} />
				<Route path={`${path}/history`} component={History} />
				<Route path={`${path}/active`} component={Active} />
				<Route path={`${path}/planned`} component={Planned} />
			</Switch>
		</AppCard>
	)
}

export default GroupActions
