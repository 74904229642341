import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'
import { SchoolState } from '../../../store/types/school'
import { loadSchool } from '../../../store/actions/school'
import { loadStudentCard } from '../../../store/actions/studentCard'

interface IDistributeToSchoolProps extends SchoolState {
	id: number
	changeSchool: (payload: any) => {}
	student: any
	loadSchool: (payload: any) => {}
	action_name: string
	loadStudentCard: (payload: any) => {}
	studentCard: any
}

export const Component: React.FC<IDistributeToSchoolProps> = ({
	changeSchool,
	id,
	student,
	fetchStatus,
	school,
	loadSchool,
	action_name,
	studentCard,
}) => {
	const [selectedSchool, setSelectedSchool] = useState<undefined | any>(null)
	const [selectedEducationForm, setSelectedEducationForm] = useState<undefined | string>(undefined)
	const [errors, setErrors] = useState<Array<string>>([])
	const student_id = student.id

	useEffect(() => {
		loadSchool(student_id)
		setSelectedSchool(student.school)
	}, [student_id])

	useEffect(() => {
		if (fetchStatus === FetchStatus.FETCHED) {
			for (const el of school) {
				if (el.id === student?.school?.id) {
					setSelectedSchool(el)
				}
			}
		}
	}, [student, school])

	useEffect(() => {
		if (studentCard.card && studentCard.card?.education_format) {
			setSelectedEducationForm(studentCard.card?.education_format)
		}
	}, [selectedSchool, studentCard.card])

	const selectedSchoolChangeCallback = useCallback((event: any) => {
		setSelectedSchool(JSON.parse(event.target.value.includes('Не выбрано') ? null : event.target.value))
	}, [])

	const selectedEducationFormChangeCallback = useCallback((event: any) => {
		setSelectedEducationForm(event.target.value)
	}, [])

	const getAvailableEducationForms = () => {
		const forms = []

		if (selectedSchool.full_time) {
			forms.push({
				name: 'Очная',
				value: 'full_time',
			})
		}

		if (selectedSchool.combined) {
			forms.push({
				name: 'Очно-Заочная',
				value: 'combined',
			})
		}

		if (selectedSchool.family) {
			forms.push({
				name: 'Семейная',
				value: 'family',
			})
		}

		if (selectedSchool.extramural) {
			forms.push({
				name: 'Заочная',
				value: 'extramural',
			})
		}

		return forms
	}

	const submitButtonPressHandler = () => {
		const newErrState = []

		if (!selectedSchool) {
			newErrState.push('selectedSchool')
		}

		if (!selectedEducationForm || selectedEducationForm === 'Не выбрано') {
			newErrState.push('selectedEducationForm')
		}

		if (newErrState.length === 0) {
			changeSchool({
				process: action_name,
				id: id,
				parameters: {
					school_id: selectedSchool.id,
					education_format: selectedEducationForm,
				},
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Распределить ребенка в другую школу</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
								<Form.Label>Выберите школу</Form.Label>
								{fetchStatus === FetchStatus.FETCHED ? (
									<Form.Control
										isInvalid={errors.includes('selectedSchool')}
										value={JSON.stringify(selectedSchool)}
										onChange={selectedSchoolChangeCallback}
										as="select"
										placeholder="Выберите школу"
									>
										<option value={null} defaultChecked>
											Не выбрано
										</option>
										{school.map((el: any) => (
											<option
												value={JSON.stringify(el)}
												key={el.id}
											>{`${el.prefix} ${el.name}`}</option>
										))}
									</Form.Control>
								) : (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								)}
							</Form.Group>
							{selectedSchool && (
								<Form.Group style={{ width: '100%' }} controlId="formBasicEmail">
									<Form.Label>Выберите форму обучения</Form.Label>
									<Form.Control
										isInvalid={errors.includes('selectedEducationForm')}
										value={selectedEducationForm}
										onChange={selectedEducationFormChangeCallback}
										as="select"
										placeholder="Выберите форму обучения"
									>
										<option value={undefined} defaultChecked>
											Не выбрано
										</option>
										{getAvailableEducationForms().map((el: any, index: number) => (
											<option value={el.value} key={index}>
												{el.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							)}
						</div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedSchool} variant="primary">
							Изменить школу
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const ChangeSchool = connect(
	({ school, studentCard }: State, ownProps: any) => ({ ...school, studentCard }),
	(dispatch) => {
		return {
			changeSchool: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadSchool: (payload: any) => {
				return dispatch(loadSchool(payload))
			},
			loadStudentCard: (payload: any) => {
				return dispatch(loadStudentCard(payload))
			},
		}
	},
)(Component)

export default ChangeSchool
