import { ActionType, createAction } from 'typesafe-actions'

export const loadParents = createAction('@parents/LOAD_PARENTS')<any>()
export const onLoadParentsSuccess = createAction('@parents/ON_LOAD_PARENTS_SUCCESS')<any>()
export const onLoadParentsError = createAction('@parents/ON_LOAD_PARENTS_ERROR')<any>()
export const loadMoreParents = createAction('@parents/LOAD_MORE_PARENTS')<any>()
export const onLoadMoreParentsSuccess = createAction('@parents/ON_LOAD_MORE_PARENTS_SUCCESS')<any>()
export const onLoadMoreParentsError = createAction('@parents/ON_LOAD_MORE_PARENTS_ERROR')<any>()

export type ParentsActions =
	| ActionType<typeof loadParents>
	| ActionType<typeof onLoadParentsSuccess>
	| ActionType<typeof onLoadParentsError>
	| ActionType<typeof loadMoreParents>
	| ActionType<typeof onLoadMoreParentsSuccess>
	| ActionType<typeof onLoadMoreParentsError>
