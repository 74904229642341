import { ActionType, createAction } from 'typesafe-actions'

export const createContract = createAction('@parent/CREATE_CONTRACT')<any>()
export const onCreateContractSuccess = createAction('@parent/ON_CREATE_CONTRACT_SUCCESS')<any>()
export const onCreateContractError = createAction('@parent/ON_CREATE_CONTRACT_ERROR')<any>()

export const sendMessage = createAction('@parent/SEND_MESSAGE')<any>()
export const onSendMessageSuccess = createAction('@parent/ON_SEND_MESSAGE_SUCCESS')<any>()
export const onSendMessageError = createAction('@parent/ON_SEND_MESSAGE_ERROR')<any>()

export const createTeacherProfile = createAction('@parent/CREATE_TEACHER_PROFILE')<any>()
export const onCreateTeacherProfileSuccess = createAction('@parent/ON_CREATE_TEACHER_PROFILE_SUCCESS')<any>()
export const onCreateTeacherProfileError = createAction('@parent/ON_CREATE_TEACHER_PROFILE_ERROR')<any>()

export const loadParentCardTab = createAction('@parent/LOAD_PARENT_CARD_TAB')<any>()
export const onLoadParentCardTabSuccess = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_SUCCESS')<any>()
export const onLoadParentCardTabError = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_ERROR')<any>()

export const loadParentCardTabProcesses = createAction('@parent/LOAD_PARENT_CARD_TAB_PROCESSES')<any>()
export const onLoadParentCardTabProcessesSuccess = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_PROCESSES_SUCCESS',
)<any>()
export const onLoadParentCardTabProcessesError = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_PROCESSES_ERROR')<any>()

export const loadParentCardTabPersonalAccountBalance = createAction(
	'@parent/LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT',
)<any>()
export const onLoadParentCardTabPersonalAccountBalanceSuccess = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT_SUCCESS',
)<any>()
export const onLoadParentCardTabPersonalAccountBalanceError = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT_ERROR',
)<any>()

export const loadParentCardTabPersonalAccountHistory = createAction(
	'@parent/LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT_HISTORY',
)<any>()
export const onLoadParentCardTabPersonalAccountHistorySuccess = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT_HISTORY_SUCCESS',
)<any>()
export const onLoadParentCardTabPersonalAccountHistoryError = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_PERSONAL_ACCOUNT_HISTORY_ERROR',
)<any>()

export const loadParentCardTabContracts = createAction('@parent/LOAD_PARENT_CARD_TAB_CONTRACTS_HISTORY')<any>()
export const onLoadParentCardTabContractsSuccess = createAction(
	'@parent/ON_LOAD_PARENT_CARD_TAB_CONTRACTS_SUCCESS',
)<any>()
export const onLoadParentCardTabContractsError = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_CONTRACTS_ERROR')<any>()

export const loadParentCardTabNotes = createAction('@parent/LOAD_PARENT_CARD_TAB_NOTES')<any>()
export const onLoadParentCardTabNotesSuccess = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_NOTES_SUCCESS')<any>()
export const onLoadParentCardTabNotesError = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_NOTES_ERROR')<any>()
export const addParentNote = createAction('@parent/ADD_PARENT_NOTE')<any>()
export const onAddParentNoteSuccess = createAction('@parent/ON_ADD_PARENT_NOTE_SUCCESS')<any>()
export const onAddParentNoteError = createAction('@parent/ON_ADD_PARENT_NOTE_ERROR')<any>()

export const loadParentCardTabBonuses = createAction('@parent/LOAD_PARENT_CARD_TAB_BONUSES')<any>()
export const onLoadParentCardTabBonusesSuccess = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_BONUSES_SUCCESS')<any>()
export const onLoadParentCardTabBonusesError = createAction('@parent/ON_LOAD_PARENT_CARD_TAB_BONUSES_ERROR')<any>()

export type ParentCardActions =
	| ActionType<typeof createContract>
	| ActionType<typeof onCreateContractSuccess>
	| ActionType<typeof onCreateContractError>
	| ActionType<typeof loadParentCardTab>
	| ActionType<typeof onLoadParentCardTabSuccess>
	| ActionType<typeof onLoadParentCardTabError>
	| ActionType<typeof loadParentCardTabProcesses>
	| ActionType<typeof onLoadParentCardTabProcessesSuccess>
	| ActionType<typeof onLoadParentCardTabProcessesError>
	| ActionType<typeof loadParentCardTabPersonalAccountBalance>
	| ActionType<typeof onLoadParentCardTabPersonalAccountBalanceSuccess>
	| ActionType<typeof onLoadParentCardTabPersonalAccountBalanceError>
	| ActionType<typeof loadParentCardTabPersonalAccountHistory>
	| ActionType<typeof onLoadParentCardTabPersonalAccountHistorySuccess>
	| ActionType<typeof onLoadParentCardTabPersonalAccountHistoryError>
	| ActionType<typeof loadParentCardTabContracts>
	| ActionType<typeof onLoadParentCardTabContractsSuccess>
	| ActionType<typeof onLoadParentCardTabContractsError>
	| ActionType<typeof loadParentCardTabNotes>
	| ActionType<typeof onLoadParentCardTabNotesSuccess>
	| ActionType<typeof onLoadParentCardTabNotesError>
	| ActionType<typeof addParentNote>
	| ActionType<typeof onAddParentNoteSuccess>
	| ActionType<typeof onAddParentNoteError>
	| ActionType<typeof createTeacherProfile>
	| ActionType<typeof onCreateTeacherProfileSuccess>
	| ActionType<typeof onCreateTeacherProfileError>
	| ActionType<typeof sendMessage>
	| ActionType<typeof onSendMessageSuccess>
	| ActionType<typeof onSendMessageError>
	| ActionType<typeof loadParentCardTabBonuses>
	| ActionType<typeof onLoadParentCardTabBonusesSuccess>
	| ActionType<typeof onLoadParentCardTabBonusesError>
