import { $BaseHttpClient, $HttpClient } from '@utils'
import {createTeacherProfile} from "@store/actions/parentCard";

export class TasksServices extends $BaseHttpClient {
	/**
	 *
	 * @description загружаем список задач
	 */
	loadTasks = (body) => {
		return $HttpClient.post('task/', body)
	}
	/**
	 *
	 * @description получение подробной информации про задачу
	 */
	loadTaskData = (id) => {
		return $HttpClient.get(`process/task/overview/${id}/`)
	}
	/**
	 *
	 * @description получение инфмаорции для мониторинга процессов
	 */
	loadProcessesMonitoring = (body) => {
		return $HttpClient.post('process/status/', body)
	}
	/**
	 *
	 * @description запускает двух-сторонний договор с родителем
	 */
	createTwoSidesContract = (userId) => {
		return $HttpClient.post('process/create/', {
			user: userId,
			type: 'create_contract',
		})
	}
	/**
	 *
	 * @description запускает Процесс создания ученических профилей новым Учителям для обучения
	 */
	createTeacherProfile = (userId) => {
		return $HttpClient.post('process/create/', {
			user: userId,
			type: 'test_drive_teacher',
		})
	}
}
