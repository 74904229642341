import { persistReducer } from 'redux-persist'
// import localStorage from 'redux-persist/es/storage'
import { getType } from 'typesafe-actions'
import {
	actionSearchChange,
	debtsFiltersChange,
	debtsSearchChange,
	parentSearchChange,
	processesSearchChange,
	studentSearchChange,
	userVisitsSearchChange,
} from '../actions/search'
import { Action } from '../store'
import { DebtsSearchState, SearchState, UserVisitsSearchState } from '../types/search'
import {indexedDBOldStore} from '@/store/STORAGE_INDEXDB'

const processesSearchInitialState = {
	parentSearch: '',
	studentSearch: '',
	idSearch: '',
	actionsSearch: [],
	schoolSearch: [],
	tariffSearch: [],
	formatSearch: [],
	statesSearch: [],
	staffUsersSearch: [],
	createdMonthSearch: '',
	processTypesSearch: [],
	gradeSearch: '',
}

const searchReducer = (state: SearchState = processesSearchInitialState, action: Action) => {
	switch (action.type) {
	case getType(parentSearchChange): {
		return {
			...state,
			parentSearch: action.payload,
		}
	}

	case getType(studentSearchChange): {
		return {
			...state,
			studentSearch: action.payload,
		}
	}

	case getType(actionSearchChange): {
		return {
			...state,
			actionsSearch: action.payload,
		}
	}

	case getType(processesSearchChange): {
		return {
			...state,
			[action.payload.type]: action.payload.value,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const search: any = persistReducer(
	{
		key: 'AdminPanel::search',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	searchReducer,
)

const debtsSearchInitialState: DebtsSearchState = {
	parent: '',
	student: '',
	school: '',
	class: '',
	tariff: '',
	paymentSite: '',
	filters: {
		activeContract: 'none',
		classEnrollment: 'none',
		parent: '',
		student: '',
	},
}

const debtsSearchReducer = (state: DebtsSearchState = debtsSearchInitialState, action: Action) => {
	switch (action.type) {
	case getType(debtsSearchChange): {
		const newState = Object.assign({}, state)
		newState[action.payload.type] = action.payload.value
		return {
			...newState,
		}
	}

	case getType(debtsFiltersChange): {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.type]: action.payload.value,
			},
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const debtsSearch: any = persistReducer(
	{
		key: 'AdminPanel::debtsSearch',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	debtsSearchReducer,
)

const userVisitsSearchInitialState: UserVisitsSearchState = {
	user: null,
	requestMethod: [],
	responseStatus: [],
	createdAt: '',
	pageSize: '100',
}

const userVisitsSearchReducer = (state: UserVisitsSearchState = userVisitsSearchInitialState, action: Action) => {
	switch (action.type) {
	case getType(userVisitsSearchChange): {
		return {
			...state,
			[action.payload.type]: action.payload.value,
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}

export const userVisitsSearch: any = persistReducer(
	{
		key: 'AdminPanel::userVisitsSearch',
		storage: indexedDBOldStore,
		blacklist: [],
		version: 1,
	},
	userVisitsSearchReducer,
)
