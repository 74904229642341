import React, { useEffect, useState } from 'react'

import { useGetPopupsState } from './Modals.utils'
import { mappedPopups } from './Modals.config'

/**
 * @description - Слой рендера модалок
 * @constructor
 */
const Modals = () => {
	const { mountedPopups, popups } = useGetPopupsState()

	const [currentComponentList, setCurrentComponentList] = useState([])

	useEffect(() => {
		const mountedList = mountedPopups.map((item) => {
			return {
				component: mappedPopups[item.type],
				key: item.type,
				urlProps: item,
			}
		})
		setCurrentComponentList(mountedList)
	}, [mountedPopups])

	return (
		<>
			{currentComponentList?.map((component, index) => {
				if (component.component && !component.urlProps?.['isHide']) {
					return React.createElement(component.component, {
						isOpen: !!popups.find((popup) => popup?.type === component?.key),
						key: `${component.key}-${index}`,
						layer: component.urlProps?.['layer'] || currentComponentList.length - index,
						urlProps: component.urlProps,
						zIndex: 1000 - component.urlProps?.['layer'] || 0,
					})
				}
				return null
			})}
		</>
	)
}

export default Modals
