import { BaseRestDataProvider } from './BaseRestDataProvider'
import { oldAxios } from './axios-init'

export class ChatDataProvider extends BaseRestDataProvider {
	sendMessage = (payload: any) => {
		return oldAxios
			.post(`${this.host}/api/processes/send/message/${payload.student}/`, { text: payload.message })
			.then((res) => res.data)
	}

	loadChat = (student_id: any) => {
		return oldAxios.get(`${this.host}/api/processes/messages/${student_id}/`).then((res) => res.data)
	}

	loadUnreadMessages = () => {
		return oldAxios.get(`${this.host}/api/processes/chats/`).then((res) => res.data)
	}
}
