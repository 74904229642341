import React, { FC } from 'react'
import { AppFontIcon, AppPopover, AppTable, AppText } from '@frontend-modules/ui-kit'
import { formatDate } from '@frontend-modules/frontend-utils'
import { smsColumns, emailColumns, dateFormat } from './UserCardPopupTabCommunicationsTable.config'
import { IUserCardPopupTableCommunicationsTable } from './UserCardPopupTabCommunicationsTable.types'

/**
 * @description - таблицы коммуникаций "смс" или "email"
 * @param props
 * @constructor
 */
const UserCardPopupTabCommunicationsTable: FC<IUserCardPopupTableCommunicationsTable> = (props) => {
	const { data, contentType, isLoading } = props

	const columnsToRender = contentType === 'email' ? emailColumns : smsColumns

	const columns = [
		...columnsToRender,
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			render: (_, record) => {
				const list = record?.statuses
				const actualStatus = list[0]

				const popupContent = () => {
					return list?.map((item, index) => {
						return (
							<div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
								<AppText textStyle={'DesktopH4'} text={item.type} />
								<AppText
									textStyle={'DesktopFootNotes'}
									text={formatDate(item.dateCreate, dateFormat)}
								/>
								{index !== list?.length - 1 && <AppFontIcon icon={'drops-up-s'} />}
							</div>
						)
					})
				}
				return (
					<AppPopover content={popupContent}>
						<AppText textStyle={'DesktopH4'} text={actualStatus?.type} />
						<AppText
							textStyle={'DesktopFootNotes'}
							text={formatDate(actualStatus?.dateCreate, dateFormat)}
						/>
					</AppPopover>
				)
			},
		},
	]

	return (
		<AppTable
			isCanDeleteRow={false}
			isLoading={isLoading}
			pagination={
				data?.length > 10
					? {
							total: data?.length,
							pageSize: 10,
							defaultCurrent: 1,
							showSizeChanger: false,
					  }
					: false
			}
			locale={{ emptyText: 'Ничего не найдено' }}
			dataSource={data}
			columns={columns}
			rowKey={(record, index) => `${index}`}
			sortingConfig={{}}
			titleSortingType={'customSorting'}
		/>
	)
}

export default UserCardPopupTabCommunicationsTable
