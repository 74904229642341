import {combineEpics, ofType} from 'redux-observable'
import {from, of} from 'rxjs'
import {catchError, switchMap,} from 'rxjs/operators'
import {getType} from 'typesafe-actions'
import {FuncEpic} from '../../common/types'
import {loadNote, loadNoteError, loadNoteSuccess} from '../actions/note'

const loadNoteEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadNote)),
		switchMap(({payload}) => {
			return from(deps.processesDataProvider.loadNotesByProcessId(payload)).pipe(
				switchMap(notes => {
					return of(loadNoteSuccess(notes))
				}),
				catchError(err => {
					return of(loadNoteError(err))
				})
			)
		})
	)
}

export const noteEpics = combineEpics(loadNoteEpic)
