import React, { useCallback } from 'react'
import { ProcessesSearchType } from '../../../store/types/search'
import { Col, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import { processesSearchChange } from '../../../store/actions/search'
import { setCurrentPage } from '../../../store/actions/processes'

export const EducationFormatFilter: React.FC = () => {
	const dispatch = useDispatch()
	const constants = useSelector((state: State) => state.constants.constants)
	const { formatSearch } = useSelector((state: State) => state.search)

	const educationFormatSearchChange = useCallback(
		(value) => {
			dispatch(setCurrentPage(1))
			dispatch(
				processesSearchChange({
					value: value,
					type: ProcessesSearchType.EDUCATION_FORMAT,
				}),
			)
		},
		[dispatch],
	)

	const handleFilterItemClick = useCallback(
		(format) => {
			educationFormatSearchChange(
				formatSearch.find((storeFormat) => storeFormat === format)
					? formatSearch.filter((storeFormat) => storeFormat !== format)
					: [...formatSearch, format],
			)
		},
		[educationFormatSearchChange, formatSearch],
	)

	return (
		<Col className="p-3 education-format-filter__wrapper" md={12} xl={12}>
			<Row className="mb-3 justify-content-between education-format-filter__header">
				<Popover.Title as="h5">Выберите формат обучения</Popover.Title>

				<div onClick={educationFormatSearchChange.bind(null, [])} className={'searchItem-clear'}>
                    &times;
				</div>
			</Row>

			<Row style={{ maxHeight: 200, overflowY: 'scroll' }}>
				<Col md={12}>
					{Object.keys(constants.EDUCATIONAL_FORMS).map((format) => {
						const isChecked = formatSearch.includes(format)

						return (
							<div
								style={{ cursor: 'pointer', paddingTop: 5, paddingBottom: 5 }}
								className="d-flex"
								key={format}
								onClick={() => handleFilterItemClick(format)}
							>
								<div style={{ marginRight: 10 }}>
									<input readOnly type="checkbox" checked={isChecked} />
								</div>
								<div>{constants.EDUCATIONAL_FORMS[format]}</div>
							</div>
						)
					})}
				</Col>
			</Row>
		</Col>
	)
}
