import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { loadEducationPlansStart } from '../../../store/actions/courses'
import { loadStudentCard } from '../../../store/actions/studentCard'
import { State } from '../../../store/store'
import Select from 'react-select'
import { FetchStatus } from '../../../common/types'
import { loadParallelsStart } from '../../../store/actions/classes'
import { showAlert } from '../../../store/actions/alert'

export interface Props {
	process: string
	id: number
	student_id: number
}

export const SelectEducationPlan: React.FC<Props> = ({ process, id, student_id }) => {
	const [selectedPlan, setSelectedPlan] = useState<number>()
	const dispatch = useDispatch()

	const { educationPlans, loadEducationPlansStatus } = useSelector((state: State) => state.additionalCourses)
	const studentCard = useSelector((state: State) => state.studentCard)
	const { parallels, parallelsFetchStatus } = useSelector((state: State) => state.classes)

	useEffect(() => {
		dispatch(loadStudentCard(student_id))
		dispatch(loadParallelsStart({}))
	}, [])

	useEffect(() => {
		if (studentCard.card?.grade && parallels?.length) {
			dispatch(
				loadEducationPlansStart({
					filter: {
						parallel: parallels.find((parallel) => Number(parallel.titleShort) === studentCard.card?.grade)
							?.id,
					},
				}),
			)
		}
	}, [dispatch, parallels, studentCard.card?.grade])

	useEffect(() => {
		if (!studentCard.card?.grade && studentCard.fetchStatus === FetchStatus.FETCHED)
			dispatch(
				showAlert({
					title: 'Ошибка!',
					text: 'Ученику необходимо заполнить параллель!',
					type: 'error',
				}),
			)
	}, [studentCard.fetchStatus])

	const changeSelectedPlan = useCallback((value) => {
		setSelectedPlan(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					education_plan_id: selectedPlan,
				},
			}),
		)
	}, [dispatch, id, process, selectedPlan])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Выбор учебного плана</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }}>
								<Form.Label>Выберите учебный план</Form.Label>
								<Select
									placeholder="Выберите план"
									onChange={(value) => changeSelectedPlan(value.value)}
									isLoading={
										studentCard.fetchStatus !== FetchStatus.FETCHED ||
										loadEducationPlansStatus !== FetchStatus.FETCHED ||
										parallelsFetchStatus !== FetchStatus.FETCHED
									}
									options={
										educationPlans?.length
											? educationPlans.map((item) => {
													return {
														label: item.title,
														value: item.id,
													}
											  })
											: []
									}
								/>
							</Form.Group>
						</div>
						<Button
							onClick={submitButtonPressHandler}
							disabled={!selectedPlan || !studentCard?.card?.grade}
							variant="primary"
						>
							Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
