import { ITaskLevel } from '@store/types/tasks'

export const getTableIcon = (type: ITaskLevel) => {
	switch (type) {
		case '10_danger':
			return {
				color: 'red',
				icon: 'no-visit-l',
			}
		case '20_warning':
			return {
				color: 'orange',
				icon: 'eye-open-l',
			}
		case '30_success':
			return {
				color: 'green',
				icon: 'check-l',
			}
		case '40_info':
			return {
				color: 'blue',
				icon: 'homework-l',
			}
		default: {
			return {
				color: 'blue',
				icon: 'homework-l',
			}
		}
	}
}
