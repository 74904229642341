import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { history } from '../reducers'
import { loadCurrentFrozenPayment, loadCurrentFrozenPaymentError, loadCurrentFrozenPaymentSuccess, loadFrozenPayment, loadFrozenPaymentError, loadFrozenPaymentSuccess, unfrozeAllPayment, unfrozeAllPaymentError, unfrozeAllPaymentSuccess, unfrozePayment, unfrozePaymentError, unfrozePaymentSuccess } from '../actions/frozenPayment'

const loadFrozenPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadFrozenPayment)),
		switchMap(({ payload }: ActionType<typeof loadFrozenPayment>) => {
			return from(deps.paymentDataProvider.loadFrozenPayment(payload)).pipe(
				switchMap(payments => {
					return of(loadFrozenPaymentSuccess(payments))
				}),
				catchError(err => {
					return of(loadFrozenPaymentError(err))
				})
			)
		})
	)
}

const loadCurrentFrozenPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadCurrentFrozenPayment)),
		switchMap(({ payload }: ActionType<typeof loadCurrentFrozenPayment>) => {
			return from(deps.paymentDataProvider.loadCurrentFrozenPayment(payload)).pipe(
				switchMap(payments => {
					return of(loadCurrentFrozenPaymentSuccess(payments))
				}),
				catchError(err => {
					return of(loadCurrentFrozenPaymentError(err))
				})
			)
		})
	)
}

const unfrozePaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(unfrozePayment)),
		switchMap(({ payload }: ActionType<typeof loadFrozenPayment>) => {
			return from(deps.paymentDataProvider.unfrozePayment(payload)).pipe(
				switchMap(response => {
					return of(unfrozePaymentSuccess(response), loadCurrentFrozenPayment(payload.contract))
				}),
				catchError(err => {
					return of(unfrozePaymentError(err))
				})
			)
		})
	)
}

const unfrozeAllPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(unfrozeAllPayment)),
		switchMap(({ payload }: ActionType<typeof unfrozeAllPayment>) => {
			return from(deps.paymentDataProvider.unfrozeAllPayment(payload)).pipe(
				switchMap(response => {
					history.push('/frozen-payment')
					return of(unfrozeAllPaymentSuccess(response), loadFrozenPayment({ page: 1 }))
				}),
				catchError(err => {
					return of(unfrozeAllPaymentError(err))
				})
			)
		})
	)
}

export const frozenPaymentEpics = combineEpics(loadFrozenPaymentEpic, unfrozePaymentEpic, unfrozeAllPaymentEpic, loadCurrentFrozenPaymentEpic)