import { ActionType, createAction } from 'typesafe-actions'
import {
	IClassesPayload,
	ICreateClassPayload,
	IEditClassPayload,
	IEditCuratorsPayload,
	IEditStudentsPayload,
	IExpelStudentPayload,
} from '../types/classes'

export const expelStudentStart = createAction('@classes/EXPEL_STUDENT/START')<IExpelStudentPayload>()
export const expelStudentSuccess = createAction('@classes/EXPEL_STUDENT/SUCCESS')<unknown>()
export const expelStudentError = createAction('@classes/EXPEL_STUDENT/ERROR')<unknown>()

export const loadClassesStart = createAction('@classes/LOAD/START')<IClassesPayload>()
export const loadClassesSuccess = createAction('@classes/LOAD/SUCCESS')<any>()
export const loadClassesError = createAction('@classes/LOAD/ERROR')<any>()

export const loadCuratorsStart = createAction('@classes/LOAD_CURATORS/START')<any>()
export const loadCuratorsSuccess = createAction('@classes/LOAD_CURATORS/SUCCESS')<any>()
export const loadCuratorsError = createAction('@classes/LOAD_CURATORS/ERROR')<any>()

export const loadTutorsStart = createAction('@classes/LOAD_TUTORS/START')<any>()
export const loadTutorsSuccess = createAction('@classes/LOAD_TUTORS/SUCCESS')<any>()
export const loadTutorsError = createAction('@classes/LOAD_TUTORS/ERROR')<any>()

export const loadParallelsStart = createAction('@classes/LOAD_PARALLELS/START')<any>()
export const loadParallelsSuccess = createAction('@classes/LOAD_PARALLELS/SUCCESS')<any>()
export const loadParallelsError = createAction('@classes/LOAD_PARALLELS/ERROR')<any>()

export const addCuratorsStart = createAction('@classes/ADD_CURATORS/START')<IEditCuratorsPayload>()
export const addCuratorsSuccess = createAction('@classes/ADD_CURATORS/SUCCESS')<any>()
export const addCuratorsError = createAction('@classes/ADD_CURATORS/ERROR')<any>()

export const removeCuratorsStart = createAction('@classes/REMOVE_CURATORS/START')<IEditCuratorsPayload>()
export const removeCuratorsSuccess = createAction('@classes/REMOVE_CURATORS/SUCCESS')<any>()
export const removeCuratorsError = createAction('@classes/REMOVE_CURATORS/ERROR')<any>()

export const addStudentsStart = createAction('@classes/ADD_STUDENTS/START')<IEditStudentsPayload>()
export const addStudentsSuccess = createAction('@classes/ADD_STUDENTS/SUCCESS')<any>()
export const addStudentsError = createAction('@classes/ADD_STUDENTS/ERROR')<any>()

export const createClassStart = createAction('@classes/CREATE/START')<ICreateClassPayload>()
export const createClassSuccess = createAction('@classes/CREATE/SUCCESS')<any>()
export const createClassError = createAction('@classes/CREATE/ERROR')<any>()

export const editClassStart = createAction('@classes/edit/START')<IEditClassPayload>()
export const editClassSuccess = createAction('@classes/edit/SUCCESS')<any>()
export const editClassError = createAction('@classes/edit/ERROR')<any>()

export const deleteClassStart = createAction('@classes/delete/START')<IClassesPayload>()
export const deleteClassSuccess = createAction('@classes/delete/SUCCESS')<any>()
export const deleteClassError = createAction('@classes/delete/ERROR')<any>()

export type ClassesActions =
    | ActionType<typeof loadClassesStart>
    | ActionType<typeof loadClassesSuccess>
    | ActionType<typeof loadClassesError>
    | ActionType<typeof expelStudentStart>
    | ActionType<typeof expelStudentSuccess>
    | ActionType<typeof expelStudentError>
    | ActionType<typeof loadCuratorsStart>
    | ActionType<typeof loadCuratorsSuccess>
    | ActionType<typeof loadCuratorsError>
    | ActionType<typeof loadTutorsStart>
    | ActionType<typeof loadTutorsSuccess>
    | ActionType<typeof loadTutorsError>
    | ActionType<typeof loadParallelsStart>
    | ActionType<typeof loadParallelsSuccess>
    | ActionType<typeof loadParallelsError>
    | ActionType<typeof addCuratorsStart>
    | ActionType<typeof addCuratorsSuccess>
    | ActionType<typeof addCuratorsError>
    | ActionType<typeof removeCuratorsStart>
    | ActionType<typeof removeCuratorsSuccess>
    | ActionType<typeof removeCuratorsError>
    | ActionType<typeof addStudentsStart>
    | ActionType<typeof addStudentsSuccess>
    | ActionType<typeof addStudentsError>
    | ActionType<typeof createClassStart>
    | ActionType<typeof createClassSuccess>
    | ActionType<typeof createClassError>
    | ActionType<typeof editClassStart>
    | ActionType<typeof editClassSuccess>
    | ActionType<typeof editClassError>
    | ActionType<typeof deleteClassStart>
    | ActionType<typeof deleteClassSuccess>
    | ActionType<typeof deleteClassError>;
