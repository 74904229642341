import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'
import { SchoolState } from '../../../store/types/school'
import { loadSchool } from '../../../store/actions/school'

interface IDistributeToSchoolProps extends SchoolState {
	id: number
	distributeToSchool: (payload: any) => {}
	student_id: number
	loadSchool: (payload: any) => {}
}

export const Component: React.FC<IDistributeToSchoolProps> = ({
	distributeToSchool,
	id,
	student_id,
	fetchStatus,
	school,
	error,
	loadSchool,
}) => {
	const [selectedSchool, setSelectedSchool] = useState<undefined | any>(undefined)
	const [selectedEducationForm, setSelectedEducationForm] = useState<undefined | string>(undefined)
	const [errors, setErrors] = useState<Array<string>>([])

	useEffect(() => {
		loadSchool(student_id)
	}, [student_id])

	const selectedSchoolChangeCallback = useCallback(
		(event: any) => {
			setSelectedSchool(JSON.parse(event.target.value))
		},
		[selectedSchool],
	)

	useEffect(() => {
		if (selectedSchool) {
			const forms = getAvailableEducationForms()
			if (forms.length !== 0) {
				setSelectedEducationForm(forms[0].value)
			}
		}
	}, [selectedSchool])

	const selectedEducationFormChangeCallback = useCallback(
		(event: any) => {
			setSelectedEducationForm(event.target.value)
		},
		[selectedEducationForm],
	)

	const getAvailableEducationForms = () => {
		const forms = []

		if (selectedSchool.full_time) {
			forms.push({
				name: 'Очная',
				value: 'full_time',
			})
		}

		if (selectedSchool.combined) {
			forms.push({
				name: 'Очно-Заочная',
				value: 'combined',
			})
		}

		if (selectedSchool.family) {
			forms.push({
				name: 'Семейная',
				value: 'family',
			})
		}

		if (selectedSchool.extramural) {
			forms.push({
				name: 'Заочная',
				value: 'extramural',
			})
		}

		return forms
	}

	const submitButtonPressHandler = () => {
		const newErrState = []

		if (!selectedSchool) {
			newErrState.push('selectedSchool')
		}

		if (!selectedEducationForm || selectedEducationForm === 'Не выбрано') {
			newErrState.push('selectedEducationForm')
		}

		if (newErrState.length === 0) {
			distributeToSchool({
				process: 'set_school_action',
				id: id,
				parameters: {
					school_id: selectedSchool.id,
					education_format: selectedEducationForm,
				},
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Распределить ребенка в школу</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
								<Form.Label>Выберите школу</Form.Label>
								{fetchStatus === FetchStatus.FETCHING ? (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								) : (
									<Form.Control
										isInvalid={errors.includes('selectedSchool')}
										value={JSON.stringify(selectedSchool)}
										onChange={selectedSchoolChangeCallback}
										as="select"
										placeholder="Выберите школу"
									>
										<option value={undefined} defaultChecked>
											Не выбрано
										</option>
										{Array.isArray(school) &&
											school.map((el: any) => (
												<option
													value={JSON.stringify(el)}
													key={el.id}
												>{`${el.prefix} ${el.name}`}</option>
											))}
									</Form.Control>
								)}
							</Form.Group>
							{selectedSchool && (
								<Form.Group style={{ width: '100%' }} controlId="formBasicEmail">
									<Form.Label>Выберите форму обучения</Form.Label>
									<Form.Control
										isInvalid={errors.includes('selectedEducationForm')}
										value={selectedEducationForm}
										onChange={selectedEducationFormChangeCallback}
										as="select"
										placeholder="Выберите форму обучения"
									>
										{getAvailableEducationForms().map((el: any, index: number) => (
											<option value={el.value} key={index}>
												{el.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							)}
						</div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedSchool} variant="primary">
							Распределить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const DistributeToSchool = connect(
	({ school }: State, ownProps: any) => ({ ...school }),
	(dispatch) => {
		return {
			distributeToSchool: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadSchool: (payload: any) => {
				return dispatch(loadSchool(payload))
			},
		}
	},
)(Component)

export default DistributeToSchool
