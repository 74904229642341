import { ActionType, createAction } from 'typesafe-actions'

export const loadStudentCard = createAction('@studentCard/LOAD/START')<any>()
export const loadStudentCardSuccess = createAction('@studentCard/LOAD/SUCCESS')<any>()
export const loadStudentCardError = createAction('@studentCard/LOAD/ERROR')<any>()
export const generateEnrollmentCertificate = createAction('@studentCard/GENERATE_CERTIFICATE/START')<any>()
export const onGenerateEnrollmentCertificateSuccess = createAction('@studentCard/GENERATE_CERTIFICATE/SUCCESS')<any>()
export const onGenerateEnrollmentCertificateError = createAction('@studentCard/GENERATE_CERTIFICATE/ERROR')<any>()

export type StudentCardActions =
	| ActionType<typeof loadStudentCard>
	| ActionType<typeof loadStudentCardSuccess>
	| ActionType<typeof loadStudentCardError>
	| ActionType<typeof generateEnrollmentCertificate>
	| ActionType<typeof onGenerateEnrollmentCertificateSuccess>
	| ActionType<typeof onGenerateEnrollmentCertificateError>
