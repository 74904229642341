import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FiltersPanel } from '@frontend-modules/filters-generator'
import { AppButton, AppDatepicker, AppFontIcon, AppTable, AppText } from '@frontend-modules/ui-kit'
import { DocumentList, StatisticCard, PageTemplate, AssignmentModal } from '@components/payments'
import { getCurrentDate, getCurrentDateMinusSomeDays, getOffsetTopToContainer } from '@utils'
import { formatDate, parseQueryFiltersParamsToObject, useQueryFiltersParams } from '@frontend-modules/frontend-utils'
import { trimSeparator } from '@frontend-modules/frontend-utils'
import {
	assignmentColor,
	REGISTRIES_PAGINATION_LIMIT,
	TABLE_PAGINATION_LIMIT,
	SERVICE_NAME,
} from './Assignments.config'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'
import { FetchStatus } from '@/common/types'
import {
	loadAssignments,
	loadMorePOFiles,
	setAssignmentsEndDate,
	setAssignmentsQuery,
	setAssignmentsStartDate,
} from '@store/actions/paymentsAssignments'
import { IAssignmentTypeEnum } from '@store/types/paymentsAssignments'
import { loadMetaCatalog, loadMoreMetaCatalog } from '@store/actions/meta'
import { AccessHelper } from '@frontend-modules/access-controller'

const Assignments = () => {
	const {
		errors,
		summary,
		POTable,
		POFiles,
		query,
		POTableFetchStatus,
		POFilesFetchStatus,
		startDate: storeStartDate,
		endDate: storeEndDate,
	} = useSelector((state: State) => state.paymentsAssignments)

	const [startDate, setStartDate] = useState(storeStartDate || getCurrentDateMinusSomeDays(7))
	const [endDate, setEndDate] = useState(storeEndDate || getCurrentDate())
	const [selectedAssignment, setSelectedAssignment] = useState(null)
	const [initQuery, changeQueryParams] = useQueryFiltersParams()
	const [selectedFilters, setSelectedFilters] = useState(initQuery || [])
	const [tablePagination, setTablePagination] = useState({
		tableCurrentPage: 1,
		tableLimit: TABLE_PAGINATION_LIMIT,
	})

	const [POFilesPagination, setPOFilesPagination] = useState({
		POFilesCurrentPage: 1,
		POFilesLimit: REGISTRIES_PAGINATION_LIMIT,
	})

	const dispatch = useDispatch()

	const { entities, catalog, fetchStatus: metaFetchStatus } = useSelector((state: State) => state.meta)

	const columns = useMemo(() => {
		return AccessHelper.filterList([
			{
				title: 'Номер ПП',
				dataIndex: 'number',
				key: 'number',
				accessKey: 'payment_orders.POTable.columns.number',
				render: (_, record) => {
					return (
						<>
							{!!record.errors.find((err) => err === 'no_payments') && (
								<AppFontIcon icon={'exclamation-in-circle-l'} color={'#C13C37'} />
							)}
							{!!record.errors.find((err) => err === 'amount_gap') && (
								<AppFontIcon icon={'exclamation-in-circle-l'} color={'#ff8725'} />
							)}
							{record?.number || '-'}
						</>
					)
				},
			},
			{
				title: 'Дата',
				dataIndex: 'date',
				key: 'date',
				accessKey: 'payment_orders.POTable.columns.PODate',
				render: (_, record) => {
					return `${formatDate(record?.PODate) || '-'}`
				},
			},
			{
				title: 'Тип',
				dataIndex: 'type',
				key: 'type',
				accessKey: 'payment_orders.POTable.columns.type',
				render: (_, record) => {
					return <AppText text={IAssignmentTypeEnum[record.type]} color={assignmentColor[record.type]} />
				},
			},
			{
				title: 'Сумма',
				dataIndex: 'amount',
				key: 'amount',
				width: '100px',
				accessKey: 'payment_orders.POTable.columns.amount',
				render: (_, record) => {
					return `${trimSeparator(record?.amount) || '-'}`
				},
			},
			{
				title: 'Получатель',
				dataIndex: 'recipient',
				key: 'recipient',
				accessKey: 'payment_orders.POTable.columns.recipient',
			},
			{
				title: 'Плательщик',
				dataIndex: 'payer',
				key: 'payer',
				accessKey: 'payment_orders.POTable.columns.payer',
			},
			{
				title: 'Назначение платежа',
				dataIndex: 'purpose',
				key: 'purpose',
				accessKey: 'payment_orders.POTable.columns.purpose',
			},
			{
				title: '',
				dataIndex: 'actions',
				key: 'actions',
				width: '150px',
				render: (_, record) => {
					return (
						<>
							<AppButton
								type={'medium-primary'}
								label={'Редактировать'}
								accessKey={'payment_orders.POTable.actions.edit'}
								onClick={() => {
									setSelectedAssignment(record)
								}}
							/>
						</>
					)
				},
			},
		])
	}, [])

	const loadPaysData = useCallback(
		(params, isLoadingMorePOFiles = false) => {
			// это должно попасть в юрлу
			const normalizedQuery = {
				filters: params?.filters || [],
				sorting: params.sorting || [],
			}
			const tablePaginationLocal = {
				tableCurrentPage: params?.tableCurrentPage,
				tableLimit: params?.tableLimit,
			}
			const POFilesPaginationLocal = {
				POFilesCurrentPage: params?.POFilesCurrentPage,
				POFilesLimit: params?.POFilesLimit,
			}
			const normalizedParams = {
				...normalizedQuery,
				...tablePaginationLocal,
				...POFilesPaginationLocal,
				dateStart: params.dateStart,
				dateEnd: params.dateEnd,
				fetchingTypes: params?.fetchingTypes,
			}
			dispatch(setAssignmentsQuery(normalizedQuery))
			setSelectedFilters(normalizedQuery)
			setTablePagination(tablePaginationLocal)
			setPOFilesPagination(POFilesPaginationLocal)
			changeQueryParams(normalizedQuery)
			if (isLoadingMorePOFiles) {
				dispatch(loadMorePOFiles(normalizedParams))
			} else {
				dispatch(loadAssignments(normalizedParams))
			}
		},
		[changeQueryParams, dispatch],
	)
	const loadData = (isInit?: boolean) => {
		const baseParams = {
			tableCurrentPage: isInit ? 1 : tablePagination.tableCurrentPage,
			tableLimit: tablePagination?.tableLimit,
			POFilesCurrentPage: isInit ? 1 : POFilesPagination.POFilesCurrentPage,
			POFilesLimit: POFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POTableFetchStatus', 'POFilesFetchStatus'],
		}
		const paysParams = {
			...baseParams,
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
		}
		const currentQuery = parseQueryFiltersParamsToObject(location.search)
		// нужно для загрузки query параметров из стор перзистного
		if (
			query &&
			(!currentQuery?.filters || currentQuery?.filters?.length <= 0) &&
			(!currentQuery?.sorting || currentQuery?.sorting?.length <= 0)
		) {
			loadPaysData({
				...query,
				...baseParams,
			})
		} else {
			loadPaysData(paysParams)
		}
	}

	useEffect(() => {
		loadData(true)
	}, [startDate, endDate, dispatch])

	const onPaginationChange = ({ page, limit }) => {
		const newPagination = {
			currentPage: page,
			limit,
		}
		loadPaysData({
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
			tableCurrentPage: newPagination?.currentPage,
			tableLimit: newPagination?.limit,
			POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
			POFilesLimit: POFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POTableFetchStatus'],
		})
	}

	/**
	 * @description - обработка смены лимита
	 * @param page
	 * @param limit
	 */
	const onPaginationSizeChangeLocal = (page, limit) => {
		onPaginationChange?.({ page: 1, limit })
		scrollToTableStart()
	}
	/**
	 * @description - обработка смены страницы
	 * внутри условие нужно, тк смена лимита тригерит эту функцию тоже - и идет двойной запрос
	 * @param page
	 */
	const onPaginationChangeLocal = (page) => {
		if (page !== POTable?.pagination.currentPage) {
			onPaginationChange?.({ page, limit: tablePagination?.tableLimit })
		}
		scrollToTableStart()
	}

	const scrollToTableStart = () => {
		const container = document.getElementById('contentContainer')
		const element = document.getElementById('assignmentsTable')
		container.scrollTop = getOffsetTopToContainer(element, container) - 25
	}

	const onPOFilesUpdate = ({ page, limit, isLoadingMore }) => {
		const newPOFilesPagination = {
			POFilesCurrentPage: page,
			POFilesLimit: limit,
		}
		const params = {
			filters: selectedFilters.filters,
			sorting: selectedFilters?.sorting,
			tableCurrentPage: tablePagination?.tableCurrentPage,
			tableLimit: tablePagination?.tableLimit,
			POFilesCurrentPage: newPOFilesPagination.POFilesCurrentPage,
			POFilesLimit: newPOFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POFilesFetchStatus'],
		}
		loadPaysData(params, isLoadingMore)
	}

	const onFilterAdd = (newFilters) => {
		const params = {
			filters: newFilters,
			tableCurrentPage: 1,
			tableLimit: tablePagination?.tableLimit,
			sorting: selectedFilters.sorting,
			POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
			POFilesLimit: POFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POTableFetchStatus', 'POFilesFetchStatus'],
		}
		loadPaysData(params)
	}

	/**
	 * @description реагируем на удаление фильтра
	 */
	const onFilterRemove = (newFilters) => {
		const params = {
			filters: newFilters,
			tableCurrentPage: 1,
			tableLimit: tablePagination?.tableLimit,
			sorting: selectedFilters.sorting,
			POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
			POFilesLimit: POFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POTableFetchStatus'],
		}
		loadPaysData(params)
	}
	/**
	 * @description реагируем на полную очистку фильтров
	 */
	const onFiltersClear = () => {
		const params = {
			filters: [],
			sorting: selectedFilters.sorting,
			tableCurrentPage: 1,
			tableLimit: tablePagination?.tableLimit,
			POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
			POFilesLimit: POFilesPagination.POFilesLimit,
			dateEnd: endDate,
			dateStart: startDate,
			fetchingTypes: ['POTableFetchStatus'],
		}
		loadPaysData(params)
	}

	const filtersApiConfig = useMemo(() => {
		return {
			catalogList: catalog.api,
			onCatalogLoad: (data) => dispatch(loadMetaCatalog(data)),
			onCatalogLoadMore: (data) => dispatch(loadMoreMetaCatalog(data)),
		}
	}, [catalog.api, dispatch])

	const sortingConfig = useMemo(() => {
		const onSortingSet = (newSorting) => {
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				tableCurrentPage: 1,
				tableLimit: tablePagination?.tableLimit,
				POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
				POFilesLimit: POFilesPagination.POFilesLimit,
				dateEnd: endDate,
				dateStart: startDate,
				fetchingTypes: ['POTableFetchStatus'],
			}
			loadPaysData(params)
		}

		const onSortingClear = (newSorting) => {
			const params = {
				filters: selectedFilters.filters,
				sorting: newSorting,
				tableCurrentPage: 1,
				tableLimit: tablePagination?.tableLimit,
				POFilesCurrentPage: POFilesPagination.POFilesCurrentPage,
				POFilesLimit: POFilesPagination.POFilesLimit,
				dateEnd: endDate,
				dateStart: startDate,
				fetchingTypes: ['POTableFetchStatus'],
			}
			loadPaysData(params)
		}

		return {
			availableList: entities?.payment_orders?.sorting,
			selectedList: selectedFilters.sorting,
			onSortingSet: onSortingSet,
			onSortingClear: onSortingClear,
		}
	}, [
		entities?.payment_orders?.sorting,
		selectedFilters,
		tablePagination?.tableLimit,
		POFilesPagination,
		endDate,
		startDate,
		loadPaysData,
	])

	const onStartDateChange = (date) => {
		setStartDate(date)
		dispatch(setAssignmentsStartDate(date))
	}

	const onEndDateChange = (date) => {
		setEndDate(date)
		dispatch(setAssignmentsEndDate(date))
	}

	return (
		<>
			{!!selectedAssignment && (
				<AssignmentModal
					id={selectedAssignment.id}
					isShow={!!selectedAssignment}
					onSetShow={(open, isWasChanges) => {
						if (!open) setSelectedAssignment(null)
						if (!open && isWasChanges) loadData()
					}}
				/>
			)}
			<PageTemplate
				type={'assignments'}
				periodStart={
					<AppDatepicker
						allowClear={false}
						defaultValue={startDate}
						label={'Дата начала'}
						onChange={onStartDateChange}
					/>
				}
				periodEnd={
					<AppDatepicker
						allowClear={false}
						defaultValue={endDate}
						label={'Дата конца'}
						onChange={onEndDateChange}
					/>
				}
				filters={
					<FiltersPanel
						isFetching={metaFetchStatus === FetchStatus.FETCHING}
						selectsApiConfig={filtersApiConfig}
						availableFilters={entities?.payment_orders?.filters || []}
						selectedFiltersList={selectedFilters.filters}
						onFilterAdd={onFilterAdd}
						onFilterRemove={onFilterRemove}
						onFiltersClear={onFiltersClear}
						serviceName={SERVICE_NAME}
					/>
				}
				infoStatistic={
					<StatisticCard
						type={'assignments'}
						data={{
							total: summary?.total,
							incoming: summary?.incoming,
							incomingValue: summary?.incomingCoins,
							outgoing: summary?.outgoing,
							outgoingValue: summary?.outgoingCoins,
							red: summary?.POWithoutPayments,
							orange: summary?.sumMismatch,
						}}
					/>
				}
				infoDocumentList={
					<DocumentList
						type={'assignments'}
						data={POFiles}
						isLoading={POFilesFetchStatus === FetchStatus.FETCHING}
						isLoadingMore={POFilesFetchStatus === FetchStatus.FETCHING_MORE}
						onLoad={onPOFilesUpdate}
					/>
				}
				table={
					<AppTable
						id={'assignmentsTable'}
						isLoading={POTableFetchStatus === FetchStatus.FETCHING}
						columns={columns}
						isCanDeleteRow={false}
						isNeedNumbering={false}
						dataSource={POTable?.results || []}
						locale={{ emptyText: 'Ничего не найдено' }}
						rowKey={(record, index) => `${record.id}-${index}`}
						sortingConfig={sortingConfig}
						pagination={{
							pageSize: tablePagination?.tableLimit,
							defaultCurrent: 1,
							onChange: onPaginationChangeLocal,
							onShowSizeChange: onPaginationSizeChangeLocal,
							locale: { items_per_page: 'записей ' },
							total: POTable?.pagination?.count,
							current: tablePagination?.tableCurrentPage,
						}}
						titleSortingType={'customSorting'}
					/>
				}
			/>
		</>
	)
}

export default Assignments
