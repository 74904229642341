import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '../../common/types'
import { showAlert } from '../actions/alert'
import {
	createProcessError,
	createProcessStart,
	createProcessSuccess,
	downloadReportError,
	downloadReportStart,
	downloadReportSuccess,
	loadFinishedProcesses,
	loadFinishedProcessesError,
	loadFinishedProcessesSuccess,
	loadProcessesError,
	loadProcessesStart,
	loadProcessesSuccess,
	updateCreateProcessesId,
} from '../actions/processes'
import { history } from '../reducers'
import { getEducationCostNum } from '../../Screens/Debts/utils'

const loadProcessesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadProcessesStart)),
		switchMap(({ payload }: ActionType<typeof loadProcessesStart>) => {
			return from(deps.processesDataProvider.loadProcesses(payload)).pipe(
				switchMap((processes) => {
					if (payload.createProcessId) {
						return of(
							loadProcessesSuccess(processes),
							updateCreateProcessesId(
								store$.value.processes.createProcessesId.filter((el) => el !== payload.createProcessId),
							),
						)
					}
					return of(loadProcessesSuccess(processes), updateCreateProcessesId([]))
				}),
				catchError((err) => {
					return of(
						loadProcessesError(err),
						updateCreateProcessesId([]),
						showAlert({
							title: 'Ошибка',
							text: JSON.stringify(err?.response?.data),
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const loadFinishedProcessesEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(loadFinishedProcesses)),
		switchMap(({ payload }: ActionType<typeof loadProcessesStart>) => {
			return from(deps.processesDataProvider.loadFinishedProcesses(payload)).pipe(
				switchMap((processes) => of(loadFinishedProcessesSuccess(processes))),
				catchError((error) => of(loadFinishedProcessesError(error))),
			)
		}),
	)
}

const createProcessEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(createProcessStart)),
		switchMap(({ payload: { id, process, parameters } }: ActionType<typeof createProcessStart>) => {
			return from(deps.processesDataProvider.createProcess(process, id, parameters)).pipe(
				switchMap((response) => {
					if (process === 'generate_order' || process === 'generate_order_existing_contract') {
						const stateNote = response.state_notes.filter((note) => note.content_type === 'data/order')

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
							window.open(reportNote.content.docx_url, '_blank')
							window.open(reportNote.content.certificate_docx_url, '_blank')
							window.open(reportNote.content.certificate_pdf_url, '_blank')
						}
					}

					if (process === 'generate_additional_agreement') {
						const stateNote = response.state_notes.filter(
							(note) => note.content_type === 'generate_additional_agreement',
						)

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
						}
					}

					if (process === 'generate_statement_grades') {
						const stateNote = response.state_notes.filter(
							(note) => note.content_type === 'generate_statement_grades',
						)

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.docx_url, '_blank')
						}
					}

					if (process === 'statement_for_enroll') {
						const stateNote = response.state_notes.filter(
							(note) => note.content_type === 'statement_for_enroll',
						)

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
						}
					}

					if (process === 'generate_offer') {
						const stateNote = response.state_notes.filter((note) => note.content_type === 'generate_offer')

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
							window.open(reportNote.content.docx_url, '_blank')
						}
					}

					if (process === 'pencil_set_data') {
						const stateNote = response.state_notes.filter(
							(note) => note.content_type === 'data/pencil_set_data',
						)

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
						}
					}

					if (process === 'generate_expulsion_order') {
						const stateNote = response.state_notes.filter(
							(note) => note.content_type === 'data/generate_expulsion_order',
						)

						if (stateNote.length) {
							const reportNote = stateNote[0]
							window.open(reportNote.content.pdf_url, '_blank')
						}
					}

					const {
						studentSearch,
						idSearch,
						parentSearch,
						actionsSearch,
						schoolSearch,
						formatSearch,
						tariffSearch,
						staffUsersSearch,
						createdMonthSearch,
						processTypesSearch,
						gradeSearch,
					} = store$.value.search
					const { constants } = store$.value.constants

					history.push('/')

					return of(
						createProcessSuccess(response),
						loadProcessesStart({
							page: store$.value.processes.currentPage,
							searchStudent: studentSearch,
							idSearch,
							searchParent: parentSearch,
							searchActions: actionsSearch,
							searchTariff: constants.EDUCATION_COST
								? tariffSearch.map((tariff) =>
									getEducationCostNum(constants.constants.EDUCATION_COST, tariff),
								)
								: [],
							searchSchool: schoolSearch,
							searchForm: formatSearch,
							searchStaffUsers: staffUsersSearch,
							searchCreatedMonth: createdMonthSearch,
							searchProcessTypes: processTypesSearch,
							searchGrade: gradeSearch,
						}),
					)
				}),
				catchError((err) => {
					return of(
						createProcessError(err),
						updateCreateProcessesId(store$.value.processes.createProcessesId.filter((el) => el !== id)),
						showAlert({
							title: 'Ошибка',
							text: JSON.stringify(err?.response?.data),
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

const downloadReportEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(downloadReportStart)),
		switchMap(({ payload: { id, process, parameters } }: ActionType<typeof downloadReportStart>) => {
			return from(deps.processesDataProvider.createProcess(process, id, parameters)).pipe(
				switchMap((response) => {
					const noteWithReports = response.state_notes.filter((el: any) => el.content_type === 'data/report')

					if (noteWithReports.length !== 0) {
						noteWithReports[0].content.reports.forEach((report: { url: string }) => {
							window.open(report.url)
						})
					}

					const {
						studentSearch,
						idSearch,
						parentSearch,
						actionsSearch,
						schoolSearch,
						formatSearch,
						tariffSearch,
						staffUsersSearch,
						createdMonthSearch,
						processTypesSearch,
						gradeSearch,
					} = store$.value.search
					const { constants } = store$.value.constants

					return of(
						downloadReportSuccess(response),
						loadProcessesStart({
							page: store$.value.processes.currentPage,
							searchStudent: studentSearch,
							idSearch,
							searchParent: parentSearch,
							searchActions: actionsSearch,
							searchTariff: constants.EDUCATION_COST
								? tariffSearch.map((tariff) =>
									getEducationCostNum(constants.constants.EDUCATION_COST, tariff),
								)
								: [],
							searchSchool: schoolSearch,
							searchForm: formatSearch,
							searchStaffUsers: staffUsersSearch,
							searchCreatedMonth: createdMonthSearch,
							searchProcessTypes: processTypesSearch,
							searchGrade: gradeSearch,
						}),
						{
							type: '@@router/LOCATION_CHANGE',
							payload: {
								location: {
									pathname: '/',
									search: '',
									hash: '',
								},
								action: 'PUSH',
								isFirstRendering: false,
							},
						},
						updateCreateProcessesId(store$.value.processes.createProcessesId.filter((el) => el !== id)),
					)
				}),
				catchError((err) => {
					return of(
						downloadReportError(err),
						updateCreateProcessesId(store$.value.processes.createProcessesId.filter((el) => el !== id)),
						showAlert({
							title: 'Ошибка',
							text: JSON.stringify(err?.response?.data),
							type: 'error',
						}),
					)
				}),
			)
		}),
	)
}

export const processesEpics = combineEpics(
	loadProcessesEpic,
	createProcessEpic,
	downloadReportEpic,
	loadFinishedProcessesEpic,
)
