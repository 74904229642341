import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../store/store'
import { createProcessStart } from '../../../store/actions/processes'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'

export interface IChooseSchoolProps {
    process: string;
    id: number;
}

export const ChooseSchool: React.FC<IChooseSchoolProps> = ({ process, id }) => {
	const { constants, fetchStatus: constantsFetchStatus } = useSelector((state: State) => state.constants)
	const [selectedSchool, setSelectedSchool] = useState<number>()
	const dispatch = useDispatch()

	const changeSelectedSchool = useCallback((value) => {
		setSelectedSchool(value)
	}, [])

	const submitButtonPressHandler = useCallback(() => {
		dispatch(
			createProcessStart({
				id,
				process,
				parameters: {
					school_id: selectedSchool,
				},
			}),
		)
	}, [dispatch, id, process, selectedSchool])

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Выбор школы</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<Form.Group style={{ width: '100%' }} className="mb-3" controlId="formBasicEmail">
								<Form.Label>Выберите школу</Form.Label>
								{constantsFetchStatus === FetchStatus.FETCHED ? (
									<Form.Control
										value={selectedSchool}
										onChange={(event) => changeSelectedSchool(event.target.value)}
										as="select"
										placeholder="Выберите школу"
									>
										<option value={''} defaultChecked>
                                            Не выбрано
										</option>
										{constants.SCHOOLS &&
                                            Object.keys(constants.SCHOOLS).map((key) => (
                                            	<option value={key} key={key}>
                                            		{constants.SCHOOLS[key]}
                                            	</option>
                                            ))}
									</Form.Control>
								) : (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								)}
							</Form.Group>
						</div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedSchool} variant="primary">
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
