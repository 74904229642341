import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'
import { State } from '../../../store/store'
import { FetchStatus } from '../../../common/types'
import Skeleton from 'react-loading-skeleton'
import { loadSpecialistStart } from '../../../store/actions/specialist'
import { SpecialistState } from '../../../store/types/specialist'

interface IRecordTrialProps {
	id: number
	loadSpecialist: (payload: any) => {}
	changeToDoSpecialist: (payload: any) => {}
	student_id: number
	specialist: SpecialistState
}

export const Component: React.FC<IRecordTrialProps> = ({
	changeToDoSpecialist,
	loadSpecialist,
	id,
	student_id,
	specialist,
}) => {
	const [selectedSpecialist, setSelectedSpecialist] = useState<null | string>(null)
	const [errors, setErrors] = useState<Array<string>>([])

	useEffect(() => {
		loadSpecialist(student_id)
	}, [student_id])

	const selectedSpecialistChangeCallback = useCallback(
		(event: any) => {
			setSelectedSpecialist(event.target.value)
		},
		[selectedSpecialist],
	)

	const submitButtonPressHandler = (e: any) => {
		const newErrState = []

		if (!selectedSpecialist) {
			newErrState.push('selectedSpecialist')
		}

		if (newErrState.length === 0) {
			changeToDoSpecialist({
				process: 'change_specialist',
				id: id,
				parameters: {
					user_id: selectedSpecialist,
				},
			})
		}
		setErrors(newErrState)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Изменить специалиста ДО</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div className="d-flex">
							<Form.Group style={{ width: '100%' }} className="mr-3" controlId="formBasicEmail">
								<Form.Label>Выберите спецалиста ДО</Form.Label>
								{specialist.fetchStatus === FetchStatus.FETCHING ? (
									<div>
										<Skeleton height={40} width={100} />
									</div>
								) : (
									<Form.Control
										isInvalid={errors.includes('selectedCourse')}
										value={selectedSpecialist == null ? undefined : selectedSpecialist}
										onChange={selectedSpecialistChangeCallback}
										as="select"
										placeholder="Выберите специалиста"
									>
										<option value={undefined} defaultChecked>
											Не выбрано
										</option>
										{specialist.specialist.map((el: any) => (
											<option
												value={el.user.id}
												key={el.id}
											>{`${el.user.first_name} ${el.user.last_name} ${el.user.patronymic}`}</option>
										))}
									</Form.Control>
								)}
							</Form.Group>
						</div>
						<div className="d-flex"></div>
						<Button onClick={submitButtonPressHandler} disabled={!selectedSpecialist} variant="primary">
							Передать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}

export const ChangeDo = connect(
	({ specialist }: State, ownProps: any) => ({ specialist, ...ownProps }),
	(dispatch) => {
		return {
			changeToDoSpecialist: (payload: any) => {
				return dispatch(createProcessStart(payload))
			},
			loadSpecialist: (payload: any) => {
				return dispatch(loadSpecialistStart(payload))
			},
		}
	},
)(Component)
