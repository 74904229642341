import { ActionType, createAction } from 'typesafe-actions'

export const login = createAction('@auth/LOGIN/START')<{
	username: string
	password: string
}>()
export const onLoginSuccess = createAction('@auth/LOGIN/SUCCESS')<any>()
export const onLoginError = createAction('@auth/LOGIN/ERROR')<any>()

export type LoginActions =
	| ActionType<typeof login>
	| ActionType<typeof onLoginSuccess>
	| ActionType<typeof onLoginError>
