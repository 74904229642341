import React, { FC, useEffect, useMemo, useState } from 'react'
import { AppPopover } from '@frontend-modules/ui-kit'
import './CreateEditPayPopup.scss'
import { usePrevState } from '@/hooks'
import { PayDistribution } from '@components/payments'
import { FetchStatus } from '@/common/types'
import { ICreateEditPayPopupProps } from './CreateEditPayPopup.types'

/**
 * @description - модалка редактирования платежа
 * @param props
 * @constructor
 */
const CreateEditPayPopup: FC<ICreateEditPayPopupProps> = (props) => {
	const { children, type, onSave, changeFetchStatus, data, defaultPaySum } = props
	const [isPopupShow, setPopupShow] = useState(false)
	const prevChangeFetchStatus = usePrevState(changeFetchStatus)

	useEffect(() => {
		if (prevChangeFetchStatus === FetchStatus.FETCHING && changeFetchStatus === FetchStatus.FETCHED) {
			setPopupShow(false)
		}
	}, [prevChangeFetchStatus, changeFetchStatus])

	const title = type === 'create' ? 'Создание платежа' : 'Редактирование платежа'

	const content = useMemo(
		() => (
			<PayDistribution
				title={title}
				inModal
				defaultSum={type === 'edit' ? data?.amount : defaultPaySum}
				isNeedRenderSum
				isMotherCapital={!!data?.isMotherCapital}
				isSumDisabled={type === 'edit'}
				data={data?.distributions || []}
				onSave={onSave}
				onCancel={() => setPopupShow(false)}
				personalAccount={data?.personalAccount}
				changeFetchStatus={changeFetchStatus}
			/>
		),
		[defaultPaySum, title, data, type, onSave, changeFetchStatus],
	)

	return (
		<AppPopover
			content={content}
			open={isPopupShow}
			placement={'left'}
			rootClassName={'create-edit-pay-popup'}
			onOpenChange={setPopupShow}
			trigger={'click'}
		>
			{children}
		</AppPopover>
	)
}

export default CreateEditPayPopup
