import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import {
	contractsPageChange,
	contractsSearchChange,
	loadContracts,
	loadContractsError,
	loadContractsSuccess,
} from '../actions/contracts'
import { Action } from '../store'
import { ContractsState } from '../types/contracts'
import { deleteContract, deleteContractError, deleteContractSuccess } from '../actions/contract'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	error: null,
	contracts: null,
	count: 0,
	page: 1,
	search: {
		counterparty: null,
		student: null,
		parent_seen: null,
		kontur_signed: null,
		number: null,
		status: {
			label: 'Активные',
			value: 'active',
		},
		termination_date: '',
		education_cost: null,
		agreed_month: null,
	},
	deleteContractStatus: FetchStatus.INITIAL,
}

export const contracts = (state: ContractsState = initialState, action: Action) => {
	switch (action.type) {
	case getType(loadContracts): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING,
		}
	}

	case getType(loadContractsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			contracts: action.payload.results,
			count: action.payload.count,
		}
	}

	case getType(loadContractsError): {
		return {
			...state,
			fetchStatus: FetchStatus.ERROR,
			error: action.payload,
		}
	}

	case getType(deleteContract): {
		return {
			...state,
			deleteContractStatus: FetchStatus.FETCHING,
		}
	}

	case getType(deleteContractError): {
		return {
			...state,
			deleteContractStatus: FetchStatus.ERROR,
		}
	}

	case getType(deleteContractSuccess): {
		return {
			...state,
			contracts: state.contracts?.filter((contract) => contract.id !== action.payload),
			count: !state.count ? 0 : state.count - 1,
			deleteContractStatus: FetchStatus.FETCHED,
		}
	}

	case getType(contractsPageChange): {
		return {
			...state,
			page: action.payload,
		}
	}

	case getType(contractsSearchChange): {
		return {
			...state,
			search: {
				...state.search,
				[action.payload.type]: action.payload.value,
			},
		}
	}

	default: {
		return {
			...state,
		}
	}
	}
}
