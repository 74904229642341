import { BaseRestDataProvider } from './BaseRestDataProvider'
import { oldAxios } from './axios-init'

interface LoadFormParams {
	parent_id: string
	student_id: string
}

export class FormDataProvider extends BaseRestDataProvider {
	loadForm = async ({ parent_id, student_id }: LoadFormParams) => {
		const parent = await this.loadParentCard(parent_id)

		const child = await this.loadStudentCard(student_id)

		return { parent, child }
	}

	loadStudentCard = async (student_id: string) => {
		return await oldAxios
			.get(`${this.host}/api/processes/student/card/${student_id}/`)
			.then((res) => res.data)
			.catch(() => null)
	}

	loadParentCard = async (parent_id: string) => {
		return await oldAxios
			.get(`${this.host}/api/processes/parent/card/${parent_id}/`)
			.then((res) => res.data)
			.catch(() => null)
	}
}
