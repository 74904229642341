import { ActionType, createAction } from 'typesafe-actions'


export const loadAncete = createAction('@ancete/load/start')<any>()
export const loadAnceteSuccess = createAction('@ancete/load/success')<any>()
export const loadAnceteError = createAction('@ancete/load/error')<any>()

export const updateAnceteError = createAction('@ancete/update/error')<any>()
export const updateAncete = createAction('@ancete/update/start')<any>()
export const updateAnceteSuccess = createAction('@ancete/update/success')<any>()

export const createAncete = createAction('@ancete/create/start')<any>()
export const createAnceteError = createAction('@ancete/create/error')<any>()
export const createAnceteSuccess = createAction('@ancete/create/success')<any>()


export type AnceteActions = ActionType<typeof loadAncete> | ActionType<typeof loadAnceteSuccess> | ActionType<typeof loadAnceteError> | ActionType<typeof updateAnceteError> | ActionType<typeof updateAncete> | ActionType<typeof updateAnceteSuccess> | ActionType<typeof createAncete> | ActionType<typeof createAnceteError> | ActionType<typeof createAnceteSuccess>;
