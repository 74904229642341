import { getType } from 'typesafe-actions'
import { FetchStatus } from '@/common/types'
import { Action } from '@/store/store'
import {
	loadProcessesMonitoring,
	onLoadProcessesMonitoringSuccess,
	onLoadProcessesMonitoringError,
	setProcessesMonitoringQuery,
} from '@store/actions/processesMonitoring'
import { ProcessessMonitoringState } from '@store/types/processessMonitoring'
import { persistReducer } from 'redux-persist'
import { indexedDBStore } from '@store/STORAGE_INDEXDB'

const processesMonitoringInitialState: ProcessessMonitoringState = {
	fetchStatus: FetchStatus.INITIAL,
	list: [],
	errors: [],
	query: {},
}

export const processesMonitoringReducer = (state = processesMonitoringInitialState, action: Action) => {
	switch (action.type) {
		case getType(loadProcessesMonitoring): {
			return {
				...state,
				fetchStatus: FetchStatus.FETCHING,
			}
		}
		case getType(onLoadProcessesMonitoringSuccess): {
			return {
				...state,
				list: action.payload.results,
				pagination: action.payload.pagination,
				fetchStatus: FetchStatus.FETCHED,
			}
		}
		case getType(onLoadProcessesMonitoringError): {
			return {
				...state,
				fetchStatus: FetchStatus.ERROR,
			}
		}
		case getType(setProcessesMonitoringQuery): {
			return {
				...state,
				query: action.payload,
			}
		}

		default: {
			return {
				...state,
			}
		}
	}
}

export const persistedProcessesMonitoringReducer: any = persistReducer(
	{
		key: 'AdminPanel::processes-monitoring',
		storage: indexedDBStore,
		whitelist: ['query'],
		version: 1,
	},
	processesMonitoringReducer,
)
