import { getType } from 'typesafe-actions'
import { FetchStatus } from '../../common/types'
import { loadConstants, loadConstantsSuccess } from '../actions/constants'
import { Action } from '../store'
import { ConstantsState } from '../types/constants'

const initialState = {
	fetchStatus: FetchStatus.FETCHING,
	constants: {},
	error: null,
}

export const constants = (state: ConstantsState = initialState, action: Action) => {

	switch (action.type) {
	case getType(loadConstants): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHING
		}
	}
	case getType(loadConstantsSuccess): {
		return {
			...state,
			fetchStatus: FetchStatus.FETCHED,
			constants: action.payload
		}
	}
	default: {
		return {
			...state
		}
	}
	}
}
