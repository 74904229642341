import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FetchStatus } from '../../common/types'
import { loadProfile } from '../../store/actions/profile'
import { loadStaffUsers, setStudentsStaffUsers } from '../../store/actions/staffUsers'
import { State } from '../../store/store'

import './StaffUsers.scss'

export const StaffUsers: React.FC<any> = ({ staffUsers: processStaffUsers, processId, students, onSubmit }) => {
	const { fetchStatus, users } = useSelector((store: State) => store.staffUsers)
	const { roles } = useSelector((store: State) => store.profile)
	const dispatch = useDispatch()
	const [checkedUsers, setCheckedUsers] = useState({})
	const [staffUsers, setStaffUsers] = useState(null)
	const [search, setSearch] = useState('')

	useEffect(() => {
		if (roles && roles.length && !users) {
			dispatch(loadStaffUsers({ roles: roles.join(',') }))
		}
	}, [roles])

	useEffect(() => {
		const newCheckedUsers = {}

		for (const id of processStaffUsers) {
			newCheckedUsers[id] = id
		}
		setCheckedUsers(newCheckedUsers)
	}, [processStaffUsers])

	useEffect(() => {
		if (!roles && !roles.length) {
			dispatch(loadProfile({}))
		}
	}, [])

	useEffect(() => {
		if (Array.isArray(users) && users.length) {
			setStaffUsers(users)
		}
	}, [users])

	const searchChangeCallback = useCallback((e) => {
		setSearch(e.target.value)
	}, [])

	useEffect(() => {
		if (fetchStatus === FetchStatus.FETCHED) {
			setStaffUsers(
				users.filter((user) => {
					const userName = `${user?.last_name ? user?.last_name : ''} ${
						user?.first_name ? user?.first_name : ''
					} ${user?.patronymic ? user?.patronymic : ''}`.toLowerCase()

					return userName.includes(search.toLowerCase())
				}),
			)
		}
	}, [search])

	const checkedUsersChangeCallback = useCallback(
		(userId) => {
			const isChecked = Boolean(checkedUsers[userId])
			const newCheckedUsers = JSON.parse(JSON.stringify(checkedUsers))

			if (isChecked) {
				delete newCheckedUsers[userId]
			} else {
				newCheckedUsers[userId] = userId
			}

			setCheckedUsers(newCheckedUsers)
		},
		[checkedUsers],
	)

	const submitClickHandler = () => {
		dispatch(setStudentsStaffUsers({ students: students, users: Object.values(checkedUsers) }))
		if (onSubmit) onSubmit()
	}

	return (
		<Col className="p-3">
			<Row className="mb-3">
				<Popover.Title as="h5">Пользователи в процессе</Popover.Title>
			</Row>
			<Col>
				<Row className="justify-content-between">
					<Form.Group style={{ width: '100%', marginBottom: 40 }}>
						<Form.Label>ФИО</Form.Label>
						<Form.Control
							placeholder="Введите ФИО пользователя"
							value={search}
							onChange={searchChangeCallback}
						/>
					</Form.Group>
				</Row>
				<Row style={{ maxHeight: 200, overflowY: 'scroll', marginBottom: 20 }}>
					<Col md={12}>
						{staffUsers &&
                            staffUsers
                            	.sort((aUser, bUser) => {
                            		const bIsChecked = Number(Boolean(checkedUsers[bUser.id]))
                            		const aIsChecked = Number(Boolean(checkedUsers[aUser.id]))

                            		return bIsChecked - aIsChecked
                            	})
                            	.map((user) => {
                            		const isChecked = Boolean(checkedUsers[user.id])

                            		return (
                            			<div
                            				style={{ cursor: 'pointer', paddingTop: 5, paddingBottom: 5 }}
                            				className="d-flex"
                            				key={user.id}
                            				onClick={() => checkedUsersChangeCallback(user.id)}
                            			>
                            				<div style={{ marginRight: 10 }}>
                            					<input type="checkbox" readOnly checked={isChecked} />
                            				</div>
                            				<div>
                            					{`${user?.last_name ? user?.last_name : ''} ${
                            						user?.first_name ? user?.first_name : ''
                            					} ${user?.patronymic ? user?.patronymic : ''}`}
                            				</div>
                            			</div>
                            		)
                            	})}
					</Col>
				</Row>
				<Row>
					<Button onClick={submitClickHandler}>Применить</Button>
				</Row>
			</Col>
		</Col>
	)
}
